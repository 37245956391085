/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  
  query MyQuery($youtubeLink: String!) {
    YoutubeAuth(youtubeLink: $youtubeLink) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
