/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-console */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
// MovieDetail.js

/*
 * IMPORT
 */
import React, { useState } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";

/*
 * CHAKRA UI IMPORT
 */
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
/*
 * QUERY
 */
/*
 * OBJECT
 */
const MovieDetail = ({ isOpen, onClose, movieDetails }) => {
  /*
   * STATE
   */
  // State to track playing state for each episode

  const [expandedSummary, setExpandedSummary] = useState({});

  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const textStyle = {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21.54px",
  };

  //   const videoBigTextStyle = {
  //     color: "#FFFFFF",
  //     fontFamily: "Poppins",
  //     fontWeight: "700",
  //     fontSize: "20px",
  //     lineHeight: "30px",
  //     letterSpacing: "0.2px",
  //   };


  /*
   * FUNCTION
   */

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody borderRadius="5px" bg="rgba(0, 2, 10, 1)" padding="0px">
          <Box
            borderRadius="10px"
            position="relative"
            overflow="hidden"
            bg="black"
            h="30rem"
          >
            <Image
              position="absolute"
              top="0"
              w="100%"
              h="30rem"
              objectFit="fit"
              src={movieDetails?.verticalPosterLink}
            />
            <Flex
              position="absolute"
              h="100%"
              w="100%"
              alignItems="center"
              bg="linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.66) 30.6%, rgba(0, 0, 0, 0.00) 103.12%)"
            >
              <Flex
                h="100%"
                flexDir="column"
                ml="2rem"
                justify="flex-end"
                gap="20px"
              >
                <Flex align="center" gap="1rem">
                  <IoCloseCircleSharp
                    color="#979898"
                    cursor="pointer"
                    onClick={onClose}
                    size="50px"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Flex
            direction="column"
            align="center"
            bg="rgba(0, 2, 10, 1)"
            justify="center"
          >
            <Flex
              m="15px 0px 15px 0px"
              borderRadius="10px"
              bg="rgba(39, 39, 39, 1)"
              w="90%"
              direction="column"
              justify="space-between"
              p="20px"
              gap="25px"
            >
              <Text
                color="rgba(255, 255, 255, 1)"
                fontFamily="Roboto"
                fontSize="20px"
                fontWeight="700"
                lineHeight="21.54px"
                letterSpacing="0.2px"
              >
                About Podcast
              </Text>
              <Flex w="100%" justify="space-between">
                <Flex justify="center" gap="20px" w="100%" direction="column">
                  {movieDetails?.directedBy && (
                    <Flex align="center" gap="8px">
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Directed By:
                      </span>
                      <Text
                        color="rgba(69, 211, 105, 1)"
                        fontFamily="Roboto"
                        fontSize="19px"
                        fontWeight="700"
                        lineHeight="21.54px"
                        letterSpacing="-0.36px"
                      >
                        {movieDetails?.directedBy}
                      </Text>
                    </Flex>
                  )}

                  {movieDetails?.producedBy && (
                    <Flex align="center" gap="8px">
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Produced By:
                      </span>
                      <Text
                        color="rgba(69, 211, 105, 1)"
                        fontFamily="Roboto"
                        fontSize="19px"
                        fontWeight="700"
                        lineHeight="21.54px"
                        letterSpacing="-0.36px"
                      >
                        {movieDetails?.producedBy}
                      </Text>
                    </Flex>
                  )}

                  {movieDetails?.host?.length > 0 && (
                    <Text style={textStyle}>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Host:
                      </span>
                      {"     "}
                      {movieDetails?.host?.map((item) => (
                        <span
                          key={item.id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.name},{" "}
                        </span>
                      ))}
                    </Text>
                  )}

                  {movieDetails?.guest?.length > 0 && (
                    <Text style={textStyle}>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Guest:
                      </span>{" "}
                      {movieDetails?.guest?.map((item) => (
                        <span
                          key={item.id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.name},{" "}
                        </span>
                      ))}
                    </Text>
                  )}

                  <Text style={textStyle}>
                    <Flex
                      fontWeight="800"
                      fontSize="20px"
                      align="center"
                      gap="8px"
                    >
                      Plot Summary{" "}
                    </Flex>
                    {expandedSummary[movieDetails?.id] ? (
                      <span>
                        {movieDetails?.PodcastDescription}
                        <Text
                          color="rgba(0, 129, 223, 1)"
                          cursor="pointer"
                          onClick={() => toggleSummary(movieDetails?.id)}
                        >
                          read less
                        </Text>
                      </span>
                    ) : (
                      <span>
                        {movieDetails?.PodcastDescription?.split(" ")
                          ?.slice(0, 20)
                          ?.join(" ")}
                        {movieDetails?.PodcastDescription.split(" ").length >
                          20 && (
                          <Text
                            color="rgba(0, 129, 223, 1)"
                            cursor="pointer"
                            onClick={() => toggleSummary(movieDetails?.id)}
                          >
                            read more
                          </Text>
                        )}
                      </span>
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              m="15px 0px 15px 0px"
              borderRadius="10px"
              bg="rgba(39, 39, 39, 1)"
              w="90%"
              direction="column"
              justify="space-between"
              p="20px"
            >
              <Flex
                mt="1rem"
                w="100%"
                justify="space-between"
                direction={{ base: "column-reverse", md: "row" }}
              >
                <Flex w="100%" gap="20px" direction="column">
                  <Flex align="center" gap="8px">
                    <Text
                      color="rgba(255, 255, 255, 1)"
                      fontFamily="Roboto"
                      fontSize="20px"
                      fontWeight="700"
                      lineHeight="21.54px"
                      letterSpacing="0.2px"
                    >
                      Podcast Episodes
                    </Text>
                  </Flex>

                  <Flex w="100%" direction="column" gap="30px">
                    {movieDetails?.PodcastEpisode?.map((item) => (
                      <Flex direction={{ base: "column", md: "row" }}>
                        <Text
                          color="rgba(186, 186, 186, 1)"
                          fontFamily="Roboto"
                          fontSize="15px"
                          fontWeight="400"
                          lineHeight="21.54px"
                          letterSpacing="-0.36px"
                        >
                          {formatDuration(item?.duration)}
                        </Text>
                        <Flex w="100%" direction="column" gap="10px">
                          <Flex justify="space-between" align="center">
                            <Text
                              w="80%"
                              color="rgba(255, 255, 255, 1)"
                              fontFamily="Roboto"
                              fontWeight="700"
                              fontSize="15px"
                              lineHeight="21.54px"
                              letterSpacing="0.14px"
                            >{`${item?.episodeTitle}`}</Text>
                            <Text
                              color="rgba(255, 255, 255, 1)"
                              fontFamily="Roboto"
                              fontWeight="700"
                              fontSize="14px"
                              lineHeight="21.54px"
                              letterSpacing="0.14px"
                              cursor="pointer"
                            >{`Episode ${item?.episodeNumber}`}</Text>
                          </Flex>

                          <Flex>
                            <Text
                              fontFamily="Roboto"
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight="28px"
                              color="rgba(255, 255, 255, 1)"
                              w="100%"
                            >
                              {expandedSummary[item.id] ? (
                                <span>
                                  {item.episodeDescription}
                                  <Text
                                    color="rgba(0, 129, 223, 1)"
                                    cursor="pointer"
                                    onClick={() => toggleSummary(item.id)}
                                  >
                                    read less
                                  </Text>
                                </span>
                              ) : (
                                <span>
                                  {item?.episodeDescription
                                    ?.split(" ")
                                    ?.slice(0, 20)
                                    ?.join(" ")}
                                  {item.episodeDescription?.split(" ").length >
                                    20 && (
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(item.id)}
                                    >
                                      read more
                                    </Text>
                                  )}
                                </span>
                              )}
                            </Text>
                          </Flex>

                          <audio
                            controls
                            controlsList="nodownload"
                            style={{
                              width: "100%",
                              backgroundColor: "#f0f0f0",
                              borderRadius: "5px",
                            }}
                          >
                            <source src={item.audioLink} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/*
 * EXPORT
 */
export default MovieDetail;
