/*
 * IMPORTS
 */
import LandingNavbar from "components/LandingNavbar";
import LandingFooter from "components/LandingFooter";
import React from "react";
import { Text, Flex } from "@chakra-ui/react";

const Privacy = () => {
  return (
    <Flex direction="column">
      <LandingNavbar />
      <Flex direction="column" p="3rem" pt="7rem">
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          {" "}
          PRIVACY POLICY A.{" "}
        </Text>
        PRIVACY POLICY A. This Privacy Policy (“Privacy Policy”) applies to the
        Shucae Films Pvt. Ltd.(“Shucae Films”) digital Site(s)/ App(s) platforms
        including without limitation www.shucaefilms.com and other related
        Site(s)/ App(s), mobile applications and other online features (each a
        “Site(s)/ App(s)”). The Site(s)/ App(s) are offered by SHUCAE FILMS and
        its subsidiaries or affiliated companies (collectively, “SHUCAE FILMS”
        or “we”, “us” and “our”). B. This Privacy Policy should be read in
        conjunction with the Terms of Use available on the Site(s)/ / App(s).
        Personal Information/Data/Information (defined below) of a user(s) is
        collected if the user(s) registers with the Site(s)/ App(s), accesses
        the Site(s)/ App(s) or takes any action on the Site(s)/ App(s) or uses
        or accesses the Services as defined in the Terms of Use. The terms
        'Personal Information' and 'Sensitive Personal Data or Information'
        shall have the meaning ascribed to it under the Indian Information
        Technology Act, 2000 read with the applicable rules, notifications, and
        amendments thereof. C. Please read this Privacy Policy carefully as your
        use of this Site(s)/ App(s) constitutes your consent to the processing
        of your Personal Information as described in the Privacy Policy. If you
        are younger than 18 years of age, and you wish to provide us with your
        Personal Information and/or Sensitive Personal Data or Information to
        use this Site(s) and/or App(s), you must read this Privacy Policy with
        your parent or guardian.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          {" "}
          SUMMARY OF KEY POINTS{" "}
        </Text>
        This Privacy Policy I explains how SHUCAE FILMS uses and protects
        Personal Information (defined below) of the users or subscribers of the
        Site(s) and/or App(s). This Privacy Policy I describes the usage of
        information provided or collected through Sites and App(s), where this
        Privacy Policy I is posted and affiliate or third-party sites /
        platforms through which the Site(s) and/or App(s) and/or any of its
        features and/or services available on such Site(s) and/or App(s) may be
        provided. We follow this Privacy Policy I in accordance with applicable
        law in the places where we operate. In some cases, we may provide
        additional data privacy notices specific to certain features or
        services. Those notices are to be read in combination with this Privacy
        Policy I. Please also keep in observance that our sites and applications
        may contain links to other sites not owned or controlled by us. Those
        sites are governed by their own respective privacy policies, and we are
        not responsible for the privacy practices of those sites. We encourage
        you to be aware when you leave our sites or applications and to read the
        privacy policies of other sites that may collect your Personal
        Information and/or Sensitive Personal Data or Information. SHUCAE FILMS
        will not be responsible for any damages and/or loss that you might
        suffer for accessing those sites. The services of SHUCAE FILMS are
        accessible globally and we may transfer your Personal Information from
        your respective jurisdiction to the location of hosting services
        (presently in India). We shall use your Personal Information where the
        processing is in our legitimate interests and not overridden by your
        fundamental rights and freedoms. Accordingly, by consenting to this
        Privacy Policy, you also provide consent to transfer your Personal
        Information to jurisdictions outside of your country of residence to
        service providers or such third-party recipients who provide us
        technology and hosting services in various locations (including India)
        for the purposes of this Privacy Policy. Whenever, your personal
        information is transferred, stored, or processed by us or our service
        providers or third parties, we may take reasonable steps to safeguard
        the Privacy of your personal information. A. We use Personal Information
        to allow you to participate in the features on the Site(s)/ App(s), to
        process your registration, and to provide you with other requested
        content related to our content and other offerings. We provide
        interactive features that engage with third-party social media site(s)/
        app(s), including without limitation Facebook, Instagram, Google, and
        Twitter. Any data that we obtain about your use of such features is
        subject to this Privacy Policy. Any data that the social media Site(s)/
        App(s) gather about you is subject to your terms with them. We use
        cookies and other technologies to track the use of our Site(s)/ App(s).
        We allow third parties to place such technologies on our Site(s)/ App(s)
        to allow them to display advertising and for other purposes. We may
        disclose your Personal Information to any of our employees, officers,
        insurers, professional advisers, agents, suppliers, or subcontractors,
        etc. insofar as necessary for the purposes set out in this Privacy
        Policy. We may also disclose Personal Information when you request or
        agree to such sharing with business partners or other third parties,
        when we engage a third-party to process data on our behalf, when such
        sharing is required by law, or in certain other situations. Your use of
        the Site(s)/ App(s) constitutes your consent to the transfer of Personal
        Information to any other jurisdiction that may not provide the same
        level of data protection as your home country. If you are located
        outside India and choose to provide information to us, please be aware
        that the SHUCAE FILMS Service is hosted primarily in India and that
        Platform keeps or transfers information to and from India for storage
        and processing. Although we strive to protect your Personal Information,
        we cannot guarantee the security of your data while it is being
        transmitted to outside India; any transmission is at your own risk. We
        will also ensure, on a commercially reasonable efforts basis, that any
        agent or third party that we employ complies with the same security
        standards as we do for the protection of your Personal Information.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          1. INFORMATION COVERED BY THIS PRIVACY POLICY
        </Text>
        (i) We may collect Personal Information/Information, including your
        name, email or street address, date of birth, telephone number, your
        preferences and interests, or other information you provide to us or
        grant us access to when you register for use of our Site(s)/ App(s),
        newsletters, communicate with us, or take part in interactive games,
        features or competitions. The Site(s)/ App(s) may also ask you to
        provide other information about yourself, such as demographic,
        geographic information (gender, zip code, age, etc.) or certain
        information about your preferences and interests. If we combine
        demographic or other information we collect about you with Personal
        Information about you, we will treat the combined information as
        personal information. Please note that information submitted to the
        Site(s)/ App(s) via a “contact us” or other similar function may not
        receive a response. We will not use the information provided to contact
        you for marketing purposes unrelated to your request unless you agree
        otherwise. (ii) Whenever you visit or interact with the Site(s)/ App(s)
        or SHUCAE FILMS content, SHUCAE FILMS, any third-party advertisers,
        content providers and/or service providers, may use a variety of
        technologies that automatically or passively collect information etc.
        about how the Site(s)/ App(s) are accessed and used (“Usage
        Information”). Usage Information may include, in part, browser type,
        operating system, the page served, the time, and the preceding page &
        video views etc. Usage Information is generally non-identifying, but if
        SHUCAE FILMS associates it with you as a specific and identifiable
        person, SHUCAE FILMS treats it as Personal Information. SHUCAE FILMS
        also automatically collects your IP address or other unique identifier
        (“Device Identifier”) from the computer, mobile device, technology or
        other device (collectively, “Device”) you use to access the Site(s)/
        App(s). A Device Identifier is a number that is automatically assigned
        to your Device when you access a Site(s)/ App(s) or its servers, and our
        computers identify your Device by its Device Identifier. Some mobile
        service providers or our third-party service provider may also provide
        us with information regarding the physical location of the Device used
        to access the Site(s)/ App(s). (iii) The technologies used on the
        Site(s)/ App(s) to collect Usage Information, including Device
        Identifiers, have been detailed herein below. We and certain third
        parties on our behalf may collect information about you and your use of
        our Platform, your interactions with us or our advertising or any direct
        marketing we send you, as well as information regarding your computer or
        other streaming device used to access our Site(s) and/or App(s). The
        information may be collected using tracking technologies such as
        cookies, Flash Cookies (defined below), pixels, tags, software
        development kits, application program interfaces, and Web beacons and
        may include: Your activity on the Site(s) and/or App(s) such as
        pages/titles viewed, load times, watch times, content selection and
        watch history, transactions initiated and/or completed, search queries
        and platform features used. This information may include the platform or
        website that you just visited (whether this platform is on Site(s)
        and/or App(s) or not), which platform or website you next visit. Device
        tokens for push notification (if you have enabled push notifications)
        Your interactions with our emails, communications, messages,
        advertisements on third party platforms and other channels; Your
        interactions with our customer support such as the date, time and reason
        for contacting us, transcripts of any chat conversations, and if you
        call us, your phone number and call recordings; Information about your
        general location, including location based on your mobile phone SIM card
        and/or IP address and, with your permission your precise location based
        on GPS data. In the event IP detection fails, we will collect the last
        cached location as your current location; Device and software
        information such as device ID, device model, platform, type of operating
        system, installed version, time zone setting, mobile carrier, screen
        resolution etc. and other unique identifiers. With your permission, we
        may also collect information pertaining to the applications frequently
        used by you on your device; Advertisement identifiers and data related
        to your interaction with the advertisement that are served on the
        Site(s) and/or App(s); We capture information related to the performance
        of the mobile application and the Site(s) and/or App(s), such as crash
        logs, build analytics and other performance statistics generated when
        you use the Site(s) and/or App(s).
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          2. COOKIES AND OTHER TRACKING TECHNOLOGIES
        </Text>
        (i) By using the Site(s)/ App(s), you consent to the use of cookies in
        accordance with the provisions of this clause. A cookie is a data file
        placed on a Device when it is used to visit the Site(s)/ App(s). A
        “Flash Cookie” is a data file placed on a Device via the Adobe Flash
        plug-in that may be built-into or downloaded by you to your Device.
        Cookies and Flash Cookies may be used for many purposes, including,
        without limitation, remembering you and your preferences and tracking
        your visits to our web pages. If you choose to disable cookies or Flash
        Cookies on your Device, some features of the Site(s)/ App(s) may not
        function properly. The use of cookies on the Site(s)/ App(s) allows you
        to enjoy more seamless visits and more accurately measures your
        behaviour on the Site(s)/ App(s). There are two types of cookies that we
        may use: session and persistent. Session cookies exist only during an
        online session. They disappear from your Device when you close the
        browser software. Persistent cookies remain on your Device after the
        browser has been closed. The cookies used on the Site(s)/ App(s) do not
        identify you personally. They merely recognize your browser, unless you
        choose to identify yourself voluntarily. You may choose to identify
        yourself for any one of the following reasons: by asking the browser to
        remember your username and password, by responding to a promotional
        offer, or by personalizing a web page or requesting more information on
        a product or service. (ii) Web beacons (also known as clear gifs) are
        used in combination with cookies to help Site(s)/ App(s) operators
        understand how you interact with their Site(s)/ App(s). A web beacon is
        typically a transparent graphic image (usually 1 pixel x 1 pixel) that
        is placed on a Site(s)/ App(s) or in an email. The use of a clear gif
        allows the Site(s)/ App(s) to measure your actions in opening the page
        that contains the clear gif. (iii) Embedded scripts are programming
        codes that are designed to collect information about your interactions
        with the Site(s)/ App(s), such as the links you click on. The code is
        temporarily downloaded onto your Device from our server or a third-party
        service provider, is active only while you are connected to the Site(s)/
        App(s), and is deactivated or deleted thereafter. (iv) SHUCAE FILMS may
        use cookies and web beacons for a number of purposes, including to: A.
        Provide general internal and customer analytics. B. Study traffic
        patterns in order to improve Site(s)/ App(s) performance, to customize
        the user experience, and to better match the users' interests and
        preferences. For example, SHUCAE FILMS keeps track of the domains from
        which people visit, and also measures/tracks visitor activity on SHUCAE
        FILMS Site(s)/ App(s). However, these tracking records are performed and
        maintained in ways that keep the data non-identifiable. C. Keep track of
        preferences you specify while you are using SHUCAE FILMS products or
        services. D. Support security measures, such as requiring re-login into
        an SHUCAE FILMS product or service, after a certain amount of time has
        elapsed. E. If you login to our products through the Site(s)/ App(s),
        cookies can also be used to save the username so that we can process the
        saved login information and quickly log you into the product. F. Assist
        in identifying possible fraudulent activities. G. Tell if an email has
        been opened and acted upon. (v) Controlling our use of Cookies: The
        Adobe Flash Player (“Flash”) is an application and web tool that allows
        rapid development of dynamic content. Flash (and similar applications)
        use technology to remember settings, preferences and usage similar to
        browser cookies, which may be used by SHUCAE FILMS. SHUCAE FILMS employs
        Adobe Flash cookies in certain situations where we use Flash to provide
        some content such as video clips or animation. SHUCAE FILMS does not use
        Flash Cookies to serve interest-based advertising. Flash Cookies are
        managed through the Flash Player Settings Manager, which is a different
        interface than the one provided by your web browser. This Settings
        Manager lets you manage global Privacy Settings, Storage Settings,
        Security Settings, and Automatic Notification Settings. This Site(s)/
        App(s) may also use Google Analytics, a web analysis service of Google
        Inc. (“Google”). Google Analytics uses cookies, text files that are
        stored on your Device and allows an analysis of your use of the Site(s)/
        App(s). If Google Analytics is used, the information generated by the
        cookie about your use of the Site(s)/ App(s) (including the IP address)
        will be transferred to and stored on a server of Google in the United
        States. Google will use such information to analyze your use of the
        Site(s)/ App(s), to create reports about the Site(s)/ App(s) activities
        for SHUCAE FILMS and to provide further services associated with the use
        of the Site(s)/ App(s) and the internet. Furthermore, Google may
        transfer such information to third parties, to the extent legally
        required or if such third parties process the data on behalf of Google.
        In no event will Google associate your IP address with other information
        of Google. By using this Site(s)/ App(s), you consent to the processing
        of your information collected by Google as described above and for the
        purposes described above. Google Analytics can be disabled through this
        link: http://tools.google.com/dlpage/gaoptout. SHUCAE FILMS at its sole
        discretion may employ any other third-party(ies) analytics services, as
        may be required.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          3. LOG FILE AND PAYMENT INFORMATION
        </Text>
        Our servers automatically collect limited information about your
        Devices' connection to the internet, including your IP address, Device
        and/or MAC ID, etc., when you visit our Site(s)/ App(s). Your IP address
        is a number that lets computers attached to the internet know where to
        send your data -- such as the web pages you view. Your IP address,
        Device and/or MAC ID, etc., does not identify you personally but may be
        combined with your Personal Information which then may identify you
        personally. When you purchase a subscription package using one or more
        of our payment gateway service providers, or any other online or mobile
        payment services provider we may use (including through any app stores),
        the following payment information will be collected: your credit/debit
        card details (number, card expiry date, CVV code) or other bank account
        details along with the date, time and amount of the transaction payment.
        This payment information is stored by our payment processors and
        associated with a merchant name/ID and location which our payment
        processor gives to us, together with a unique customer ID which we share
        with them but this payment information is never stored or processed by
        us. Our payment processors use this payment information to process and
        facilitate the payment of your subscription fee in order that we may
        provide you with the service you have requested or to enter into a
        contract with you to access the Site(s) and/or App(s) (in other words to
        perform the contract). So that we know you have paid your subscription
        fee our payment processors will send us a unique transaction ID which we
        associate with your unique customer ID and which we keep for internal
        accounting purposes because we have a legal obligation to do so. Our
        payment processors may use third party fraud detection software or
        providers which analyse your payment information in order to make
        automated decisions as to whether or not payment from you will be
        accepted. You have the right to contest any fraud decision made about
        you and to be given more information about why any such decision was
        made by contacting us. Where you pay a third party for your subscription
        package (e.g. an app store like Apple App Store) your payment
        information is directly collected and controlled by that third party.
        However, we receive invoice receipts from third parties for your
        subscription purchase and these receipts are stored by us as we have a
        legitimate business interest to keep them for internal accounting and
        tax purposes and to comply with our legal obligation as per any
        applicable laws including any audits, legal proceedings and/or
        investigations. Where our payment processors are providing payment
        processing services to us we put in place appropriate contracts to
        protect your personal information. For the following aspects of the
        payment processing our payment processor may act as a controller of your
        payment information (identified and listed above): providing the payment
        processing services to you at your request, monitoring, preventing and
        detecting fraudulent payment transactions, complying with legal or
        regulatory obligations which apply to the financial services sector and
        analysing, developing and improving their products and services. Our
        payment processors have their own privacy policies in place and we
        recommend you read those policies before you provide your payment
        information. In order to process any of your customer support queries
        related to payment processing, our payment processors give us access to
        the first 6 digits and last 4 digits of your credit/debit card
        (including any updates) so that we can perform the contract with you and
        provide you with a quick resolution of your queries. We also use the
        first 6 digits of your credit/debit card so that, on the basis of our
        legitimate business interest, we can analyse the most popular card types
        and issuing banks of our customer base to ensure that we are able to
        improve our user experience and provide you better service. Our payment
        processors will also pass us your expiry date after each payment
        transaction is completed and we will use this to send you an email
        reminder to update your payment information before your card expires as
        we believe we have a legitimate interest to make sure your access to the
        Site(s) and/or App(s) is not interrupted, you can still cancel at any
        time. However, at no point in time do we have access to or store the
        full credit/debit card number in our systems.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          4. HOW WE USE YOUR INFORMATION
        </Text>
        We will use Personal Information collected through the Site(s)/ App(s)
        to: (1) allow you to participate in the features we offer on the
        Site(s)/ App(s); (2) provide you with information that you request or
        agree to receive; (3) improve the Site(s)/ App(s) and for internal
        business purposes; (4) contact you with regards to your use of the
        Site(s)/ App(s), and any changes to our policies; (5) tailor content,
        advertisements and offers we serve you; and (6) fulfil other purposes
        disclosed at the time you provide your Personal Information or otherwise
        with your consent. We will only collect and process Personal Information
        about You where we have lawful basis to do so. Our lawful basis for
        processing your Personal Information will vary depending on your
        country, the context in which we collect and use it and the following
        examples give you as a guide as to which basis we think applies (but if
        the context changes or other circumstances arise then the lawful basis
        may also change). Our primary purpose in collecting your information,
        including Personal Information from you is to deliver the personalized
        service and its various features. Please note that should you choose to
        not provide us consent to collect your information, including Personal
        Information, we may not be able to perform the contract with you. Where
        we refer to 'perform the contract' we mean the Terms of Use applicable
        to your use of the Site(s) and/or App(s), which means that you may
        either not be able to access certain features of the Site(s) and/or
        App(s)or we may not be able to provide you the Site(s) and/or App(s) in
        entirety. For example, we use your information where we need the
        Personal Information to perform the contract with you (for example, to
        provide the Site(s) and/or App(s) or our services to you) so as to:
        Register you on our Site(s) and/or App(s) and create a subscription
        account, , Allow you to Sign Up/ Log In using social media accounts like
        Facebook ID, Apple ID, Google ID etc., Process your payment card details
        (as described in Section 3 above), Provide you with customer or
        technical support including addressing support queries related to, but
        not limited to, payment processing, or to diagnose any problems with our
        servers etc. Send you transactional emails or to provide you with
        information, direct marketing, online and offline advertising and other
        materials regarding products, services and other offers from time to
        time in connection with the Site(s) and/or App(s) or its parent,
        subsidiary and affiliated companies ("Site(s) and/or App(s) Entities")
        and its joint ventures. Determine your geographic location, to provide
        you localized content including advertisements, personalized viewing
        recommendations, allow you to continue watching previous content at
        another time or on another streaming device, and to determine your
        internet service provider to troubleshoot a technical issue, and help us
        quickly and efficiently respond to inquiries and requests; If you are
        traveling / visiting various countries around the world and avail the
        Site(s) and/or App(s)'s services, then we will determine your location
        using your IP address to determine the relevant Site(s) and/or App(s)
        which may be offered to you; To provide you with a personalised service
        and experience; we do this by linking some or all of your Personal
        Information with your activity on our Site(s) and/or App(s) across all
        your streaming devices and associating and storing this information with
        a unique platform generated identifier that is generated for each user
        (guest as well as registered user). When a guest user subsequently
        registers and creates an account, the information and data relating to
        such user's past activities gets added to and associated to the
        registered account. In other contexts, we use your Personal Information
        where the processing is in our legitimate interests and not overridden
        by your fundamental rights and freedoms (for example, our direct
        marketing activities in accordance with your preferences) such as to:
        Display or target advertisements on the Site(s) and/or App(s) based on
        your registration or profile information, location and/or your activity
        on the Site(s) and/or App(s) across all your streaming devices
        (including inferred information through usage and log data) which is
        associated with your device advertising identifier – this is typically
        referred to as 'interest- based advertising'. We also display contextual
        advertisements on the Site(s) and/or App(s) but these are not targeted
        to any particular user and does not make use of any information that
        personally identifies you or precisely locates you (contextual
        advertising is advertising which we target based on the content being
        viewed, for example if you are watching a particular sporting event we
        may serve adverts of companies or products related to that particular
        sport or the age-group most likely to be watching). Research, analyze
        and understand our audience and what content and advertising they may
        view, watch or otherwise engage with, in order to improve our service
        (including our user interface experiences and security features) and
        optimize content selection i.e. recommend or make it easier for you to
        find content we think you will enjoy; evaluate your eligibility for
        certain types of offers, products or services and market such products
        or services to you; to perform analytics and conduct customer research,
        including general market research or surveying our customers' needs and
        opinions on specific issues, generating sales and traffic patterns, and
        to analyze advertising effectiveness, both on an anonymous basis (e.g.,
        by aggregating data) or on an individual basis (if legally permissible),
        in combination with other information collected from tracking
        technologies and aggregate it with information collected from other
        Users using our Site(s) and/or App(s), to attempt to provide you with
        the Services and a better experience on the Site(s) and/or App(s). For
        example, one of the ways we use this information is to count the number
        of times an advert or other content is viewed because this might be the
        metric by which our advertisers pay us. This information is compiled and
        analyzed on an aggregated basis. Allow you to participate in interactive
        features such as Game play events on the Site(s) and/or App(s),
        providing you with relevant gameplay experience, determine and declaring
        winners and providing you with prizes. Analyse and measure how effective
        our advertising is by determining which media sources should be paid for
        delivering a desired action e.g. users who install our application or
        subscribe (also known as 'attribution'), so that we pay for demonstrated
        value and don't overspend. Our affiliate and group companies may access
        and use your information where they perform services on our behalf and,
        unless prohibited under applicable law, for use on their own behalf to
        market Site(s) and/or App(s) services, our other services, and the
        services and products generally offered by Our affiliate and group
        companies. We will use your Personal Information as follows: When you
        agree to share your precise location based on GPS data, we will combine
        this information with other information that you provide to us on the
        Site(s) and/or App(s) Service to provide personalised content and
        advertisements with better accuracy basis your geographic location e.g.
        you may see more regional content recommendations or advertisements for
        local establishments, when you share your precise location based on GPS
        data; When you agree to share information pertaining to the applications
        frequently used by you on your device, we will combine this information
        with other information that you provide to us on the Site(s) and/or
        App(s) Service, to display advertisements that are more relevant to your
        interest areas e.g. if you frequently use applications related to
        sporting events, we may serve advertisements of companies or products
        related to sporting events; and When you agree to share information
        pertaining to the content you are exposed on the television, we may use
        this information along with other information that you provide us on the
        Site(s) and/or App(s) Service, to provide you with personalised content
        similar to those you watch on television. We may also use this
        information to serve advertisements more relevant to you as well to
        understand and analyse your exposure to advertisements on other
        platforms to demonstrate the effectiveness of our Site(s) and/or App(s).
        In some contexts, we use your Personal Information where we have a legal
        obligation to do so, or to protect your vital interests or those of
        another person: Prevent, detect and investigate potentially prohibited
        or illegal activities, including fraud, and enforcing our terms (such as
        determining free trial eligibility); Confirming your identity if you are
        exercising legal rights; Notify you about changes in the Terms of Use or
        in this Privacy Policy; Carry out our obligations and enforce our rights
        arising from any contracts entered into between you and us/our
        distributor. When you access or use the Services or send any data,
        query, request or communication to us via our Support email, you
        understand that you are communicating with us electronically, and that
        we may respond via electronic means from us, such as by email.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          5. HOW WE SHARE YOUR PERSONAL INFORMATION
        </Text>
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          5.1. WHEN YOU AGREE TO HAVE YOUR PERSONAL INFORMATION SHARED
        </Text>
        While on our Site(s)/ App(s), you may have the opportunity to opt-in to
        receive information and/or marketing offers from someone else or to
        otherwise consent to the sharing of your Personal Information with a
        third-party. If you agree to have your Personal Information shared, your
        Personal Information will be disclosed to the third-party and the
        Personal Information you disclose will be subject to the Privacy Policy
        and business practices of that third-party. You may also specifically
        request SHUCAE FILMS, sometimes, through your use of an interactive
        feature, a widget or an application, that we share your Personal
        Information with a third-party and we will typically do so under those
        circumstances. In addition, if you choose to register for a promotion,
        such as a competition, prize draw or contest, your Personal Information
        may be disclosed to third parties for the purposes of administration of
        such promotions.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          5.2. FOR SWEEPSTAKES, CONTESTS AND PROMOTIONS
        </Text>
        The Site(s)/ App(s) may offer sweepstakes, contests, and other
        promotions (each, a “Promotion”) that may require registration. If you
        choose to enter a Promotion, your Personal Information may be disclosed
        to third parties in connection with the administration of such
        Promotion, such as in connection with winner selection, prize
        fulfilment, and as required by law, such as on a winners list. By
        entering a Promotion, you are agreeing to the official rules that govern
        that Promotion, which may contain specific requirements from you,
        including, except where prohibited by law, allowing the sponsor and/or
        other parties to use your name, voice and/or likeness in advertising or
        marketing materials.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          5.3. ON SITE(S)/APP(S) THIRD PARTIES PROVIDING SERVICES ON OUR BEHALF
        </Text>
        We may engage any third parties to perform functions on our behalf, such
        as hosting or operating our Site(s)/ App(s), sending e-mail
        communications, and data analysis. We may share Personal Information
        with third parties in order for them to provide content or otherwise
        perform services for us, and we will require such parties to maintain
        appropriate security to protect such data from unauthorized access or
        processing. It being clarified that in no event shall SHUCAE FILMS, or
        any of its directors, employees, or any representatives, be held liable
        for use of such Personal Information by itself and the aforementioned
        third parties. At times we may make your Personal Information available
        to our service providers (such as but not limited to: marketing,
        analytics, research, communication, advertisements, infrastructure and
        IT services, technology or software providers, payment processing and
        other service providers) that work with us or assist us to provide
        services to you or license us software which we include in our Site(s)
        and/or App(s). Personal Information will only be shared with our service
        providers to provide or improve the Site(s) and/or App(s) Service and to
        enable us to analyse our marketing efforts. We do not authorize our
        service providers to use or disclose your Personal Information except in
        connection with providing their services. We or our service providers
        may also share your advertisement identifiers with advertising / data
        exchanges, and marketing analytics and other digital advertising and
        marketing service providers. The advertisement identifiers are used by
        these exchanges and service providers to decide which ads you see both
        on our sites and applications and on third-party sites and applications.
        We may offer joint promotions that, in order for you to participate and
        benefit from any promotional offer, will require us to share your
        Personal Information with third parties so that we co-ordinate and
        fulfil the incentive offered. These third parties are responsible for
        their own privacy practices. In the event of any requirement by court
        order, government or quasi-government agency to disclose your Personal
        Information, we will disclose information as may be legally required. We
        may also disclose your Personal Information if we, in the good faith
        believe that such disclosure is reasonably necessary to respond to
        subpoenas, court orders, or other legal process.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          5.4. FOR AFFILIATE SHARING AND BUSINESS TRANSFERS
        </Text>
        We may share your Personal Information with other entities (group
        entities and/ or affiliates) for internal reasons, primarily for
        business and operational purposes. SHUCAE FILMS or any of its assets,
        including the Site(s)/ App(s), may be sold, or other transactions may
        occur in which your Personal Information is one of the business assets
        of the transaction. In such case, your Personal Information may be
        transferred. Hence, SHUCAE FILMS reserves the right to disclose and
        transfer user information, including Personal Information in connection
        with a corporate merger, consolidation, restructuring, the sale of
        certain of SHUCAE FILMS's assets, or other corporate change including,
        without limitation, during the course of any due diligence process.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          5.5. POTENTIAL INVESTORS
        </Text>
        Certain investors may be interested in investing funds in SHUCAE FILMS.
        For the purposes of securing such investment, SHUCAE FILMS may be
        required to disclose data pertaining to your Personal Information or to
        your Usage Information. We will require such parties to maintain
        appropriate security to protect such data from unauthorized access or
        processing. It being clarified that in no event shall SHUCAE FILMS be
        held liable for use of such Personal Information/ Information by itself
        and the aforementioned third parties.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          6. LEGAL PROTECTIONS AND OTHER DISCLOSURES
        </Text>
        We may transfer and disclose information, including your Personal
        Information, Usage Information and Device Identifier (including IP
        address, Device and/or MAC ID, etc), to third parties: to comply with a
        legal obligation; when we believe in good faith that the law requires
        it; at the request of governmental authorities conducting an
        investigation; to verify or enforce the Site(s)/ App(s)'s Terms of Use
        or other applicable policies; to respond to an emergency; or otherwise
        to protect the rights, property, safety, or security of third parties,
        visitors to this Site(s)/ App(s) or the public. Pursuant to valid
        requests by law enforcement agencies, we may also use Device Identifiers
        to identify users, and may do so in cooperation with copyright owners,
        internet service providers, wireless service providers or law
        enforcement agencies, at our discretion. Such disclosures may be carried
        out without notice to you.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          7.LINKS TO OTHER SITE(S)/ APP(S) AND SERVICES, AND SHUCAE FILMS
          CONTENT FOUND ON OTHER SITE(S)/ APP(S)
        </Text>
        The Site(s)/ App(s) may provide links to other Site(s)/ App(s)
        applications. This includes links from advertisers, sponsors and
        partners that may use our logo(s) as part of a co-branding agreement.
        Certain content on the Site(s)/ App(s) may be hosted and served by third
        parties, which may include third-party widgets (such as those that allow
        you to “like” or “share” content with third-party Site(s)/ App(s) and
        interactive services, or third-party “get tickets and showtimes”
        widgets). The use of these third-party Site(s)/ App(s) applications and
        any information collected, stored and transferred by them is not subject
        to this Privacy Policy and is governed by their own terms of use and
        Privacy Policy. By clicking through to these third-party Site(s)/ App(s)
        applications, you will be governed by such third- party's Privacy
        Policy/ Terms of Use. We have no control over these third parties. These
        third parties may send their own cookies to your Device, they may
        independently collect data or solicit Personal Information and may or
        may not have their own published privacy policies. SHUCAE FILMS is not
        responsible for the privacy practices or business practices of any
        third-party Site(s)/ App(s). SHUCAE FILMS may also display its content/
        widgets on third-party Site(s)/ App(s) /Application(s). Please note that
        in such an eventuality, your use of the third-party Site(s)/ App(s)/
        Application(s) shall continue to be governed by the third-party's
        Privacy Policy/ Terms of Use. We have no control over these third
        parties. These third parties may send their own cookies to your Device,
        they may independently collect data or solicit Personal Information and
        may or may not have their own published Privacy Policies. SHUCAE FILMS
        is not responsible for the privacy practices or business practices of
        any third-party Site(s)/ App(s).
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          8. PERSONAL INFORMATION YOU DISCLOSE PUBLICLY ON OUR SITE(S)/ APP(S)
        </Text>
        Our Site(s)/ App(s) may offer message boards, chat rooms, alternative
        reality games, multi-player games and other public areas (collectively,
        “Community Forums”) where you can engage in social networking, interact
        with others, as well as have the opportunity to submit photographs,
        writings, music, video, audio recordings, computer graphics, pictures,
        data, questions, comments, suggestions or other content, including
        Personal Information (collectively, “User Submissions”). To participate
        in certain Community Forums, you may be asked to select a username,
        screen name or member name and password. Furthermore, any such User
        Submissions that you publish on our Site(s)/ App(s) or submit for
        publication on our Site(s)/ App(s) may be available, via the internet,
        around the world, and we cannot prevent the use or misuse of such
        information by others.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          9. NOTE THAT ANYTHING YOU POST TO A COMMUNITY FORUM IS PUBLIC
        </Text>
        User Submission(s) may be used or shared with any third parties.
        Information you choose to post or make available in the Community Forums
        may be viewed, collected and used by others and we shall have no control
        over the manner and mode of such use. If you choose to voluntarily
        disclose User Submissions, the protections of this Privacy Policy will
        not cover such disclosure of information. Think carefully before you
        post and use caution before disclosing any Personal Information. Also,
        functionality on the Site(s)/ App(s) may permit interactions between the
        Site(s)/ App(s) and a third-party Site(s)/ App(s) or service, such as
        enabling you to “like” a product on our Site(s)/ App(s) or “share”
        content to other Site(s)/ App(s) or services. If you choose to “like” or
        “share” content on the Site(s)/ App(s) or to otherwise post information
        from or via the Site(s)/ App(s) to a third-party's Site(s)/ App(s),
        service, feature or application, etc. that information will become
        public and the third- party may have access to information about you and
        your use of our Site(s)/ App(s). User Submissions may be published
        online or offline in any media or format (currently existing or
        hereafter developed). We may also publish your name, voice, likeness and
        other Personal Information with your User Submissions, and SHUCAE FILMS
        may use the content, or any portion of the content, for advertising,
        marketing, publicity, promotional or any other activities. For full
        terms and conditions regarding User Submissions you submit to the
        Site(s)/ App(s), please refer to our Terms of Use.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          10. INFORMATION WE RECEIVE FROM THIRD PARTIES
        </Text>
        SHUCAE FILMS may receive information about you from third parties. For
        example, if you are on another Site(s)/ App(s) and you opt-in to receive
        information from SHUCAE FILMS that Site(s)/ App(s) will forward to us
        your email address and other information about you so that we may
        contact you as requested. You may also choose to participate in a
        third-party application or feature on our Site(s)/ App(s) (such as
        logging in through Facebook Connect or otherwise linking the Site(s)/
        App(s) with another Site(s)/ App(s) or interactive service) or on a
        third-party Site(s)/ App(s) or service (such as one of our Facebook
        applications or a similar application or feature on a third-party
        Site(s)/ App(s) or service) through which you allow us to collect (or
        the third-party to share) information about you, including Personal
        Information. When you choose to participate, you may be opting to link
        your activity on our Site(s)/ App(s) with that third-party Site(s)/
        App(s) or service, which may then collect information about your visits
        to our Site(s)/ App(s) and may publish that activity as you request to
        your “profile” or similar feature with that third-party (such as if you
        choose to share content you find on one of our Site(s)/ App(s) with your
        “connections” on the third-party's Site(s)/ App(s) or service). The
        information we collect is subject to this Privacy Policy. However, the
        information collected and stored by the third-party remains subject to
        the third-party's privacy practices, including whether the third- party
        continues to share information with us or with other third parties, the
        types of information shared, and your choices with regard to what is
        visible to others on that third-party's Site(s)/ App(s) or service. The
        third-party may allow you to remove the application or feature, in which
        case we will no longer collect information about you through the
        application or feature, but we may retain the information previously
        collected. In addition, we may receive information about you if other
        users of a third-party Site(s)/ App(s) or service give us access to
        their profiles and you are one of their “connections” or information
        about you is otherwise accessible through your “connections” web page,
        profile page, or similar page on a social networking or other
        third-party Site(s)/ App(s) or interactive service. We may also
        supplement the information we collect about you through the Site(s)/
        App(s) with outside records from third parties in order to enhance our
        ability to serve you, to tailor our content to you and to offer you
        opportunities to purchase products or services that we believe may be of
        interest to you. We may combine the information we receive from these
        third parties with information we collect through the Site(s)/ App(s).
        In those cases, we will apply this Privacy Policy to any Personal
        Information received, unless we have disclosed otherwise.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          11. WE MAY USE AD SERVING AND TRAFFIC MEASUREMENT SERVICES; HOW TO
          “OPT OUT” OF RECEIVING ADVERTISING RELEVANT TO YOU
        </Text>
        SHUCAE FILMS licenses technology to serve advertisements on its own
        Site(s)/ App(s) and within its videos and other content as that content
        is served across the internet. In addition, SHUCAE FILMS may use
        third-party network advertisers to serve advertisements and third-party
        analytics service providers to evaluate and provide us with information
        about the use of the Site(s)/ App(s) and viewing of our content. We do
        not share Personal Information with these third parties, but ad network
        providers, the advertisers, the sponsors, and/or analytics service
        providers may set and access their own cookies, web beacons and embedded
        scripts on your Device and they may otherwise collect or have access to
        information about you, including Usage Information. With regard to
        advertisements, we and our network advertisers may target advertisements
        for products and services in which you might be interested based on your
        visits to both the Site(s)/ App(s) and other Site(s)/ App(s). Cookies
        and web beacons, including those set by third-party network advertisers,
        may be used to, among other things, target advertisements, prevent you
        from seeing the same advertisements too many times, or to conduct
        research regarding the usefulness of certain advertisements to you. We
        may share Usage Information about visitors to the Site(s)/ App(s) with
        third-party advertising companies for the same purposes. We may use a
        variety of companies to serve advertisements.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          12. SECURITY
        </Text>
        We are continuously implementing and updating administrative, technical,
        and physical security measures to help protect your Personal Information
        against unauthorized access, loss, destruction, or alteration. Some of
        the safeguards we use to protect your information are firewalls and data
        encryption, and information access controls. These measures are designed
        to provide a level of security appropriate to the risks of processing
        your Personal Information. If you know or have reason to believe that
        your account credentials have been lost, stolen, altered, or otherwise
        compromised or in case of any actual or suspected unauthorized use of
        your account, please (See Contact Information below). The Site(s) and/or
        App(s) Service uses commercially reasonable security practices and
        procedures industry practices and procedures designed to help keep your
        Personal Information safe and secure. Unfortunately, the transmission of
        information over the Internet is not completely secure and protection of
        the electronic data transmissions or storage of information cannot be
        guaranteed completely. Although we strive to protect your Personal
        Information in our best efforts, you expressly understand and agree that
        full guarantee for the same cannot be provided for such transmission of
        data to our Site(s) and/ or App(s), and the information provided to us
        shall be at your own informed risk.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          13. PERSONAL INFORMATION SITE(S)/ APP(S) AMENDING OR REMOVING YOUR
          PERSONAL INFORMATION, CONTROLLING MARKETING COMMUNICATIONS, AND
          PRIVACY QUESTIONS
        </Text>
        You have the right to access your Personal Information held by us and,
        if necessary, have it amended or deleted. You can also request not to
        receive email, SMS, and MMS marketing communications from us. You may
        opt out of receiving email newsletters and other marketing
        communications by following the opt-out instructions provided to you in
        those emails. If you sign up to receive SMS or MMS messages, you may
        unsubscribe from any SMS or MMS messages received by replying “STOP”.
        Please note that we may still need to send you certain communications
        relating to your account or use of the Site(s)/ App(s) and these
        transactional account messages may be unaffected if you choose to
        opt-out from marketing communications. For more details or any privacy
        questions and/or complaints please contact us by email at
        contact.us@Shucae Films.co.in (name and contact no. of the Grievance
        office not mentioned. Who) or write to us at: Legal Department
        <Flex direction="column">
          {" "}
          <Text fontWeight="bold" fontSize="1rem">
            Shucae Films Private Limited
          </Text>{" "}
          <Text fontWeight="bold" fontSize="1rem">
            C/o Vidya Sagar Dogra – Banyorka (Dhruman) – Ropri-Sarkaghat- Mandi
            (H.P) 175024
          </Text>{" "}
          <Text fontWeight="bold" fontSize="1rem">
            We request you to please provide the following information in your
            grievance and/or complaint:{" "}
          </Text>
        </Flex>
        Identification of the information provided by you. Clear statement as to
        whether the information is Personal Information or sensitive Personal
        Information. Your address, telephone number or email address. A
        statement that you have a good-faith belief that the information has
        been processed incorrectly or disclosed without authorization, as the
        case may be. A statement, under penalty of perjury, that the information
        in the notice is accurate, and that the information being complained
        about belongs to you. You may also contact us in case you have any
        questions / suggestions about the Privacy Policy using the contact
        information mentioned above.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          14. NOTIFICATION OF CHANGES AND YOUR ACCEPTANCE OF THE PRIVACY POLICY
        </Text>
        We reserve the right to change the terms of this Privacy Policy at any
        time, without notice, by prominently posting notice of the amendment on
        this Site(s)/ App(s). To the extent permitted by applicable law, such
        changes will be applicable from the time they are posted. SHUCAE FILMS
        will use your Personal Information in a manner consistent with the
        Privacy Policy in effect at the time you submitted the information.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          15. COMPLIANCE WITH LAWS AND LAW ENFORCEMENT
        </Text>
        We cooperate with mandated government and law enforcement agencies or to
        any third parties by an order under law for the time being in force to
        enforce and comply with the law. We will also disclose any information
        including Personal Information or Information about you to government
        and law enforcement agencies or private parties without your permission
        as we, in our sole discretion, believe necessary or appropriate to
        respond to valid claims and legal process, to protect the property and
        rights of Site(s)/ App(s) or a third-party, to protect the safety of the
        public or any person, or to prevent or stop any illegal, unethical or
        legally actionable activity. Site(s)/ App(s) may also provide your
        Personal Information including Sensitive Personal Information or
        Information to various authorities upon any demand or request from them.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          16. EMAILS
        </Text>
        We may use your Personal Information or Information for the
        aforementioned purposes of the services/Site(s)/ App(s). You have full
        control regarding which emails you want to receive. If you decide at any
        time that you no longer wish to receive such communications from us,
        please follow the instructions to unsubscribe, as provided in any of the
        communications. Please note that once we receive your request, it may
        take an additional period of time for your opt-out to become effective.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          17. PHISHING
        </Text>
        Identity theft and the practice currently known as “phishing” are of
        great concern to us. Safeguarding information to help protect you from
        identity theft is a top priority. We do not and will not, at any time,
        request your credit card information or national identification numbers
        in a non-secure or unsolicited email or telephone communication.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          18. GRIEVANCE OFFICER
        </Text>
        The grievance officer for the purpose of this Privacy Policy is already
        designated. If you have any questions or grievance regarding this
        Privacy Policy, or want to delete or modify your Personal Information,
        you shall mark your correspondence (given under the heading “Contact
        Us”) in the name of contact.us@shucaefilms.com. Any communication which
        is not marked as such shall not be treated as a valid and binding
        communication for the purpose of this Privacy Policy and applicable laws
        with respect to this Privacy Policy.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          19. APPLICABLE LAWS AND JURISDICTION
        </Text>
        The present policy shall be governed by the applicable laws of India and
        the courts of Shimla (Himachal), India only, shall have the exclusive
        jurisdiction to deal with matters relating to this Privacy Policy.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          20. MISCELLANEOUS
        </Text>
        The Terms of Use (as provided on the Site(s)/ App(s)), and any other
        documents, instructions, etc. included on the Site(s)/ App(s) shall be
        treated as co-terminus shall be read in conjunction with and shall be an
        integral part of these Privacy Policy.
        <Text fontWeight="bold" fontSize="2rem" m="10px 0">
          21. LIMITED USE DISCLOSURE:
        </Text>
        Our application respects user privacy and handles all Google user data
        in strict accordance with the Limited Use requirements set by Google's
        API Services User Data Policy. We commit to providing this information
        transparently to all users. Limiting our use of Google user data to the
        scope necessary for app functionality. Not transferring this data to any
        third parties unless it is necessary for app functionality or as
        required by law. Ensuring that the handling of data adheres strictly to
        the permissions granted by users. For detailed information on how we
        comply with these requirements and to understand your rights as a user,
        please visit the{" "}
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy"
          style={{ color: "blue" }}
          target="_blank" rel="noreferrer"
        >
          Google API Services User Data Policy
        </a>
        .
      </Flex>

      <LandingFooter />
    </Flex>
  );
};

/*
 * EXPORT
 */
export default Privacy;
