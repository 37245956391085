/*
 * IMPORTS
 */
import React from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import Loader from "Views/User/website/VideoPlayPage/Loader";
import CardComponent from "../SearchContent/CardComponent";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
/*
 * CHAKRA IMPORTS
 */
import { Flex, Box } from "@chakra-ui/react";

/*
 * QUERY
 */
import SpecificCategoryRead from "./__query__/index.trayVideos.query";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */

  const { title } = useParams();

  const _SpecificCategoryRead = useQuery(SpecificCategoryRead, {
    variables: {
      name: title?.replace(/-/g, " "),
    },
  });

  /*
   * FUNCTION
   */
  const itemList = _SpecificCategoryRead?.data?.SpecificCategoryRead?.map(
    (item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      url: `https://www.shucaefilms.com/video-play-page/${item?.contentTitle?.replace(
        / /g,
        "-"
      )}/${item?.ContentDetail?.[0]?.id}`,
      name: item?.contentTitle,
      image: item?.ContentDetail?.[0]?.lowerThumbnailLink,
    })
  );

  const breadcrumbList = _SpecificCategoryRead?.data?.SpecificCategoryRead?.map(
    (item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item?.contentTitle,
      item: `https://www.shucaefilms.com/video-play-page/${item?.contentTitle?.replace(
        / /g,
        "-"
      )}/${item?.ContentDetail?.[0]?.id}`,
    })
  );

  /*
   * USEEFFECT
   */

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            name: `Watch ${title} Films Here`,
            itemListElement: itemList,
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: breadcrumbList,
          })}
        </script>
      </Helmet>

      <Box bg="#00020A">
        <Navbar />

        <Box
          // bg="rgba(22, 7, 39, .4)"
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          pt={{ base: "90px", md: "70px", lg: "30px" }}
          fontFamily="Poppins"
          fontSize={["clamp(12px, 1.5vw, 15px)"]}
          fontWeight="500"
          color="#263238"
          overflow="hidden"
        >
          <Flex
            ml={{ base: "1rem", md: "3rem" }}
            color="white"
            fontSize={{ base: "20px", md: "40px" }}
            pt="5rem"
          >
            {`${title}`}
          </Flex>
          <Flex
            pb="8rem"
            gap={{ base: "2rem", md: "2rem" }}
            flexWrap="wrap"
            justify="center"
          >
            {_SpecificCategoryRead?.loading ? (
              <Flex justify="center" w="100%">
                <Loader />
              </Flex>
            ) : _SpecificCategoryRead?.data?.SpecificCategoryRead?.[0]
                ?.status === "NO_CONTENT" ? (
              <Flex
                mt="5rem"
                justify="center"
                w="100%"
                color="white"
                fontSize={{ base: "20px", md: "50px" }}
              >
                No content available.
              </Flex>
            ) : (
              <>
                {_SpecificCategoryRead?.data?.SpecificCategoryRead?.map(
                  (item, index) => (
                    <CardComponent item={item} />
                  )
                )}
              </>
            )}
          </Flex>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
