/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query GoogleAccountLoginQuery($route: String = "signin" , $scopes:[String] = []) {
    GoogleAccountLogin(route: $route , scopes: $scopes) {
      message
      status
      token
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
