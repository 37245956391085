/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($brandName: String, $location: String) {
    SpecialBrandFreeSubscriptionCreate(
      brandName: $brandName
      location: $location
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
