/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import bannerUpload from "../../../../Assets/images/profilePage/bannerUpload.svg";
import { useHistory } from "react-router-dom";
import UploadContent from "../../../../components/UploadContent/UploadContent";
import _ from "underscore";
import { tabStyle } from "components/Styles/index.style";
import Videos from "./Videos";
import ManageVideos from "./ManageVideos";
import Stories from "react-insta-stories";
import { MdWorkspacePremium } from "react-icons/md";

/*
 * REACT-ICONS IMPORT
 */
import { MdEdit } from "react-icons/md";
import { IoClose } from "react-icons/io5";

/*
 * CHAKRA IMPORTS
 */
import {
  Image,
  Box,
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Avatar,
  Spinner,
  InputGroup,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import FollowLikeAppreciationRead from "./__query__/index.profile.query";
import AccountDetail from "./__query__/index.profileDetail.query";
import FollowingList from "../../website/__query__/index.following.list";
import FollowersList from "../../website/__query__/index.followers.list";

/*
 * MUTATAION
 */
import ContentPerUserFetch from "./__mutation__/index.perUser.mutation";
import AccountCoverUpload from "./__mutation__/index.bannerUpload.mutation";
import AccountCoverRemove from "./__mutation__/index.bannerRemove.mutation";
import AccountStoryRemove from "./__mutation__/index.storyRemove.mutation";
import { useMutation, useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.

import FollowerCard from "components/CreatorProfile/FollowerCard";
import FollowingCard from "../UserProfilePage/FollowingCard";
/*
 * OBJECT
 */
function ProfilePage({ account }) {
  /*
   * States
   */
  const history = useHistory();
  const toast = useToast();
  const [tabState, setTabState] = useState(true);
  const [perUser, setPerUser] = useState();
  const [banner, setBanner] = useState();
  const [showStory, setShowStory] = useState(false);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFollowing,
    onOpen: onOpenFollowing,
    onClose: onCloseFollowing,
  } = useDisclosure();

  const _FollowersList = useQuery(FollowersList, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const _FollowingList = useQuery(FollowingList, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });
  const [AccountStoryRemoveMutation, { loading: loadingAccountStoryRemove }] =
    useMutation(AccountStoryRemove, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });
  const [ContentPerUserFetchMutation, { loading: loadingPerUser }] =
    useMutation(ContentPerUserFetch, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });
  const _FollowLikeAppreciationRead = useQuery(FollowLikeAppreciationRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const _AccountDetail = useQuery(AccountDetail, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [AccountCoverRemoveMutation, { loading: loadingRemoveBanner }] =
    useMutation(AccountCoverRemove, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [AccountCoverUploadMutation, { loading: loadingBannerUpload }] =
    useMutation(AccountCoverUpload, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * Functions
   */

  const storiesData = _AccountDetail?.data?.AccountDetail?.Stories || [];
  const stories = storiesData.map((story) => ({
    url: story.profileStoryLink,
    id: story.id,
  }));

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      handleBannerUpload(file);
      setBanner({
        banner: file,
      });
    }
  };
  const handlePerUser = async () => {
    try {
      const response = await ContentPerUserFetchMutation({
        variables: {},
      });

      const data = response.data.ContentPerUserFetch;

      if (!_.isEmpty(data)) {
        setPerUser(data);
      }
    } catch (error) {}
  };
  const handleStoryRemove = async (id) => {
    try {
      const response = await AccountStoryRemoveMutation({
        variables: {
          storiesId: storiesData[currentStoryIndex].id,
        },
      });

      const data = response.data.AccountStoryRemove;

      if (!_.isEmpty(data) && data.status === "STORY_DELETE_SUCCESSFULLY") {
        toast({
          title: "Story removed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setShowStory(false);
        setCurrentStoryIndex(0);
        _AccountDetail?.refetch();
      }
    } catch (error) {
      toast({
        title: "Pls try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleBannerUpload = async (file) => {
    try {
      const response = await AccountCoverUploadMutation({
        variables: {
          accountCoverPhoto: file,
        },
      });

      const data = response.data.AccountCoverUpload;

      if (!_.isEmpty(data) && data.status === "BANNER_UPLOAD_SUCCESSFULLY") {
        toast({
          title: "Banner uploaded successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {}
  };
  const handleBannerRemove = async () => {
    try {
      const response = await AccountCoverRemoveMutation({
        variables: {},
      });

      const data = response.data.AccountCoverRemove;

      if (!_.isEmpty(data) && data.status === "BANNER_REMOVED_SUCCESSFULLY") {
        toast({
          title: "Banner removed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {}
  };
  const formatDateProfile = (value) => {
    const date = new Date(value);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const handleCloseModal = () => {
    setShowStory(false);
    setCurrentStoryIndex(0);
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    _AccountDetail.refetch();
  }, [loadingRemoveBanner, loadingBannerUpload]);

  useEffect(() => {
    handlePerUser();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        {_AccountDetail?.data?.AccountDetail?.profileBannerLink ? (
          <Flex
            position="relative"
            h="172px"
            w="100%"
            zIndex={1}
            display={{ base: "none", md: "flex" }}
          >
            <Image
              w="100%"
              h="100%"
              src={_AccountDetail?.data?.AccountDetail?.profileBannerLink}
            />
            <Flex position="absolute" top="2" right="2">
              {loadingRemoveBanner ? (
                <Spinner />
              ) : (
                <IoClose
                  cursor="pointer"
                  onClick={() => handleBannerRemove()}
                  size="30px"
                  color="white"
                />
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex
            zIndex={1}
            h="172px"
            w="100%"
            bg="#505050"
            align="center"
            justify="center"
            direction="column"
            cursor="pointer"
            display={{ base: "none", md: "flex" }}
            onClick={() => {
              document.getElementById("bannerInput").click();
            }}
          >
            <InputGroup align="center">
              {/* Hidden file input */}
              <Input
                type="file"
                id="bannerInput"
                name="thumbnailStoredAt"
                onChange={handleImageUpload}
                display="none"
              />
            </InputGroup>
            <Image src={bannerUpload} />
            <Text
              color="#FFFFFF"
              fontFamily="Poppins"
              fontWeight="500"
              fontSize="18px"
              letterSpacing="-0.36px"
            >
              Add a Banner Image
            </Text>
            <Text
              color="#FFFFFF"
              fontFamily="Poppins"
              fontWeight="400"
              fontSize="14px"
              letterSpacing="-0.14px"
            >
              Optimal dimensions 3200*410px
            </Text>
          </Flex>
        )}

        <Flex>
          <Flex
            pt="20px"
            pb="20px"
            zIndex={2}
            transform="translate(30px, -130px)"
            maxW="700px"
            w="550px"
            h="700px"
            bg="#000000"
            borderRadius="2px"
            direction="column"
            align="center"
            gap="2rem"
            display={{ base: "none", md: "flex" }}
          >
            <Avatar
              border={
                _AccountDetail?.data?.AccountDetail?.Stories?.[0]
                  ?.profileStoryLink
                  ? "3px solid rgba(255, 193, 7, 1)"
                  : "none"
              }
              onClick={
                _AccountDetail?.data?.AccountDetail?.Stories?.[0]
                  ?.profileStoryLink
                  ? () => setShowStory(!showStory)
                  : null
              }
              cursor="pointer"
              width="110px"
              h="110px"
              name={_AccountDetail?.data?.AccountDetail?.fullName}
              src="https://bit.ly/broken-link"
            />

            <Modal
              isOpen={showStory}
              onClose={() => setShowStory(!showStory)}
              isCentered
            >
              <ModalOverlay />
              <ModalContent bg="black">
                <ModalBody p="5px">
                  <Stories
                    stories={stories}
                    defaultInterval={10000}
                    width="100%"
                    height="100%"
                    currentIndex={currentStoryIndex}
                    onAllStoriesEnd={handleCloseModal}
                    onStoryEnd={() =>
                      setCurrentStoryIndex((prevIndex) => {
                        const nextIndex = prevIndex + 1;
                        return nextIndex >= stories.length ? 0 : nextIndex;
                      })
                    }
                  />
                </ModalBody>
                <Button
                  onClick={() => handleStoryRemove()}
                  position="absolute"
                  bottom="10px"
                  right="10px"
                  colorScheme="red"
                  cursor="pointer"
                  zIndex="9999"
                >
                  {loadingAccountStoryRemove ? <Spinner /> : " Delete Story"}
                </Button>
              </ModalContent>
            </Modal>

            <Flex align="center" gap=".5rem" direction="column">
              <Text
                color="#FFFFFF"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="24px"
                lineHeight="30px"
              >
                {_AccountDetail?.data?.AccountDetail?.fullName}
              </Text>
              <Text
                color="#FFFFFF"
                fontFamily="Poppins"
                fontWeight="500"
                fontSize="14px"
                lineHeight="15px"
              >
                {_AccountDetail?.data?.AccountDetail?.userName
                  ? `@${_AccountDetail?.data?.AccountDetail?.userName}`
                  : "-"}
              </Text>
            </Flex>

            <Flex align="center" gap=".5rem" direction="column">
              <Button
                bg="#052DB0"
                color="#FFFFFF"
                w="300px"
                h="40px"
                borderRadius="24px"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="16px"
                lineHeight="30px"
                letterSpacing="0.32px"
                gap=".5rem"
                _hover={{ bg: "#052DB0" }}
                onClick={() => history.push("/edit-profile")}
              >
                <MdEdit /> Edit Your Profile
              </Button>
            </Flex>

            <Flex
              color="#D9D9D9"
              fontFamily="Poppins"
              fontWeight="400"
              fontSize="18.15px"
              lineHeight="36.3px"
              mt="2rem"
              align="center"
              direction="column"
            >
              <Flex w="260px" align="center" justify="space-between">
                <Text>Movie Views</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalViews
                  }
                </Text>
              </Flex>
              <Flex w="260px" align="center" justify="space-between">
                <Text>Appreciations</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalLikes
                  }
                </Text>
              </Flex>
              <>
                <Flex
                  w="260px"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  isOpen={isOpen}
                  onClose={onClose}
                  onClick={onOpen} // Add onClick handler to open modal
                >
                  <Text>Follower</Text>
                  <Text>
                    {
                      _FollowLikeAppreciationRead?.data
                        ?.FollowLikeAppreciationRead?.followerCount
                    }
                  </Text>
                </Flex>
                {/* {isOpen && (
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent margin="auto" bg="#000">
                      <ModalHeader color="#fff">Follower</ModalHeader>
                      <ModalCloseButton color="#fff" />
                      <ModalBody>
                        {_FollowersList?.data?.FollowerList?.map((each) => (
                          <FollowerCard
                            each={each}
                            key={each.id}
                            account={account}
                          />
                        ))}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )} */}
              </>
              <>
                <Flex
                  w="260px"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  onClick={() => onOpenFollowing()} // Add onClick handler to open modal
                >
                  <Text>Following</Text>
                  <Text>
                    {
                      _FollowLikeAppreciationRead?.data
                        ?.FollowLikeAppreciationRead?.followingCount
                    }
                  </Text>
                </Flex>
                {/* {isOpenFollowing && ( // Render the modal only if isOpenFollowing is true
                  <Modal isOpen={isOpenFollowing} onClose={onCloseFollowing}>
                    {" "}
                    <ModalOverlay />
                    <ModalContent margin="auto" bg="#000">
                      <ModalHeader color="#fff">Following</ModalHeader>
                      <ModalCloseButton color="#fff" />
                      <ModalBody>
                        {_FollowingList?.data?.FollowingList?.map((each) => (
                          <FollowingCard
                            each={each}
                            key={each.id}
                            account={account}
                          />
                        ))}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )} */}
              </>
            </Flex>

             {_AccountDetail?.data?.AccountDetail?.premiumStatus ? (
              <Flex
                color="#FFFFFF"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="20px"
                lineHeight="20px"
                gap="5px"
              >
                <Text>
                  Your are on{" "}
                  <span style={{ color: "rgba(255, 193, 7, 1)" }}>
                    {_AccountDetail?.data?.AccountDetail?.subscriptionName}
                  </span>
                </Text>{" "}
                <MdWorkspacePremium color="rgba(255, 193, 7, 1)" />
                Plan
              </Flex>
            ) : (
              <Flex
                color="#FFFFFF"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="20px"
                lineHeight="20px"
                gap="5px"
              >
                <Text>Your have no plan</Text>
              </Flex>
            )}
            <Flex align="center" gap=".5rem" direction="column">
              <Button
                bg="#052DB0"
                color="#FFFFFF"
                w="300px"
                h="40px"
                borderRadius="24px"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="16px"
                lineHeight="30px"
                letterSpacing="0.32px"
                gap=".5rem"
                _hover={{ bg: "#052DB0" }}
                onClick={() => history.push("/subscription-page")}
              >
                {_AccountDetail?.data?.AccountDetail?.premiumStatus
                  ? "Upgrade Plan"
                  : "Get Premium"}
              </Button>
            </Flex> 

            <Flex>
              <Text
                color="#A9AAA9"
                fontFamily="Poppins"
                fontSize="11px"
                fontWeight="400"
                lineHeight="15px"
                letterSpacing="0.11px"
              >
                {`MEMBER SINCE : ${formatDateProfile(
                  _AccountDetail?.data?.AccountDetail?.createdAt
                )}`}
              </Text>
            </Flex>
          </Flex>

          <Flex
            overflow="scroll"
            direction="column"
            marginLeft="32px"
            w="100%"
            h="100vh"
          >
            <Flex display={{ base: "flex", md: "none" }}>
              <Avatar name={_AccountDetail?.data?.AccountDetail?.fullName} />
              <Flex direction="column" ml="5">
                <Text
                  color="#FFFFFF"
                  fontFamily="Poppins"
                  fontWeight="600"
                  fontSize="24px"
                  lineHeight="30px"
                >
                  {_AccountDetail?.data?.AccountDetail?.fullName}
                </Text>
                <Flex direction="column">
                  <Text
                    color="#FFFFFF"
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="15px"
                  >
                    {`@${_AccountDetail?.data?.AccountDetail?.userName}`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex display={{ base: "none", md: "flex" }}>
              {tabState ? (
                <UploadContent _AccountDetail={_AccountDetail} />
              ) : (
                <></>
              )}
            </Flex>

            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml={{ base: "0px", md: "3rem" }}
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => setTabState(true)}
                >
                  Videos
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => setTabState(false)}
                >
                  Manage Videos
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {loadingPerUser ? (
                    <Flex
                      w="100%"
                      h="100%"
                      mt="12rem"
                      align="center"
                      justify="center"
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="purple.800"
                        size="xl"
                      />
                    </Flex>
                  ) : (
                    <Videos perUser={perUser} />
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <ManageVideos perUser={perUser} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(ProfilePage);
