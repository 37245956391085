/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $companyEmail: String!
    $companyName: String!
    $phone: String!
    $reason: String!
  ) {
    GenerateToken(
      companyEmail: $companyEmail
      companyName: $companyName
      phone: $phone
      reason: $reason
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
