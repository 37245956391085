/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { connect } from "react-redux"; // NPM: React-redux library.
import { NavLink, useLocation } from "react-router-dom";
import "./../../index.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BiSearch, BsPlus } from "./../../Assets/reactIcons/index";
import { logo1, logo3 } from "../../Assets/images/shucae/export";
import { CiBoxList } from "react-icons/ci";
import { FaHome } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";

/*
 * CHAKRA UI IMPORT
 */
import {
  Box,
  Flex,
  Button,
  useDisclosure,
  Text,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Image,
  Avatar,
  AvatarGroup,
  Input,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import MutationAccountLogout from "./__mutation__/index.logout.mutation";
import { useMutation } from "@apollo/client";

const Navbar = ({ account, AccountLogout }) => {
  /*
   * STATE
   */

  const location = useLocation();
  const routeName = location.pathname;
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery("(min-width: 1000px)");
  const [openNavbar, setOpenNavbar] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const [hasShadow, setHasShadow] = React.useState(false);

  const [_MutationAccountLogout, { loading }] = useMutation(
    MutationAccountLogout,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      history.push(`/search-results?searchKeyword=${searchTerms}`);
    }
  };
  const navbarLinksData = [
    { name: "Home", takeTo: "/", icon: <FaHome /> },

    {
      name: "My List",
      takeTo: "/not-logged-in",
      icon: <CiBoxList />,
    },
    {
      name: "Blogs",
      takeTo: "/blogs",
    },
  ];
  const mobileNavbarLinksData = [
    { name: "Home", takeTo: "/", icon: <FaHome /> },

    {
      name: "My List",
      takeTo: "/not-logged-in",
      icon: <CiBoxList />,
    },
    // {
    //   name: "Buy/Sell",
    //   takeTo: "/buy-sell",
    // },
  ];

  /*
   * FUNCTION
   */
  async function _handleLogout() {
    const res = await _MutationAccountLogout({
      variables: {
        email: account?.email,
      },
    });
    if (res?.data?.AccountLogout?.status === "LOGOUT_SUCCESSFUL") {
      AccountLogout({
        isUserLoggedIn: false,
      });
      history.push("/signin");
    }
  }
  function _handleNavbar() {
    setOpenNavbar(!openNavbar);
  }
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasShadow(true);
    } else {
      setHasShadow(false);
    }
  };
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const handleCheckLogin = () => {
    if (account.isUserLoggedIn) {
      history.push("/subscription-page");
    } else {
      history.push("/signin");
    }
  }

  /*
   * USEEFFECT
   */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Bonus />  */}
      <Flex
        fontFamily="Montserrat"
        id="navbar"
        as="nav"
        align="center"
        justify="space-between"
        h="115px"
        w="100%"
        color="white"
        // bg="linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.76) 30.6%, rgba(0, 0, 0, 0.02) 90.12%)"
        bg="linear-gradient(180deg, #120B25 0%, rgba(22, 7, 39, 1) 20.6%, rgba(0, 0, 0, 0) 70.12%)"
        zIndex="5"
        position="fixed"
        // display={localStorage.getItem("brandVideoEnd") ? "flex" : "none"}
      >
        {/* HL ---------------------------------- web navbar ------------------------------------------- */}
        <Flex
          display={!isLargerThan900 ? "none" : "flex"}
          w="95%"
          mx="auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <NavLink to="/">
            <Flex alignItems="center">
              <Image width="200px" src={logo3} />
            </Flex>
          </NavLink>

          <Flex
            px="30px"
            justifyContent="space-evenly"
            // minW="600px"
            width="100%"
          >
            {navbarLinksData?.map((item) => (
              <Flex
                fontWeight="500"
                color={location.pathname === item.takeTo ? "white" : "white"}
                alignItems="center"
              >
                <NavLink to={item.takeTo}>{item.name}</NavLink>
              </Flex>
            ))}
            <Flex fontWeight="500" color="white" alignItems="center">
              <a
                href="https://buysell.shucaefilms.com/"
                target="_blank"
                rel="noreferrer"
              >
                Buy/Sell (Content)
              </a>
            </Flex>
          </Flex>

          <Flex color="white" gap="10px" alignItems="center">
            <Flex align="center">
              <Input
                type="text"
                placeholder="Type your search"
                _placeholder={{ color: "white" }}
                color="white"
                width={isSearchOpen ? "200px" : "0px"}
                overflow="hidden"
                transition="width 0.3s ease-in-out"
                border={isSearchOpen ? "1px solid white" : "none"}
                onChange={(e) => setSearchTerms(e.target.value)}
                onKeyPress={handleKeyPress}
              />

              <Button
                onClick={toggleSearch}
                color="white"
                variant="unstyled"
                align="center"
                justify="center"
                display="flex"
                ml="-35px" // Adjust the margin to position the icon correctly
              >
                <BiSearch fontSize="20px" />
              </Button>
            </Flex>
            {/*  <Button
              bg={
                routeName === "/for-developer"
                  ? "linear-gradient(93.55deg, #052DB0 5.03%, #C30280 95.67%)"
                  : "transparent"
              }
              border="1px solid white"
              borderRadius="19px"
              color="white"
              px="20px"
              variant="none"
              onClick={() => handelDeveloperPage()}
            >
              <BsPlus fontSize="25px" /> For Developer
            </Button> */}
            <Button
              bg={
                routeName === "/creator-page"
                  ? "linear-gradient(93.55deg, #052DB0 5.03%, #C30280 95.67%)"
                  : "transparent"
              }
              border="1px solid white"
              borderRadius="19px"
              color="white"
              px="20px"
              variant="none"
              onClick={() => history.push("/creator-page")}
            >
              <BsPlus fontSize="25px" /> For Creator
            </Button>
            {/* <Button
              variant="unstyled"
              color="white"
              align="center"
              justify="center"
              display="flex"
            >
              <BiSolidBell fontSize="20px" />
            </Button> */}
            {!account.isUserLoggedIn ? (
              <Button
                onClick={() => {
                  history.push("/signin");
                }}
                variant="unstyled"
                color="white"
              >
                Login
              </Button>
            ) : (
              <Flex gap=".5rem">
                <Flex
                  color="white"
                  fontFamily="Roboto"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="32.83px"
                  letterSpacing="0.12px"
                  gap=".5rem"
                  align="center"
                  onClick={() => {
                    account.accountType === "CREATOR"
                      ? history.push("/profile-page")
                      : history.push("/user-profile");
                  }}
                  cursor="pointer"
                >
                  <AvatarGroup spacing="1rem">
                    <Avatar
                      width="35px"
                      h="35px"
                      name={account?.fullName}
                      src="https://bit.ly/broken-link"
                    />
                    <Text whiteSpace="nowrap">{account?.fullName}</Text>
                  </AvatarGroup>
                </Flex>
                <Flex
                  color="white"
                  fontFamily="Roboto"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="32.83px"
                  letterSpacing="0.12px"
                  gap=".5rem"
                  align="center"
                  cursor="pointer"
                  onClick={() => _handleLogout()}
                >
                  Logout
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>

        {/* HL ---------------------------------- mobile navbar ------------------------------------------- */}

        <Flex
          display={isLargerThan900 ? "none" : "flex"}
          w="100%"
          mx="auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" p="10px">
            <NavLink to="/">
              <Flex align="center">
                <Image width="150px" src={logo3} alt="" />
              </Flex>
            </NavLink>
          </Box>

          <Flex>
            <Flex align="center">
              <Input
                type="text"
                placeholder="Type your search"
                _placeholder={{ color: "white" }}
                color="white"
                width={isSearchOpen ? "200px" : "0px"}
                height={isSearchOpen ? "30px" : "0px"}
                overflow="hidden"
                transition="width 0.3s ease-in-out"
                border={isSearchOpen ? "1px solid white" : "none"}
                onChange={(e) => setSearchTerms(e.target.value)}
                onKeyPress={handleKeyPress}
              />

              <Button
                onClick={toggleSearch}
                color="white"
                variant="unstyled"
                align="center"
                justify="center"
                display="flex"
                ml="-35px" // Adjust the margin to position the icon correctly
              >
                <BiSearch fontSize="20px" />
              </Button>
            </Flex>
            <Button
              w="3rem"
              h="3rem"
              p="0"
              aspectRadio="1"
              borderRadius="50%"
              bg="none"
              onClick={() => _handleNavbar()}
            >
              <HamburgerIcon fontSize="30px" />
            </Button>
          </Flex>
        </Flex>

        <Drawer
          isOpen={openNavbar}
          placement="right"
          initialFocusRef={firstField}
          onClose={onClose}
          width="100%"
          border="2px solid yellow"
        >
          <DrawerOverlay />
          <DrawerContent borderRadius="20px" bg="#0f0f0f" h="95%">
            <DrawerHeader
              py="30px"
              px="10px"
              display="flex"
              color="white"
              justifyContent="start"
              alignItems="center"
            >
              <Button
                color="white"
                onClick={() => _handleNavbar()}
                bg="none"
                mr="8px"
                w="2.5rem"
                h="2.5rem"
                borderRadius="50%"
                border="none"
                outline="none"
              >
                <CloseIcon style={{ fontSize: "16px" }} />
              </Button>
            </DrawerHeader>

            <DrawerBody display="grid" flexDirection="column" gridGap="0.4rem">
              <Flex
                bg="#191919"
                borderRadius="10px"
                h="88px"
                w="275px"
                align="center"
                justify="space-evenly"
              >
                {account.isUserLoggedIn ? (
                  <Avatar
                    name={account?.fullName}
                    src="https://bit.ly/broken-link"
                    onClick={() => {
                      account.accountType === "CREATOR"
                        ? history.push("/profile-page")
                        : history.push("/user-profile");
                    }}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="39"
                    height="39"
                    viewBox="0 0 39 39"
                    fill="none"
                  >
                    <circle cx="19.5" cy="19.5" r="19.5" fill="#2F2F2F" />
                    <path
                      d="M10 31C10 31 8 31 8 29C8 27 10 21 20 21C30 21 32 27 32 29C32 31 30 31 30 31H10ZM20 19C21.5913 19 23.1174 18.3679 24.2426 17.2426C25.3679 16.1174 26 14.5913 26 13C26 11.4087 25.3679 9.88258 24.2426 8.75736C23.1174 7.63214 21.5913 7 20 7C18.4087 7 16.8826 7.63214 15.7574 8.75736C14.6321 9.88258 14 11.4087 14 13C14 14.5913 14.6321 16.1174 15.7574 17.2426C16.8826 18.3679 18.4087 19 20 19Z"
                      fill="white"
                    />
                  </svg>
                )}
                {account.isUserLoggedIn ? (
                  <Text
                    color="#fff"
                    textTransform="capitalize"
                    fontSize="1.2rem"
                    fontWeight="500"
                    onClick={() => {
                      account.accountType === "CREATOR"
                        ? history.push("/profile-page")
                        : history.push("/user-profile");
                    }}
                  >
                    {account?.fullName}
                  </Text>
                ) : (
                  <Button
                    color="#fff"
                    fontSize="12px"
                    w="78px"
                    h="26px"
                    borderRadius="19px"
                    background="linear-gradient(93deg, rgba(5, 45, 176, 0.80) 7.36%, rgba(195, 2, 128, 0.80) 94.64%)"
                  >
                    {!account.isUserLoggedIn ? (
                      <Button
                        onClick={() => {
                          history.push("/signin");
                        }}
                        variant="unstyled"
                        color="white"
                      >
                        Login
                      </Button>
                    ) : (
                      <Flex gap=".5rem">
                        <Flex
                          color="white"
                          fontFamily="Roboto"
                          fontWeight="400"
                          fontSize="8px"
                          lineHeight="32.83px"
                          letterSpacing="0.12px"
                          gap=".5rem"
                          align="center"
                          onClick={() => {
                            account.accountType === "CREATOR"
                              ? history.push("/profile-page")
                              : history.push("/user-profile");
                          }}
                          cursor="pointer"
                        >
                          <AvatarGroup spacing="1rem">
                            {/* <Avatar
                          width="35px"
                          h="35px"
                          name={account?.fullName}
                          src="https://bit.ly/broken-link"
                        /> */}
                            <Text width="fit-content" whiteSpace="nowrap">
                              {account?.fullName?.split(" ")[0]}
                            </Text>
                          </AvatarGroup>
                        </Flex>
                        <Flex
                          ml=".5rem"
                          color="white"
                          fontFamily="Roboto"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="32.83px"
                          letterSpacing="0.12px"
                          gap=".5rem"
                          align="center"
                          cursor="pointer"
                          onClick={() => _handleLogout()}
                        >
                          Logout
                        </Flex>
                      </Flex>
                    )}
                  </Button>
                )}
              </Flex>
              <hr style={{ border: "1px solid #2e2e2e", margin: "10px 0" }} />
              <Flex color="white" flexDirection="column" gap="2rem">
                {mobileNavbarLinksData?.map((item) => (
                  <Flex
                    _hover={{ color: "#ff896c" }}
                    fontWeight="500"
                    align="center"
                  >
                    {item.icon}
                    <NavLink
                      color={
                        location.pathname === item.takeTo
                          ? "#ff896c"
                          : "#263238"
                      }
                      to={item.takeTo}
                    >
                      <Text ml="1rem">{item.name}</Text>
                    </NavLink>
                  </Flex>
                ))}
              </Flex>
              <hr style={{ border: "1px solid #2e2e2e", margin: "10px 0" }} />
              <Flex
                bg="#191919"
                direction="column"
                borderRadius="10px"
                h="157px"
                mb="16px"
                justify="space-evenly"
                p="10px 5px"
                w="275px"
              >
                <Text color="#fff" fontSize="16px" fontWeight="600">
                  For Creator
                </Text>
                <Text color="#fff" fontSize="11px" fontWeight="300">
                  Unlock global visibility, hassle-free submissions. Showcase
                  your work and explore monetization options on our platform
                  today!
                </Text>
                <Button
                  color="#fff"
                  fontSize="11px"
                  w="90px"
                  h="26px"
                  borderRadius="19px"
                  background="linear-gradient(93deg, rgba(5, 45, 176, 0.80) 7.36%, rgba(195, 2, 128, 0.80) 94.64%)"
                  onClick={() => history.push("/creator-page")}
                >
                  View Details
                </Button>
              </Flex>
              <Flex
                bg="#191919"
                direction="column"
                borderRadius="10px"
                h="157px"
                mb="16px"
                justify="space-evenly"
                p="5px"
                w="275px"
              >
                <Text color="#fff" fontSize="16px" fontWeight="600">
                  Buy/Sell
                </Text>
                <Text color="#fff" fontSize="11px" fontWeight="300">
                  "Buy or Sell: Your content marketplace. Explore and transact
                  with ease – buy captivating movies or sell your creations
                  hassle-free."
                </Text>
                <Button
                  color="#fff"
                  fontSize="11px"
                  w="90px"
                  h="26px"
                  borderRadius="19px"
                  background="linear-gradient(93deg, rgba(5, 45, 176, 0.80) 7.36%, rgba(195, 2, 128, 0.80) 94.64%)"
                >
                  <a
                    href="https://buysell.shucaefilms.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Details
                  </a>
                </Button>
              </Flex>
              <Flex
                bg="#191919"
                direction="column"
                borderRadius="10px"
                h="157px"
                mb="16px"
                justify="space-evenly"
                p="5px"
                w="275px"
              >
                <Text color="#fff" fontSize="16px" fontWeight="600">
                  Blogs
                </Text>
                <Text color="#fff" fontSize="11px" fontWeight="300">
                  "Blogs: Your blogosphere exchange. Dive into a seamless
                  journey – purchase insightful blogs or monetize your own
                  creations effortlessly."
                </Text>
                <Button
                  color="#fff"
                  fontSize="11px"
                  w="90px"
                  h="26px"
                  borderRadius="19px"
                  background="linear-gradient(93deg, rgba(5, 45, 176, 0.80) 7.36%, rgba(195, 2, 128, 0.80) 94.64%)"
                  onClick={() => history.push("/blogs")}
                >
                  View Details
                </Button>
              </Flex>
              <Flex
                bg="#191919"
                direction="column"
                borderRadius="10px"
                h="157px"
                mb="16px"
                justify="space-evenly"
                p="5px"
                w="275px"
              >
                <Text color="#fff" fontSize="16px" fontWeight="600">
                  Subscription Plans
                </Text>
                <Text color="#fff" fontSize="11px" fontWeight="300">
                  "Subscription Plans: Your gateway to premium content. Unlock
                  exclusive benefits – enjoy unlimited access or elevate your
                  experience with our tailored plans effortlessly."
                </Text>
                <Button
                  color="#fff"
                  fontSize="11px"
                  w="90px"
                  h="26px"
                  borderRadius="19px"
                  background="linear-gradient(93deg, rgba(5, 45, 176, 0.80) 7.36%, rgba(195, 2, 128, 0.80) 94.64%)"
                  onClick={handleCheckLogin}
                >
                  View Details
                </Button>
              </Flex>
              {account.isUserLoggedIn && (
                <Flex color="#fff" align="center" alignSelf="flex-end">
                  <AiOutlineLogout />
                  <Text ml="0.5rem" onClick={() => _handleLogout()}>
                    Logout
                  </Text>
                </Flex>
              )}
            </DrawerBody>

            {/* <DrawerFooter borderTopWidth="1px"></DrawerFooter> */}
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
};

const __MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, __MapDispatchToProps)(Navbar);
