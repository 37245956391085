/*
 * IMPORTS
 */
import { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import _ from "underscore";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import { IoVolumeMediumOutline } from "react-icons/io5";
import { FiVolumeX } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory } from "react-router-dom";
import Loader from "../../VideoPlayPage/Loader";
import { FaPlay } from "react-icons/fa";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Helmet } from "react-helmet";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Text,
  Button,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import GetTrailerVideos from "../__query__/index.trailersVideo.query";
import GetSpecificTrailer from "../__query__/index.fetchSpecificTrailer.query";

/*
 * OBJECT
 */
function TrailerPlay({ account, brandVideoEnded, brandVideoUrl }) {
  /*
   * STATE
   */
  const [sound, setSound] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [trailerList, setTrailerList] = useState();
  const [specificTrailer, setSpecificTrailer] = useState();
  const [trailerChange, setTrailerChange] = useState(false);
  const [blobUrl, setBlobUrl] = useState();
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const brand = searchParams.get("Brand");
  const state = searchParams.get("location");
  const queryParams = {
    Brand: brand,
    location: state,
  };
  const jsonParams = JSON.stringify(queryParams);
  const iconSize = useBreakpointValue({ base: "7px", md: "20px" });

  const [GetTrailerVideosQuery, { loading: loadingGetTrailerVideos }] =
    useLazyQuery(GetTrailerVideos);

  const [GetSpecificTrailerQuery, { loading: loadingGetSpecificTrailer }] =
    useLazyQuery(GetSpecificTrailer);

  /*
   * FUNCTION
   */
  const handleTrailerList = async () => {
    try {
      const response = await GetTrailerVideosQuery();

      const data = response.data.GetTrailerVideos;

      if (!_.isEmpty(data)) {
        setTrailerList(data);
      }
    } catch (error) {}
  };

  const handleSpecificTrailer = async (id) => {
    setTrailerChange(true);
    try {
      const response = await GetSpecificTrailerQuery({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.GetSpecificTrailer;

      if (!_.isEmpty(data)) {
        setSpecificTrailer(data[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleTrailerList();
  }, []);


  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
  {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": "Trailers",
    "itemListElement": ${trailerList
      ?.map((item, index) => {
        return `
      {
        "@type": "ListItem",
        "position": ${index + 1},
        "url": "https://www.shucaefilms.com/video-play-page/${item?.ContentSummary?.contentTitle?.replace(
          / /g,
          "-"
        )}/${item?.id}",
        "name": "${item?.ContentSummary?.contentTitle}",
        "image": "${item?.lowerThumbnailLink}"
      }`;
      })
      .join(",")}
  }

  `}
        </script>
      </Helmet>
      {loadingGetTrailerVideos ? (
        <Flex w="100%" h="100%" justify="center" align="center">
          <Loader />
        </Flex>
      ) : (
        <Flex
          borderRadius="0px"
          w="100%"
          h={{ lg: "700px", md: "400px", base: "300px" }}
        >
          {loadingGetSpecificTrailer ? (
            <Flex w="100%" h="100%" justify="center" align="center">
              <Loader />
            </Flex>
          ) : (
            <video
              id="myVideo"
              autoPlay
              loop
              muted={isMuted}
              playsInline
              style={{
                objectFit: "cover",
                backgroundSize: "cover",
                width: "100%",
                height: "100%",
                // height: "200px"
              }}
              src={
                trailerChange
                  ? specificTrailer?.trailerLink
                  : trailerList?.[0]?.trailerLink
              }
              zIndex={brandVideoEnded || brandVideoUrl === null ? "99" : "1"}
            ></video>
          )}

          <Flex
            // display={localStorage.getItem("brandVideoEnd") ? "flex" : "none"}
            position="absolute"
            h={{
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            }}
            w="100%"
            mt="30px"
            bg="linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.66) 20.6%, rgba(0, 0, 0, 0.00) 103.12%)"
          >
            <Flex>
              <Flex
                position="absolute"
                direction="column"
                bottom="120px"
                align="left"
              >
                <Text
                  pb="5px"
                  ml="10px"
                  w={{ base: "90px", md: "300px" }}
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="Oswald"
                  fontWeight="700"
                  fontSize={{ base: "15px", md: "50px" }}
                  letterSpacing="-0.75px"
                  textTransform="capitalize"
                  zIndex={99}
                >
                  {trailerChange
                    ? specificTrailer?.ContentSummary?.contentTitle
                    : trailerList?.[0]?.ContentSummary?.contentTitle}
                </Text>
                <Flex ml="10px">
                  {trailerChange ? (
                    <Button
                      bg="white"
                      color="#432C83"
                      p="0px"
                      _hover={{ bg: "white" }}
                      width={{ base: "30px", md: "100px" }}
                      height={{ base: "20px", md: "50px" }}
                      onClick={() =>
                        history.push(
                          `/video-play-page/${specificTrailer?.ContentSummary?.contentTitle?.replace(
                            / /g,
                            "-"
                          )}/${specificTrailer?.id}`
                        )
                      }
                    >
                      <FaPlay size={iconSize} />
                      <Text fontSize={{ base: "6px", md: "20px" }}>Play</Text>
                    </Button>
                  ) : (
                    <Button
                      p="0px"
                      bg="white"
                      color="#432C83"
                      _hover={{ bg: "white" }}
                      width={{ base: "30px", md: "100px" }}
                      height={{ base: "20px", md: "50px" }}
                      onClick={() =>
                        history.push(
                          `/video-play-page/${trailerList?.[0]?.ContentSummary?.contentTitle?.replace(
                            / /g,
                            "-"
                          )}/${trailerList?.[0]?.id}`
                        )
                      }
                    >
                      <FaPlay size={iconSize} />

                      <Text fontSize={{ base: "6px", md: "20px" }}>Play</Text>
                    </Button>
                  )}
                </Flex>
              </Flex>

              <Flex
                zIndex="30"
                pos="absolute"
                bottom="10"
                right="0"
                direction="column"
              >
                <Flex
                  mr={{ base: "10px", md: "40px" }}
                  mb={{ base: "0", md: "10px" }}
                  justify="flex-end"
                >
                  {sound ? (
                    <Flex
                      onClick={() => {
                        setSound(!sound);
                        setIsMuted(!isMuted);
                      }}
                      cursor="pointer"
                      id="soundButton"
                      h="35px"
                    >
                      <IoVolumeMediumOutline size="25px" color="white" />
                    </Flex>
                  ) : (
                    <Flex
                      onClick={() => {
                        setSound(!sound);
                        setIsMuted(!isMuted);
                      }}
                      cursor="pointer"
                      id="soundButton"
                      h="35px"
                    >
                      <FiVolumeX color="white" size="25px" />
                    </Flex>
                  )}
                </Flex>

                <Flex width={{ base: "320px", md: "500px" }}>
                  <Swiper
                    slidesPerView={3}
                    centeredSlides={false}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    style={{
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {trailerList?.map((movie, index) => (
                      <SwiperSlide
                        key={index}
                        style={{
                          width: "100px",
                        }}
                      >
                        <Flex
                          justify="center"
                          align="center"
                          h={{ base: "70px", md: "100px" }}
                          w={{ base: "100px", md: "150px" }}
                          cursor="pointer"
                          onClick={() => handleSpecificTrailer(movie?.id)}
                        >
                          <Image
                            loading="lazy"
                            src={movie?.lowerThumbnailLink}
                            w="100%"
                            h="100%"
                            objectFit="cover"
                            borderRadius="2px"
                          />
                        </Flex>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}

const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(TrailerPlay);
