/*
 * IMPORT
 */
import React, { useState } from "react";
import { FaRegBookmark } from "react-icons/fa";
import {
  videoSmallTextStyle,
  videoBigTextStyle,
  videoTextStyle,
} from "components/Styles/index.style";

/*
 * REACT-ICONS IMPORT
 */
import { FaRegHeart } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

/*
 * CHAKRA IMPORTS
 */
import { Image, Flex, Text } from "@chakra-ui/react";

/*
 * QUERY
 */
/*
 * MUTATAION
 */

/*
 * OBJECT
 */
function Videos({ perUser }) {
  /*
   * States
   */
  const [expandedSummary, setExpandedSummary] = useState({});

  /*
   * FUNCTIONS
   */
  function calculateDaysDifference(createdAt) {
    const createdAtTimestamp = new Date(createdAt);
    const today = new Date();

    const timeDifference = today - createdAtTimestamp;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return `${daysDifference} days ago`;
  }

  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  /*
   * USEEFFECT
   */

  return (
    <Flex
      overflowY="auto"
      maxHeight={{ base: "90vh", md: "500px" }}
      p="35px 24px"
      direction="column"
      gap="1rem"
    >
      {perUser?.map((data, index) => (
        <Flex
          p="15px"
          key={data.id}
          borderRadius="10px"
          bg="#000000"
          w="100%"
          gap="1rem"
          direction={{ base: "column", md: "row" }}
        >
          <Flex w="256px" h="158px">
            <Image w="100%" h="100%" src={data.lowerThumbnailLink} />
          </Flex>
          <Flex gap=".5rem" direction="column">
            <Text style={videoTextStyle}>
              {calculateDaysDifference(data?.createdAt)}
            </Text>

            <Text style={videoBigTextStyle}>
              {data?.ContentSummary?.contentTitle}
            </Text>
            <Text w={{ base: "auto", md: "850px" }} style={videoSmallTextStyle}>
              {expandedSummary[data.id] ? (
                <span>
                  {data?.plotSummary}
                  <Text
                    color="rgba(0, 129, 223, 1)"
                    cursor="pointer"
                    onClick={() => toggleSummary(data.id)}
                  >
                    read less
                  </Text>
                </span>
              ) : (
                <span>
                  {data?.plotSummary?.split(" ")?.slice(0, 20)?.join(" ")}
                  {data?.plotSummary?.split(" ").length > 20 && (
                    <Text
                      color="rgba(0, 129, 223, 1)"
                      cursor="pointer"
                      onClick={() => toggleSummary(data.id)}
                    >
                      read more
                    </Text>
                  )}
                </span>
              )}
            </Text>
            <Flex gap=".8rem" style={videoTextStyle} align="center">
              <Text color="#5A2CC1">Status</Text>
              <Flex w="90px" h="5px" borderRadius="12px" bg="#FFFFFF">
                <Flex
                  w={data?.approved ? "100%" : data.approved ? "100%" : "100%"}
                  h="5px"
                  borderRadius="12px"
                  bg={
                    data?.approved
                      ? "#4CAF50"
                      : data.approved
                      ? "#4CAF50"
                      : "#FF3D00"
                  }
                ></Flex>
              </Flex>
              <Text
                color={
                  data?.approved
                    ? "#4CAF50"
                    : data.status === "Approved"
                    ? "#4CAF50"
                    : "#FF3D00"
                }
              >
                {data?.approved ? "Approved" : "Declined"}
              </Text>
              {data?.approved ? (
                <Flex color="#8C8C8E" gap="1rem" align="center">
                  <Flex gap=".2rem" align="center">
                    <FaRegHeart size="20px" />
                    {data?.likes}
                  </Flex>

                  <Flex gap=".2rem" align="center">
                    <FaRegBookmark size="20px" />
                    {data?.History?.length}
                  </Flex>
                  <Flex gap=".2rem" align="center">
                    <FaEye size="20px" />
                    {data?.MyList?.length}
                  </Flex>
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

/*
 * EXPORTS
 */
export default Videos;
