export const labelStyle = {
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "14px",
  color: "#B5C9DB",
};

export const inputStyle = {
  // width: "410px",
  height: "50px",
  border: "1px solid #3D445A",
  borderRadius: "16px",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "14px",
  letterSpacing: "-0.28px",
  color: "#878992",
};

export const inputStyle1 = {
  // width: "852px",
  height: "50px",
  border: "1px solid #3D445A",
  borderRadius: "16px",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "14px",
  letterSpacing: "-0.28px",
  color: "#878992",
};

export const tabStyle = {
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "55.62px",
  letterSpacing: "0.32px",
};

export const videoSmallTextStyle = {
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "22px",
};

export const videoBigTextStyle = {
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
  letterSpacing: "0.2px",
};

export const headerStyle = {
  color: "#A8A8A8",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "55.62px",
  letterSpacing: "0.28px",
};

export const smallTextStyle = {
  color: "#ADADAD",
  fontFamily: "Roboto",
  fontWeight: "300",
  fontSize: "11px",
  lineHeight: "17.04px",
  letterSpacing: "0.33px",
};
export const videoTextStyle = {
  color: "#7D798E",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "11.44px",
  lineHeight: "17.04px",
};
export const bigTextStyle = {
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17.62px",
};

export const textStyle = {
  color: "rgba(255, 255, 255, 1)",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "21.54px",
};

export const swiperCardStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "18px",
  fontSize: "22px",
  fontWeight: "bold",
  color: "#fff",
};

