/*
 * IMPORTS
 */
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux"; // NPM: React-redux library.

/*
 * CHAKRA IMPORTS
 */
import { Flex } from "@chakra-ui/react";

/*
 * QUERY
 */

/*
 * OBJECT
 */
function LandingPage({ LogoUpdate, logo }) {
  /*
   * STATE
   */
  const [videoPlayed, setVideoPlayed] = useState(false);
  const videoRef = useRef(null);
  const shucaeRef = useRef(null);
  // Access the parameters from the URL

  /*
   * FUNCTION
   */

  const _IsOneDayOlder = (dateString) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Set the time of currentDate to the same time as givenDate
    currentDate.setHours(0, 0, 0, 0);

    // Set the time of givenDate to the same time as currentDate
    givenDate.setHours(0, 0, 0, 0);

    // Calculate the difference in milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    const difference = currentDate.getTime() - givenDate.getTime();

    // Check if the difference is exactly one day
    return difference === oneDay;
  };

  const handleFirstVideoEnded = () => {
    if (videoPlayed === true) {
      shucaeRef.current.pause();
      videoRef.current.play();
    }
  };

  function convertToIsoDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `PT${hours}H${minutes}M${remainingSeconds}S`;
  }
  

  /*
   * USEEFECT
   */
  useEffect(() => {
    if (!logo.isLogo) {
      const _date = new Date();
      const video = document.getElementById("video");

      const handleVideoEnd = () => {
        setVideoPlayed(true);
        LogoUpdate({ isLogo: true, _date });
      };

      // Add event listener for video end
      video.addEventListener("ended", handleVideoEnd);

      // Play video on mount
      video.play().catch((error) => {
        // Handle play error
      });

      return () => {
        // Cleanup: Remove event listener
        video.removeEventListener("ended", handleVideoEnd);
      };
    } else {
      if (_IsOneDayOlder(logo._date)) {
        LogoUpdate({ isLogo: false });
      }
    }
  }, []);

  return (
    <>
      <Flex
        w="100%"
        h="100vh"
        zIndex={999}
        background="#000"
        // p={{base:'85% 0',md:'0'}}
        display={videoPlayed ? "none" : "block"}
      >
        <Flex w="100%" align="center" h="100%">
          <video
            ref={shucaeRef}
            id="video"
            autoPlay
            muted
            playsInline
            style={{
              objectFit: "cover",
              backgroundSize: "cover",
              width: "100%",
              // height: "200px"
            }}
            onEnded={handleFirstVideoEnded}
          >
            <source
              src="https://shucaeimage.blr1.cdn.digitaloceanspaces.com/WhatsApp%20Video%202024-02-21%20at%2010.06.58.mp4"
              type="video/mp4"
            />
            {/* Add additional source elements for other formats if needed */}
            Your browser does not support the video tag.
          </video>
        </Flex>
      </Flex>
    </>
  );
}

const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(LandingPage);
