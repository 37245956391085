/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { useLocation, useHistory } from "react-router-dom";
import {
  tabStyle,
  videoBigTextStyle,
  videoSmallTextStyle,
} from "components/Styles/index.style";
import Stories from "react-insta-stories";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  useMediaQuery,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Avatar,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Button,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import { useMutation, useLazyQuery, useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import _ from "underscore";

/*
 * QUERY
 */
import FollowLikeAppreciationRead from "../../Views/User/website//ProfilePage/__query__/index.profile.query";
import AccountDetail from "../../Views/User/website/ProfilePage/__query__/index.profileDetail.query";

import FollowerList from "./__query__/index.followers.list";
import FollowingList from "./__query__/index.following.list";

/*
 * MUTATION
 */
import SpecificUserfetch from "./__mutation__/index.creatorVideo.mutation";
import FollowingCard from "Views/User/website/UserProfilePage/FollowingCard";
import FollowerCard from "./FollowerCard";
/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [creatorVideos, setCreatorVideos] = useState();
  const [showStory, setShowStory] = useState(false);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [creatorVideosStatus, setCreatorVideosStatus] = useState("");
  const [expandedSummary, setExpandedSummary] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFollowing,
    onOpen: onOpenFollowing,
    onClose: onCloseFollowing,
  } = useDisclosure();

  const _FollowerList = useQuery(FollowerList, {
    context: {
      headers: {
        "l-authorization": account.token,},
    },
  });
  const _FollowingList = useQuery(FollowingList, {
    context: {
      headers: {
        "l-authorization": account.token, },
    },
  });
  const [SpecificUserfetchMutation, { loading: loadingCreatorVideos }] =
    useMutation(SpecificUserfetch, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const _FollowLikeAppreciationRead = useQuery(FollowLikeAppreciationRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
    variables: {
      accountId: id,
    },
  });

  const _AccountDetail = useQuery(AccountDetail, {
    context: {
      headers: {
        "l-authorization": account.token, },
    },
    variables: {
      accountId: id,
    },
  });
  const storiesData = _AccountDetail?.data?.AccountDetail?.Stories || [];
  const stories = storiesData.map((story) => ({
    url: story.profileStoryLink,
    id: story.id,
  }));

  /*
   * Functions
   */

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const formatDateProfile = (value) => {
    const date = new Date(value);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleCreatorVideos = async (id) => {
    try {
      const response = await SpecificUserfetchMutation({
        variables: {
          accountId: id,
        },
      });

      const data = response.data.SpecificUserfetch;

      if (!_.isEmpty(data)) {
        setCreatorVideos(data);
        setCreatorVideosStatus(data[0]?.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setShowStory(false);
    setCurrentStoryIndex(0);
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    handleCreatorVideos(id);
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex
            display={{ base: "none", md: "flex" }}
            pt="20px"
            pb="20px"
            transform="translate(30px , 10px)"
            maxW="700px"
            w="550px"
            h="630px"
            bg="#000000"
            borderRadius="2px"
            direction="column"
            align="center"
            gap="2rem"
          >
            <Avatar
              border={
                _AccountDetail?.data?.AccountDetail?.Stories?.[0]
                  ?.profileStoryLink
                  ? "3px solid rgba(255, 193, 7, 1)"
                  : "none"
              }
              onClick={
                _AccountDetail?.data?.AccountDetail?.Stories?.[0]
                  ?.profileStoryLink
                  ? () => setShowStory(!showStory)
                  : null
              }
              cursor="pointer"
              width="110px"
              h="110px"
              name={_AccountDetail?.data?.AccountDetail?.fullName}
              src="https://bit.ly/broken-link"
            />

            <Modal
              isOpen={showStory}
              onClose={() => setShowStory(!showStory)}
              isCentered
            >
              <ModalOverlay />
              <ModalContent bg="black">
                <ModalBody p="5px">
                  <Stories
                    stories={stories}
                    defaultInterval={5000}
                    width="100%"
                    height="100%"
                    currentIndex={currentStoryIndex}
                    onAllStoriesEnd={handleCloseModal}
                    onStoryEnd={() =>
                      setCurrentStoryIndex((prevIndex) => {
                        const nextIndex = prevIndex + 1;
                        return nextIndex >= stories.length ? 0 : nextIndex;
                      })
                    }
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Flex align="center" gap=".5rem" direction="column">
              <Text
                color="#FFFFFF"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="24px"
                lineHeight="30px"
              >
                {_AccountDetail?.data?.AccountDetail?.fullName}
              </Text>
              <Text
                color="#FFFFFF"
                fontFamily="Poppins"
                fontWeight="500"
                fontSize="14px"
                lineHeight="15px"
              >
                {_AccountDetail?.data?.AccountDetail?.userName
                  ? `@${_AccountDetail?.data?.AccountDetail?.userName}`
                  : null}
              </Text>
            </Flex>

            <Flex
              color="#D9D9D9"
              fontFamily="Poppins"
              fontWeight="400"
              fontSize="18.15px"
              lineHeight="36.3px"
              mt="3rem"
              align="center"
              direction="column"
            >
              <>
                <Flex
                  w="260px"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  isOpen={isOpen}
                  onClose={onClose}
                  onClick={onOpen} // Add onClick handler to open modal
                >
                  <Text>Followers</Text>
                  <Text>
                    {
                      _FollowLikeAppreciationRead?.data
                        ?.FollowLikeAppreciationRead?.followerCount
                    }
                  </Text>
                </Flex>
                {/* {isOpen && (
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent margin="auto" bg="#000">
                      <ModalHeader color="#fff">Follower</ModalHeader>
                      <ModalCloseButton color="#fff" />
                      <ModalBody>
                        {_FollowerList?.data?.FollowerList?.map((each) => (
                          <FollowerCard
                            account={account}
                            each={each}
                            key={each.id}
                          />
                        ))}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )} */}
              </>
              <>
                <Flex
                  w="260px"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  onClick={() => onOpenFollowing()}
                >
                  <Text>Following</Text>
                  <Text>
                    {
                      _FollowLikeAppreciationRead?.data
                        ?.FollowLikeAppreciationRead?.followingCount
                    }
                  </Text>
                </Flex>
                {/* {isOpenFollowing && ( 
                  <Modal isOpen={isOpenFollowing} onClose={onCloseFollowing}>
                    {" "}
                    <ModalOverlay />
                    <ModalContent margin="auto" bg="#000">
                      <ModalHeader color="#fff">Following</ModalHeader>
                      <ModalCloseButton color="#fff" />
                      <ModalBody>
                        {_FollowingList?.data?.FollowingList?.map((each) => (
                          <FollowingCard
                            account={account}
                            each={each}
                            key={each.id}
                          />
                        ))}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )} */}
              </>

              <Flex w="260px" align="center" justify="space-between">
                <Text>Videos liked</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalLikes
                  }
                </Text>
              </Flex>
              <Flex w="260px" align="center" justify="space-between">
                <Text>Videos added to list</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalMyList
                  }
                </Text>
              </Flex>
              <Flex w="260px" align="center" justify="space-between">
                <Text>Videos watched</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalViews
                  }
                </Text>
              </Flex>
            </Flex>
            <Text
              mt="4rem"
              color="#A9AAA9"
              fontFamily="Poppins"
              fontSize="11px"
              fontWeight="400"
              lineHeight="15px"
              letterSpacing="0.11px"
            >
              {`MEMBER SINCE : ${formatDateProfile(
                _AccountDetail?.data?.AccountDetail?.createdAt
              )}`}
            </Text>
          </Flex>
          <Flex
            overflow="scroll"
            direction="column"
            ml={{ base: "0px", md: "36px" }}
            w="100%"
          >
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Videos
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />

              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {loadingCreatorVideos ? (
                    <Flex
                      w="100%"
                      h="100%"
                      mt="12rem"
                      align="center"
                      justify="center"
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="purple.800"
                        size="xl"
                      />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {creatorVideosStatus === "NO_CONTENT" ? (
                        <Flex h="500px" align="center" justify="center">
                          <Text
                            color="#FFFFFF"
                            fontFamily="Poppins"
                            fontWeight="600"
                            fontSize="24px"
                            lineHeight="30px"
                            textAlign="center"
                          >
                            No Content Uploaded By Creator
                          </Text>
                        </Flex>
                      ) : (
                        creatorVideos?.map((data, index) => (
                          <Flex
                            p="15px"
                            key={data.id}
                            borderRadius="10px"
                            bg="#000000"
                            w="100%"
                            gap="1rem"
                            direction={{ base: "column", lg: "row" }}
                          >
                            <Flex
                              cursor="pointer"
                              onClick={() =>
                                history.push(
                                  `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                    / /g,
                                    "-"
                                  )}/${data?.id}`
                                )
                              }
                              w="256px"
                              h="158px"
                            >
                              <Image
                                w="100%"
                                h="100%"
                                src={data?.lowerThumbnailLink}
                              />
                            </Flex>
                            <Flex gap=".5rem" direction="column">
                              <Text
                                cursor="pointer"
                                onClick={() =>
                                  history.push(
                                    `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                      / /g,
                                      "-"
                                    )}/${data?.id}`
                                  )
                                }
                                style={videoBigTextStyle}
                              >
                                {data?.ContentSummary?.contentTitle}
                              </Text>
                              <Text
                                w={{ base: "256px", lg: "500px" }}
                                style={videoSmallTextStyle}
                              >
                                {expandedSummary[index] ? (
                                  <span>
                                    {data.plotSummary}
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(index)}
                                    >
                                      read less
                                    </Text>
                                  </span>
                                ) : (
                                  <span>
                                    {data?.plotSummary
                                      ?.split(" ")
                                      ?.slice(0, 20)
                                      ?.join(" ")}
                                    {data.plotSummary?.split(" ").length >
                                      20 && (
                                      <Text
                                        color="rgba(0, 129, 223, 1)"
                                        cursor="pointer"
                                        onClick={() => toggleSummary(index)}
                                      >
                                        read more
                                      </Text>
                                    )}
                                  </span>
                                )}
                              </Text>
                              <Flex gap=".5rem" direction="column">
                                <Flex align="center" gap="1rem">
                                  <Text
                                    color="rgba(69, 211, 105, 1)"
                                    fontFamily="Roboto"
                                    fontSize="19px"
                                    fontWeight="700"
                                    lineHeight="21.54px"
                                    letterSpacing="-0.36px"
                                  >
                                    {new Date(data?.createdAt).getFullYear()}
                                  </Text>
                                  <Text
                                    color="rgba(186, 186, 186, 1)"
                                    fontFamily="Roboto"
                                    fontSize="19px"
                                    fontWeight="400"
                                    lineHeight="21.54px"
                                    letterSpacing="-0.36px"
                                  >
                                    {formatDuration(data.duration)}
                                  </Text>
                                  <Flex
                                    borderRadius="3px"
                                    justify="center"
                                    align="center"
                                    border="1px solid rgba(116, 116, 116, 1)"
                                    color="rgba(255, 255, 255, 1)"
                                    fontFamily="Roboto"
                                    fontWeight="400"
                                    fontSize="12px"
                                    lineHeight="21.54px"
                                    p="0px 5px 0px 5px"
                                  >
                                    HD
                                  </Flex>
                                </Flex>
                                <Flex align="center" gap="1rem">
                                  <Flex
                                    color="white"
                                    w="70px"
                                    h="20px"
                                    justify="center"
                                    align="center"
                                    border="1px solid rgba(116, 116, 116, 1)"
                                  >
                                    {`${
                                      data?.ContentSummary?.ageRating === "18"
                                        ? "A"
                                        : "U/A"
                                    } ${data?.ContentSummary?.ageRating}+`}
                                  </Flex>
                                  <Text style={videoSmallTextStyle}>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {data?.ContentSummary?.genre}
                                    </span>
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        ))
                      )}
                    </Flex>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
