/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    PodcastReadAll {
      PodcastDescription
      podcastTitle
      horizontalPosterLink
      message
      status
      id
      host
      guest
      directedBy
      producedBy
      createdAt
      verticalPosterLink
      PodcastEpisode {
        audioLink
        episodeDescription
        episodeNumber
        id
        episodeTitle
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
