/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  mutation AccountGoogleLoginMutation($googleToken: String!) {
    AccountGoogleLogin(googleToken: $googleToken) {
      status
      message
      displayName
      email
      token
      profileCoverThumbnailStoredAt
      activeAs
      accountType
      isActive
      approved
      phone
      fullName
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
