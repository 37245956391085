/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($accountId: ID!) {
    SpecificUserfetch(accountId: $accountId) {
      status
      message
      id
      thumbnailLink
      lowerThumbnailLink
      likes
      ContentSummary {
        contentTitle
        ageRating
        genre
      }
      createdAt
      duration
      plotSummary
      other
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
