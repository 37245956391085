/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $ageRating: String!
    $cast: [JSON]!
    $content: [JSON]!
    $contentLanguage: String!
    $contentTitle: String!
    $contentType: String!
    $dop: String
    $producer: String!
    $genre: [String]!
    $music: String!
  ) {
    ContentAddMore(
      ageRating: $ageRating
      cast: $cast
      content: $content
      contentLanguage: $contentLanguage
      contentTitle: $contentTitle
      contentType: $contentType
      dop: $dop
      producer: $producer
      genre: $genre
      music: $music
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
