/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-shadow */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState } from "react";
import _ from "underscore";
import { useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import { useHistory } from "react-router-dom"; // NPM: React-router-dom for routing.

/*
 * CHAKRA UI IMPORT
 */
import {
  FormControl,
  FormLabel,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Input,
  useDisclosure,
  useToast,
  Tooltip,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import GenerateToken from "./__mutation__/index.generateDevToken.mutation";

/*
 * OBJECT
 */
function Index({ account, contentDetailId, disable, setRefetch }) {
  /*
   * STATE
   */
  const toast = useToast();
  const history = useHistory();
  const [formData, setFormData] = useState({
    companyEmail: "",
    companyName: "",
    phone: "",
    reason: "",
  });
  const [modalOpen, setModalOpen] = useState(true);
  const {
    isOpen: isOpenWebSeries,
    onOpen: onOpenWebSeries,
    onClose: onCloseWebSeries,
  } = useDisclosure();

  const [GenerateTokenMutation, { loading: loadingGenerateToken }] =
    useMutation(GenerateToken, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const labelStyle = {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#B5C9DB",
  };
  const inputStyle = {
    // Width: "410px",
    height: "50px",
    border: "1px solid #3D445A",
    borderRadius: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "-0.28px",
    color: "#878992",
  };
  const inputStyle1 = {
    // Width: "852px",
    height: "50px",
    border: "1px solid #3D445A",
    borderRadius: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "-0.28px",
    color: "#878992",
  };

  const resetForm = () => {
    setFormData({
      companyEmail: "",
      companyName: "",
      phone: "",
      reason: "",
    });
  };

  /*
   * FUNCTION
   */
  const handleGenerateToken = async (e) => {
    e.preventDefault();
    try {
      const response = await GenerateTokenMutation({
        variables: {
          companyEmail: formData.companyEmail,
          companyName: formData.companyName,
          phone: formData.phone,
          reason: formData.reason,
        },
      });

      const data = response.data.GenerateToken;

      if (data?.status === "CREATED_TOKEN") {
        setRefetch(true);
        toast({
          title: "Form submitted",
          description: "Your request has been sent successfully.",
          status: "success",
          duration: 5000, // milliseconds
          isClosable: true,
        });
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line no-console
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    if (modalOpen) {
      resetForm();
      onCloseWebSeries();
    }
  };

  const handleButtonClick = () => {
    if (account.isUserLoggedIn) {
      onOpenWebSeries();
    } else {
      history.push('/signin'); // Redirect to sign-in page if not logged in
    }
  };


  return (
    <>
      <Tooltip
        label={
          disable === "PENDING"
            ? "Your request has been sent pls wait for the response."
            : disable === "ACCEPTED"
            ? "Your request has been accepted"
            : disable === "DECLINED"
            ? "Your request has been declined"
            : "Request Developer Token"
        }
      >
        <Flex>
          <Button
            onClick={handleButtonClick}
            zIndex="1"
            w="100%"
            h="52px"
            borderRadius="10px"
            border="1px solid linear-gradient(90.49deg, #052DB0 0.15%, #C30280 101.09%)"
            mb={{ base: "20px" }}
            fontFamily="Poppins"
            fontWeight="600"
            fontSize="20px"
            lineHeight="31.74px"
            letterSpacing="-0.4px"
            gap=".5rem"
            cursor={
              disable === "PENDING" ||
              disable === "DECLINED" ||
              disable === "ACCEPTED"
                ? "not-allowed"
                : "pointer"
            }
            isDisabled={
              disable === "PENDING" ||
              disable === "DECLINED" ||
              disable === "ACCEPTED"
                ? true
                : false
            }
          >
            Request Developer Token
          </Button>
        </Flex>
      </Tooltip>

      <Modal
        size="5xl"
        isOpen={isOpenWebSeries}
        onClose={handleClose}
        scrollBehavior="inside"
        isCentered
        closeOnOverlayClick={false}
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          p={{ base: "7px", md: "15px" }}
          borderRadius="20px"
          bg="rgba(42, 45, 62, 1)"
          position="fixed"
        >
          <ModalHeader
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={{ base: "25px", md: "32px" }}
            lineHeight="28.21px"
            letterSpacing="-0.64px"
            color="rgba(255, 255, 255, 1)"
          >
            Request Developer Token
          </ModalHeader>
          <ModalCloseButton
            size="lg"
            mt="15px"
            mr="10px"
            color="rgba(238, 93, 80, 1)"
          />
          <ModalBody pb={10} overflowY="auto" overflowX="hidden">
            {/* /
            Form
            / */}

            <form onSubmit={(e) => handleGenerateToken(e)}>
              <Flex gap="1rem" direction="column">
                {/* /
                Link of Content
                and
                Plot Summary
                and 
                Duration
                and
                Thumbnail
                / */}

                <Flex
                  p="1rem 10px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w={{ base: "106%", md: "100%" }}
                  ml={{ base: "-0.4rem", md: "0" }}
                >
                  <Flex
                    gap="1rem"
                    direction="column"
                    w={{ base: "100%", md: "92%" }}
                  >
                    <Flex
                      gap="32px"
                      align="center"
                      direction={{ base: "column", md: "row" }}
                      width="100%"
                    >
                      <FormControl isRequired>
                        <FormLabel style={labelStyle}>Company name</FormLabel>
                        <Input
                          name="companyName"
                          type="text"
                          onChange={handleInputChange}
                          value={formData.companyName}
                          placeholder="Company name"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          w={{ base: "320px", md: "410px" }}
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel style={labelStyle}>Company email</FormLabel>
                        <Input
                          name="companyEmail"
                          type="email"
                          onChange={handleInputChange}
                          value={formData.companyEmail}
                          placeholder="Company email"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          w={{ base: "320px", md: "410px" }}
                        />
                      </FormControl>
                    </Flex>
                    <Flex
                      gap="32px"
                      align="center"
                      direction={{ base: "column", md: "row" }}
                      width="100%"
                    >
                      <FormControl isRequired>
                        <FormLabel style={labelStyle}>Phone number</FormLabel>
                        <Input
                          name="phone"
                          type="number"
                          onChange={handleInputChange}
                          value={formData.phone}
                          placeholder="Phone number"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          w={{ base: "320px", md: "410px" }}
                        />
                      </FormControl>
                    </Flex>

                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>
                        Reason for developer token
                      </FormLabel>
                      <Input
                        name="reason"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.reason}
                        placeholder="Reason for developer token"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle1}
                        width={{ base: "320px", md: "852px" }}
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                <Flex
                  color="rgba(43, 54, 116, 1)"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="16px"
                  fontFamily="Roboto"
                  letterSpacing="-0.32px"
                  justifyContent="center"
                  mt="3rem"
                >
                  <Button
                    _focus={{ outline: "2px solid rgba(66, 153, 225, 0.6)" }}
                    type="submit"
                    width={{ base: "361px", md: "385px" }}
                    height={{ base: "41.34px", md: "54px" }}
                    borderRadius={{ base: "7.6px", md: "10px" }}
                    bg="rgba(255, 255, 255, 1)"
                  >
                    <Text
                      bg="linear-gradient(96.31deg, #052DB0 7.3%, #C30280 118.79%)"
                      bgClip="text"
                    >
                      {loadingGenerateToken ? (
                        <Spinner size="lg" color="black" />
                      ) : (
                        "Submit"
                      )}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

/*
 * EXPORT
 */

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
