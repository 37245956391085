/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  mutation AccountGoogleRegisterMutation($googleToken: String!) {
    AccountGoogleRegister(googleToken: $googleToken) {
      status
      fullName
      token
      updatedAt
      createdAt
      displayName
      email
      message
      
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
