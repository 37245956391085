/*
 * IMPORTS
 */
import React from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import Loader from "Views/User/website/VideoPlayPage/Loader";
import { Helmet } from "react-helmet";
import Carousels from "../../Views/User/website/LandingPage/Components/carousel";
/*
 * CHAKRA IMPORTS
 */
import { Flex, Box } from "@chakra-ui/react";

/*
 * QUERY
 */

import CategoryRead from "./__query__/index.categoryRead";

/*
 * OBJECT
 */
function Index({ account }) {
  const _CategoryRead = useQuery(CategoryRead);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "ListElement": [
          ${
            _CategoryRead?.data?.CategoryRead?.map((item, index) => {
              if (item.name === "Shucae Content") {
                return `
                  {
                    "@type": "${item.name}",
                    "position": ${index + 1},
                    "itemListElement": [
                      ${item.ContentDetailCategory?.map((each, index) => {
                        return `
                          {
                            "position": ${index + 1},
                            "@type": "${each?.ContentSummary?.contentTitle}",
                            "image": "${
                              each?.ContentSummary?.ContentDetail[0]
                                .lowerThumbnailLink
                            }",
                            "url": "https://www.shucaefilms.com/video-play-page/${each?.ContentSummary?.contentTitle?.replace(
                              / /g,
                              "-"
                            )}/${item?.id}"
                          }
                        `;
                      })}
                    ]
                  }
                `;
              } else {
                return ""; // Return an empty string for non-"Top Ten" items
              }
            })
              .filter(Boolean)
              .join("") // Filter out empty strings and join the results
          }
        ]
      }
    `}
        </script>
      </Helmet>
      <Box bg="#00020A">
        <Navbar />
        <>
          {_CategoryRead?.loading ? (
            <Flex
              align="center"
              justify="center"
              bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
              h="100vh"
              w="100%"
            >
              <Loader />
            </Flex>
          ) : (
            <Flex
              pt="100px"
              pb="100px"
              bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
              direction="column"
            >
              {_CategoryRead?.data?.CategoryRead.map((data, index) => (
                <Carousels
                  key={data.id}
                  props={{
                    title: data?.name,
                    trayData: data?.ContentDetailCategory,
                    trayId: data?.id,
                    loading: _CategoryRead?.loading,
                    account: account,
                  }}
                />
              ))}
            </Flex>
          )}
        </>

        <Footer />
      </Box>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
