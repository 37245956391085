/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($contentDetailId: ID!) {
    GetSpecificTrailer(contentDetailId: $contentDetailId) {
      ContentSummary {
        contentTitle
        id
      }
      id
      trailerLink
      lowerThumbnailLink
      lowerVerticalThumbnailStoredAt
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
