/*
 * IMPORT
 */
import React, { useState, useEffect, useRef } from "react";
import _ from "underscore";
import twitter from "../../Assets/images/landingPage/twitter.svg";
import insta from "../../Assets/images/landingPage/insta.svg";
import facebook from "../../Assets/images/landingPage/facebook.svg";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { ShareSocial } from "react-share-social";
import { textStyle } from "../../components/Styles/index.style";
/*
 * REACT ICON IMPORT
 */
import { FaPlay, FaThumbsUp } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";

import { BsPlusLg } from "react-icons/bs";
import { FaRegThumbsUp } from "react-icons/fa";
import { IoCloseCircleSharp } from "react-icons/io5";
import { FaBell, FaTimes } from "react-icons/fa";
import { BsFillShareFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";

/*
 * CHAKRA UI IMPORT
 */
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button,
  Flex,
  Box,
  Avatar,
  Divider,
  useClipboard,
  useToast,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import ContentFetchPerVideo from "../../Views/User/website/VideoPlayPage/__mutation__/index.contentFetchPerVideo.mutation";
import AccountFollwoUnfollow from "../../Views/User/website/UserProfilePage/__mutation__/index.followUnfollow.mutation";
import { useMutation, useLazyQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * QUERY
 */
import AccountFollowStatus from "../../Views/User/website/LandingPage/__query__/index.followStatus.query";
import ContentLikeCount from "../../Views/User/website/__query__/index.like";
import ContentVidioLikeDislike from "../../Views/User/website/LandingPage/__mutation__/index.likeDislike.mutation";
/*
 * OBJECT
 */
const MovieDetail = ({
  loadingMyListCreate,
  handleAddHistory,
  isOpen,
  onClose,
  addToMyList,
  account,
  movieDetails,
  handleMyListAdd,
}) => {
  /*
   * STATE
   */
  const [videoData, setVideoData] = useState();
  const [likeData, setLikeData] = useState([]);
  const [status, setStatus] = useState("");
  const [followStatus, setFollowStatus] = useState("");
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [expandedSummary, setExpandedSummary] = useState({});
  const [timeStamp, setTimeStamp] = useState();
  const playerRef = useRef(null);
  const [ready, setReady] = useState(false);
  const toast = useToast();
  const [contentItems, setContentItems] = useState([]);
  const [likedStatus, setlikedStatus] = useState("");

  const [isShareOpen, setIsShareOpen] = useState(false);
  const shareUrl = `https://shucaefilms.com/video-play-page/${movieDetails?.contentTitle?.replace(
    / /g,
    "-"
  )}/${movieDetails?.ContentDetail?.[0]?.id}`;
  const { hasCopied, onCopy } = useClipboard(
    `https://shucaefilms.com/video-play-page/${movieDetails?.contentTitle?.replace(
      / /g,
      "-"
    )}/${movieDetails?.ContentDetail?.[0]?.id}`
  );
  const [genresData, setGenresData] = useState({
    action: [],
    comedy: [],
    romance: [],
    drama: [],
    horrow: [],
    mystery: [],
    fantasy: [],
    thriller: [],
    music: [],
    education: [],
    scienceFiction: [],
  });
  const history = useHistory();
  const [like, setLike] = useState();
  const shareStyle = {
    root: {
      background: "rgba(100, 100, 100,1)",
      borderRadius: 10,
      border: 0,
      color: "white",
    },
    copyContainer: {
      background: "rgba(0,0,0,.5)",
      color: "black",
    },
    copyIcon: {
      color: "white",
    },
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const [ContentFetchPerVideoMutation, { loading: loadingVideoPlay }] =
    useMutation(ContentFetchPerVideo);
  const [
    AccountFollowStatusQuery,
    { loading: loadingFollowStatus, refetch: refetchFollowStatus },
  ] = useLazyQuery(AccountFollowStatus, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });
  const [AccountFollwoUnfollowMutation, { loading: loadingFollowUnFollow }] =
    useMutation(AccountFollwoUnfollow, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });
  const [
    ContentLikeCountQuery,
    { loading: loadingLikeStatus, refetch: refetchLikeStatus },
  ] = useLazyQuery(ContentLikeCount, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [ContentVidioLikeDislikeMutation, { loading: loadinglikeDislike }] =
    useMutation(ContentVidioLikeDislike, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const handleLikeDislikeVideo = async (id) => {
    try {
      const response = await ContentVidioLikeDislikeMutation({
        variables: {
          id: id,
        },
      });
      const updatedContentItems = contentItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            likedStatus: response.data.ContentVidioLikeDislike.status,
          };
        }
        return item;
      });

      setContentItems(updatedContentItems);

      const data = response.data.ContentVidioLikeDislike;

      setlikedStatus(data);

      const status = response.data.ContentVidioLikeDislike.status;
      if (status === "LIKED") {
        toast({
          title: "Liked",
          description: "You have liked this video",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        refetchLikeStatus({
          accountId: id,
        });
      } else if (status === "DISLIKED") {
        refetchLikeStatus({
          accountId: id,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*
   * FUNCTION
   */

  const handleSocialButtonClick = (socialType) => {
    switch (socialType) {
      case "facebook":
        openSocialAppOrBrowser("https://www.facebook.com/");
        break;
      case "whatsapp":
        openSocialAppOrBrowser(
          `whatsapp://send?text=${encodeURIComponent(shareUrl)}`
        );
        break;
      case "twitter":
        openSocialAppOrBrowser(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`
        );
        break;
      case "linkedin":
        openSocialAppOrBrowser(
          `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
            shareUrl
          )}`
        );
        break;
      case "telegram":
        openSocialAppOrBrowser(
          `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}`
        );
        break;
      case "email":
        window.location.href = `mailto:?subject=Check%20out%20this%20link&body=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      default:
        // Handle other social buttons if needed

        break;
    }
  };
  const openSocialAppOrBrowser = (url) => {
    // Try to open the URL in the app
    window.open(url, "_blank");

    // Fallback to opening in the browser if the app is not available
    setTimeout(() => {
      window.location.href = url;
    }, 1000);
  };
  const handleVideoPlay = async (id) => {
    try {
      const response = await ContentFetchPerVideoMutation({
        variables: {
          id: id,
        },
      });

      const data = response.data.ContentFetchPerVideo;

      if (!_.isEmpty(data)) {
        setVideoData(data);
        const durationInSeconds = data.duration * 60;
        const halfDurationTimestamp = durationInSeconds / 2;
        setTimeStamp(halfDurationTimestamp);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleFollowUnFollow = async (id) => {
    try {
      const response = await AccountFollwoUnfollowMutation({
        variables: {
          followerId: id,
        },
      });

      const data = response.data.AccountFollwoUnfollow;

      if (!_.isEmpty(data)) {
        if (data.status === "FOLLOW_SUCCESSFULLY") {
          setStatus(data.status);
          toast({
            title: "Followed successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          refetchFollowStatus({
            accountId: id,
          });
          setFollowStatus("FOLLOW_SUCCESSFULLY");
        } else if (data.status === "UNFOLLOW_SUCCESSFUL") {
          setStatus(data.status);
          toast({
            title: "Unfollowed successfully",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
          refetchFollowStatus({
            accountId: id,
          });
          setFollowStatus("UNFOLLOW_SUCCESSFULLY");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const toggleShare = () => {
    setIsShareOpen(!isShareOpen);
  };
  const handleShareClick = () => {
    setIsShareOpen(!isShareOpen);

    onCopy(); // This will copy the text to the clipboard

    toast({
      position: "top-right",
      title: "Link Copied",
      description: "The link has been copied to the clipboard.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };
  const handleFollowStatus = async (id) => {
    try {
      const response = await AccountFollowStatusQuery({
        variables: {
          accountId: id,
        },
      });

      const data = response.data.AccountFollowStatus;

      if (!_.isEmpty(data)) {
        if (data.status === "FOLLOW_SUCCESSFULLY") {
          setFollowStatus("FOLLOW_SUCCESSFULLY");
        } else if (data.status === "UNFOLLOW_SUCCESSFUL") {
          setFollowStatus("UNFOLLOW_SUCCESSFUL");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  function handleFollowUnfollowRequest(id) {
    if (account.isUserLoggedIn) {
      handleFollowUnFollow(id);
    } else {
      history.push("/signin");
    }
  }
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const handleLikeStatus = async (id) => {
    try {
      const response = await ContentLikeCountQuery({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.ContentLikeCount;

      setLikeData(data);
    } catch (error) {}
  };
  const handleMediaLinkClick = (media) => {
    if (!movieDetails?.Account?.media) {
      toast({
        title: `No ${media} Link!`,
        description: `Please add a ${media} link.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /*
   * USEEFFECT
   */

  useEffect(() => {
    if (isOpen) {
      if (ready && playerRef.current) {
        playerRef.current.seekTo(timeStamp);
      }
      handleVideoPlay(movieDetails?.ContentDetail[0]?.id);
      handleLikeStatus(movieDetails?.ContentDetail[0]?.id);
      handleFollowStatus(movieDetails?.Account?.id);
    }
  }, [isOpen, movieDetails?.id, ready]);

  const handleLoginCheck = (item) => {
    if (account.isUserLoggedIn) {
      history.push(`/creator-profile?id=${movieDetails?.Account?.id}`);
    } else {
      history.push("/signin");
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setIsShareOpen(false);
        }}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody borderRadius="5px" bg="rgba(0, 2, 10, 1)" padding="0px">
            <Box
              borderRadius="10px"
              position="relative"
              overflow="hidden"
              bg="black"
              h="30rem"
            >
              {/* <Image
                position="absolute"
                top="0"
                w="100%"
                h="30rem"
                objectFit="cover"
                src={movieDetails?.thumbnailLink}
              /> */}
              <Flex h="100%" w="100%" position="absolute">
                <ReactPlayer
                  ref={playerRef}
                  playing={isOpen && ready}
                  onReady={() => setReady(true)}
                  onProgress={(state) => {
                    if (state.playedSeconds >= timeStamp + 50) {
                      playerRef.current.seekTo(timeStamp);
                    }
                  }}
                  url={videoData && videoData?.straminLinks}
                  width="100%"
                  height="100%"
                  onContextMenu={(e) => e.preventDefault()} // Prevents right-click context menu
                  onTouchStart={(e) => e.preventDefault()} // Prevents long-tap context menu
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </Flex>

              <Flex
                position="absolute"
                h="100%"
                w="100%"
                alignItems="center"
                bg="linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.66) 30.6%, rgba(0, 0, 0, 0.00) 103.12%)"
              >
                <Flex
                  h="100%"
                  flexDir="column"
                  ml="2rem"
                  justify="flex-end"
                  gap="20px"
                >
                  <Text
                    pb="15px"
                    color="rgba(255, 255, 255, 1)"
                    fontFamily="Oswald"
                    fontWeight="700"
                    fontSize={{ base: "35px", md: "50px" }}
                    lineHeight="45px"
                    letterSpacing="-0.75px"
                    w="20rem"
                  >
                    {movieDetails?.contentTitle}
                  </Text>
                  <Flex align="top" gap="1rem">
                    <Button
                      w="112px"
                      gap=".7rem"
                      h={{ base: "40px", md: "45px" }}
                      borderRadius="5px"
                      bg="white"
                      color="black"
                      mb="20px"
                      onClick={() => {
                        history.push(
                          `/video-play-page/${movieDetails?.contentTitle?.replace(
                            / /g,
                            "-"
                          )}/${movieDetails?.ContentDetail?.[0]?.id}`
                        );
                        handleAddHistory(movieDetails);
                      }}
                    >
                      <FaPlay />
                      Play
                    </Button>
                    <Flex gap="10px">
                      <Flex direction="column" align="center">
                        <Flex
                          w="40px"
                          h="40px"
                          bg={
                            addToMyList?.MyListCount !== undefined
                              ? addToMyList.status ===
                                "VIDEO_MYLIST_SUCCESSFULLY_REMOVED"
                                ? "black"
                                : "white"
                              : likeData?.myListStatusMessage !== "ADDED"
                              ? "black"
                              : "white"
                          }
                          borderRadius="50%"
                          border="2px solid #979898"
                          justify="center"
                          align="center"
                          cursor="pointer"
                          onClick={() =>
                            handleMyListAdd(
                              movieDetails?.ContentDetail?.[0]?.id
                            )
                          }
                        >
                          {loadingMyListCreate ? (
                            <Spinner color="purple.600" />
                          ) : addToMyList?.MyListCount !== undefined ? (
                            addToMyList.status ===
                            "VIDEO_MYLIST_SUCCESSFULLY_REMOVED" ? (
                              <FaPlus color="white" size="20px" />
                            ) : (
                              <FaPlus color="black" size="20px" />
                            )
                          ) : likeData?.myListStatusMessage !== "ADDED" ? (
                            <FaPlus color="white" size="20px" />
                          ) : (
                            <FaPlus color="black" size="20px" />
                          )}
                        </Flex>
                        <Text color="#fff">
                          {addToMyList?.MyListCount !== undefined
                            ? addToMyList?.MyListCount
                            : likeData?.myListCount}
                        </Text>
                      </Flex>
                      <Flex direction="column" align="center">
                        <Flex
                          w="40px"
                          h="40px"
                          borderRadius="50%"
                          border="2px solid #979898"
                          justify="center"
                          align="center"
                          cursor="pointer"
                          bg={like ? "purple.800" : "transparent"}
                          onClick={() =>
                            handleLikeDislikeVideo(
                              movieDetails?.ContentDetail?.[0]?.id
                            )
                          }
                        >
                          {loadinglikeDislike ? (
                            <Spinner color="purple.600" />
                          ) : likedStatus?.likeCount !== undefined ? (
                            likedStatus.status !== "LIKED" ? (
                              <FaRegThumbsUp color="white" size="20px" />
                            ) : (
                              <FaThumbsUp color="white" size="20px" />
                            )
                          ) : likeData?.likeStatusMessage !== "LIKED" ? (
                            <FaRegThumbsUp color="white" size="20px" />
                          ) : (
                            <FaThumbsUp color="white" size="20px" />
                          )}
                        </Flex>
                        <Text color="#fff">
                          {" "}
                          {likedStatus.likeCount !== undefined
                            ? likedStatus.likeCount
                            : likeData?.likeCount}
                        </Text>
                      </Flex>
                      <Flex direction="column" align="center">
                        <Flex
                          w="40px"
                          h="40px"
                          borderRadius="50%"
                          border="2px solid #979898"
                          justify="center"
                          align="center"
                          cursor="pointer"
                          onClick={handleShareClick}
                        >
                          <BsFillShareFill color="white" size="20px" />
                        </Flex>
                        {isShareOpen && (
                          <Flex
                            position="fixed"
                            bottom="0"
                            left="0"
                            right="0"
                            justifyContent="center"
                            alignItems="center"
                            zIndex="999"
                          >
                            <Flex
                              w={{ base: "100%", md: "500px" }}
                              position="relative"
                              borderRadius="5px"
                            >
                              <ShareSocial
                                style={shareStyle}
                                url={shareUrl}
                                socialTypes={[
                                  "facebook",
                                  "whatsapp",
                                  "twitter",
                                  "linkedin",
                                  "telegram",
                                  "email",
                                ]}
                                onSocialButtonClicked={handleSocialButtonClick}
                              />
                              <Flex position="absolute" top="2" right="2">
                                <FaTimes
                                  size="20px"
                                  onClick={toggleShare}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>

                    <IoCloseCircleSharp
                      color="#979898"
                      cursor="pointer"
                      onClick={() => {
                        onClose();
                        setIsShareOpen(false);
                      }}
                      size="50px"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
            <Flex
              direction="column"
              align="center"
              bg="rgba(0, 2, 10, 1)"
              justify="center"
            >
              <Flex
                m="15px 0px 15px 0px"
                borderRadius="10px"
                bg="rgba(39, 39, 39, 1)"
                w="90%"
                h="10rem"
                justify="center"
                direction="column"
                gap="8.5px"
                p="5px 15px 5px 15px"
              >
                <Text
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="Roboto"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="21.54px"
                  letterSpacing="0.2px"
                >
                  About Creator
                </Text>

                <Flex align="center" justify="space-between">
                  <Flex gap={{ base: "5px", md: "15px" }} align="center">
                    <Flex
                      w={{ base: "35.32px", md: "41.64px" }}
                      h={{ base: "35.32px", md: "41.64px" }}
                      borderRadius="50%"
                    >
                      <Avatar
                        w={{ base: "35.32px", md: "41.64px" }}
                        h={{ base: "35.32px", md: "41.64px" }}
                        name={movieDetails?.Account?.displayName}
                        src="https://bit.ly/broken-link"
                      />
                    </Flex>
                    <Flex direction="column">
                      <Text
                        color="rgba(255, 255, 255, 1)"
                        fontFamily="Roboto"
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="21.54px"
                        letterSpacing="0.14px"
                        cursor="pointer"
                        onClick={handleLoginCheck}
                      >
                        {movieDetails?.Account?.fullName}
                      </Text>
                      <Text
                        color="rgba(116, 116, 116, 1)"
                        fontFamily="Roboto"
                        fontWeight="400"
                        lineHeight="21.54px"
                        fontSize="8px"
                        letterSpacing="0.08px"
                      >
                        {movieDetails?.Account?.fullName}
                      </Text>
                    </Flex>
                    <Flex
                      color="white"
                      justify="center"
                      align="center"
                      gap="5px"
                      h="30px"
                      w="87px"
                      cursor="pointer"
                      borderRadius="4px"
                      border="1px solid rgba(116, 116, 116, 1)"
                      fontFamily="Poppins"
                      mr="2"
                      fontWeight="300"
                      fontSize="13.26px"
                      lineHeight="82px"
                      onClick={() => {
                        handleFollowUnfollowRequest(movieDetails?.Account?.id);
                      }}
                    >
                      {loadingFollowUnFollow ? (
                        <Spinner />
                      ) : (
                        <>
                          <FaBell size="12px" />{" "}
                          <Text>
                            {followStatus === "FOLLOW_SUCCESSFULLY"
                              ? "Following"
                              : "Follow"}
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                  <Flex gap={{ base: "5px", md: "15px" }}>
                    <>
                      {movieDetails?.Account?.twitter ? (
                        <a
                          href={
                            movieDetails?.Account?.twitter?.startsWith("http")
                              ? movieDetails?.Account?.twitter
                              : `https://${movieDetails?.Account?.twitter}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            w="25px"
                            h="25px"
                            cursor="pointer"
                            src={twitter}
                          />
                        </a>
                      ) : (
                        <span onClick={() => handleMediaLinkClick("twitter")}>
                          <Image
                            w="25px"
                            h="25px"
                            cursor="pointer"
                            src={twitter}
                          />
                        </span>
                      )}
                    </>

                    <Divider
                      h="25px"
                      borderWidth="1px"
                      color="rgba(255, 255, 255, 1)"
                      orientation="vertical"
                    />
                    <>
                      {movieDetails?.Account?.instagram ? (
                        <a
                          href={
                            movieDetails?.Account?.instagram.startsWith("http")
                              ? movieDetails?.Account?.instagram
                              : `https://${movieDetails?.Account?.instagram}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            w="25px"
                            h="25px"
                            cursor="pointer"
                            src={insta}
                          />
                        </a>
                      ) : (
                        <span onClick={() => handleMediaLinkClick("instagram")}>
                          <Image
                            w="25px"
                            h="25px"
                            cursor="pointer"
                            src={insta}
                          />
                        </span>
                      )}
                    </>

                    <Divider
                      h="25px"
                      borderWidth="1px"
                      color="rgba(255, 255, 255, 1)"
                      orientation="vertical"
                    />
                    <>
                      {movieDetails?.Account?.facebook ? (
                        <a
                          href={
                            movieDetails?.Account?.facebook?.startsWith("http")
                              ? movieDetails?.Account?.facebook
                              : `https://${movieDetails?.Account?.facebook}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            w="25px"
                            h="25px"
                            cursor="pointer"
                            src={facebook}
                          />
                        </a>
                      ) : (
                        <span onClick={() => handleMediaLinkClick("facebook")}>
                          <Image
                            w="25px"
                            h="25px"
                            cursor="pointer"
                            src={facebook}
                          />
                        </span>
                      )}
                    </>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                m="15px 0px 15px 0px"
                borderRadius="10px"
                bg="rgba(39, 39, 39, 1)"
                w="90%"
                direction="column"
                justify="space-between"
                p="20px"
              >
                <Text
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="Roboto"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="21.54px"
                  letterSpacing="0.2px"
                >
                  About Movie
                </Text>
                <Flex
                  w="100%"
                  gap="10px"
                  justify="space-between"
                  direction={{ base: "column", md: "row" }}
                >
                  <Flex
                    gap="20px"
                    w={{ base: "100%", md: "100%" }}
                    direction="column"
                  >
                    <Flex w="100%" align="center" gap="8px">
                      <Text
                        color="rgba(69, 211, 105, 1)"
                        fontFamily="Roboto"
                        fontSize="19px"
                        fontWeight="700"
                        lineHeight="21.54px"
                        letterSpacing="-0.36px"
                      >
                        {new Date(movieDetails?.createdAt).getFullYear()}
                      </Text>
                      <Text
                        color="rgba(186, 186, 186, 1)"
                        fontFamily="Roboto"
                        fontSize="19px"
                        fontWeight="400"
                        lineHeight="21.54px"
                        letterSpacing="-0.36px"
                      >
                        {formatDuration(
                          movieDetails?.ContentDetail?.[0]?.duration
                        )}
                      </Text>
                      <Flex
                        borderRadius="3px"
                        justify="center"
                        align="center"
                        w="32px"
                        h="19px"
                        border="1px solid rgba(116, 116, 116, 1)"
                        color="rgba(255, 255, 255, 1)"
                        fontFamily="Roboto"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="21.54px"
                      >
                        HD
                      </Flex>
                    </Flex>
                    <Flex color="white" align="center" gap="5px">
                      <Flex
                        w="70px"
                        h="20px"
                        justify="center"
                        align="center"
                        border="1px solid rgba(116, 116, 116, 1)"
                      >
                        {`${movieDetails?.ageRating === "18" ? "A" : "U/A"} ${
                          movieDetails?.ageRating
                        }+`}
                      </Flex>
                      {movieDetails?.ageRating === "18" ? (
                        <Text>
                          <span style={{ textTransform: "capitalize" }}>
                            gore, language, mature themes, violence
                          </span>
                        </Text>
                      ) : (
                        <></>
                      )}
                    </Flex>
                    <Text style={textStyle}>
                      {movieDetails?.ContentDetail?.[0]?.plotSummary}
                    </Text>
                  </Flex>
                  <Flex
                    gap="20px"
                    style={textStyle}
                    w={{ base: "100%", md: "40%" }}
                    direction="column"
                  >
                    <Text>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Cast:
                      </span>{" "}
                      {movieDetails?.cast?.map((item) => (
                        <span>{item.name}, </span>
                      ))}
                    </Text>
                    <Text style={{ textTransform: "capitalize" }}>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Genres:
                      </span>{" "}
                      {movieDetails?.genre}
                    </Text>
                    <Text style={{ textTransform: "capitalize" }}>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Content Language:
                      </span>

                      {` ${movieDetails?.contentLanguage}`}
                    </Text>
                    <Text style={{ textTransform: "capitalize" }}>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Director:
                      </span>{" "}
                      {movieDetails?.dop}
                    </Text>
                    <Text style={{ textTransform: "capitalize" }}>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Producer:
                      </span>{" "}
                      {movieDetails?.producer}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              {movieDetails?.ContentDetail?.[0]?.isWebseries ? (
                <Flex
                  m="15px 0px 15px 0px"
                  borderRadius="10px"
                  bg="rgba(39, 39, 39, 1)"
                  w="90%"
                  direction="column"
                  justify="space-between"
                  p="20px"
                >
                  <Flex
                    mt="1rem"
                    w="100%"
                    justify="space-between"
                    direction={{ base: "column-reverse", md: "row" }}
                  >
                    <Flex w="100%" gap="20px" direction="column">
                      <Flex align="center" gap="8px">
                        <Text
                          color="rgba(255, 255, 255, 1)"
                          fontFamily="Roboto"
                          fontSize="20px"
                          fontWeight="700"
                          lineHeight="21.54px"
                          letterSpacing="0.2px"
                        >
                          Episodes
                        </Text>
                      </Flex>

                      <Flex w="100%" direction="column" gap="30px">
                        {movieDetails?.ContentDetail?.map((item) => (
                          <Flex gap="10px" w="100%">
                            <Flex
                              direction={{ base: "column", md: "row" }}
                              gap="10px"
                            >
                              <Flex
                                cursor="pointer"
                                onClick={() =>
                                  history.push(
                                    `/video-play-page?id=${item?.id}`
                                  )
                                }
                                w="180px"
                                h="100px"
                              >
                                <Image
                                  w="100%"
                                  h="100%"
                                  objectFit="fill"
                                  src={item?.thumbnailLink}
                                />
                              </Flex>
                              <Flex direction="column" gap="10px">
                                <Flex align="center" gap="10px">
                                  <Text
                                    color="rgba(255, 255, 255, 1)"
                                    fontFamily="Roboto"
                                    fontWeight="700"
                                    fontSize="14px"
                                    lineHeight="21.54px"
                                    letterSpacing="0.14px"
                                    cursor="pointer"
                                    onClick={() =>
                                      history.push(
                                        `/video-play-page?id=${item?.id}`
                                      )
                                    }
                                  >{`Episode ${item?.episodeNumber}`}</Text>
                                  <Text
                                    color="rgba(186, 186, 186, 1)"
                                    fontFamily="Roboto"
                                    fontSize="15px"
                                    fontWeight="400"
                                    lineHeight="21.54px"
                                    letterSpacing="-0.36px"
                                  >
                                    {formatDuration(item?.duration)}
                                  </Text>
                                </Flex>
                                <Flex w={{ base: "100%", md: "500px" }}>
                                  <Text
                                    fontFamily="Roboto"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight="28px"
                                    color="rgba(255, 255, 255, 1)"
                                    w="100%"
                                  >
                                    {expandedSummary[item?.id] ? (
                                      <span>
                                        {item.plotSummary}
                                        <Text
                                          color="rgba(0, 129, 223, 1)"
                                          cursor="pointer"
                                          onClick={() =>
                                            toggleSummary(item?.id)
                                          }
                                        >
                                          read less
                                        </Text>
                                      </span>
                                    ) : (
                                      <span>
                                        {item?.plotSummary
                                          ?.split(" ")
                                          ?.slice(0, 20)
                                          ?.join(" ")}
                                        {item?.plotSummary?.split(" ").length >
                                          20 && (
                                          <Text
                                            color="rgba(0, 129, 223, 1)"
                                            cursor="pointer"
                                            onClick={() =>
                                              toggleSummary(item?.id)
                                            }
                                          >
                                            read more
                                          </Text>
                                        )}
                                      </span>
                                    )}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

/*
 * EXPORT
 */
export default MovieDetail;
