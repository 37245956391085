/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Text,
  OrderedList,
  ListItem,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";

import { text } from "../../Views/User/website/LandingPage/data";

/*
 * OBJECT
 */
function Index() {
  /*
   * States
   */

  return (
    <Box bg="#fff">
      <Navbar />
      <Box h="100%" p="8rem 4rem">
        <Text fontSize="2rem" fontWeight="500" color="#000">
          Terms and Conditions
        </Text>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
      <Footer />
    </Box>
  );
}

/*
 * EXPORTS
 */
export default Index;
