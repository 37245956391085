/*
 * IMPORT
 */

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

/*
 * SVG IMPORT
 */

/*
 * CHAKRA IMPORTS
 */
import { Flex, Text } from "@chakra-ui/react";

/*
 * QUERY
 */

/*
 * OBJECT
 */
function ForWeb() {
  /*
   * STATE
   */

  /*
   * FUNCTION
   */

  /*
   * USEEFFECT
   */
  return (
    <Flex mt="3rem" justify="center">
      <Flex
        gap="2rem"
        p="60px"
        w="90%"
        fontFamily="Poppins"
        direction="column"
        bg="rgba(22, 7, 39, 1)"
      >
        <Flex
          gap="1rem"
          align="center"
          color="rgba(0, 0, 0, 1)"
          fontFamily="Roboto"
          fontWeight="600"
          fontSize="32px"
          lineHeight="48px"
        >
          <Flex
            ml="1.5rem"
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
          >
            1
          </Flex>
          <Flex
            w="20%"
            h="2px"
            bg="linear-gradient(90.01deg, #052DB0 0.06%, #C30280 100.1%)"
          ></Flex>
          <Flex
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
          >
            2
          </Flex>
          <Flex
            w="20%"
            h="2px"
            bg="linear-gradient(90.01deg, #052DB0 0.06%, #C30280 100.1%)"
          ></Flex>
          <Flex
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
          >
            3
          </Flex>
          <Flex
            w="20%"
            h="2px"
            bg="linear-gradient(90.01deg, #052DB0 0.06%, #C30280 100.1%)"
          ></Flex>
          <Flex
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
          >
            4
          </Flex>
        </Flex>
        <Flex gap="4rem">
          <Flex w="25%" direction="column" color="white">
            <Flex w="80%">
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Submit Your Content
              </Text>
            </Flex>
            <Flex w="80%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                Creators can upload their content and get approval and stream to
                our platform, reaching a broad audience of movie enthusiasts.
              </Text>
            </Flex>
          </Flex>
          <Flex w="25%" direction="column" color="white">
            <Flex w="60%">
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Content Review
              </Text>
            </Flex>
            <Flex w="90%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                We subject every film to a thorough content review process. This
                ensures that the content showcased meets our standards and
                resonates with our audience.
              </Text>
            </Flex>
          </Flex>
          <Flex w="25%" direction="column" color="white">
            <Flex w="60%">
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Approval & Earning
              </Text>
            </Flex>
            <Flex w="100%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                Once your movie is approved, it becomes available on our
                platform, and you start earning. The more views and engagement
                your movie generates, the more you earn.
              </Text>
            </Flex>
          </Flex>
          <Flex w="25%" direction="column" color="white">
            <Flex w="60%">
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Your Global Reach
              </Text>
            </Flex>
            <Flex w="80%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                Our platform connects creators to a global audience, potentially
                opening doors to international recognition and opportunities.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
 * EXPORTS
 */
export default ForWeb;
