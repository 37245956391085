/* eslint-disable jsx-a11y/img-redundant-alt */
/*
 * IMPORT
 */
import React, { useRef, useState, useEffect, Suspense } from "react";
import _ from "underscore";
import {
  BsFillPlayFill,
  BsPlus,
  FiThumbsUp,
  BiChevronDown,
} from "../../../../../Assets/reactIcons/index";
import "./../Components/index.css";
import premiumIcon from "../../../../../Assets/premium-quality.svg";
import premiumIcon1 from "../../../../../Assets/premium-quality (1).svg";

import MovieDetail from "./MovieDetail";
import Loader from "../../VideoPlayPage/Loader";
import Stories from "react-insta-stories";

import Ribbon from "../../../../../components/Ribbon/index";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { FaArrowRightLong } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { useLocation } from "react-router-dom";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Text,
  Image,
  Avatar,
  IconButton,
  useToast,
  useMediaQuery,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import ContentVidioLikeDislike from "../__mutation__/index.likeDislike.mutation";
import VideoMylistCreate from "../__mutation__/index.myList.mutation";
import VideoHistoryCreate from "../__mutation__/index.myHistory.mutation";

/*
 * QUERY
 */

/*
 * OBJECT
 */
export default function Index({ props }) {
  const {
    account,
    gap,
    title,
    ribbon,
    loading,
    trayData,
    trayId,
    brandVideoEnded,
    brandVideoUrl,
    premiumStatus,
  } = props;

  /*
   * STATE
   */
  const toast = useToast();
  const location = useLocation();
  const [slidesPerView, setSlidesPerView] = useState(5); // Default number of slides per view
  const [likedStatus, setlikedStatus] = useState("");
  const [addToMyListStatus, setAddToMyListStatus] = useState("");
  const margin = 10; // Margin between cards
  const history = useHistory();

  const [ContentVidioLikeDislikeMutation, { loading: loadinglikeDislike }] =
    useMutation(ContentVidioLikeDislike, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [VideoMylistCreateMutation, { loading: loadingMyListCreate }] =
    useMutation(VideoMylistCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [VideoHistoryCreateMutation, { loading: loadingMyHistoryCreate }] =
    useMutation(VideoHistoryCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * FUNCTION
   */
  const handleLikeDislike = async (id) => {
    try {
      const response = await ContentVidioLikeDislikeMutation({
        variables: {
          id: id,
        },
      });

      const data = response.data.ContentVidioLikeDislike;

      if (data?.status === "LIKED") {
        setlikedStatus("LIKED");
        toast({
          title: "Liked",
          description: "You have liked this video",
          status: "success",
          duration: 5000, // milliseconds
          isClosable: true,
        });
      } else if (data?.status === "DISLIKED") {
        setlikedStatus("DISLIKED");
        // toast({
        //   title: "DisLiked",
        //   description: "You have  disliked this video",
        //   status: "success",
        //   duration: 5000, // milliseconds
        //   isClosable: true,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleMyList = async (id) => {
    try {
      const response = await VideoMylistCreateMutation({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.VideoMylistCreate;
      setAddToMyListStatus(data);

      if (
        !_.isEmpty(data) &&
        data.status === "VIDEO_MYLIST_SUCCESSFULLY_ADDED"
      ) {
        toast({
          description: "Video added to your list",
          status: "success",
          duration: 5000, // milliseconds
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleMyHistory = async (id) => {
    try {
      const response = await VideoHistoryCreateMutation({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.VideoHistoryCreate;

      if (!_.isEmpty(data)) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    const handleResize = () => {
      // Adjust slidesPerView based on screen width
      if (window.innerWidth < 768) {
        setSlidesPerView(2); // Adjust for smaller screens
      } else if (window.innerWidth > 767 && window.innerWidth < 1025) {
        setSlidesPerView(4); // Default for larger screens
      } else {
        setSlidesPerView(gap ? 4 : 5); // Default for larger screens
      }
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Initial call to set slidesPerView based on initial screen width
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex
      style={{ paddingTop: location.pathname === "/tray" ? "6rem" : "3rem" }}
      bg="transparent"
      flexDir="column"
    >
      <Flex align="center" justify="space-between">
        <Text
          pl="30px"
          color="white"
          fontWeight="700"
          fontSize={{ base: "16px", md: "28px" }}
        >
          {title}
        </Text>
        {trayData?.length > 0 ? (
          <Flex
            cursor="pointer"
            mr="1rem"
            color="white"
            zIndex={brandVideoEnded || brandVideoUrl === null ? "99" : "1"}
            onClick={() => history.push(`/${title.replace(/ /g, "-")}`)}
            // display={localStorage.getItem("brandVideoEnd") ? "flex" : "none"}
          >
            <FaArrowRightLong size="25px" />
          </Flex>
        ) : (
          <></>
        )}
      </Flex>

      <Flex ml="16px" mt="20px" maxW="100%">
        {trayData?.length > 0 ? (
          <Flex w="100%">
            {loading ? (
              <Flex w="100%">
                <Loader />
              </Flex>
            ) : (
              <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={gap ? gap : margin}
                centeredSlides={false}
                // navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                style={{
                  width: "100%",
                  alignItems: "center",
                  overflow: "visible",
                }}
              >
                {trayData?.map((movie, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      width: "100px",
                    }}
                  >
                    <Flex w="100%" justify="center" align="center" h="100%">
                      <CardComp
                        Spinner={Spinner}
                        title={title}
                        loadingMyListCreate={loadingMyListCreate}
                        loadinglikeDislike={loadinglikeDislike}
                        handleLikeDislike={handleLikeDislike}
                        handleMyList={handleMyList}
                        handleMyHistory={handleMyHistory}
                        addToMyListStatus={addToMyListStatus}
                        loading={loading}
                        index={index}
                        account={account}
                        items={movie}
                        ribbon={ribbon}
                        likedStatus={likedStatus}
                        premiumStatus={premiumStatus}
                      />
                    </Flex>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Flex>
        ) : (
          <Flex
            p="100px 0px 100px 0px"
            w="100%"
            justify="center"
            align="center"
            fontWeight="bold"
          >
            <Text color="white" fontSize={{ base: "20px", md: "40px" }}>
              NO VIDEOS IN THIS TRAY
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

/*
 * COMPONENT
 */
function CardComp({
  handleLikeDislike,
  loadingMyListCreate,
  loadinglikeDislike,
  handleMyList,
  addToMyListStatus,
  handleMyHistory,
  account,
  loading,
  items,
  index,
  likedStatus,
  ribbon,
  Spinner,
  title,
  premiumStatus,
}) {
  /*
   * STATE
   */
  const cardRef = useRef(null);
  const [item, setItem] = useState(items);
  const history = useHistory();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [showModal, setShowModal] = useState(false);
  const [showStory, setShowStory] = useState(false);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

  /*
   * FUNCTION
   */
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleLikeDislikeVideo = (id) => {
    if (account.isUserLoggedIn) {
      handleLikeDislike(id);
    } else {
      history.push("/signin");
    }
  };

  const handleMyListAdd = (id) => {
    if (account.isUserLoggedIn) {
      handleMyList(id);
    } else {
      history.push("/signin");
    }
  };

  const handleAddHistory = (item) => {
    if (account.isUserLoggedIn) {
      if (premiumStatus || item?.ContentSummary?.ContentDetail?.[0]?.isPremium !== "PREMIUM") {
        handleMyHistory(item?.ContentSummary?.ContentDetail?.[0]?.id);
        history.push(
          `/video-play-page/${item?.ContentSummary?.contentTitle?.replace(
            / /g,
            "-"
          )}/${item?.ContentSummary?.ContentDetail?.[0]?.id}`
        );
      } else {
        history.push("/subscription-page");
      }
    } else {
      history.push("/signin");
    }
  };
 
  // const handleAddHistory = (item) => {
  //   if (account.isUserLoggedIn) {
  //     handleMyHistory(item?.ContentSummary?.ContentDetail[0]?.id);
  //     history.push(
  //       `/video-play-page/${item?.ContentSummary?.contentTitle?.replace(
  //         / /g,
  //         "-"
  //       )}/${item?.ContentSummary?.ContentDetail[0]?.id}`
  //     );
  //   } else {
  //     history.push("/signin");
  //   }
  // };

  function truncateAfterTenLetters(text) {
    if (isLargerThan768) {
      if (!text) return "";
      if (text.length <= 20) return text;
      return text.substring(0, 20) + "...";
    } else {
      if (!text) return "";
      if (text.length <= 10) return text;
      return text.substring(0, 10) + "...";
    }
  }

  const storiesData = item.ContentSummary.Account?.Stories || [];
  const stories = storiesData.map((story) => ({
    url: story.profileStoryLink,
    id: story.id,
  }));

  const handleCloseModal = () => {
    setShowStory(false);
    setCurrentStoryIndex(0);
  };

  return (
    <>
      <Flex
        ref={cardRef}
        borderRadius="20px"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        transition="all 0.3s ease"
        objectFit="cover"
        w={{ base: "170px", md: "320px" }}
        h={{ base: "200px", md: "200px" }}
        _hover={{
          transform: "scale(1.04, 1.05)",
          boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.67)",
          ".carousel_main_div": {
            overflowX: "none",
          },
          borderRadius: "0",
          ".additional-content2": {
            transform: "translateY(-60px)",
            opacity: 1,
          },
          ".additional-content": {
            transform: "translateY(90px)",
            opacity: 1,
          },
          ".movie_thumbnail": {
            borderRadius: "0px",
          },
          ".card_image_div": {
            borderRadius: "0px",
          },
        }}
      >
        {ribbon && <Ribbon props={{ desc: index + 1 }} />}

        <Flex
          w="100%"
          zIndex="0"
          // bg="#00020A"
          bg="rgba(0, 0, 0, 1)"
          px="10px"
          borderTopRadius="20px"
          h="60px"
          className="additional-content2"
          opacity={0}
          position="absolute"
          top="0"
          left="0"
          right="0"
          textAlign="center"
          alignItems="center"
          justify="space-between"
          transition="all 0.3s ease"
          color="white"
        >
          <Flex align="center" gap="10px">
            <Avatar
              border={
                item.ContentSummary.Account?.Stories?.[0]?.profileStoryLink
                  ? "3px solid rgba(255, 193, 7, 1)"
                  : "none"
              }
              onClick={
                item.ContentSummary.Account?.Stories?.[0]?.profileStoryLink
                  ? () => setShowStory(!showStory)
                  : null
              }
              cursor="pointer"
              w="35px"
              h="35px"
              name={item.ContentSummary.Account.displayName}
              src="https://bit.ly/broken-link"
            />

            <Modal
              isOpen={showStory}
              onClose={() => setShowStory(!showStory)}
              isCentered
            >
              <ModalOverlay />
              <ModalContent bg="black">
                <ModalBody p="5px">
                  <Stories
                    stories={stories}
                    defaultInterval={5000}
                    width="100%"
                    height="100%"
                    currentIndex={currentStoryIndex}
                    onAllStoriesEnd={handleCloseModal}
                    onStoryEnd={() =>
                      setCurrentStoryIndex((prevIndex) => {
                        const nextIndex = prevIndex + 1;
                        return nextIndex >= stories.length ? 0 : nextIndex;
                      })
                    }
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Box textAlign="left">
              <Flex
                fontWeight="400"
                fontFamily="Admina"
                fontSize="14px"
                lineHeight="35.83px"
                textTransform="uppercase"
                gap="10px"
              >
                <Text>
                  {item.ContentSummary.Account.displayName?.split(" ")?.[0]}
                </Text>
                <Text>
                  {item.ContentSummary.Account.displayName?.split(" ")?.[1]}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Flex>


        <Flex
          className="card_image_div"
          w="100%"
          zIndex="1"
          h="100%"
          borderRadius="20px"
          position="relative"
          sx={{
            filter: "brightness(1.1) contrast(1.1) saturate(1.2) sepia(0.2) drop-shadow(0 0 10px rgba(0, 0, 0, 0.2)) hue-rotate(10deg) opacity(0.9)",

            "&:hover": {
              filter: "brightness(1.2) contrast(1.15) saturate(1.3) sepia(0.3) drop-shadow(0 0 15px rgba(0, 0, 0, 0.3)) ",
            },
          }}
        >
          <Image
            src={
              isLargerThan768
                ? item?.ContentSummary?.ContentDetail?.[0]?.lowerThumbnailLink
                : item?.ContentSummary?.ContentDetail?.[0]
                    ?.lowerVerticalThumbnailStoredAt
            }
            style={{
              objectFit: "fill",
              height: "100%",
              width: "100%",
            }}
            className="movie_thumbnail"
            borderRadius="20px"
            alt="image"
          />
          {item?.ContentSummary?.ContentDetail?.[0]?.isPremium === "PREMIUM" ? (
            <Flex>
              <Image
                src={premiumIcon}
                alt="premium"
                w="20px"
                h="20px"
                position="absolute"
                top="10px"
                left="10px"
              />
            </Flex>
          ) : null}
        </Flex>

        <Flex
          borderBottomRadius="20px"
          w="100%"
          zIndex="0"
          // bg="#00020A"
          bg="rgba(0, 0, 0, 1)"
          direction="column"
          h="90px"
          className="additional-content"
          opacity={0}
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          p="10px"
          textAlign="start"
          justify="space-between"
          transition="all 0.3s ease"
          color="#7D798E"
        >
          <Flex alignItems="center" justify="space-between">
            <Flex gap="10px" alignItems="center">
              <IconButton
                fontSize="22px"
                bg="white"
                icon={<BsFillPlayFill />}
                color="black"
                border="1px solid #fff"
                px="10px"
                borderRadius="50%"
                _hover={{ bg: "white", color: "black", fontSize: "25px" }}
                onClick={() => {
                  handleAddHistory(item);
                }}
              ></IconButton>
              <IconButton
                fontSize="22px"
                bg="transparent"
                icon={
                  loadingMyListCreate ? (
                    <Spinner />
                  ) : addToMyListStatus.status ===
                    "VIDEO_MYLIST_SUCCESSFULLY_REMOVED" ? (
                    <BsPlus />
                  ) : (
                    <BsPlus />
                  )
                }
                display={{ base: "none", md: "flex" }}
                color="white"
                alignItems="center"
                justify="center"
                border="1px solid #fff"
                px="10px"
                borderRadius="50%"
                _hover={{ bg: "white", color: "black" }}
                onClick={() =>
                  handleMyListAdd(item?.ContentSummary?.ContentDetail[0]?.id)
                }
              ></IconButton>
              <IconButton
                fontSize="20px"
                bg="transparent"
                icon={
                  loadinglikeDislike ? (
                    <Spinner />
                  ) : likedStatus.status === "LIKED" ? (
                    <FiThumbsUp />
                  ) : (
                    <FaThumbsUp />
                  )
                }
                color="white"
                border="1px solid #fff"
                px="10px"
                borderRadius="50%"
                display={{ base: "none", md: "flex" }}
                alignItems="center"
                justify="center"
                _hover={{ bg: "white", color: "black" }}
                onClick={() =>
                  handleLikeDislikeVideo(
                    item?.ContentSummary?.ContentDetail[0]?.id
                  )
                }
              ></IconButton>
            </Flex>
            <IconButton
              fontSize="22px"
              icon={<BiChevronDown />}
              color="black"
              border="1px solid #fff"
              px="10px"
              borderRadius="50%"
              _hover={{ bg: "white", color: "black" }}
              onClick={() => toggleModal()}
            ></IconButton>
          </Flex>
          <Flex ml="5px" direction="column">
            <Text
              fontWeight="600"
              fontSize="14px"
              color="white"
              style={{ textTransform: "capitalize" }}
            >
              {truncateAfterTenLetters(item?.ContentSummary?.contentTitle)}
            </Text>
          </Flex>
        </Flex>
        {showModal ? (
          <MovieDetail
            title={title}
            loadinglikeDislike={loadinglikeDislike}
            loadingMyListCreate={loadingMyListCreate}
            handleAddHistory={handleAddHistory}
            handleLikeDislikeVideo={handleLikeDislikeVideo}
            handleMyListAdd={handleMyListAdd}
            addToMyListStatus={addToMyListStatus}
            isOpen={showModal}
            onClose={toggleModal}
            account={account}
            likedStatus={likedStatus}
            movieDetail={item}
          />
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
}
