/*
 * IMPORT
 */

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

/*
 * SVG IMPORT
 */

/*
 * CHAKRA IMPORTS
 */
import { Flex, Text } from "@chakra-ui/react";

/*
 * QUERY
 */
/*
 * OBJECT
 */
function ForMobile() {
  /*
   * STATE
   */

  /*
   * FUNCTION
   */

  /*
   * USEEFFECT
   */
  return (
    <Flex direction="column">
      <Flex
        p="10px"
        justify="center"
        fontFamily="Poppins"
        fontSize="14px"
        fontWeight="400"
        lineHeight="21px"
        letterSpacing="0.02em"
        color="rgba(173, 173, 173, 1)"
      >
        <Text>Four easy steps to get started.</Text>
      </Flex>
      <Flex gap="30px" align="center" direction="column">
        <Flex
          borderRadius="20px"
          direction="column"
          gap="30px"
          bg="rgba(22, 7, 39, 1)"
          w="301px"
          h="379px"
          p="20px"
        >
          <Flex
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
            color="rgba(0, 0, 0, 1)"
            fontFamily="Roboto"
            fontWeight="600"
            fontSize="32px"
            lineHeight="48px"
          >
            1
          </Flex>
          <Flex gap="20px" direction="column" color="white">
            <Flex>
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Submit Your Content
              </Text>
            </Flex>
            <Flex w="80%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                Creators can upload their content and get approval and stream to
                our platform, reaching a broad audience of movie enthusiasts.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          borderRadius="20px"
          direction="column"
          gap="30px"
          bg="rgba(22, 7, 39, 1)"
          w="301px"
          h="379px"
          p="20px"
        >
          <Flex
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
            color="rgba(0, 0, 0, 1)"
            fontFamily="Roboto"
            fontWeight="600"
            fontSize="32px"
            lineHeight="48px"
          >
            2
          </Flex>
          <Flex gap="20px" direction="column" color="white">
            <Flex>
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Content Review
              </Text>
            </Flex>
            <Flex w="80%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                We subject every film to a thorough content review process. This
                ensures that the content showcased meets our standards and
                resonates with our audience.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          borderRadius="20px"
          direction="column"
          gap="30px"
          bg="rgba(22, 7, 39, 1)"
          w="301px"
          h="379px"
          p="20px"
        >
          <Flex
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
            color="rgba(0, 0, 0, 1)"
            fontFamily="Roboto"
            fontWeight="600"
            fontSize="32px"
            lineHeight="48px"
          >
            3
          </Flex>
          <Flex gap="20px" direction="column" color="white">
            <Flex>
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Approval & Earning
              </Text>
            </Flex>
            <Flex w="80%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                Once your movie is approved, it becomes available on our
                platform, and you start earning. The more views and engagement
                your movie generates, the more you earn.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          borderRadius="20px"
          direction="column"
          gap="30px"
          bg="rgba(22, 7, 39, 1)"
          w="301px"
          h="379px"
          p="20px"
        >
          <Flex
            align="center"
            justify="center"
            h="55px"
            w="55px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 1)"
            color="rgba(0, 0, 0, 1)"
            fontFamily="Roboto"
            fontWeight="600"
            fontSize="32px"
            lineHeight="48px"
          >
            4
          </Flex>
          <Flex gap="20px" direction="column" color="white">
            <Flex>
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                letterSpacing="0.48px"
                color="rgba(255, 255, 255, 1)"
              >
                Your Global Reach
              </Text>
            </Flex>
            <Flex w="80%">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.32px"
                color="rgba(173, 173, 173, 1)"
              >
                Our platform connects creators to a global audience, potentially
                opening doors to international recognition and opportunities.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
 * EXPORTS
 */
export default ForMobile;
