/* eslint-disable no-console */
/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
/*
 * IMPORT
 */
import React, { useState } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useQuery } from "@apollo/client";
import _ from "underscore";
import { useHistory } from "react-router-dom";
/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Image,
  Text,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */

/*
 * QUERY
 */
import ReadAllBlogs from "./__query__/index.getAllBlogs.query";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const history = useHistory();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleLeave = () => {
    setHoveredIndex(null);
  };

  const _getBlogs = useQuery(ReadAllBlogs);

  /*
   * STYLE
   */
  const tabStyle = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "55.62px",
    letterSpacing: "0.32px",
  };

  /*
   * Functions
   */

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };

    return date.toLocaleDateString("en-US", options);
  };

  /*
   * UseEffect
   */

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Blogs List
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex
                    justify="center"
                    gap="50px"
                    p={{ base: "10px", md: "50px" }}
                    align="center"
                    direction="column"
                  >
                    {_getBlogs?.data?.ReadAllBlogs?.map((data, index) => (
                      <Flex
                        direction={{ base: "column", md: "row" }}
                        key={data?.id}
                        w="100%"
                        gap="20px"
                        pb={{ base: "50px", md: "0px" }}
                        bg="transparent"
                        position="relative"
                        boxShadow={
                          hoveredIndex === index
                            ? "0px 0px 20px 5px rgba(255, 255, 255, 0.8)"
                            : "0px 0px 5px 1px white"
                        }
                        cursor="pointer"
                        onClick={() =>
                          history.push(`/blog-page?id=${data?.id}`)
                        }
                        onMouseEnter={() => handleHover(index)}
                        onMouseLeave={handleLeave}
                        transition="box-shadow 0.2s ease-in-out" // Apply transition to box-shadow
                      >
                        <Flex
                          w={{ base: "100%", md: "35%" }}
                          h={{ base: "250px", md: "350px" }}
                        >
                          <Image
                            objectFit="fill"
                            w="100%"
                            h="100%"
                            src={data?.horizontalImage}
                            alt="image"
                          />
                        </Flex>
                        <Flex
                          gap="20px"
                          direction="column"
                          justify={{ base: "none", md: "center" }}
                          w={{ base: "100%", md: "60%" }}
                        >
                          <Text
                            fontFamily="Poppins"
                            fontSize="30px"
                            fontWeight="700"
                            color="white"
                            textAlign="center"
                          >
                            {data?.heading}
                          </Text>
                          <Text
                            fontFamily="Poppins"
                            fontSize="20px"
                            fontWeight="400"
                            color="white"
                            textAlign="center"
                          >
                            {data?.title}
                          </Text>
                          {/* <Flex w="70%">
                            <div
                              dangerouslySetInnerHTML={{ __html: data.content }}
                            />
                          </Flex> */}
                        </Flex>
                        <Flex pos="absolute" bottom="1" right="1">
                          <Text color="white">
                            {formatDate(data?.createdAt)}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
