/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useHistory, Link } from "react-router-dom";
import _ from "underscore"; // Npm: Utility module.
import { google } from "./../../../Assets/images/companies/export.js";
import {
  loginBackground,
  loginCapa,
} from "./../../../Assets/images/loginPage/export.js";
import { logo3 } from "./../../../Assets/images/shucae/export.js";
import { IoMdArrowRoundBack } from "react-icons/io";

/*
 * CHAKRA IMPORT
 */
import {
  Flex,
  FormControl,
  Heading,
  Image,
  Text,
  SimpleGrid,
  Button,
  useMediaQuery,
  Spinner,
  useToast,
  Checkbox,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import { useMutation, useLazyQuery } from "@apollo/client";
import AccountGoogleRegisterMutation from "./__mutation__/index.signup.mutation"; // GRAPHQL: AddManually mutation.
import FreeSubscriptionCreate from "./__mutation__/index.freeSubscriptionCreate.mutation.js";

/*
 * QUERY
 */
import signupQuery from "./__query__/index.signup.query";

/*
 * REDUX IMPORT
 */
import { connect } from "react-redux";

/*
 * OBJECT
 */
function Index({ account, AccountUpdate, brandLocation }) {
  /*
   * STATE
   */
  const history = useHistory();
  const toast = useToast();
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [loadingMutation, setLoadingMutation] = useState(false);
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState([]);
  const location = useLocation();
  const [isChecked, setChecked] = useState(false);

  const [QuerySignup, { loading: signupQueryLoading }] = useLazyQuery(
    signupQuery,
    {
      context: {},
    }
  );

  const [signUpMutation, { loading: mutationLoading, error: mutationError }] =
    useMutation(AccountGoogleRegisterMutation);

  const [
    FreeSubscriptionCreateMutation,
    {
      loading: LoadingFreeSubscriptionCreate,
      error: ErrorFreeSubscriptionCreate,
    },
  ] = useMutation(FreeSubscriptionCreate, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * FUNCTION
   */
  async function _QuerySignup() {
    const { data } = await QuerySignup();

    setToken(data?.GoogleAccountLogin?.token);
  }
  const handleSignUpMutationData = async (token) => {
    setLoadingMutation(true);
    const { data } = await signUpMutation({
      variables: {
        googleToken: token,
      },
    });
    setUserData(data);

    if (data?.AccountGoogleRegister?.status === "ACCOUNT_WITH_EMAIL_FOUND") {
      toast({
        title: "Pls sign in you are already registered",
        description: "Account with this email already exist.",
        status: "warning",
        duration: 10000, // milliseconds
        isClosable: true,
      });
      history.push("/signin");
    } else if (data?.AccountGoogleRegister?.status === "REGISTER_SUCCESSFUL") {
      setLoadingMutation(false);
      AccountUpdate({ isUserLoggedIn: true, ...data.AccountGoogleRegister });
      if (brandLocation?.brand && brandLocation?.locationParam) {
        const res = await FreeSubscriptionCreateMutation({
          variables: {
            brandName: brandLocation?.brand,
            location: brandLocation?.locationParam,
          },
        });
        history.push("/");
      }
      history.push("/");
    } else {
      setLoadingMutation(false);
    }
  };

  const _GoogleSignUp = () => {
    if (isChecked === true) {
      window.location.href = token;
    } else {
      toast({
        title: "Error",
        description: "Please agree to our terms and condition first.",
        status: "error",
        duration: 5000, // milliseconds
        isClosable: true,
      });
    }
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    const _codeParam = new URLSearchParams(location.search).get("code");
    if (!_.isEmpty(_codeParam)) {
      handleSignUpMutationData(_codeParam);
    } else {
      _QuerySignup();
    }
  }, [AccountUpdate, location.search]);

  return (
    <Flex
      fontFamily="Poppins"
      bgImage={`url(${loginBackground})`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      fontSize={["clamp(13px, 1.5vw, 15px)"]}
      color="white"
      h="100vh"
    >
      <Flex w="100%" bg="rgba(0,0,0, 0.6)">
        <Flex
          flexDir="column"
          bgImage={`url(${loginCapa})`}
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
          w="100%"
        >
          <Flex>
            {!isLargerThan900 ? (
              <Flex align="center" pl="20px">
                <NavLink to="/">
                  <IoMdArrowRoundBack size="20px" />
                </NavLink>
              </Flex>
            ) : null}
            <Flex
              pt={{ base: "2rem", md: "20px" }}
              w="100%"
              ml="20px"
              justify={{ base: "center", md: "start" }}
            >
              <NavLink to="/">
                <Image width="200px" src={logo3} />
              </NavLink>
            </Flex>
          </Flex>

          <SimpleGrid
            w="100%"
            my="auto"
            bg="transparent"
            columns={isLargerThan900 ? 2 : 1}
            spacing={10}
          >
            <Flex
              fontFamily="Montserrat"
              alignItems="center"
              justifyContent="center"
            >
              <Flex
                fontFamily="Montserrat"
                fontSize={{ base: "32px", md: "60px" }}
                fontWeight={{ base: "600", md: "700" }}
                lineHeight={{ base: "45px", md: "92px" }}
                letterSpacing="0.03em"
                flexDir="column"
              >
                <Text>Movie Magic:</Text>
                <Text
                  bgGradient="linear-gradient(92deg, #052DB0 1.34%, #C30280 94.76%)"
                  bgClip="text"
                >
                  Watch, Sell and Buy
                </Text>
                <Text>All in One Place!</Text>
              </Flex>
            </Flex>

            <Flex
              flexDir="column"
              textAlign="start"
              alignItems="center"
              justifyContent="center"
            >
              <Flex maxW="500px" w="90%" flexDir="column">
                <Heading fontSize="26px" mb="30px">
                  Sign Up
                </Heading>

                <FormControl>
                  {signupQueryLoading ? (
                    <Flex justify="center">
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="purple.800"
                        size="xl"
                      />
                    </Flex>
                  ) : (
                    <Flex flexDir="column" gap="30px">
                      <Button
                        variant="blank"
                        border="1px solid"
                        borderColor="#7D798E"
                        _hover={{ borderColor: "white", color: "white" }}
                        onClick={_GoogleSignUp}
                      >
                        {loadingMutation ? (
                          <Spinner />
                        ) : (
                          <>
                            <Image width="25px" mr="10px" src={google} /> Sign
                            up with Google
                          </>
                        )}
                        {/* <Image width="25px" mr="10px" src={google} />  */}
                      </Button>
                    </Flex>
                  )}
                </FormControl>

                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="start"
                  maxW="100%"
                  mt="10px"
                >
                  <Text color="white" fontWeight="400" fontSize="14px">
                    Already part of Shucae Films?
                    <NavLink to="/signin">
                      <Text
                        color="#C30280"
                        _hover={{ textDecor: "underline" }}
                        as="span"
                        ms="5px"
                        fontWeight="500"
                      >
                        Login here
                      </Text>
                    </NavLink>
                  </Text>
                  <Flex gap={3} m={"10px 0"}>
                    <Checkbox onChange={() => setChecked(true)} />
                    <Text>
                      I agree to your{" "}
                      <Link to="/terms" style={{ color: "#c30280" }}>
                        terms and condition
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
 * REDUX
 */
const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(Index);
