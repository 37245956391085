/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($accountCoverPhoto: JSON) {
    AccountCoverUpload(accountCoverPhoto: $accountCoverPhoto) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
