/*
 * IMPORTS
 */
import React, { useState, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useHistory } from "react-router-dom";
import _ from "underscore";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import MovieDetail from "./MovieDetail";
import premiumIcon from "../../Assets/premium-quality.svg";

import {
  BsFillPlayFill,
  BsPlus,
  FiThumbsUp,
  BiChevronDown,
} from "../../Assets/reactIcons/index";
import { FaPlay, FaThumbsUp } from "react-icons/fa";
import Stories from "react-insta-stories";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Text,
  Image,
  Avatar,
  IconButton,
  useMediaQuery,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import ContentVidioLikeDislike from "../../Views/User/website/LandingPage/__mutation__/index.likeDislike.mutation";
import VideoMylistCreate from "../../Views/User/website/LandingPage/__mutation__/index.myList.mutation";
import VideoHistoryCreate from "../../Views/User/website/LandingPage/__mutation__/index.myHistory.mutation";

/*
 * QUERY
 */
import AccountFollowStatus from "../../Views/User/website/LandingPage/__query__/index.followStatus.query";
import AccountDetail from "../../Views/User/website/LandingPage/__query__/index.accountDetail.query";
/*
 * OBJECT
 */
function CardComponent({ account, item }) {
  /*
   * STATE
   */
  const [likedStatus, setlikedStatus] = useState("DISLIKED");
  const cardRef = useRef(null);
  const history = useHistory();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [showModal, setShowModal] = useState(false);
  const [addToMyList, setAddToMyList] = useState();
  const [followStatus, setFollowStatus] = useState();
  const toast = useToast();

  const [showStory, setShowStory] = useState(false);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

  const [ContentVidioLikeDislikeMutation, { loading: loadinglikeDislike }] =
    useMutation(ContentVidioLikeDislike, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [VideoMylistCreateMutation, { loading: loadingMyListCreate }] =
    useMutation(VideoMylistCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [VideoHistoryCreateMutation, { loading: loadingMyHistoryCreate }] =
    useMutation(VideoHistoryCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [AccountFollowStatusQuery, { loading: loadingFollowStatus }] =
    useLazyQuery(AccountFollowStatus, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const _AccountDetail = useQuery(AccountDetail, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * FUNCTION
   */
  const handleFollowStatus = async (id) => {
    try {
      const response = await AccountFollowStatusQuery({
        variables: {
          accountId: id,
        },
      });

      const data = response.data.AccountFollowStatus;

      if (!_.isEmpty(data)) {
        if (data.AccountFollowStatus.status === "FOLLOW_SUCCESSFULLY") {
          setFollowStatus("FOLLOW_SUCCESSFULLY");
        } else if (
          data.AccountFollwoUnfollow.status === "UNFOLLOW_SUCCESSFUL"
        ) {
          setFollowStatus("UNFOLLOW_SUCCESSFUL");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleLikeDislike = async (id) => {
    try {
      const response = await ContentVidioLikeDislikeMutation({
        variables: {
          id: id,
        },
      });

      const data = response.data.ContentVidioLikeDislike;
      if (data?.status === "LIKED") {
        setlikedStatus("LIKED");
        toast({
          title: "Liked",
          description: "You have liked this video",
          status: "success",
          duration: 5000, // milliseconds
          isClosable: true,
        });
      } else if (data?.status === "DISLIKED") {
        setlikedStatus("DISLIKED");
        // toast({
        //   title: "DisLiked",
        //   description: "You have  disliked this video",
        //   status: "success",
        //   duration: 5000, // milliseconds
        //   isClosable: true,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleMyList = async (id) => {
    try {
      const response = await VideoMylistCreateMutation({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.VideoMylistCreate;

      setAddToMyList(data);

      if (
        !_.isEmpty(data) &&
        data.status === "VIDEO_MYLIST_SUCCESSFULLY_ADDED"
      ) {
        toast({
          description: "Video added to your list",
          status: "success",
          duration: 5000, // milliseconds
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleMyHistory = async (id) => {
    try {
      const response = await VideoHistoryCreateMutation({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.VideoHistoryCreate;

      if (!_.isEmpty(data)) {
      }
    } catch (error) {
      console.error(error);
    }
  };
  function handleFollowChecking(id) {
    toggleModal();
    if (account.isUserLoggedIn) {
      handleFollowStatus(id);
    }
  }
  const handleAddHistory = (item) => {
    if (account.isUserLoggedIn) {
      if (_AccountDetail?.data?.AccountDetail?.premiumStatus || item?.ContentDetail?.[0]?.isPremium !== "PREMIUM") {
        handleMyHistory(item?.ContentDetail[0]?.id);
        history.push(
          `/video-play-page/${item?.contentTitle?.replace(/ /g, "-")}/${
            item?.ContentDetail[0]?.id
          }`
        );
      } else {
        history.push("/subscription-page");
      }
    } else {
      history.push("/signin");
    }
  };


  // const handleAddHistory = (item) => {
  //   if (account.isUserLoggedIn) {
  //     handleMyHistory(item?.ContentDetail[0]?.id);
  //     history.push(
  //       `/video-play-page/${item?.contentTitle?.replace(/ /g, "-")}/${
  //         item?.ContentDetail[0]?.id
  //       }`
  //     );
  //   } else {
  //     history.push("/signin");
  //   }
  // };

  const handleMyListAdd = (id) => {
    if (account.isUserLoggedIn) {
      handleMyList(id);
    } else {
      history.push("/signin");
    }
  };
  const handleLikeDislikeVideo = (id) => {
    if (account.isUserLoggedIn) {
      handleLikeDislike(id);
    } else {
      history.push("/signin");
    }
  };
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }
  function truncateAfterTenLetters(text) {
    if (!text) return "";
    if (text.length <= 10) return text;
    return text.substring(0, 10) + "...";
  }
  

  const storiesData = item?.Account?.Stories || [];
  const stories = storiesData.map((story) => ({
    url: story.profileStoryLink,
    id: story.id,
  }));

  const handleCloseModal = () => {
    setShowStory(false);
    setCurrentStoryIndex(0);
  };

  return (
    <Flex
      ref={cardRef}
      borderRadius="20px"
      flexDir="column"
      ml="10px"
      mt="70px"
      alignItems="center"
      justifyContent="center"
      transition="all 0.3s ease"
      objectFit="cover"
      w={{ base: "150px", md: "320px" }}
      h={{ base: "185px", md: "200px" }}
      _hover={{
        transform: "scale(1.04, 1.05)",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.67)",
        ".carousel_main_div": {
          overflowX: "none",
        },
        borderRadius: "0",
        ".additional-content2": {
          transform: "translateY(-60px)",
          opacity: 1,
        },
        ".additional-content": {
          transform: "translateY(90px)",
          opacity: 1,
        },
        ".movie_thumbnail": {
          borderRadius: "0px",
        },
        ".card_image_div": {
          borderRadius: "0px",
        },
      }}
    >
      <Flex
        w="100%"
        zIndex="0"
        bg="#00020A"
        px="10px"
        borderTopRadius="20px"
        h="60px"
        className="additional-content2"
        opacity={0}
        position="absolute"
        top="0"
        left="0"
        right="0"
        textAlign="center"
        alignItems="center"
        justify="space-between"
        transition="all 0.3s ease"
        color="white"
      >
        <Flex align='center' gap="10px">
          <Avatar
            border={
              item?.Account?.Stories?.[0]?.profileStoryLink
                ? "3px solid rgba(255, 193, 7, 1)"
                : "none"
            }
            onClick={
              item?.Account?.Stories?.[0]?.profileStoryLink
                ? () => setShowStory(!showStory)
                : null
            }
            cursor="pointer"
            w={{base:'25px' , md:'35px'}}
            h={{base:'25px' , md:'35px'}}
            name={item?.Account?.displayName}
            src="https://bit.ly/broken-link"
          />

          <Modal
            isOpen={showStory}
            onClose={() => setShowStory(!showStory)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent bg="black">
              <ModalBody p="5px">
                <Stories
                  stories={stories}
                  defaultInterval={5000}
                  width="100%"
                  height="100%"
                  currentIndex={currentStoryIndex}
                  onAllStoriesEnd={handleCloseModal}
                  onStoryEnd={() =>
                    setCurrentStoryIndex((prevIndex) => {
                      const nextIndex = prevIndex + 1;
                      return nextIndex >= stories.length ? 0 : nextIndex;
                    })
                  }
                />
              </ModalBody>
            </ModalContent>
          </Modal>
          <Box textAlign="left">
            <Flex
              fontWeight="400"
              fontFamily="Admina"
              fontSize={{base:'12px' , md:'14px'}}
              lineHeight="35.83px"
              textTransform="uppercase"
              gap="10px"
            >
              <Text>{item?.Account?.displayName?.split(" ")[0]}</Text>
              <Text>{item?.Account?.displayName?.split(" ")[1]}</Text>
            </Flex>
            {/* <Text fontSize="9px" color="#7D798E">
              {`${item?.Account?.Follow_Follow_Account__followingIdToAccount?.length}
                          Followers`}
            </Text> */}
          </Box>
        </Flex>
      </Flex>
      <Flex
        className="card_image_div"
        w="100%"
        zIndex="1"
        h="100%"
        borderRadius="20px"
        position="relative"
        sx={{
          filter: "brightness(1.1) contrast(1.1) saturate(1.2) sepia(0.2) drop-shadow(0 0 10px rgba(0, 0, 0, 0.2)) hue-rotate(10deg) opacity(0.9)",

          "&:hover": {
            filter: "brightness(1.2) contrast(1.15) saturate(1.3) sepia(0.3) drop-shadow(0 0 15px rgba(0, 0, 0, 0.3)) ",
          },
        }}
      >
        <Image
          src={
            isLargerThan768
              ? item?.ContentDetail?.[0]?.lowerThumbnailLink
              : item?.ContentDetail?.[0]?.lowerVerticalThumbnailStoredAt
          }
          style={{
            objectFit: "fill",
            height: "100%",
            width: "100%",
            loading: "lazy",
          }}
          borderRadius="20px"
          className="movie_thumbnail"
          alt="image"
        />
        {item?.ContentDetail?.[0]?.isPremium === "PREMIUM" ? (
          <Flex>
            <Image
              src={premiumIcon}
              alt="premium"
              w="20px"
              h="20px"
              position="absolute"
              top="10px"
              left="10px"
            />
          </Flex>
        ) : null}
      </Flex>
      <Flex
        borderBottomRadius="20px"
        w="100%"
        zIndex="0"
        bg="#00020A"
        direction="column"
        h="90px"
        className="additional-content"
        opacity={0}
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        p="10px"
        textAlign="start"
        justify="space-between"
        transition="all 0.3s ease"
        color="#7D798E"
      >
        <Flex alignItems="center" justify="space-between">
          <Flex gap="10px" alignItems="center">
            <IconButton
              fontSize="22px"
              bg="white"
              icon={<BsFillPlayFill />}
              color="black"
              border="1px solid #fff"
              px="10px"
              borderRadius="50%"
              _hover={{
                bg: "white",
                color: "black",
                fontSize: "25px",
              }}
              onClick={() => {
                handleAddHistory(item);
              }}
            ></IconButton>
            <IconButton
              fontSize="22px"
              bg="transparent"
              icon={loadingMyListCreate ? <Spinner /> : <BsPlus />}
              display={{ base: "none", md: "flex" }}
              color="white"
              alignItems="center"
              justify="center"
              border="1px solid #fff"
              px="10px"
              borderRadius="50%"
              _hover={{ bg: "white", color: "black" }}
              onClick={() => handleMyListAdd(item?.ContentDetail?.[0]?.id)}
            ></IconButton>
            <IconButton
              fontSize="20px"
              bg="transparent"
              icon={
                loadinglikeDislike ? (
                  <Spinner />
                ) : likedStatus.status === "LIKED" ? (
                  <FiThumbsUp />
                ) : (
                  <FaThumbsUp />
                )
              }
              color="white"
              border="1px solid #fff"
              px="10px"
              borderRadius="50%"
              display={{ base: "none", md: "flex" }}
              alignItems="center"
              justify="center"
              _hover={{ bg: "white", color: "black" }}
              onClick={() =>
                handleLikeDislikeVideo(item?.ContentDetail?.[0]?.id)
              }
            ></IconButton>
          </Flex>
          <IconButton
            fontSize="22px"
            icon={<BiChevronDown />}
            color="black"
            border="1px solid #fff"
            px="10px"
            borderRadius="50%"
            _hover={{ bg: "white", color: "black" }}
            onClick={() => {
              handleFollowChecking(item?.Account?.id);
            }}
          ></IconButton>
        </Flex>
        <Flex ml="5px" direction="column">
          <Text
            fontWeight="600"
            fontSize="14px"
            color="white"
            style={{ textTransform: "capitalize" }}
          >
            {truncateAfterTenLetters(item?.contentTitle)}
          </Text>
          {/* <Flex align="center">
            <Text
              fontWeight="500"
              fontSize="12px"
              style={{ textTransform: "capitalize" }}
            >
              {item?.ContentSummary?.genre}
            </Text>
            /
            <Text fontWeight="500" fontSize="12px">
              {formatDuration(item?.ContentSummary?.ContentDetail[0]?.duration)}
            </Text>
          </Flex> */}
        </Flex>
      </Flex>

      <MovieDetail
        allData={item}
        loadinglikeDislike={loadinglikeDislike}
        loadingMyListCreate={loadingMyListCreate}
        handleAddHistory={handleAddHistory}
        handleLikeDislikeVideo={handleLikeDislikeVideo}
        handleMyListAdd={handleMyListAdd}
        addToMyList={addToMyList}
        isOpen={showModal}
        onClose={toggleModal}
        account={account}
        followStatus={followStatus}
        likedStatus={likedStatus}
        movieDetails={item}
      />
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(CardComponent);
