/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Text,
  OrderedList,
  ListItem,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";

/*
 * OBJECT
 */
function Index() {
  /*
   * States
   */
 
  return (
    <Box bg="#00020A">
      <Navbar />
      <Box h="100%" p="8rem 4rem">
        <Text fontSize="2rem" fontWeight="500" color="white">
          Content License Agreement
        </Text>
        <Text color="white">
          As the content submitted by me, is owned by me in every possible
          manner, and I, In my sound mind, hereby submitting my content to
          Shucae Films Private Limited, Shucae Films, a company incorporated
          under the provisions of the Companies Act, 1956 having its registered
          office at BANYORKA, VILL, DHRUMAN, 3131, MANDI, Mandi,Himachal
          Pradesh, 175040, having CIN U92490HP2021PTC008884 and having PAN
          ABHCS0419G (hereinafter referred to as “Licensee”, which expression
          shall, unless repugnant to the context or meaning thereof, be deemed
          to mean and include its successors and assigns) of the OTHER PART.
          “Licensor” and “Licensee” shall be collectively referred to as
          “Parties” and individually as “Party”, as the case may be.
          <Text>Preamble –</Text>
          <OrderedList>
            <ListItem>
              Licensee owns and operates the Services (as defined in Clause 8)
            </ListItem>
            <ListItem>
              The Licensor/Content Creator owns the Licensed Content (as defined
              in Clause 3)
            </ListItem>
            <ListItem>
              Licensor has agreed to grant to the Licensee the Rights (defined
              in Clause 9) in the Licensed Content, on the terms and conditions
              contained herein.
            </ListItem>
          </OrderedList>
        </Text>
        <Table>
          <Tbody>
            <Tr color="white">
              <Td>Licensed Content</Td>
              <Td>
                Licensed Content shall mean and include audio-visual content as
                listed out in Annexure A hereto along with the Associated
                Material (as defined Clause 16).
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Licensed Language(s)</Td>
              <Td>
                All languages and dialects Notwithstanding anything stated in
                this Agreement, the Licensor hereby grants sub-titling rights in
                the respective languages which are mandated as per local laws of
                the respective Territory
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Territory</Td>
              <Td>Worldwide</Td>
            </Tr>
            <Tr color="white">
              <Td>Runs</Td>
              <Td>Unlimited</Td>
            </Tr>
            <Tr color="white">
              <Td>Shucae Films Pvt. Ltd.</Td>
              <Td>
                means (i) all services owned and/or operated by Licensee
                including but not limited to services currently branded as
                Shucae Films as may be re-branded from time to time)
                (collectively “Brand(s)”) and/or any of its variants and/or any
                successor services/platforms operating under any Brand(s), made
                accessible to the end users through Permitted Means of Delivery
                for Permitted Means of Viewing on Permitted Devices The current
                names of the Brands may be changed by Shucae Films at its sole
                discretion anytime. “Permitted Means of Delivery” means all
                means of digital delivery of audio- visual content including but
                not limited to mobile networks, telecommunication networks,
                internet, closed networks (including but not limited to WAN,
                LAN, WiFi etc.) and/or any other means of digital delivery of
                audio-visual content, accessible by viewers across India using
                applications, progressive web applications, websites, browsers,
                streaming devices etc. (“Permitted Means of Viewing”), on any
                and all devices without any restriction whatsoever (“Permitted
                Devices”) for providing audio-visual content through any modes
                of on-demand (i.e. SVOD, AVOD (each, as defined under Clause 16)
                or hybrid of these models, whereby the Licensed Content may be
                offered along with other third-party programmes and/or otherwise
                as a package of the Licensed Content on the Services as deemed
                fit by Licensee for enabling access to viewers to receive and
                view content including the Licensed Content by way of streaming
                and/or temporary downloads or offline play or download-to-go
                permitted for 15 (fifteen) days (from the date on which the
                Licensed Content is made available for viewing offline). The
                Services may be available/licensed, embedded with, or included
                as part of a package or section under the Brands, hosted by any
                platform/service operated by third parties, including telecom
                networks, internet providers or other service providers.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Grant of Rights</Td>
              <Td>
                1.1. Licensor hereby grants to Licensee, license to transmit,
                stream, communicate to the public and to exhibit the Licensed
                Content (more particularly detailed in Annexure-A) in the
                Licensed Language(s) in the Territory and for the Runs on, and
                by means of the following, on-demand rights (as set out herein
                below) on the Services (including (a) offline viewing /temporary
                downloads for a period of 15 (fifteen) days at a time; as set
                out herein below (collectively defined as "Rights"): i. AVOD (as
                defined in Clause 16) ii. SVOD (as defined in Clause 16) and
                iii. Hybrid of the AVOD and/or SVOD iv. Airborn rights, v. Which
                include Taxi Cab/Cab, Private buses and government buses,
                Railway Trains, Metro trains, waiting lounge in hospitals,
                airports, bus-stand, Hotels, Café, Restaurants, resorts,
                Railways,. vi. Anywhere waiting lounge are possible. Rights
                further include the following: 1.2. Ad Sales Rights: 1.2.1.
                right to sell, serve and insert advertisements and brand
                partnership and brand association in and around the Licensed
                Content, promotions, commercials etc. directly and/or indirectly
                through integrations with ad serving tools/agencies/services or
                in any other manner as per sole discretion and manner deemed fit
                by Licensee and/or as pre-rolls, post-rolls, mid-rolls, spots,
                overlays, pop-ups, tickers, banners, skyscrapers, bottom boards,
                other inventory, advertising, etc. before and/or during and/or
                after the Licensed Content, appearing with, between segments or
                otherwise sold against the Licensed Content provided such
                inclusion of advertisements shall have no impact on the Licensed
                Content. For the sake of clarity, the decision on whether to
                serve any such advertisements, brand partnership and brand
                association or charge any subscription fee or other fees shall
                solely be at the discretion of Licensee. 1.2.2. The
                advertisements, promotions, commercials, etc. on Services may
                also include display/banner/text advertisements including but
                not limited to medium rectangle, leaderboard, roadblock,
                hyperlink, page branding, framing, widgets, pop-ups, pop-under,
                network advertisements available on Services. Licensee shall
                have the sole right and discretion to decide the style,
                placement and format of the advertisement and promotion and the
                price and/or any other consideration, if any, for the sale and
                license of such advertisements, brand partnership and brand
                association, promotions, etc. 1.2.3. for the above purpose,
                right to receive revenues and collect monies for sale of any
                type of advertisements on the Service. 1.3. Licensor agrees that
                the rights granted under the Agreement shall not lapse for any
                reason whatsoever on account of Licensee not exercising such
                rights granted for any period during the Term. Notwithstanding
                anything contrary contained in the Copyright Act, 1957 (as
                amended), Licensor hereby waives the rights granted under the
                provisions of Section 30A read with Section 19(4) of the
                Copyright Act, 1957 (as amended) in favour of Licensee.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Other Terms</Td>
              <Td>
                1.1. Licensee shall have the right to use, exhibit, copy,
                encode, store, cache, host, archive, publish, distribute,
                transmit, modify, edit, create Promotional Content, create
                Metadata, create index, include in any programming guide,
                stream, upgrade or downgrade/up convert the technical and visual
                format of the Licensed Content, broadcast, communicate to
                public, publicly display the Licensed Content in whole or in
                part via any means, and on any and all devices, in connection
                with Services. The foregoing includes necessary rights and
                licenses to use of the literary work, artistic work,
                compositions and sound recordings of any music included (along
                with cue sheets) in the Licensed Content in the Services. 1.2.
                Licensee shall have the right to permit the viewing (but not
                copying) of textual material, data, voice, audio, video,
                audio-visual and/or other multimedia services except as required
                for buffering/cache of Licensed Content while
                streaming/progressive download/offline viewing and for such
                other requirements. 1.3. Notwithstanding anything to the
                contrary, Licensee shall have the sole right to undertake edits
                of the Licensed Content in any manner necessary including but
                not limited: (a) to comply with time segment or censorship
                requirements; and/or (b) for edits required in terms of
                self-regulatory norms of Licensee and/or the industry; and/or
                (c) for removal/replacement of songs, music-pieces, sound
                recordings, soundtrack, etc.; and/or (d) to create edited
                versions, clips, bundled/packaged clips, best-of’s, content
                around content, etc. of the Licensed Content; and/or (e) to
                create Promotional Content; and/or (vi) to sell, include and
                insert advertisements, brand partnership and brand association ,
                promotions, commercials, etc. as pre-rolls, post-rolls,
                mid-rolls, spots, overlays, pop-ups, tickers, banners,
                skyscrapers, bottom boards, other inventory, advertising, etc.
                before and/or during and/or after the Licensed Content,
                appearing with, between segments or otherwise sold against the
                Licensed Content. Licensee shall also have absolute, complete
                and unrestricted rights/ power to delete or edit or split the
                Licensed Content to the extent necessary to suit the format and
                enhance convenience of viewing the Licensed Content on Services.
                1.4. The advertisements, promotions, commercials, etc. on
                Services may also include display/banner/text advertisements
                including but not limited to medium rectangle, leader-board,
                roadblock, hyperlink, page branding, framing, widgets, pop-ups,
                pop-under, network advertisements available on Services.
                Licensee shall have the sole right and discretion to decide the
                style, placement and format of the advertisement and promotion
                and the price and/or any other consideration, if any, for the
                sale and license of such advertisements, brand partnership and
                brand association, promotions, etc. Notwithstanding what is
                stated herein above, Licensee shall also have the right, but not
                an obligation, to exhibit the Licensed Content with and/or
                without advertisements on Services. Further, the decision on
                whether to serve any such advertisements, brand partnership and
                brand association or charge any subscription fee or other fees
                shall solely be at the discretion of Licensee. 1.5. Licensee
                shall have the right to create and publish Promotional Content
                on any and all media including digital platforms, social media,
                print, television, internet (including YouTube, social media
                platforms, etc.), and/or on such other media as Licensee deems
                fit, for promotion of the Licensed Content. The Licensor hereby
                grants the right to Licensee to use Licensor’s trademark and
                logo only for the purpose of exercising the Rights granted
                herein and for promotion of the Licensed Content. The Licensor
                shall provide sufficient Promotional Content for the Licensed
                Content, scripts, music cue sheets, etc. 1.6. The Licensee shall
                be entitled to promote/ market the Licensed Content on any
                third-party platforms/ channels/ services or any other
                platforms, media and mode as required by the Licensee.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Consideration</Td>
              <Td>
                The Parties agree that in lieu of the Rights granted to the
                Licensee in relation to the Licensed Content, shall be entitled
                to pay the Consideration as set out in Annexure A
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Termination</Td>
              <Td>
                12.1 The Licensee shall have the right (but not the obligation)
                to terminate the Agreement, in part or in whole, by giving a
                notice of 7 (seven) days to the Licensor in the following
                circumstances: 1.1.1. if the Licensor fails to deliver and/or
                defaults in delivery of the Delivery Materials of the Licensed
                Content by the timeline agreed with Licensee and/or fails to
                clear title/claim/dispute with respect to the Licensed Content
                on account of release of public notice and/or fails to obtain
                CBFC certificate of the Licensed Content as per clause 15.2.3
                and/or commits breach of any term, obligation, undertaking,
                representation or warranty as mentioned under this Agreement;
                1.1.2. if the Licensee has to withdraw/cease exploitation of the
                Licensed Content on account of any dispute or claim raised from
                any person/party, court order, notification issued by any
                government or non-government authority/association and/or the
                Licensor commits any breach; 1.1.3. if the Licensed Content is
                banned, put on hold/exploitation has to be withdrawn, etc., due
                to any court order/notification issued by any government or
                non-government body, claim or dispute arises due to
                obscenity/blasphemous/defamation/contempt of court/breach of
                contract/breach of privilege/violation of any provisions of the
                statute/hurting sentiments of any religious
                groups/person/declared against public policy of any nation or
                state; 1.1.4. without giving any reason whatsoever. 1.2. If this
                Agreement is terminated by the Licensee on account of reasons
                stated under Clause 12.1.1 or 12.1.2 or 12.1.3 above, then the
                Licensor shall refund the entire Consideration received by it
                under this Agreement within 30 (thirty) days from the date of
                termination . Further, the Licensee shall have the lien on the
                Licensed Content till the date the Licensee receives the
                outstanding payment, from the Licensor. The aforesaid shall be
                without prejudice to all other rights and remedies available to
                Licensee under law and as per this Agreement. 1.3. If this
                Agreement is terminated by the Licensee under clause 12.1.4
                above, then Licensee shall pay to the Licensor the Consideration
                calculated on a pro rata basis for the period of exploitation of
                the respective Licensed Content (if any accrue) until the date
                of termination, subject to receipt of such monies from the
                advertisers/advertising agencies by the Licensee, with no
                further obligation/liability upon the Licensee. 1.4. If the
                Licensor fails to refund the Consideration as per the terms of
                Clause 12.2 above, the Licensor shall be liable to pay forthwith
                the outstanding amount along with 24% (Twenty-Four Percent)
                interest thereon, calculated from the date on which such refund
                was due, till the date of actual realization of such refund, by
                Licensee. 1.5. Notwithstanding the termination of this
                Agreement, the provisions of this Agreement, the nature of which
                should reasonably require the survival thereof shall survive the
                termination of this Agreement.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Replacement of Licensed Content</Td>
              <Td>
                1.14. Licensee shall have the right to block/exclude the
                Licensed Content (or any part thereof) from being hosted on the
                Services, if in its opinion, it violates or will violate any law
                or regulation or any standard and practices or policies of
                Licensee or it causes or may cause any claim or dispute of any
                nature including infringement of intellectual property rights or
                other proprietary rights of any party and/or is not delivered as
                per the technical specifications required by Licensee. 1.15. In
                such an event, Licensee shall have the option to stop hosting
                such Licensed Content (in whole or in part) and the Licensor
                shall immediately replace and substitute the Licensed Content
                (in whole or in part) with another audio-visual content of
                equivalent market and monetary value and as agreed by Licensee
                and deliver the same at its sole cost and expense, no later than
                7 (seven) days. 1.16. However, if the Parties are unable to
                agree to such replaced audio-visual content, then the Licensee
                shall exercise its rights and remedies available to it under
                Clause 12 of the Agreement and/or in law and equity, with
                respect to whole or part of the Licensed Content.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Delivery Materials and Delivery Dates</Td>
              <Td>
                1.17. The Licensed Content shall be delivered by the Licensor in
                mix and unmix (clean) masters as per the technical
                specifications and timelines notified by Licensee (emails
                permitted). The Licensed Content may be delivered in high
                definition through FTP via Aspera or via Signiant or as maybe
                required by Licensee ("Delivery Materials"). 1.18. Along with
                the Delivery Materials, Licensor shall also provide Licensee the
                scripts of the Licensed Content in English, credits, promotional
                materials (images, stills, clips, etc.), music cue sheets, etc.
                The Licensor shall, on best efforts basis, carry out brand
                promotions for the Services including putting advertisements in
                all its channels and also outdoor hoardings / metro etc.
                strictly as per the brand guidelines shared by Licensee from
                time to time in writing (emails permitted). 1.19. Licensor shall
                deliver all materials as stated herein above, including Delivery
                Materials at its sole cost. 1.20. Delivery Dates / Timelines for
                delivery of the Delivery Materials: as per the timelines
                notified by Licensee (emails permitted) from time to time during
                the Term.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Representations and Warranties</Td>
              <Td>
                1.1. Each Party represents and warrants to the other Party that:
                15.1.1 Either Party is not under any disability, restriction or
                prohibition, legal, contractual or otherwise, which might
                prevent them from performing or observing any of the obligations
                under this Agreement uninterrupted during the Term. Either Party
                further represents that it is competent to enter into this
                Agreement and suffers from no disqualification under the laws of
                India, contract, constitution or any other rule that may apply
                to, or bind either Party and; 15.1.2 it has the power and
                authority to enter into this Agreement and will fully perform
                its obligations hereunder. 1.2. Licensor represents and warrants
                to Licensee that: 1.2.1. it is the sole, absolute, exclusive and
                unencumbered owner of the Licensed Content throughout the
                Territory and/or has and shall continue to have all rights
                essential to grant the Rights, as contemplated under this
                Agreement for the Term. 1.2.2. it has not committed any act or
                omission which will prevent it from performing its obligations
                under this Agreement. 1.2.3. it shall furnish all the relevant
                link documents (including without limitation the CBFC
                certificate of the Licensed Content) in relation to the Licensed
                Content. It is the producer and/or has acquired rights in the
                Licensed Content as per valid arrangement from owner/right
                holder of the Licensed Content. 1.2.4. it has not committed any
                breach under the link documents and/or has not committed any act
                or omission which will prevent it from performing its
                obligations under this Agreement. 1.2.5. it has no actual or
                constructive notice knowledge of any default on the part of the
                producer(s) or any third party or any breach of warranty or
                misrepresentation pursuant to the link documents or any actual
                or alleged defect in any of the Right(s) acquired by it or any
                other agreement with the producer(s)/third party or any other
                event which might constitute an event of default or breach of
                warranty or obligation of the producer(s)/third party or defect
                in any rights in the Licensed Content. 1.2.6. neither it nor the
                producer or any right holder has licensed or assigned or
                encumbered or transferred or otherwise disposed of any rights of
                copyright or other rights in or relation to the Rights in the
                Licensed Content, which shall prejudice the Rights granted to
                the Licensee under this Agreement. 1.2.7. the Licensed Content
                and/or marketing materials/promotional materials do not infringe
                any intellectual property and/or any third- party rights. 1.2.8.
                there are no threatened or outstanding claims in or to the
                Licensed Content, Associated Materials, marketing/ promotional
                materials or the materials upon which the Licensed Content is
                based, or the Licensor’s/ producer(s) names, trademarks, logos
                etc which might impair or interfere with any of the Rights
                granted to Licensee under the Agreement. 1.2.9. it the Licensed
                Content is in conformity with the laws prevailing in Territory
                including but not limited to being duly censored, as applicable,
                by applicable law and/or the Central Board of Film Certification
                (CBFC) with “U/A” certification (i.e Unrestricted exhibition”)
                and/or such censor/statutory authorities of the Territory. In
                case any Licensed Content, as certified by CBFC is not suitable
                for unrestricted exhibition under the Cinematograph Act, 1952,
                the Licensor undertakes to do the needful to obtain
                “Unrestricted” exhibition certificate from CBFC as per the
                aforesaid act before handing over tapes to Licensee on dates and
                terms set out in this Agreement 1.2.10. Licensed Content,
                Associated Materials and/or marketing/promotional materials will
                be in compliance with requirements under laws of the Territory
                and shall not be obscene, blasphemous, lead to contempt of
                court, breach of contract, breach of privilege or hurt the
                sentiments of any religious group/s and/or breach or be against
                any declared public policy of any nation or state. 1.2.11.
                neither the Licensed Content, any materials,
                marketing/promotional materials nor the exercise by Licensee of
                any of the Rights granted under this Agreement, will be
                defamatory in any way or violate, prejudice or infringe upon the
                rights of any person, firm or corporation; 1.2.12. prior to
                delivery of the Licensed Content, all necessary consents will
                have been secured and third- party payments which are the
                responsibility of Licensor will have been paid which are
                necessary to permit the full and unhindered exploitation of the
                Licensed Content by Licensee in accordance with this Agreement.
                1.2.13. no other amount in addition to the Consideration needs
                to be paid by Licensee to Licensor for exploiting the Rights on
                Services for the Term in the Territory. 1.2.14. Licensed Content
                and all underlying works therein including but not limited to
                any artist performances, musical works, literary works etc.,
                incorporated within/ synchronized with the Licensed Content has
                been rights cleared, are free of any encumbrances. Licensor
                further undertakes that it has obtained and shall maintain and
                has paid or shall pay for (as and when applicable) during the
                Term, for all necessary rights, consents, approvals, licenses,
                permissions, as required, including without limitation that from
                any and all authorities, government/non-government bodies,
                societies, third parties etc. for the uninterrupted
                utilization/exploitation of the Rights by the Licensee and
                Licensee shall not be responsible to make any payments towards,
                fees, charges, royalties etc. to any party /entity for
                whatsoever reason and the same shall be the sole responsibility
                of the Licensor. 1.2.15. it or the producers of the Licensed
                Content have entered into necessary agreements/ written
                arrangements with any and all contributors who were engaged by
                the Licensor or producers of the Licensed Content to develop and
                create the Licensed Content (“Contributors”) and such
                agreements/ written arrangements with the Contributors authorise
                the Licensor and producers of the Licensed Content to grant the
                Rights to the Licensee as contemplated hereunder. Licensor
                agrees to provide a copy of such agreements with Contributors,
                if called upon by Licensee. Any breach of the foregoing shall be
                considered as material breach by Licensor and Licensee shall be
                entitled to all remedies/rights available under law and/or as
                per terms of this Agreement. Further, Licensor agree to
                indemnify and keep Licensee indemnified from any claims arising
                from such breach. 1.2.16. Licensor hereby undertakes to liaison,
                coordinate, provide complete support and assistance as required
                by Licensee and/or its representatives and/or it’s
                sub-contractors for the purpose of processing, delivery,
                transcoding, etc., of the Licensed Content as detailed in
                Annexure A. 1.2.17. Licensor hereby represents and warrants that
                it has the applicable licenses, permission, approvals from any
                and all authorities, government/non-government bodies, third
                parties, etc. for providing the Licensed Content to Licensee as
                contemplated herein. 1.2.18. Licensor represents, warrants and
                undertakes that in performing their respective obligations or
                exercising their rights etc. under this Agreement, they or their
                respective employees shall not pay, offer or promise to pay, or
                authorise the payment directly or indirectly of, any monies or
                anything of value to any government official or employee or any
                political party or any candidate for political office for the
                purpose of influencing any act or decision of the government
                official or employee, political party or candidate in order to
                obtain or retain business or to direct business to any person
                and shall conduct themselves and all transactions under this
                Agreement and/or any transaction relating to the business
                contemplated herein in a manner consistent with the intent and
                goals (to the extent applicable) of the OECD Convention on
                Combating Bribery of Foreign Public Officials in International
                Business Transaction, 37 ILM 1 of 15 February 1999, United
                States’ Foreign Corrupt Practices Act, United Kingdom Bribery
                Act, 2010 and any other Indian law including Indian Penal Code,
                1860 and Prevention of Corruption Act, 1988 (“Legislations”). If
                Licensee becomes aware or has reasonable grounds to suspect that
                any transaction relating to the business contemplated herein has
                taken place in contravention of the principles set forth in the
                Legislations, it shall be entitled forthwith, in its sole
                discretion, to terminate this Agreement with immediate effect
                and any and all other arrangements with the Licensor and/or its
                affiliates and the same shall be treated as material breach of
                the Agreement. 1.2.19. Licensor hereby represents and warrants
                that the quality, technicality, compliances and legality
                including the elements contained in the Licensed Content, shall
                be sole liability and responsibility of Licensor. 1.2.20. The
                Licensor hereby agrees that the Licensee shall be free to issue
                a notice inviting objection from any person/entity in respect of
                any kind of infringement of any rights of such person/entity
                with regard to the licensing of the Rights in and to the
                Licensed Content and/or in relation to promotion/marketing of
                the Licensed Content. However, after publication of the public
                notice of the Licensed Content, in the event there are any
                claims received against Licensed Content, then the Licensor
                shall resolve/settle all such disputes/claims raised by any
                third party(ies) and provide a NOC/withdrawal letter from such
                third parties evidencing that all such claim/s are either
                successfully settled by the Licensor or are disposed off vide an
                order of the court of competent jurisdiction not later than 30
                (thirty) days from the date of receipt of such claim/dispute in
                order to ensure continued unhindered and seamless exploitation
                of Rights by the Licensee.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Definitions </Td>
              <Td>
                1.1. “Associated Material”: which inter-alia includes the
                associated materials of the Licensed Content such as “behind the
                scenes”, “uncut / footages”, “the making of”, “bloopers” etc.
                1.2. “AVOD (Advertisement video on-demand)”: means a video on
                demand service on the Services whereby a viewer is authorized to
                watch episodes/clips of the Licensed Content on an on-demand
                basis (i.e. at times chosen by the viewer entirely at his/her
                discretion without reference to a schedule of viewing times
                pre-established by the service provider), where the Licensed
                Content is made available for viewing with advertising, but
                without the payment of any fee (whether by way of subscription
                charge, fixed charge or per exhibition charge of any kind), or
                such a low level of fee as to be virtually free (except for fee
                payable by users to operators (internet, mobile,
                telecommunications)). 1.3. “Force Majeure Event” shall mean
                circumstances or events beyond the reasonable control of the
                relevant Party including acts of God, floods, earthquake, any
                natural calamity, explosions, riots, wars, hurricane, sabotage
                terrorism, civil disturbance, accident, fire, explosion,
                epidemic, strike, lockout, inclement weather, transmission
                and/or satellite or transponder failure or degradation, signal
                interference caused by transmission via any adjacent satellite,
                failure or degradation of facilities for satellite uplink
                services, any decision of any court or other body of competent
                jurisdiction (including any competent regulatory authority), the
                enactment of any law, the issuance of any executive or judicial
                order or decree, or other cause in the nature of force majeure
                beyond the reasonable control of any of the Parties which could
                not reasonably have been avoided or event that is not
                foreseeable. 1.4. “FVOD (Free video on-demand)”: means an on
                demand service permitting the streaming of a program/content, or
                parts thereof, at the individual request of the end user
                (non-linear), at a time chosen by the user (i.e. entirely at
                his/her discretion without reference to a schedule of viewing
                times pre-established by the service provider), on a service
                where no charge (whether by way of subscription charge, fixed
                charge or per exhibition charge of any kind except for fee
                payable by users to operators (internet, mobile,
                telecommunications)). 1.5. “Metadata”: in relation to Licensed
                Content shall mean data and information about the contents or
                material contained in the Licensed Content such as transcripts
                of conversations, lyrics of song and text description of its
                scenes. 1.6. “Promotional Content”: shall mean any portion,
                excerpt, extract, etc. of the Content (either provided by
                Licensor and/or created by Licensee from the materials provided
                by Licensor under this Agreement), and will include without
                limitation images, stills, clips, audio-visuals, etc., to
                promote the availability of the Licensed Content on the Services
                and/or to promote the availability of the Services. 1.7. “NVOD
                (Near Video on Demand)” means multiple regularly scheduled
                transmissions in a short time period (such as 24 (twenty-four)
                hours), where a charge is made to the viewer to access any
                particular programming which runs in loop within such short time
                period. For sake of clarity, NVOD allows subscribers to access
                any particular programming with greater frequency than the
                length of the particular programme. 1.8. “SVOD (Subscription
                video on-demand)” : means a video on demand service on the
                Services whereby a viewer is authorized to watch episodes/clips
                of the Licensed Content on an on-demand basis (i.e. at times
                chosen by the viewer entirely at his/her discretion without
                reference to a schedule of viewing times pre-established by the
                service provider), subject to payment of a subscription fee (and
                not as per Licensed Content, per exhibition, or per transaction
                fee), during the period of their subscription. SVOD may be
                advertisement supported or subscription fee supported or both.
                SVOD may or may not include advertisements. It is further
                clarified that such subscription fee may also be levied for a
                bundle of services, which, along with the Services containing
                the program/content (including Licensed Content), also includes
                data (Internet) and telephony (i.e. a triple play / converged
                model), which converged model may or may not initially be
                offered free of charge for a limited period of time (whether in
                lieu of payment for future duration or otherwise) as part of
                this subscription service. It is clarified that the fee may be
                in the form of overall tariff plan availed by end users and/or
                internet data packages to be purchased by the end users as a
                condition to access such SVOD service(s). 1.9. “TVOD
                (Transaction Video On Demand)” means an on demand service
                permitting the streaming of a program/content, or parts thereof,
                at the individual request of the end user (non-linear), at a
                time chosen by the user (i.e. entirely at his/her discretion
                without reference to a schedule of viewing times pre-established
                by the service provider), on a service upon payment of a
                separate one-off fee, to view such program.
              </Td>
            </Tr>
            <Tr color="white">
              <Td>Miscellaneous</Td>
              <Td>
                1.1. Licensor agrees to fully indemnify and keep indemnified
                Licensee, its directors, employees, agents, affiliates at all
                times against all damages, loss, claims, demands, expenses
                (including legal and professional expenses) costs and
                liabilities arising, directly or indirectly as a result of any
                breach, act or omission or non-performance by the Licensor of
                any of undertakings, representations, warranties, or obligations
                hereunder including but not limited to in connection with the
                Licensed Content. In the event of any claim, the Licensor will
                promptly adjust, settle or defend or otherwise dispose of such
                claim at Licensor’s sole cost. 1.2. The Parties agree that save
                and except for the Consideration to be paid by Licensee and any
                additional monies (if any) expressly agreed under this
                Agreement, Licensee shall not be liable in any manner whatsoever
                for any other monies/costs/fees towards any claims arising
                pursuant to this Agreement and/or liabilities howsoever incurred
                by the Licensor in relation to this Agreement. 1.3. Licensor
                hereby expressly acknowledges and agrees that its failure to
                comply with the provisions of this Agreement may cause
                irreparable harm and injury to Licensee, which cannot be fully
                or adequately compensated by monetary damages. Accordingly,
                Licensee may seek interim and permanent equitable relief, in the
                event of any such breach, without prejudice to all other
                rights/remedies available under this Agreement and/or as per law
                and/or equity. 1.4. Any information that is not publicly
                available and is provided by one Party to the other Party for
                the purpose of implementing this Agreement and/or during the
                course of negotiations including but not limited to the
                operations of either Party, financial information and/or the
                terms of this Agreement, shall not be disclosed by the receiving
                Party to any third party without the prior written consent of
                the other Party. The Parties shall treat this Agreement as
                confidential (“Confidential Information”). The Parties hereby
                agree that if any Party receives notice that it may be required
                or ordered by any judicial, governmental, or other judicial or
                regulatory entity to produce any information which is construed
                as confidential under this Agreement or any of its terms or any
                such confidential information is required or ordered to be
                produced under any law, order, rule or regulation, such Party
                shall give the other Party prompt notice of such requirement so
                that the other Party may seek a protective order or other
                appropriate remedies in respect thereof. If such protective
                order or other remedy is not obtained, then the Party required
                to provide the information will exercise reasonable endeavors to
                attain assurances that confidential treatment will be accorded
                to such Confidential Information so disclosed. However, the
                Licensor shall be free to issue a notice inviting objection from
                any person/entity in respect of any kind of infringement of any
                rights of such person/entity with regard to the licensing of the
                Rights in and to the Licensed Content and/or in relation to
                promotion/marketing of the Licensed Content. 1.5. Neither Party
                shall be liable for its inability or delay in performing any of
                its obligations hereunder if such inability or delay is caused
                by Force Majeure Event, provided that the Party whose
                performance is delayed or prevented by Force Majeure Event
                promptly notifies the other party immediately (in writing e-mail
                permitted) upon becoming aware of the act giving rise to the
                delay or inability of performance and undertakes all reasonable
                action in good faith to minimize the effect of Force Majeure
                Event. The Parties hereby agree that, if the Force Majeure Event
                continues for a period of 7 (seven) days or more, then either
                party’s obligations stand suspended during such period Force
                Majeure Event continues including Shucae Films payment
                obligations. However, if the Licensee is not able to exploit the
                Licensed Rights (or part thereof), due to any Force Majeure
                Event, then the Term under this Agreement shall stand extended
                for a period equal to such period the Force Majeure Event
                continues and if the Force Majeure Event continues for more than
                60 (sixty) days then non-affected Party may terminate this
                Agreement upon written notice, without any further obligation
                except pro-rata payment (to the extent unpaid by the Licensee)
                for the Term of license elapsed as per the terms of this
                Agreement until the occurrence of the Force Majeure Event,
                subject to no breach by the Licensor. Further, excess amounts
                (if any) received by the Licensee shall forthwith stand refunded
                to the Licensee. 1.6. This Agreement constitutes a binding
                agreement between the Parties with respect to the subject matter
                hereof and supersedes any prior agreement and communication
                whether written or verbal. The recitals, annexures, schedules
                and exhibits to this Agreement form an integral part of the
                Agreement. 1.7. The relationship of the Parties under this
                Agreement is on a principal-to-principal basis and nothing
                contained in this Agreement shall make either Party a partner,
                joint venture participant or agent of the other. 1.8. If any
                provision of this Agreement or part thereof is rendered void,
                illegal or unenforceable by any legislation to which it is
                subject, it shall be rendered void, illegal or unenforceable to
                that extent and it shall in no way affect or prejudice the
                enforceability of the remainder of such provision or the other
                provisions of this Agreement. The invalidity, illegality or
                unenforceability of any provision in this Agreement under the
                laws of any one jurisdiction shall not in itself affect the
                validity, legality and enforceability of such provisions under
                the laws of any other jurisdiction. 1.9. The provisions relating
                to representations, warranties, undertakings, indemnities,
                confidentiality shall survive the expiration or early
                termination of this Agreement. 1.10. Save and except as
                expressly provided in this Agreement, no exercise, or failure to
                exercise, or delay in exercising any right, power, or remedy
                vested in any Party under or pursuant to this Agreement shall
                constitute a waiver by that Party of that or any other right,
                power, or remedy. 1.11. This Agreement may be executed in
                separate counterparts, each of which when executed and delivered
                will be deemed an original and will be binding upon both
                Parties. 1.12. Licensor shall not assign, sell or transfer this
                Agreement without the express written consent of Licensee.
                Licensee shall have unrestricted right to assign this Agreement
                to any third party. In such event all the representations,
                warranties and terms and conditions as contained in this
                Agreement shall be binding on such party. 1.13. All notices
                given pursuant to this Agreement shall be in writing and shall
                be delivered to the Parties at their respective
                addresses/official email, as stated hereinabove, in this
                Agreement. Any notice given as provided by this clause shall be
                deemed received by the party to whom it is addressed when:
                1.13.1. if sent by e-mail, 24 hours after the e-mail is sent
                only where expressly agreed under the Agreement. Such email
                notices shall be addressed to Shucae Films Private Limited and
                sent on legal@shucaefilms.com 1.14. This Agreement shall be
                governed by and construed in accordance with the laws of India
                and shall be subject to the exclusive jurisdiction of the courts
                at Shimla.
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Footer />
    </Box>
  );
}

/*
 * EXPORTS
 */
export default Index;
