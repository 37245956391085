/*
 * IMPORT
 */
import React, { useEffect, useState } from "react"; // NPM: React package.
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom"; // NPM: React Router Dom for routing.
import { connect } from "react-redux"; // NPM: React Redux for state managment.
import { Toaster } from "react-hot-toast"; // NPM: React Hot Toast for toast messages.
import _ from "underscore";
import { Button, Flex } from "@chakra-ui/react";
/*
 * CONTEXT
 */

/*
 * COMPONENTS
 */
import SignIn from "./Views/Auth/signIn/SignInDefault.jsx";
import SignUp from "./Views/Auth/signUp/SignUpDefault.jsx";
import LandingPage from "./Views/User/website/LandingPage/index";
import Terms from "components/Terms/index.js";
import VideoPlayPage from "./Views/User/website/VideoPlayPage/index";
import CreatorPage from "./Views/User/website/CreatorPage/index";
import ProfilePage from "./Views/User/website/ProfilePage/index";
import UserProfilePage from "./Views/User/website/UserProfilePage/index";
import NotLoggedIn from "./Views/User/website/UserProfilePage/NotLoggedIn";
import EditProfile from "./components/EditProfile/EditProfile.js";
import CreatorProfile from "./components/CreatorProfile/index.js";
import EdituserProfile from "./components/EditProfile/EdituserProfile.js";
import SearchContent from "components/SearchContent/index.js";
import TrayVideos from "components/TrayVideos/index.js";
import Privacy from "Views/User/website/Privacy/index.js";
import Context from "./Context/index.js";
import ForDeveloper from "./Views/User/website/ForDeveloper/index.js";
import Blogs from "./Views/User/website/Blogs/index.js";
import TermsCondition from "components/TermsCondition/index.js";
import BlogPage from "components/BlogPage/index.js";
import AudioPlayPage from "Views/User/website/AudioPlayPage/index.js";

/*
 * MUTATION
 */
import { useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import watchTimeUpdate from "./Views/User/website/__mutation__/index.watchTime.mutation";
import { useLocation } from "react-router-dom";
import TrayPage from "./components/TrayPage/index.js";
import SubscriptionPage from "Views/User/website/SubscriptionPage/index.js";
import PodCast from "components/PodCastPage/index.js";

//
const Index = ({
  account,
  BrandAndLocation,
  brandlocation,
  BrandAndLocationClear,
}) => {
  const [brandName, setBrandName] = useState("");
  const [brandLocation, setBrandLocation] = useState("");
  const [context, setContext] = React.useState({});
  const [watchTimeData, setWatchTimeData] = useState();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const brand = queryParams.get("Brand");
    const locationParam = queryParams.get("location");

    if (brand && locationParam) {
      BrandAndLocation({
        brand,
        locationParam,
      });
    } else {
      BrandAndLocationClear();
      // Redirect to home page when 'our-story.php' or 'index.php' is accessed directly
      if (location.pathname === "/our-story.php" || location.pathname === "/index.php") {
        window.location.href = "/";
      }
    }

    setBrandName(brand);
    setBrandLocation(locationParam);
  }, [location.search]);

  const [watchTimeUpdateMutation, { loading: loadingWatchTime }] = useMutation(
    watchTimeUpdate,
    {
      context: {
        headers: {
          "l-authorization": account?.token,
        },
      },
    }
  );

  useEffect(() => {
    const intervalId = setInterval(handleWatchTimeCalculate, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleWatchTimeCalculate = async () => {
    try {
      const watchTimeObj = JSON.parse(localStorage.getItem("watchTimeData"));

      const watchTimeArray = Object.entries(watchTimeObj).map(
        ([contentDetailId, watchTime]) => ({
          contentDetailId,
          watchTime,
        })
      );
      const response = await watchTimeUpdateMutation({
        variables: {
          watchTimeJson: watchTimeArray,
        },
      });

      const data = response.data.watchTimeUpdate;

      if (!_.isEmpty(data)) {
        if (localStorage.getItem("watchTimeData")) {
          localStorage.removeItem("watchTimeData");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Context.Provider value={{ context, setContext }}>
      <Toaster />
      <Router>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/terms-conditions" component={Terms} />
          <Route exact path="/for-developer" component={ForDeveloper} />
          <Route
            exact
            path="/video-play-page/:title/:id"
            component={VideoPlayPage}
          />
          <Route
            exact
            path="/audio-play-page/:title/:id"
            component={AudioPlayPage}
          />
          <Route exact path="/creator-page" component={CreatorPage} />
          <Route exact path="/profile-page" component={ProfilePage} />
          <Route exact path="/creator-profile" component={CreatorProfile} />
          <Route exact path="/terms" component={TermsCondition} />
          <Route exact path="/user-profile" component={UserProfilePage} />
          <Route exact path="/not-logged-in" component={NotLoggedIn} />
          <Route path="/edit-profile" component={EditProfile} />
          <Route path="/edit-user-profile" component={EdituserProfile} />
          <Route path="/tray-page" component={TrayPage} />
          <Route path="/subscription-page" component={SubscriptionPage} />
          <Route path="/search-results" component={SearchContent} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/blog-page" component={BlogPage} />
          <Route path="/privacy" component={Privacy} />
          <Redirect from="/our-story.php" to="/" />
          <Route path="/Shucae-Films-Audio-Podcast" component={PodCast} />
          <Route path="/:title" component={TrayVideos} />
        </Switch>
      </Router>
    </Context.Provider>
  );
};

const mapStateToProps = (state) => ({
  account: state.Account,
  brandlocation: state.Brand,
});
const mapDispatchToProps = (dispatch) => ({
  BrandAndLocation: (u) =>
    dispatch({ type: "SET_BRAND_AND_LOCATION", BrandLocation: u }),
  accountUpdate: (u) => dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  accountLogout: () => dispatch({ type: "ACCOUNT_CLEAR" }),
  BrandAndLocationClear: (u) => dispatch({ type: "CLEAR_BRAND_AND_LOCATION" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
