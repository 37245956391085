/*
 * IMPORTS
 */
import React, { useEffect, useState, useRef } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import Loader from "Views/User/website/VideoPlayPage/Loader";
import CardComponent from "../PodCast/index";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import MovieDetail from "components/PodCast/MovieDetail";
import { BiChevronDown } from "../../Assets/reactIcons/index";

/*
 * CHAKRA IMPORTS
 */
import {
  Text,
  Avatar,
  Image,
  IconButton,
  Flex,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import PodcastReadAll from "./__query__/index.audioPodcastAll.query";
import CardComp from "components/PodCast/CardComp";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */

  const { name } = useParams();
  const _PodcastReadAll = useQuery(PodcastReadAll);

  /*
   * FUNCTION
   */
  const cardRef = useRef(null);
  const history = useHistory();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [showModal, setShowModal] = useState(false);

  /*
   * FUNCTION
   */

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  function truncateAfterTenLetters(text) {
    if (isLargerThan768) {
      if (!text) return "";
      if (text.length <= 30) return text;
      return text.substring(0, 30) + "...";
    } else {
      if (!text) return "";
      if (text.length <= 10) return text;
      return text.substring(0, 10) + "...";
    }
  }

  const podcastList = _PodcastReadAll?.data?.PodcastReadAll?.map(
    (item, index) => ({
      "@type": "ListItem",
      description: item?.PodcastDescription,
      encodingFormat: "audio/mpeg",
      name: item?.podcastTitle,
      episodeNumber: item?.PodcastEpisode?.[0]?.episodeNumber,
    })
  );

  const itemList = _PodcastReadAll?.data?.PodcastReadAll?.map(
    (item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item?.podcastTitle,
      image: item?.horizontalPosterLink,
    })
  );

  const breadcrumbList = _PodcastReadAll?.data?.PodcastReadAll?.map(
    (item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item?.podcastTitle,
      item: item?.horizontalPosterLink,
    })
  );

  /*
   * USEEFFECT
   */

  return (
    <>
      <Helmet>
        <title>Shucae Films Audio Podcast</title>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "AudioObject",
            itemListElement: podcastList,
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            name: `Listen Audio Podcast Here On Shucae Films`,
            itemListElement: itemList,
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: breadcrumbList,
          })}
        </script>
      </Helmet>

      <Box bg="#00020A">
        <Navbar />

        <Box
          // bg="rgba(22, 7, 39, .4)"
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          pt={{ base: "90px", md: "70px", lg: "30px" }}
          fontFamily="Poppins"
          fontSize={["clamp(12px, 1.5vw, 15px)"]}
          fontWeight="500"
          color="#263238"
          overflow="hidden"
        >
          <Flex
            ml={{ base: "1rem", md: "3rem" }}
            color="white"
            fontSize={{ base: "20px", md: "40px" }}
            pt="5rem"
          >
            Shucae Films Audio Podcast
          </Flex>
          <Flex
            h="90vh"
            gap={{ base: "2rem", md: "2rem" }}
            flexWrap="wrap"
            justify="center"
            overflow="scroll"
          >
            {_PodcastReadAll?.loading ? (
              <Flex justify="center" w="100%">
                <Loader />
              </Flex>
            ) : (
              <>
                {_PodcastReadAll?.data?.PodcastReadAll?.map((item, index) => (
                  <CardComp item={item} margin={70} />
                ))}
              </>
            )}
          </Flex>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
