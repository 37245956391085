/*
 * IMPORT
 */

import "./index.css";

import React from "react";

/*
 * CHAKRA UI IMPORT
 */
import { Box, Text, Flex } from "@chakra-ui/react";

export default function Index({ props }) {
  const { heading, desc } = props;
  return (
    <>
      <Flex
        position="absolute"
        left="-10px"
        bottom="20%"
        zIndex="3"
        color="white"
        background="linear-gradient(93deg, #052DB0 14.59%, #C30280 98.03%)"
        width="100px"
        height="50px"
        clipPath="polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%)"
        paddingLeft="25px"
        flexDir="column"
        justify="center"
      >
        <Text fontWeight="600" fontSize="50px">
          {desc}
        </Text>
      </Flex>
      <Box
        position="absolute"
        left="-10px"
        bottom="10%"
        color="white"
        background="#031D71"
        clipPath="polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)"
        width="40px"
        height="20px"
      >
        {desc}
      </Box>
      {/* <Text
        position="absolute"
        bottom="0"
        left="-15px"
        bg="linear-gradient(180deg, #C30280 0%, #6900D8 100%)"
        bgClip="text"
        fontSize="150px"
        textAlign="end"
        zIndex="10"
        height="170px"
      >
        {desc}
      </Text> */}
    </>
  );
}
