import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isValid } from './validate'; // Make sure this path is correct

const inputStyle1 = {
  width: '410px',
  height: '50px',
  border: '1px solid #3D445A',
  borderRadius: '16px',
  fontFamily: 'Roboto',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '14px',
  letterSpacing: '-0.28px',
  color: '#878992',
  padding: '2px',
  backgroundColor: '#1e202d',
};

const TimeInput = ({
  initTime = '',
  disabled = false,
  mountFocus = false,
  onTimeChange,
  type = 'tel',
  placeholder = ' ',
  className,
  name,
  onBlurHandler,
}) => {
  const [time, setTime] = useState(initTime);
  const inputRef = useRef(null);
  const lastValidTimeRef = useRef(initTime);

  useEffect(() => {
    if (!disabled && mountFocus) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [disabled, mountFocus]);

  const onChangeHandler = (val) => {
    if (val === time) {
      return;
    }
    
    // Automatically add colons for user convenience while ensuring valid formatting
    // eslint-disable-next-line require-unicode-regexp
    const autoFormattedValue = val.replace(/(\d{2})(?=\d)/g, '$1:').slice(0, 8);

    if (isValid(autoFormattedValue)) {
      setTime(autoFormattedValue);
      lastValidTimeRef.current = autoFormattedValue; // Update the reference to the last valid time

      if (autoFormattedValue.length === 8) { // Adjust based on the expected format
        onTimeChange(autoFormattedValue);
      }
    } else {
      // Maintain the last valid value
      setTime(lastValidTimeRef.current);
    }
  };

  return (
    <input
      name={name}
      className={className}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      value={time}
      onChange={(e) => onChangeHandler(e.target.value)}
      onBlur={onBlurHandler}
      ref={inputRef}
      style={inputStyle1}
    />
  );
};

TimeInput.propTypes = {
  initTime: PropTypes.string,
  disabled: PropTypes.bool,
  mountFocus: PropTypes.bool,
  onTimeChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  onBlurHandler: PropTypes.func,
};

export default TimeInput;
