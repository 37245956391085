/*
 * IMPORT
 */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
/*
 * SVG IMPORT
 */

/*
 * REACT ICONS
 */

/*
 * MUTATION
 */
/*
 * QUERY
 */

/*
 * CHAKRA UI IMPORT
 */
import { Image, Text, Flex } from "@chakra-ui/react";

/*
 * OBJECT
 */
const WebSeriesSection = ({ details , title }) => {
  /*
   * STATE
   */
  const history = useHistory();
  const [expandedSummary, setExpandedSummary] = useState({});

  /*
   * FUNCTION
   */
  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);
  
    // eslint-disable-next-line init-declarations
    let formattedDuration = "";
  
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }
  
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  /*
   * USEEFECT
   */


  return (
    <Flex
      m="15px 0px 15px 0px"
      borderRadius="10px"
      bg="rgba(39, 39, 39, 1)"
      w="90%"
      direction="column"
      justify="space-between"
      p="20px"
    >
      <Flex
        mt="1rem"
        w="100%"
        justify="space-between"
        direction={{ base: "column-reverse", md: "row" }}
      >
        <Flex w="100%" gap="20px" direction="column">
          <Flex align="center" gap="8px">
            <Text
              color="rgba(255, 255, 255, 1)"
              fontFamily="Roboto"
              fontSize="20px"
              fontWeight="700"
              lineHeight="21.54px"
              letterSpacing="0.2px"
            >
              Episodes
            </Text>
          </Flex>

          <Flex w="100%" direction="column" gap="30px">
            {details?.map((item) => (
              <Flex gap="10px" w="100%">
                <Flex direction={{ base: "column", md: "row" }} gap="10px">
                  <Flex
                    cursor="pointer"
                    onClick={() =>
                      history.push(
                        `/video-play-page/${title?.replace(
                          / /g,
                          "-"
                        )}/${item?.id}`
                      )
                    }
                    w="180px"
                    h="100px"
                  >
                    <Image
                      w="100%"
                      h="100%"
                      objectFit="fill"
                      src={item?.lowerThumbnailLink}
                    />
                  </Flex>
                  <Flex direction="column" gap="10px">
                    <Flex align="center" gap="10px">
                      <Text
                        color="rgba(255, 255, 255, 1)"
                        fontFamily="Roboto"
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="21.54px"
                        letterSpacing="0.14px"
                        cursor="pointer"
                        onClick={() =>
                          history.push(
                            `/video-play-page/${title?.replace(
                              / /g,
                              "-"
                            )}/${item?.id}`
                          )
                        }
                      >{`Episode ${item?.episodeNumber}`}</Text>
                      <Text
                        color="rgba(186, 186, 186, 1)"
                        fontFamily="Roboto"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="21.54px"
                        letterSpacing="-0.36px"
                      >
                        {formatDuration(item?.duration)}
                      </Text>
                    </Flex>
                    <Flex w={{ base: "100%", md: "500px" }}>
                      <Text
                        fontFamily="Roboto"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="28px"
                        color="rgba(255, 255, 255, 1)"
                        w="100%"
                      >
                        {expandedSummary[item.id] ? (
                          <span>
                            {item.plotSummary}
                            <Text
                              color="rgba(0, 129, 223, 1)"
                              cursor="pointer"
                              onClick={() => toggleSummary(item.id)}
                            >
                              read less
                            </Text>
                          </span>
                        ) : (
                          <span>
                            {item?.plotSummary
                              ?.split(" ")
                              ?.slice(0, 20)
                              ?.join(" ")}
                            {item.plotSummary?.split(" ").length > 20 && (
                              <Text
                                color="rgba(0, 129, 223, 1)"
                                cursor="pointer"
                                onClick={() => toggleSummary(item.id)}
                              >
                                read more
                              </Text>
                            )}
                          </span>
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

/*
 * EXPORT
 */
export default WebSeriesSection;
