/*
 * IMPORT
 */
import { useState } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import useRazorpay from "react-razorpay";
import { logo1 } from "../../../../Assets/images/shucae/export";
import _ from "underscore";
import { MdWorkspacePremium } from "react-icons/md";
import { useHistory } from "react-router-dom";

/*
 * SVG IMPORT
 */

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Button,
  useMediaQuery,
  Flex,
  Text,
  Divider,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import PaymentCreate from "./__mutation__/index.paymentCreate.mutation";
import PaymentUpdate from "./__mutation__/index.paymentUpdate.mutation";
import SubscriptionPlanUpgrade from "./__mutation__/index.subscriptionPlanUpgrade.mutation";
import { useMutation, useQuery } from "@apollo/client";

/*
 * QUERY
 */
import SubscriptionPlanRead from "./__query__/index.subscriptionPlanRead.query";
import AccountDetail from "./__query__/index.accountDetail.query";
/*
 * OBJECT
 */
function SubscriptionPage({ account }) {
  /*
   * STATE
   */
  const toast = useToast();
  const history = useHistory();
  const Razorpay = useRazorpay();
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [isLg] = useMediaQuery("(min-width: 1280px)");
  const [isMd] = useMediaQuery("(min-width: 768px)");
  const [subscriptionValue, setSubscriptionValue] = useState();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [saveUserName, setSaveUserName] = useState("");
  const [saveUserEmail, setSaveUserEmail] = useState("");

  const _AccountDetail = useQuery(AccountDetail, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [PaymentCreateMutation, { loading: loadingPaymentCreate }] =
    useMutation(PaymentCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [
    SubscriptionPlanUpgradeMutation,
    { loading: loadingSubscriptionPlanUpgrade },
  ] = useMutation(SubscriptionPlanUpgrade, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const _SubscriptionPlanRead = useQuery(SubscriptionPlanRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const details = _SubscriptionPlanRead?.data?.SubscriptionPlanRead?.map(
    (item) => ({
      plan: item?.subscriptionName,
      price: item?.amount,
      duration: item?.duration,
      features: "High quality video content",
    })
  );

  const [PaymentUpdateMutation, { loading: loadingPaymentUpdate }] =
    useMutation(PaymentUpdate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * FUNCTION
   */
  function handelGetDetails(items) {
    setSelectedPlan(items.plan);
    setSubscriptionValue(items.price);
  }

  const handlePayment = async () => {
    if (subscriptionValue && selectedPlan) {
      const responmse = await PaymentCreateMutation({
        variables: {
          amount: subscriptionValue,
        },
      });
      const data = responmse?.data?.PaymentCreate;
      setSaveUserName(data?.userName);
      setSaveUserEmail(data?.userEmail);
      if (data) {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: data?.orderJson?.amount,
          currency: data?.orderJson?.currency,
          name: "Shucae Films",
          description: "Payment Transaction",
          image: logo1,
          order_id: data?.orderJson?.id,
          handler: async function (response) {
            if (
              _AccountDetail?.data?.AccountDetail?.subscriptionName !== "FREE"
            ) {
              const res = await SubscriptionPlanUpgradeMutation({
                variables: {
                  orderId: response?.razorpay_order_id,
                  paymentId: response?.razorpay_payment_id,
                  signature: response?.razorpay_signature,
                  amount: data?.orderJson?.amount,
                },
              });
              if (
                res?.data?.SubscriptionPlanUpgrade?.status ===
                "SUBSCRIPTION_UPDATED"
              ) {
                toast({
                  title: "Success",
                  description: "Payment Successfull",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                _AccountDetail?.refetch();
                history.push("/");
              } else {
                toast({
                  title: "Error",
                  description: "Payment Failed",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            } else {
              const res = await PaymentUpdateMutation({
                variables: {
                  amount: data?.orderJson?.amount,
                  orderId: response?.razorpay_order_id,
                  paymentId: response?.razorpay_payment_id,
                  signature: response?.razorpay_signature,
                },
              });
              if (res?.data?.PaymentUpdate?.status === "PAYMENT_SUCCESSFUL") {
                toast({
                  title: "Success",
                  description: "Payment Successfull",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                _AccountDetail?.refetch();
                history.push("/");
              } else {
                toast({
                  title: "Error",
                  description: "Payment Failed",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            }
          },
          prefill: {
            name: saveUserName,
            email: saveUserEmail,
          },
          notes: {
            address:
              "Shucae Films Pvt. Ltd. , Banyorka (Ropri),Sarka ghat, Himachal Pradesh, India, 175024",
          },
          theme: {
            color: "#671d61",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          console.log("Payment failed:", response.error.description);
        });

        rzp1.on("payment.cancel", function () {
          console.log("Payment cancelled by user");
        });

        rzp1.on("close", function () {
          console.log("Payment Window is closed");
        });

        rzp1.on("hide", function () {
          console.log("Payment Window is hidden");
        });

        rzp1.open();
      }
    } else {
      toast({
        title: "Warning",
        description: "Please select a plan to proceed",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /*
   * USEEFFECT
   */
  return (
    <Box bg="#00020A" width={{ base: "100%", md: "100%" }}>
      <Navbar />
      <Box bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)">
        <Flex pt="7rem" pb="7rem" direction="column" align="center" w="100%">
          <Flex direction="column" gap="20px" w="80%">
            <Text fontSize={{ base: "20px", md: "40px" }} color="white">
              Choose the plan that’s right for you
            </Text>
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              gap="20px"
              mt='20px'
            >
              {_SubscriptionPlanRead?.loading ? (
                <>
                  {[1, 2, 3, 4].map((item) => (
                    <Flex
                      borderRadius="20px"
                      h="400px"
                      w={{ base: "100%", md: "23%" }}
                      border="1px solid rgba(255, 255, 255, .5)"
                      align="center"
                      cursor="pointer"
                      zIndex="4"
                      direction="column"
                    >
                      <Flex
                        mt=".5rem"
                        borderRadius="10px"
                        w="90%"
                        h="5rem"
                        cursor="pointer"
                        bg="rgba(255, 255, 255, .1)"
                        border="1px solid #36454F"
                        justify="center"
                        align="center"
                        direction="column"
                      ></Flex>
                    </Flex>
                  ))}
                </>
              ) : (
                <>
                  {details?.map((items) => (
                    <Flex
                      borderRadius="20px"
                      h="400px"
                      w={{ base: "100%", md: "23%" }}
                      border="1px solid rgba(255, 255, 255, .5)"
                      align="center"
                      onClick={() => handelGetDetails(items)}
                      cursor="pointer"
                      zIndex="4"
                      direction="column"
                      boxShadow={
                        selectedPlan === items.plan
                          ? "0px 0px 10px 5px white"
                          : "none"
                      }
                    >
                      <Flex
                        mt=".5rem"
                        borderRadius="10px"
                        w="90%"
                        h={selectedPlan === items.plan ? "6rem" : "5rem"}
                        cursor="pointer"
                        bg="rgba(255, 255, 255, .1)"
                        border="1px solid #36454F"
                        justify="center"
                        align="center"
                        direction="column"
                      >
                        {items.plan ===
                        _AccountDetail?.data?.AccountDetail
                          ?.subscriptionName ? (
                          <Flex
                            color="#FFFFFF"
                            fontFamily="Roboto"
                            fontWeight="500"
                            fontSize="20px"
                            lineHeight="20px"
                            gap="5px"
                          >
                            <span style={{ color: "rgba(255, 193, 7, 1)" }}>
                              {
                                _AccountDetail?.data?.AccountDetail
                                  ?.subscriptionName
                              }
                            </span>
                            <MdWorkspacePremium color="rgba(255, 193, 7, 1)" />
                          </Flex>
                        ) : (
                          <Text fontSize="20px" color="white">
                            {items.plan}
                          </Text>
                        )}

                        <Text fontSize="15px" color="white">
                          {`Rs ${items.price} /-`}
                        </Text>
                      </Flex>
                      <Flex
                        mt="15px"
                        p="0px 10px 0px 10px"
                        w="90%"
                        direction="column"
                        h="100%"
                        justify="space-evenly"
                      >
                        <Flex gap="5px" direction="column">
                          <Text color="white">Desktop , Tabs , Mobile</Text>
                          <Divider />
                        </Flex>
                        <Flex gap="5px" direction="column">
                          <Text color="white">{`Valid for ${items?.duration} days only`}</Text>
                          <Divider />
                        </Flex>
                        <Flex gap="5px" direction="column">
                          <Text color="white">{items?.features}</Text>
                          <Divider />
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Flex>
            {/* <Text fontSize="15px" color="white">
              HD (720p), Full HD (1080p)
              subject to your internet service and device capabilities. Not all
              content is available in all resolutions. See our Terms of Use for
              more details.
            </Text> */}
            <Flex w="100%"  justify="center">
              <Button mt='20px' w={{ base: "30%", md: "20%" }} onClick={handlePayment}>
                {loadingPaymentCreate ? <Spinner /> : "Submit"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(SubscriptionPage);
