/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($contentDetailId: ID!) {
    VideoMylistCreate(contentDetailId: $contentDetailId) {
      message
      status
      MyListCount
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
