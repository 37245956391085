/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import _ from "underscore";
import { connect } from "react-redux"; // NPM: React-redux library.
import {
  videoSmallTextStyle,
  videoBigTextStyle,
  tabStyle,
} from "components/Styles/index.style";

/*
 * QUERY
 */
import ProfileVideoLikeList from "./__query__/index.likeVideos.query";
import VideoMylistRead from "./__query__/index.myList.query";
import VideoHistoryRead from "./__query__/index.myHistory.query";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  useMediaQuery,
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Input,
  Spinner,
} from "@chakra-ui/react";

/*
 * OBJECT
 */
function NotLoggedIn({ account }) {
  /*
   * States
   */
  const [inputData, setInputData] = useState("");
  const [likedVideos, setLikedVideos] = useState();
  const [myList, setMyList] = useState();
  const [myHistory, setMyHistory] = useState();
  const [expandedSummary, setExpandedSummary] = useState({});
  const history = useHistory();
  const [VideoMylistReadQuery, { loading: loadingMyList }] = useLazyQuery(
    VideoMylistRead,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [VideoHistoryReadQuery, { loading: loadingMyHistory }] = useLazyQuery(
    VideoHistoryRead,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [ProfileVideoLikeListQuery, { loading: loadingLikedVideos }] =
    useLazyQuery(ProfileVideoLikeList, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * FUNCTION
   */
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);
  
    // eslint-disable-next-line init-declarations
    let formattedDuration = "";
  
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }
  const filteredMyListData = myList?.filter((data) => {
    // Customize the condition based on your requirements
    return data?.ContentSummary?.contentTitle
      .toLowerCase()
      .includes(inputData.toLowerCase());
  });
  const filteredlikedData = likedVideos?.filter((data) => {
    return data?.ContentSummary?.contentTitle
      .toLowerCase()
      .includes(inputData.toLowerCase());
  });
  const filteredHistoryData = myHistory?.filter((data) => {
    return data?.ContentSummary?.contentTitle
      .toLowerCase()
      .includes(inputData.toLowerCase());
  });
  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };
  const handleMyList = async () => {
    try {
      const response = await VideoMylistReadQuery({
        variables: {},
      });

      const data = response.data.VideoMylistRead;

      if (!_.isEmpty(data)) {
        setMyList(data.data);
      }
    } catch (error) {}
  };
  const handleMyHistory = async () => {
    try {
      const response = await VideoHistoryReadQuery({
        variables: {},
      });

      const data = response.data.VideoHistoryRead;

      if (!_.isEmpty(data)) {
        setMyHistory(data.data);
      }
    } catch (error) {}
  };
  const handleLikedVideos = async () => {
    try {
      const response = await ProfileVideoLikeListQuery({
        variables: {},
      });

      const data = response.data.ProfileVideoLikeList;

      if (!_.isEmpty(data)) {
        setLikedVideos(data.data);
      }
    } catch (error) {}
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    handleLikedVideos();
    handleMyList();
    handleMyHistory();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      {account.isUserLoggedIn ? (
        <Box
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          h="100vh"
          pt="10rem"
          color="#fff"
        >
          {" "}
          <Flex h="100%" overflow="scroll" direction="column" w="100%">
            <Flex justify="center">
              <Input
                placeholder="Search"
                color="#fff"
                w="95%"
                onChange={handleInputChange}
                value={inputData}
              />{" "}
            </Flex>
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="1rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    setInputData("");
                  }}
                >
                  My List
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    setInputData("");
                  }}
                >
                  Liked Videos
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    setInputData("");
                  }}
                >
                  History
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {loadingMyList ? (
                    <Flex
                      w="100%"
                      h="100%"
                      mt="12rem"
                      align="center"
                      justify="center"
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="purple.800"
                        size="xl"
                      />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="800px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {(inputData ? filteredMyListData : myList)?.map(
                        (data) => (
                          <Flex
                            p="15px"
                            key={data.id}
                            borderRadius="10px"
                            bg="#000000"
                            w="100%"
                            gap="1rem"
                            direction={{ base: "column", lg: "row" }}
                          >
                            <Flex
                              w="256px"
                              h="158px"
                              cursor="pointer"
                              onClick={() =>
                                history.push(
                                  `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                    / /g,
                                    "-"
                                  )}/${data?.id}`
                                )
                              }
                            >
                              <Image
                                w="100%"
                                h="100%"
                                src={data?.lowerThumbnailLink}
                              />
                            </Flex>
                            <Flex gap=".5rem" direction="column">
                              <Text
                                cursor="pointer"
                                onClick={() =>
                                  history.push(
                                    `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                      / /g,
                                      "-"
                                    )}/${data?.id}`
                                  )
                                }
                                style={videoBigTextStyle}
                              >
                                {data?.ContentSummary.contentTitle}
                              </Text>
                              <Text
                                style={videoSmallTextStyle}
                                w={{
                                  base: "fit-content",
                                  md: "fit-content",
                                  lg: "850px",
                                }}
                              >
                                {expandedSummary[data.id] ? (
                                  <span>
                                    {data.plotSummary}
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(data.id)}
                                    >
                                      read less
                                    </Text>
                                  </span>
                                ) : (
                                  <span>
                                    {data?.plotSummary
                                      ?.split(" ")
                                      ?.slice(0, 20)
                                      ?.join(" ")}
                                    {data.plotSummary?.split(" ").length >
                                      20 && (
                                      <Text
                                        color="rgba(0, 129, 223, 1)"
                                        cursor="pointer"
                                        onClick={() => toggleSummary(data.id)}
                                      >
                                        read more
                                      </Text>
                                    )}
                                  </span>
                                )}
                              </Text>
                              <Flex gap=".5rem" direction="column">
                                <Flex align="center" gap="1rem">
                                  <Text
                                    color="rgba(69, 211, 105, 1)"
                                    fontFamily="Roboto"
                                    fontSize="19px"
                                    fontWeight="700"
                                    lineHeight="21.54px"
                                    letterSpacing="-0.36px"
                                  >
                                    {new Date(data.createdAt).getFullYear()}
                                  </Text>
                                  <Text
                                    color="rgba(186, 186, 186, 1)"
                                    fontFamily="Roboto"
                                    fontSize="19px"
                                    fontWeight="400"
                                    lineHeight="21.54px"
                                    letterSpacing="-0.36px"
                                  >
                                    {formatDuration(data?.duration)}
                                  </Text>
                                  <Flex
                                    borderRadius="3px"
                                    justify="center"
                                    align="center"
                                    border="1px solid rgba(116, 116, 116, 1)"
                                    color="rgba(255, 255, 255, 1)"
                                    fontFamily="Roboto"
                                    fontWeight="400"
                                    fontSize="12px"
                                    lineHeight="21.54px"
                                  >
                                    {data.other}
                                  </Flex>
                                </Flex>
                                <Flex align="center" gap="1rem">
                                  <Flex
                                    color="white"
                                    w="70px"
                                    h="20px"
                                    justify="center"
                                    align="center"
                                    border="1px solid rgba(116, 116, 116, 1)"
                                  >
                                    {`${
                                      data?.ContentSummary?.ageRating === "18"
                                        ? "A"
                                        : "U/A"
                                    } ${data?.ContentSummary?.ageRating}+`}
                                  </Flex>
                                  <Text style={videoSmallTextStyle}>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {data?.ContentSummary?.genre}
                                    </span>
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        )
                      )}
                    </Flex>
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex
                    overflowY="auto"
                    maxHeight="800px"
                    p="35px 24px"
                    direction="column"
                    gap="1rem"
                  >
                    {(inputData ? filteredlikedData : likedVideos)?.map(
                      (data) => (
                        <Flex
                          p="15px"
                          key={data.id}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          direction={{ base: "column", md: "row" }}
                        >
                          <Flex
                            w="256px"
                            h="158px"
                            onClick={() =>
                              history.push(
                                `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                  / /g,
                                  "-"
                                )}/${data?.id}`
                              )
                            }
                          >
                            <Image
                              w="100%"
                              h="100%"
                              src={data?.lowerThumbnailLink}
                            />
                          </Flex>
                          <Flex gap=".5rem" direction="column">
                            <Text
                              cursor="pointer"
                              style={videoBigTextStyle}
                              onClick={() =>
                                history.push(
                                  `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                    / /g,
                                    "-"
                                  )}/${data?.id}`
                                )
                              }
                            >
                              {data?.ContentSummary.contentTitle}
                            </Text>
                            <Text
                              style={videoSmallTextStyle}
                              w={{ base: "fit-content", md: "850px" }}
                            >
                              {expandedSummary[data.id] ? (
                                <span>
                                  {data.plotSummary}
                                  <Text
                                    color="rgba(0, 129, 223, 1)"
                                    cursor="pointer"
                                    onClick={() => toggleSummary(data.id)}
                                  >
                                    read less
                                  </Text>
                                </span>
                              ) : (
                                <span>
                                  {data?.plotSummary
                                    ?.split(" ")
                                    ?.slice(0, 20)
                                    ?.join(" ")}
                                  {data.plotSummary?.split(" ").length > 20 && (
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(data.id)}
                                    >
                                      read more
                                    </Text>
                                  )}
                                </span>
                              )}
                            </Text>
                            <Flex gap=".5rem" direction="column">
                              <Flex align="center" gap="1rem">
                                <Text
                                  color="rgba(69, 211, 105, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="700"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {new Date(data.createdAt).getFullYear()}
                                </Text>
                                <Text
                                  color="rgba(186, 186, 186, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="400"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {formatDuration(data?.duration)}
                                </Text>
                                <Flex
                                  borderRadius="3px"
                                  justify="center"
                                  align="center"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                  color="rgba(255, 255, 255, 1)"
                                  fontFamily="Roboto"
                                  fontWeight="400"
                                  fontSize="12px"
                                  lineHeight="21.54px"
                                >
                                  {data.other}
                                </Flex>
                              </Flex>
                              <Flex align="center" gap="1rem">
                                <Flex
                                  color="white"
                                  w="70px"
                                  h="20px"
                                  justify="center"
                                  align="center"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                >
                                  {`${
                                    data?.ContentSummary?.ageRating === "18"
                                      ? "A"
                                      : "U/A"
                                  } ${data?.ContentSummary?.ageRating}+`}
                                </Flex>
                                <Text style={videoSmallTextStyle}>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {data?.ContentSummary?.genre}
                                  </span>
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      )
                    )}
                  </Flex>
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex
                    overflowY="auto"
                    maxHeight="800px"
                    p="35px 24px"
                    direction="column"
                    gap="1rem"
                  >
                    {(inputData ? filteredHistoryData : myHistory)?.map(
                      (data) => (
                        <Flex
                          p="15px"
                          key={data.id}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          direction={{ base: "column", md: "row" }}
                        >
                          <Flex
                            w="256px"
                            h="158px"
                            onClick={() =>
                              history.push(
                                `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                  / /g,
                                  "-"
                                )}/${data?.id}`
                              )
                            }
                          >
                            <Image
                              w="100%"
                              h="100%"
                              src={data?.lowerThumbnailLink}
                            />
                          </Flex>
                          <Flex gap=".5rem" direction="column">
                            <Text
                              cursor="pointer"
                              style={videoBigTextStyle}
                              onClick={() =>
                                history.push(
                                  `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                                    / /g,
                                    "-"
                                  )}/${data?.id}`
                                )
                              }
                            >
                              {data?.ContentSummary.contentTitle}
                            </Text>
                            <Text
                              style={videoSmallTextStyle}
                              w={{ base: "fit-content", md: "850px" }}
                            >
                              {expandedSummary[data.id] ? (
                                <span>
                                  {data.plotSummary}
                                  <Text
                                    color="rgba(0, 129, 223, 1)"
                                    cursor="pointer"
                                    onClick={() => toggleSummary(data.id)}
                                  >
                                    read less
                                  </Text>
                                </span>
                              ) : (
                                <span>
                                  {data?.plotSummary
                                    ?.split(" ")
                                    ?.slice(0, 20)
                                    ?.join(" ")}
                                  {data.plotSummary?.split(" ").length > 20 && (
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(data.id)}
                                    >
                                      read more
                                    </Text>
                                  )}
                                </span>
                              )}
                            </Text>
                            <Flex gap=".5rem" direction="column">
                              <Flex align="center" gap="1rem">
                                <Text
                                  color="rgba(69, 211, 105, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="700"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {new Date(data?.createdAt).getFullYear()}
                                </Text>
                                <Text
                                  color="rgba(186, 186, 186, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="400"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {formatDuration(data?.duration)}
                                </Text>
                                <Flex
                                  borderRadius="3px"
                                  justify="center"
                                  align="center"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                  color="rgba(255, 255, 255, 1)"
                                  fontFamily="Roboto"
                                  fontWeight="400"
                                  fontSize="12px"
                                  lineHeight="21.54px"
                                  p="0px 5px"
                                >
                                  HD
                                </Flex>
                              </Flex>
                              <Flex align="center" gap="1rem">
                                <Flex
                                  color="white"
                                  w="70px"
                                  h="20px"
                                  justify="center"
                                  align="center"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                >
                                  {`${
                                    data?.ContentSummary?.ageRating === "18"
                                      ? "A"
                                      : "U/A"
                                  } ${data?.ContentSummary?.ageRating}+`}
                                </Flex>
                                <Text style={videoSmallTextStyle}>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {data?.ContentSummary?.genre}
                                  </span>
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      )
                    )}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
          <Footer />
        </Box>
      ) : (
        <Box
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          w="100%"
          height="100vh"
        >
          <Flex w="100%" h="100px"></Flex>

          <Flex height="100vh" align="center">
            <Flex
              mb="7rem"
              align="center"
              justify="center"
              direction="column"
              w="100%"
              gap="1rem"
            >
              <MdOutlineVideoLibrary color="#FFFFFF" size="100px" />

              <Text
                style={videoBigTextStyle}
                fontSize={{ base: "20px", md: "24px" }}
                mt="2"
              >
                Enjoy your favourite videos
              </Text>
              <Text
                style={videoSmallTextStyle}
                fontSize={{ base: "14px", md: "16px" }}
                textAlign="center"
              >
                Sign in to access videos that you’ve liked or saved.
              </Text>
              <Button
                color="#047FCF"
                w="131px"
                h="48px"
                borderRadius="46px"
                border="1px solid #404040"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize={{ base: "13px", md: "16px" }}
                lineHeight="29.04px"
                bg="transparent"
                _hover={{ bg: "transparent" }}
                gap=".5rem"
                onClick={() => history.push("/signin")}
              >
                <CgProfile size="20px" />
                Sign in
              </Button>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );
}
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(NotLoggedIn);
