/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $bio: String!
    $facebook: String!
    $gender: String!
    $instagram: String!
    $twitter: String!
    $userName: String!
  ) {
    AccountUpdate(
      bio: $bio
      facebook: $facebook
      gender: $gender
      instagram: $instagram
      twitter: $twitter
      userName: $userName
    ) {
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
