/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { useLazyQuery, useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import _ from "underscore";
import { tabStyle } from "components/Styles/index.style";
import FollowingCard from "./FollowingCard";
import LikedVideos from "./LikedVideos";
import MyList from "./MyList";
import History from "./History";
import { MdWorkspacePremium } from "react-icons/md";
import { useHistory } from "react-router-dom";

/*
 * QUERY
 */
import FollowersList from "../__query__/index.followers.list";
/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Spinner,
  Avatar,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import ProfileVideoLikeList from "./__query__/index.likeVideos.query";
import VideoMylistRead from "./__query__/index.myList.query";
import VideoHistoryRead from "./__query__/index.myHistory.query";
import FollowLikeAppreciationRead from "../ProfilePage/__query__/index.profile.query";
import AccountDetail from "../ProfilePage/__query__/index.profileDetail.query";

/*
 * OBJECT
 */
function UserProfilePage({ account }) {
  /*
   * States
   */
  const history = useHistory();
  const [likedVideos, setLikedVideos] = useState();
  const [myList, setMyList] = useState();
  const [myHistory, setMyHistory] = useState();
  const [inputData, setInputData] = useState("");
  const [profileInfo, setProfileInfo] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [followingList, setFollowingList] = useState();

  const _AccountDetail = useQuery(AccountDetail, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const _FollowLikeAppreciationRead = useQuery(FollowLikeAppreciationRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [ProfileVideoLikeListQuery, { loading: loadingLikedVideos }] =
    useLazyQuery(ProfileVideoLikeList, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });
  const [VideoMylistReadQuery, { loading: loadingMyList }] = useLazyQuery(
    VideoMylistRead,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [VideoHistoryReadQuery, { loading: loadingMyHistory }] = useLazyQuery(
    VideoHistoryRead,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const [FollowersListQuery, { loading: loadingFollowers }] = useLazyQuery(
    FollowersList,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  /*
   * Functions
   */
  const handleLikedVideos = async () => {
    try {
      const response = await ProfileVideoLikeListQuery({
        variables: {},
      });

      const data = response.data.ProfileVideoLikeList;

      if (!_.isEmpty(data)) {
        setLikedVideos(data.data);
      }
    } catch (error) {}
  };
  const handleMyList = async () => {
    try {
      const response = await VideoMylistReadQuery({
        variables: {},
      });

      const data = response.data.VideoMylistRead;

      if (!_.isEmpty(data)) {
        setMyList(data.data);
      }
    } catch (error) {}
  };
  const handleMyHistory = async () => {
    try {
      const response = await VideoHistoryReadQuery({
        variables: {},
      });

      const data = response.data.VideoHistoryRead;

      if (!_.isEmpty(data)) {
        setMyHistory(data.data);
      }
    } catch (error) {}
  };
  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };
  const filteredMyListData = myList?.filter((data) => {
    // Customize the condition based on your requirements
    return data?.ContentSummary?.contentTitle
      .toLowerCase()
      .includes(inputData.toLowerCase());
  });
  const filteredlikedData = likedVideos?.filter((data) => {
    // Customize the condition based on your requirements
    return data?.ContentSummary?.contentTitle
      .toLowerCase()
      .includes(inputData.toLowerCase());
  });
  const filteredHistoryData = myHistory?.filter((data) => {
    // Customize the condition based on your requirements
    return data?.ContentSummary?.contentTitle
      .toLowerCase()
      .includes(inputData.toLowerCase());
  });

  const formatDateProfile = (value) => {
    const date = new Date(value);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleFollowersList = async () => {
    try {
      const response = await FollowersListQuery({
        variables: {},
      });

      const data = response.data.FollowingList;

      if (!_.isEmpty(data)) {
        setFollowingList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*
   * USEEFFECT
   */

  useEffect(() => {
    handleLikedVideos();
    handleMyList();
    handleMyHistory();
    handleFollowersList();
  }, []);



  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex
            pt="20px"
            pb="20px"
            transform="translate(30px , 10px)"
            maxW="700px"
            w="550px"
            h="650px"
            bg="#000000"
            borderRadius="2px"
            direction="column"
            align="center"
            gap="2rem"
            display={{ base: "none", md: "flex" }}
          >
            <Avatar
              width="110px"
              h="110px"
              name={_AccountDetail?.data?.AccountDetail?.fullName}
            />
            <Flex align="center" gap=".5rem" direction="column">
              <Text
                color="#FFFFFF"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="24px"
                lineHeight="30px"
              >
                {_AccountDetail?.data?.AccountDetail?.fullName}
              </Text>
              <Text
                color="#FFFFFF"
                fontFamily="Poppins"
                fontWeight="500"
                fontSize="14px"
                lineHeight="15px"
              >
                {_AccountDetail?.data?.AccountDetail?.userName
                  ? `@${_AccountDetail?.data?.AccountDetail?.userName}`
                  : null}
              </Text>
            </Flex>

            <Flex
              color="#D9D9D9"
              fontFamily="Poppins"
              fontWeight="400"
              fontSize="18.15px"
              lineHeight="36.3px"
              mt="3rem"
              align="center"
              direction="column"
            >
              <>
                <Flex
                  w="260px"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  isOpen={isOpen}
                  onClose={onClose}
                  onClick={onOpen} // Add onClick handler to open modal
                >
                  <Text>Following</Text>
                  <Text>
                    {
                      _FollowLikeAppreciationRead?.data
                        ?.FollowLikeAppreciationRead?.followingCount
                    }
                  </Text>
                </Flex>
                {/* {isOpen && ( // Render the modal only if isOpen is true
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent margin="auto" bg="#000">
                      <ModalHeader color="#fff">Following</ModalHeader>
                      <ModalCloseButton color="#fff" />
                      <ModalBody>
                        {followingList.map((each) => (
                          <FollowingCard
                            account={account}
                            each={each}
                            key={each.id}
                          />
                        ))}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )} */}
              </>
              <Flex w="260px" align="center" justify="space-between">
                <Text>Liked</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalLikes
                  }
                </Text>
              </Flex>
              <Flex w="260px" align="center" justify="space-between">
                <Text>My List</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalMyList
                  }
                </Text>
              </Flex>
              <Flex w="260px" align="center" justify="space-between">
                <Text>Videos watched</Text>
                <Text>
                  {
                    _FollowLikeAppreciationRead?.data
                      ?.FollowLikeAppreciationRead?.TotalViews
                  }
                </Text>
              </Flex>
            </Flex>

             {_AccountDetail?.data?.AccountDetail?.premiumStatus ? (
              <Flex
                color="#FFFFFF"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="20px"
                lineHeight="20px"
                gap="5px"
              >
                <Text>
                  Your are on{" "}
                  <span style={{ color: "rgba(255, 193, 7, 1)" }}>
                    {_AccountDetail?.data?.AccountDetail?.subscriptionName}
                  </span>
                </Text>{" "}
                <MdWorkspacePremium color="rgba(255, 193, 7, 1)" />
                Plan
              </Flex>
            ) : (
              <Flex
                color="#FFFFFF"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="20px"
                lineHeight="20px"
                gap="5px"
              >
                <Text>Your have no plan</Text>
              </Flex>
            )}

            <Flex align="center" gap=".5rem" direction="column">
              <Button
                bg="#052DB0"
                color="#FFFFFF"
                w="300px"
                h="40px"
                borderRadius="24px"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="16px"
                lineHeight="30px"
                letterSpacing="0.32px"
                gap=".5rem"
                _hover={{ bg: "#052DB0" }}
                onClick={() => history.push("/subscription-page")}
              >
                {_AccountDetail?.data?.AccountDetail?.premiumStatus
                  ? "Upgrade Plan"
                  : "Get Premium"}
              </Button>
            </Flex>

            <Flex>
              <Text
                color="#A9AAA9"
                fontFamily="Poppins"
                fontSize="11px"
                fontWeight="400"
                lineHeight="15px"
                letterSpacing="0.11px"
              >
                {`MEMBER SINCE : ${formatDateProfile(
                  _AccountDetail?.data?.AccountDetail?.createdAt
                )}`}
              </Text>
            </Flex>
          </Flex>

          <Flex overflow="scroll" direction="column" w="100%">
            <Flex justify="center">
              <Input
                placeholder="Search"
                color="#fff"
                w="95%"
                onChange={handleInputChange}
                value={inputData}
              />{" "}
            </Flex>
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    setInputData("");
                  }}
                >
                  My List
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    setInputData("");
                  }}
                >
                  Liked Videos
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    setInputData("");
                  }}
                >
                  History
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {loadingMyList ? (
                    <Flex
                      w="100%"
                      h="100%"
                      mt="12rem"
                      align="center"
                      justify="center"
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="purple.800"
                        size="xl"
                      />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="800px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {(inputData ? filteredMyListData : myList)?.map(
                        (data) => (
                          <MyList data={data} />
                        )
                      )}
                    </Flex>
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex
                    overflowY="auto"
                    maxHeight="800px"
                    p="35px 24px"
                    direction="column"
                    gap="1rem"
                  >
                    {(inputData ? filteredlikedData : likedVideos)?.map(
                      (data) => (
                        <LikedVideos data={data} />
                      )
                    )}
                  </Flex>
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex
                    overflowY="auto"
                    maxHeight="800px"
                    p="35px 24px"
                    direction="column"
                    gap="1rem"
                  >
                    {(inputData ? filteredHistoryData : myHistory)?.map(
                      (data) => (
                        <History data={data} />
                      )
                    )}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(UserProfilePage);
