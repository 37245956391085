/*
 * IMPORT
 */
import React, { useState } from "react";
import { LuUpload } from "react-icons/lu";
import UploadContentForm from "./UploadContentForm";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import YoutubeAuthModal from "./modal";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Text,
  useDisclosure,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import AccountStoryUpload from "./__mutation__/index.uploadStory.mutation";

/*
 * OBJECT
 */
function UploadContent({ account , _AccountDetail }) {
  /*
   * STATE
   */
  const [poster, setPoster] = useState();
  const toast = useToast();
 

  const [AccountStoryUploadMutation, { loading: loadingAccountStoryUpload }] =
    useMutation(AccountStoryUpload, {
      context: {
        headers: {
          "l-authorization": account.token},
      },
    });

  /*
   * Functions
   */
  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      handleUploadStory(file);
    }
  };

  const handleUploadStory = async (file) => {
    try {
      const response = await AccountStoryUploadMutation({
        variables: {
          accountStoryPhoto: file,
        },
      });

      const data = response.data.AccountStoryUpload;

      if (data.status === "STORY_UPLOAD_SUCCESSFULLY") {
        toast({
          title: "Success",
          description: "Story uploaded successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        _AccountDetail?.refetch();
        setPoster("");
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex gap="27px" m="20px">
        <UploadContentForm />
        <Flex
          border="0.8px solid #7B7B7B"
          width="485px"
          height="131px"
          borderRadius="12px"
          justify="center"
          align="center"
          directio="column"
          cursor="pointer"
          onClick={() => {
            document.getElementById("thumbnailInput").click();
          }}
        >
          <Flex
            color="#FFFFFF"
            fontFamily="Poppins"
            fontWeight="500"
            fontSize="18px"
            lineHeight="30px"
            letterSpacing="-0.18px"
            direction="column"
            align="center"
          >
            <Flex align="center">
              {loadingAccountStoryUpload ? (
                <Spinner />
              ) : (
                <LuUpload size="20px" />
              )}
            </Flex>
            <Text>Upload your story</Text>
            <Input
              type="file"
              id="thumbnailInput"
              name="thumbnailStoredAt"
              onChange={handleImageUpload}
              display="none"
            />
          </Flex>
        </Flex>
      </Flex>
     
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(UploadContent);
