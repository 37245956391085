/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    GetTrailerVideos {
      id
      lowerThumbnailLink
            lowerVerticalThumbnailStoredAt
      trailerLink
      ContentSummary {
        id
        contentTitle
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
