/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($barcodeInfo: JSON) {
    AdsPlay(barcodeInfo: $barcodeInfo) {
      brandVideo
      id
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
