/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($id: ID!) {
    ContentVidioLikeDislike(id: $id) {
      status
      message
      likeCount
    }
  }

`;

/*
 * EXPORTS
 */
export default Index;
