export const isValid = (val) => {
    // Check for the correct overall format: optional hours, minutes, and seconds
    // eslint-disable-next-line require-unicode-regexp
    const regexp = /^(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?$/;
    const match = val.match(regexp);
  
    // If the format doesn't match, return false
    if (!match) return false;
  
    // Extract parts using destructuring. Undefined if the group did not match.
    const [, hoursStr, minutesStr, secondsStr] = match;
  
    // Function to validate a time component
    const isValidTime = (str, max) => {
        // eslint-disable-next-line no-undefined
        if (str !== undefined) {
            const num = Number(str);
  
            return Number.isInteger(num) && num >= 0 && num <= max;
        }
  
        return true; // If the component is not provided, it's considered valid
    };
  
    // Validate hours, minutes, and seconds
    return (
        isValidTime(hoursStr, 23) && // Hours must be between 0 and 23
        isValidTime(minutesStr, 59) && // Minutes must be between 0 and 59
        isValidTime(secondsStr, 59) // Seconds must be between 0 and 59
    );
  };
  