/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($barcodeInfo: JSON, $categoryId: ID) {
    CategoryRead(barcodeInfo: $barcodeInfo, categoryId: $categoryId) {
      message
      status
      id
      priority
      name
      ContentDetailCategory {
        id
        ContentSummary {
          ageRating
          cast
          contentLanguage
          contentTitle
          contentType
          genre
          dop
          producer
          id
          Account {
            createdAt
            displayName
            fullName
            id
            profileCoverThumbnailStoredAt
            updatedAt
            instagram
            twitter
            facebook
            Stories {
              id
              profileStoryLink
            }
            Follow_Follow_Account__followingIdToAccount {
              id
            }
          }
          ContentDetail {
            duration
            createdAt
            id
            episodeNumber
            likes
            plotSummary
            isEmbedded
            lowerThumbnailLink
            thumbnailLink
            lowerVerticalThumbnailStoredAt
            isPremium

          }
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
