/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { useHistory, useLocation } from "react-router-dom";

/*
 * CHAKRA  IMPORT
 */
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  useToast,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import GoogleAccountAttach from "./__mutation__/index.youtubeTokenAttach.mutation";
import { useMutation, useLazyQuery } from "@apollo/client";

/*
 * QUERY
 */
import GoogleAccountLogin from "./__query__/index.youtubeGoogleToken.query";

/*
 * OBJECT
 */
const YoutubeAuthModal = ({
  account,
  isOpen,
  onClose,
  setModalOpen,
  setShareLoading,
}) => {
  /*
   * STATE
   */
  const [token, setToken] = useState(null);
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const [QueryGetToken, { loading: QueryGetTokenLoading }] =
    useLazyQuery(GoogleAccountLogin);

  const [AttachToken, { loading: mutationLoading }] = useMutation(
    GoogleAccountAttach,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  /*
   * FUNCTION
   */
  const _GoogleSignUp = () => {
    window.location.href = token;
  };

  async function _QueryGetToken() {
    const { data } = await QueryGetToken();
    setToken(data?.GoogleAccountLogin?.token);
  }

  const handleAttachTokenMutationData = async (token) => {
    setShareLoading(true);
    const { data } = await AttachToken({
      variables: {
        code: token,
      },
    });

    if (
      !_.isEmpty(data) &&
      data.GoogleAccountAttach.message === "ACCOUNT_WITH_EMAIL_NOT_FOUND"
    ) {
      toast({
        title: "Error",
        description: "Account with this email already does not exist.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      history.push("/creator-page");
      onClose();
    } else if (
      !_.isEmpty(data) &&
      data.GoogleAccountAttach.status === "CREATE_SUCCESSFUL"
    ) {
      setShareLoading(false);
      toast({
        title: "Succes",
        description: "Successfully attached your account.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      history.push("/creator-page");
      onClose();
      setModalOpen(true);
    } else {
      setShareLoading(false);
      toast({
        title: "error",
        description: "Something went wrong. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      history.push("/creator-page");
      onClose();
    }
  };

  useEffect(() => {
    const _codeParam = new URLSearchParams(location.search).get("code");
    if (!_.isEmpty(_codeParam)) {
      handleAttachTokenMutationData(_codeParam);
    } else {
      _QueryGetToken();
    }
  }, [token]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select your upload method</ModalHeader>
        <ModalCloseButton />
        <ModalBody justify="center" align="center">
          {QueryGetTokenLoading ? (
            <Spinner />
          ) : (
            <Button colorScheme="red" onClick={_GoogleSignUp} mr={3}>
              {mutationLoading ? <Spinner /> : "Youtube"}
            </Button>
          )}

          <Button colorScheme="blue" isDisabled>
            Vimeo
          </Button>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/*
 * EXPORTS
 */
export default YoutubeAuthModal;
