/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($contentSummaryId: ID!, $genre: String!) {
    MoreLikeThis(contentSummaryId: $contentSummaryId, genre: $genre) {
      id
      message
      status
      contentTitle
      ContentDetail {
        plotSummary
        duration
        lowerThumbnailLink
            lowerVerticalThumbnailStoredAt
        id
      }
      ageRating
      createdAt
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
