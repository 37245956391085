/*
 * IMPORT
 */
import React, { useRef, useState, useEffect } from "react";

import { BiChevronDown, BsFillPlayFill } from "../../Assets/reactIcons/index";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Pagination, Navigation } from "swiper/modules";
/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Text,
  Image,
  Avatar,
  IconButton,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import MovieDetail from "./MovieDetail";
import Loader from "../../Views/User/website/VideoPlayPage/Loader";
import { FaArrowRightLong } from "react-icons/fa6";

/*
 * MUTATION
 */

/*
 * QUERY
 */

/*
 * COMPONENT
 */
const CardComp = ({ account, loading, item, index, Spinner, margin }) => {
  /*
   * STATE
   */
  const cardRef = useRef(null);
  const history = useHistory();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [showModal, setShowModal] = useState(false);

  /*
   * FUNCTION
   */

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  function truncateAfterTenLetters(text) {
    if (isLargerThan768) {
      if (!text) return "";
      if (text.length <= 25) return text;
      return text.substring(0, 25) + "...";
    } else {
      if (!text) return "";
      if (text.length <= 10) return text;
      return text.substring(0, 10) + "...";
    }
  }

  return (
    <Flex
      mt={margin || "10px"}
      ref={cardRef}
      borderRadius="20px"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      transition="all 0.3s ease"
      objectFit="cover"
      w={{ base: "170px", md: "320px" }}
      h={{ base: "200px", md: "200px" }}
      _hover={{
        transform: "scale(1.04, 1.05)",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.67)",
        ".carousel_main_div": {
          overflowX: "none",
        },
        borderRadius: "0",
        ".additional-content2": {
          transform: "translateY(-60px)",
          opacity: 1,
        },
        ".additional-content": {
          transform: "translateY(60px)",
          opacity: 1,
        },
        ".movie_thumbnail": {
          borderRadius: "0px",
        },
        ".card_image_div": {
          borderRadius: "0px",
        },
      }}
    >
      <Flex
        w="100%"
        zIndex="0"
        // bg="#00020A"
        bg="rgba(0, 0, 0, 1)"
        px="10px"
        borderTopRadius="20px"
        h="60px"
        className="additional-content2"
        opacity={0}
        position="absolute"
        top="0"
        left="0"
        right="0"
        textAlign="center"
        alignItems="center"
        justify="space-between"
        transition="all 0.3s ease"
        color="white"
      >
        <Flex align="center" gap="10px">
          <Avatar
            width="35px"
            h="35px"
            name="Shucae Films"
            src="https://bit.ly/broken-link"
          />
          <Box textAlign="left">
            <Flex
              fontWeight="400"
              fontFamily="Admina"
              fontSize="14px"
              lineHeight="35.83px"
              textTransform="uppercase"
              gap="10px"
            >
              <Text>SHUCAE</Text>
              <Text>FILMS</Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Flex
        className="card_image_div"
        w="100%"
        zIndex="1"
        h="100%"
        borderRadius="20px"
        sx={{
          filter:
            "brightness(1.1) contrast(1.1) saturate(1.2) sepia(0.2) drop-shadow(0 0 10px rgba(0, 0, 0, 0.2)) hue-rotate(10deg) opacity(0.9)",

          "&:hover": {
            filter:
              "brightness(1.2) contrast(1.15) saturate(1.3) sepia(0.3) drop-shadow(0 0 15px rgba(0, 0, 0, 0.3)) ",
          },
        }}
      >
        <Image
          src={
            isLargerThan768
              ? item?.horizontalPosterLink
              : item?.verticalPosterLink
          }
          className="movie_thumbnail"
          loading="lazy"
          alt="image"
          h="100%"
          w="100%"
          borderRadius="20px"
          objectFit="fill"
        />
      </Flex>

      <Flex
        borderBottomRadius="20px"
        w="100%"
        zIndex="0"
        // bg="#00020A"
        bg="rgba(0, 0, 0, 1)"
        direction="column"
        h="60px"
        className="additional-content"
        opacity={0}
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        p="10px"
        justify="space-between"
        transition="all 0.3s ease"
        color="#7D798E"
      >
        <Flex alignItems="center" justify="space-between">
          <IconButton
            fontSize="22px"
            bg="white"
            icon={<BsFillPlayFill />}
            color="black"
            border="1px solid #fff"
            px="10px"
            borderRadius="50%"
            _hover={{
              bg: "white",
              color: "black",
              fontSize: "25px",
            }}
            onClick={() => {
              history.push(
                `/audio-play-page/${encodeURIComponent(item?.podcastTitle?.replace(/ /g, "-"))}/${
                  item?.id
                }`
              );
            }}
          ></IconButton>
          <Text
            fontWeight="600"
            fontSize="14px"
            color="white"
            style={{ textTransform: "capitalize" }}
          >
            {truncateAfterTenLetters(item?.podcastTitle)}
          </Text>
          <IconButton
            fontSize="22px"
            icon={<BiChevronDown />}
            color="black"
            border="1px solid #fff"
            px="10px"
            borderRadius="50%"
            _hover={{ bg: "white", color: "black" }}
            onClick={() => toggleModal()}
          ></IconButton>
        </Flex>
      </Flex>

      <MovieDetail
        isOpen={showModal}
        onClose={toggleModal}
        account={account}
        movieDetails={item}
      />
    </Flex>
  );
};

export default CardComp;
