/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $orderId: String!
    $paymentId: String!
    $signature: String!
    $amount: Int!
  ) {
    SubscriptionPlanUpgrade(
      orderId: $orderId
      paymentId: $paymentId
      signature: $signature
      amount: $amount
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
