/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($name: String) {
    SpecificCategoryRead(name: $name) {
      message
      status
      id
      createdAt
      contentType
      contentTitle
      contentLanguage
      ageRating
      genre
      dop
      producer
      cast
      ContentDetail {
        id
        duration
        likes
        isWebseries
        isEmbedded
        plotSummary
        contentLink
        lowerThumbnailLink
        lowerVerticalThumbnailStoredAt
        episodeNumber
        isPremium
      }
      Account {
        id
        displayName
        fullName
        userName
        instagram
        twitter
        facebook
        Stories {
          id
          profileStoryLink
        }
        Follow_Follow_Account__followingIdToAccount {
          id
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
