/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    DeveloperInfo {
      message
      status
      account
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
