/*
 * IMPORTS
 */
import { useState, useEffect, useRef } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import TrailerPlay from "./Components/TrailerPlay";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Carousels from "./Components/carousel";
import { logo3 } from "./../../../../Assets/images/shucae/export";
import _ from "underscore";
import { useLocation } from "react-router-dom";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import Loader from "../VideoPlayPage/Loader";
import ShucaeBrandVideo from "../../../../components/ShucaeBrandVideo";
import { Helmet } from "react-helmet";
import AdComponent from "../../../../components/AdComponent";

/*
 * CHAKRA IMPORTS
 */
import { Box, Image, Flex, Text, Button, useToast } from "@chakra-ui/react";

/*
 * MUTATION
 */
import SpecialBrandFreeSubscriptionCreate from "./__mutation__/index.specailBrandFreeSub.mutation";

/*
 * QUERY
 */
import CategoryRead from "./__query__/index.trayFetch.query";
import BrandNameRead from "./__query__/index.adVideoPlay.query";
import BrandNameAlwaysRead from "./__query__/index.adCarsouelData.query";
import GetTopTen from "./__query__/index.getTopRated.query";
import AccountDetail from "./__query__/index.accountDetail.query";

import PodCast from "components/PodCast";

/*
 * OBJECT
 */
function LandingPage({ account, LogoUpdate, logo, brandLocation, BrandSVG }) {
  /*
   * STATE
   */
  const toast = useToast();
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [carsoul1Data, setCarsoul1Data] = useState([]);
  const [carsoul2Data, setCarsoul2Data] = useState([]);
  const location = useLocation();
  const [brandVideoUrl, setBrandVideoUrl] = useState("");
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [brandVideoEnded, setBrandVideoEnded] = useState(false);
  const [brandSvg, setBrandSvg] = useState("");
  const videoRef = useRef(null);
  const shucaeRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  // Access the parameters from the URL
  const brand = searchParams.get("Brand");
  const state = searchParams.get("location");
  const queryParams = {
    Brand: brand,
    location: state,
  };
  const jsonParams = JSON.stringify(queryParams);

  const _AccountDetail = useQuery(AccountDetail, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [
    SpecialBrandFreeSubscriptionCreateMutation,
    { loading: loadingSpecialBrandFreeSubscriptionCreate },
  ] = useMutation(SpecialBrandFreeSubscriptionCreate, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [BrandNameAlwaysReadQuery, { loading: loadingBrandNameAlwaysRead }] =
    useLazyQuery(BrandNameAlwaysRead);

  const _CategoryRead = useQuery(CategoryRead, {
    variables: {
      barcodeInfo: jsonParams,
    },
  });
  const [BrandNameReadQuery, { loading: loadingBrandNameRead }] =
    useLazyQuery(BrandNameRead);

  const _GetTopTen = useQuery(GetTopTen);

  /*
   * FUNCTION
   */
  const handleSpecialBrandFreeSubscriptionCreate = async () => {
    try {
      const response = await SpecialBrandFreeSubscriptionCreateMutation({
        variables: {
          brandName: brandLocation?.brand,
          location: brandLocation?.locationParam,
        },
      });

      const data = response.data.SpecialBrandFreeSubscriptionCreate;

      if (!_.isEmpty(data) && data.status === "ALREADY_SCANNED") {
      }
    } catch (error) {}
  };
  const handleCarsouelAd = async () => {
    try {
      const response = await BrandNameAlwaysReadQuery({
        variables: {
          // barcodeInfo: brandLocation.brand ? brandLocation.brand : jsonParams,
          barcodeInfo: {
            Brand: brandLocation?.brand,
            location: brandLocation?.locationParam,
          },
        },
      });

      const data = response.data.BrandNameAlwaysRead;

      if (!_.isEmpty(data)) {
        setBrandSvg(data?.[0]?.brandSVG);
        BrandSVG({
          brandSvg: data?.[0]?.brandSVG,
        });
        const option1Campaigns = data?.filter(
          (brand) => brand.brandCarousel === "option1"
        );

        const option2Campaigns = data?.filter(
          (brand) => brand.brandCarousel === "option2"
        );
        setCarsoul1Data(option1Campaigns);
        setCarsoul2Data(option2Campaigns);
      }
    } catch (error) {}
  };
  const handleAdVideoPlay = async () => {
    try {
      const response = await BrandNameReadQuery({
        variables: {
          barcodeInfo: {
            Brand: brandLocation.brand,
            location: brandLocation.locationParam,
          },
        },
      });

      const data = response.data.BrandNameRead;

      setBrandVideoUrl(data[0]?.brandVideo);
    } catch (error) {}
  };
  const _IsOneDayOlder = (dateString) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Set the time of currentDate to the same time as givenDate
    currentDate.setHours(0, 0, 0, 0);

    // Set the time of givenDate to the same time as currentDate
    givenDate.setHours(0, 0, 0, 0);

    // Calculate the difference in milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    const difference = currentDate.getTime() - givenDate.getTime();

    // Check if the difference is exactly one day
    return difference === oneDay;
  };
  const handleVideoPlay = () => {
    setTimeout(() => {
      // Set the brandVideoUrl to the desired URL
      setIsVideoPlayed(true);
    }, 3000);
  };
  const handleBrandVideoEnd = () => {
    // Add any logic you need when the brand video ends
    setBrandVideoEnded(true);
    setBrandVideoUrl("");
    localStorage.setItem("brandVideoUrl", "empty");
    localStorage.setItem("brandVideoEnd", brandVideoEnded);
  };
  const handleFirstVideoEnded = () => {
    if (videoPlayed === true) {
      shucaeRef.current.pause();
      videoRef.current.play();
    }
  };

  /*
   * USEEFECT
   */

  useEffect(() => {
    if (!logo.isLogo) {
      const _date = new Date();
      const video = document.getElementById("video");

      const handleVideoEnd = () => {
        setVideoPlayed(true);
        LogoUpdate({ isLogo: true, _date });
      };

      // Add event listener for video end
      video.addEventListener("ended", handleVideoEnd);

      // Play video on mount
      video.play().catch((error) => {
        // Handle play error
      });

      return () => {
        // Cleanup: Remove event listener
        video.removeEventListener("ended", handleVideoEnd);
      };
    } else {
      if (_IsOneDayOlder(logo._date)) {
        LogoUpdate({ isLogo: false });
      }
    }
  }, []);

  useEffect(() => {
    handleAdVideoPlay();
    handleCarsouelAd();
    if (account.isUserLoggedIn) {
      handleSpecialBrandFreeSubscriptionCreate();
    }
  }, [brandLocation]);

  useEffect(() => {
    if (location.pathname === "/") {
    } else {
      setBrandVideoUrl(null);
      setBrandVideoEnded(true);
    }
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "ListElement": [
          ${
            _CategoryRead?.data?.CategoryRead?.map((item, index) => {
              if (item.name === "Shucae Content") {
                return `
                  {
                    "@type": "${item.name}",
                    "position": ${index + 1},
                    "itemListElement": [
                      ${item.ContentDetailCategory?.map((each, index) => {
                        return `
                          {
                            "position": ${index + 1},
                            "@type": "${each?.ContentSummary?.contentTitle}",
                            "image": "${
                              each?.ContentSummary?.ContentDetail[0]
                                .thumbnailLink
                            }",
                            "url": "https://www.shucaefilms.com/video-play-page/${each?.ContentSummary?.contentTitle?.replace(
                              / /g,
                              "-"
                            )}/${item?.id}"
                          }
                        `;
                      })}
                    ]
                  }
                `;
              } else {
                return ""; // Return an empty string for non-"Top Ten" items
              }
            })
              .filter(Boolean)
              .join("") // Filter out empty strings and join the results
          }
        ]
      }
    `}
        </script>
      </Helmet>

      {!logo.isLogo ? (
        <ShucaeBrandVideo />
      ) : (
        <>
          <Flex
            w="105%"
            h="105%"
            display={
              brandVideoUrl === null ||
              brandVideoEnded === true ||
              localStorage.getItem("brandVideoEnd") === true
                ? "none"
                : "block"
            }
          >
            <Flex
              w="100%"
              h="100%"
              top="0"
              bg={
                brandVideoUrl !== null &&
                (localStorage.getItem("brandVideoEnd") || brandVideoEnded)
                  ? "transparent"
                  : "#000"
              }
              zIndex={brandVideoEnded || brandVideoUrl === null ? "99" : "1"}
              left="0"
              alignItems="center"
              position="fixed"
            >
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                autoPlay
                muted
                playsInline
                style={{
                  objectFit: "fill",
                  backgroundSize: "fill",
                  display:
                    brandVideoUrl === null ||
                    brandVideoEnded === true ||
                    localStorage.getItem("brandVideoEnd") === true
                      ? "none"
                      : "flex",
                }}
                onPlay={handleVideoPlay}
                onEnded={handleBrandVideoEnd}
              >
                <source
                  src={
                    localStorage.getItem("brandVideoUrl") === "empty"
                      ? ""
                      : brandVideoUrl
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Flex>
          </Flex>
          <>
            <Box
              bg="#00020A"
              flexDirection="column"
              // display={localStorage.getItem("brandVideoUrl") === "empty" || brandVideoEnded === true ? "flex" : "none"}
              opacity={
                localStorage.getItem("brandVideoEnd") || brandVideoUrl === null
                  ? "99"
                  : "0"
              }
            >
              <Navbar />

              <Box
                // bg="rgba(22, 7, 39, .4)"
                bg={
                  brandSvg
                    ? `url(${brandSvg})`
                    : "linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                }
                pt={{ base: "90px", md: "70px", lg: "30px" }}
                fontFamily="Poppins"
                fontSize={["clamp(12px, 1.5vw, 15px)"]}
                fontWeight="500"
                color="#263238"
                overflow='hidden'
              >
                <Box
                  borderRadius="0px"
                  bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                  position="relative"
                  overflow="hidden"
                  h={{ lg: "700px", md: "400px", base: "300px" }}
                >
                  <TrailerPlay
                    brandVideoUrl={brandVideoUrl}
                    brandVideoEnded={brandVideoEnded}
                  />
                </Box>

                {_CategoryRead?.loading ? (
                  <Flex direction="column" gap="50px">
                    {[1, 2, 3, 4, 5].map((index, item) => (
                      <Flex w="100%" h="100%" justify="center" align="center">
                        <Loader />
                      </Flex>
                    ))}
                  </Flex>
                ) : (
                  <Flex direction="column" mb="7rem">
                    <PodCast
                      brandVideoUrl={brandVideoUrl}
                      brandVideoEnded={brandVideoEnded}
                    />
                    {_CategoryRead?.data?.CategoryRead?.slice(0, 2).map(
                      (data, index) => (
                        <Carousels
                          key={data.id}
                          brandVideoUrl={brandVideoUrl}
                          brandVideoEnded={brandVideoEnded}
                          props={{
                            title: data?.name,
                            trayData: data?.ContentDetailCategory,
                            trayId: data?.id,
                            loading: _CategoryRead?.loading,
                            account: account,
                            premiumStatus:
                              _AccountDetail?.data?.AccountDetail
                                ?.premiumStatus,
                          }}
                        />
                      )
                    )}
                    <Carousels
                      props={{
                        title: _GetTopTen?.data?.GetTopTen?.[0]?.name,
                        trayData:
                          _GetTopTen?.data?.GetTopTen?.[0]
                            ?.ContentDetailCategory,
                        trayId: _GetTopTen?.data?.GetTopTen?.[0]?.id,
                        loading: _GetTopTen?.loading,
                        account: account,
                        premiumStatus:
                          _AccountDetail?.data?.AccountDetail?.premiumStatus,

                        gap: "50px",
                        ribbon: {
                          heading: "bhanu",
                          desc: _GetTopTen?.data?.GetTopTen?.[0]
                            ?.ContentDetailCategory?.length,
                        },
                      }}
                    />
                    {/* <AdComponent adSlot="1234567890" /> */}
                    <Carousel
                      showStatus={false}
                      autoPlay={true}
                      interval={3000}
                      showArrows={false}
                      infiniteLoop={true}
                      showThumbs={false}
                      // onChange={onChange}
                      // onClickItem={onClickItem}
                      // onClickThumb={onClickThumb}
                    >
                      <Flex
                        
                        color="white"
                        overflow="hidden"
                        bg="#230B4D"
                        mx="10px"
                        mt="60px"
                        height={{ base: "162px", md: "451px" }}
                      >
                        <Flex w="100%" justify="space-between">
                          <Flex
                            p="10px 0px 40px 10px"
                            pl={{ base: "10px", md: "40px" }}
                            direction="column"
                          >
                            <Flex
                              alignItems="center"
                              gap="10px"
                              mt={{ base: "0px", md: "14px" }}
                              w={{ base: "40px", md: "148px" }}
                              h={{ base: "45px", md: "45px" }}
                            >
                              <Image src={logo3} />
                            </Flex>

                            <Flex mt="0px" w="60%" direction="column">
                              <Text
                                textAlign="left"
                                color="rgba(255, 255, 255, 1)"
                                fontFamily="Montserrat"
                                fontSize={{ base: "8px", md: "16px" }}
                                fontWeight="500"
                                p={{ base: "5px", md: "2rem 0" }}
                                pl="0"
                                lineHeight={{
                                  base: "8.476px",
                                  md: "32.83px",
                                }}
                                letterSpacing="0.16px"
                              >
                                Sell, Buy and Enjoy{" "}
                              </Text>
                              <Text
                                textAlign="left"
                                color="rgba(255, 255, 255, 1)"
                                fontFamily="Montserrat"
                                w={{ base: "208px", md: "517.241px" }}
                                fontWeight="600"
                                fontSize={{ base: "12px", md: "40px" }}
                                lineHeight={{ base: "18px", md: "49.83px" }}
                                letterSpacing="0.4px"
                              >
                                Unlock the Value of Your Movies, Sell and
                                Discover!
                              </Text>
                            </Flex>

                            <Button
                              color="rgba(255, 255, 255, 1)"
                              fontFamily="Montserrat"
                              fontSize={{ base: "8px", md: "16px" }}
                              fontWeight="600"
                              lineHeight="32.83px"
                              letterSpacing="0.16px"
                              mt={{ base: "20px", md: "30px" }}
                              h={{ base: "24px", md: "50px" }}
                              w={{ base: "82px", md: "155.8px" }}
                              bg={{
                                base: "linear-gradient(85deg, #052DB0 0.38%, #C30280 100.14%)",
                                md: "transparent",
                              }}
                              border={{
                                base: "none",
                                md: "1px solid rgba(255, 255, 255, 1)",
                              }}
                              px="10px"
                              borderRadius="38px"
                              _hover={{ bg: "white", color: "black" }}
                            >
                              More Details
                            </Button>
                          </Flex>
                          <Flex
                            align="center"
                            mr={{ base: "10px", md: "40px" }}
                            justify="flex-end"
                          >
                            <Image
                              objectFit="fill"
                              h={{ base: "250px", md: "250px" }}
                              w={{ base: "250px", md: "100%" }}
                              borderRadius="20px"
                              src={logo3}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                      {carsoul1Data?.map((item, index) => (
                        <Flex
                          mt="60px"
                          
                          key={item?.id}
                          zIndex={
                            brandVideoEnded || brandVideoUrl === null
                              ? "100"
                              : "110"
                          }
                        >
                          <a
                            style={{ width: "100%", borderRadius: "50px" }}
                            href={item?.brandUrl}
                            target="_blank"
                            zIndex={
                              brandVideoEnded === true || brandVideoUrl === null
                                ? "100"
                                : "11"
                            }
                            bg="transparent"
                            rel="noreferrer"
                          >
                            <Image
                              
                              h={{ base: "162px", md: "451px" }}
                              w={{ base: "162px", md: "100%" }}
                              src={item?.brandPoster}
                              objectFit="fill"
                              border="3px solid red"
                              zIndex={
                                brandVideoEnded === true ||
                                brandVideoUrl === null
                                  ? "100"
                                  : "110"
                              }
                            />
                          </a>
                        </Flex>
                      ))}
                    </Carousel>
                    {_CategoryRead?.data?.CategoryRead?.slice(2, 4).map(
                      (data, index) => (
                        <Carousels
                          key={data.id}
                          props={{
                            title: data?.name,
                            trayData: data?.ContentDetailCategory,
                            trayId: data?.id,
                            loading: _CategoryRead?.loading,
                            account: account,
                            premiumStatus:
                              _AccountDetail?.data?.AccountDetail
                                ?.premiumStatus,
                          }}
                        />
                      )
                    )}
                    <Carousel
                      autoPlay={true}
                      interval={3000}
                      showArrows={true}
                      infiniteLoop={true}
                      showStatus={false}
                      showThumbs={false}

                      // onChange={onChange}
                      // onClickItem={onClickItem}
                      // onClickThumb={onClickThumb}
                    >
                      {carsoul2Data?.map((item, index) => (
                        <Flex mt="60px" key={item?.id}>
                          <a
                            style={{ width: "100%", borderRadius: "50px" }}
                            href={item?.brandUrl}
                            target="_blank"
                            zIndex="200"
                            bg="transparent"
                            rel="noreferrer"
                          >
                            <Image
                              
                              h={{ base: "134px", md: "450px" }}
                              w={{ base: "162px", md: "100%" }}
                              src={item?.brandPoster}
                              objectFit="fill"
                            />
                          </a>
                        </Flex>
                      ))}
                    </Carousel>

                    {_CategoryRead?.data?.CategoryRead?.slice(4).map(
                      (data, index) => (
                        <Carousels
                          key={data.id}
                          props={{
                            title: data?.name,
                            trayData: data?.ContentDetailCategory,
                            trayId: data?.id,
                            loading: _CategoryRead?.loading,
                            account: account,
                            premiumStatus:
                              _AccountDetail?.data?.AccountDetail
                                ?.premiumStatus,
                          }}
                        />
                      )
                    )}
                  </Flex>
                )}

                <Footer />
              </Box>
            </Box>
          </>
        </>
      )}
    </>
  );
}

const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(LandingPage);
