/*
 * IMPORTS
 */

import CardComp from "./CardComp";
import { useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import React, { useRef, useState, useEffect } from "react";
import { BiChevronDown } from "../../Assets/reactIcons/index";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Pagination, Navigation } from "swiper/modules";

import { Helmet } from "react-helmet";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Text,
  Image,
  Avatar,
  IconButton,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import MovieDetail from "./MovieDetail";
import Loader from "../../Views/User/website/VideoPlayPage/Loader";
import { FaArrowRightLong } from "react-icons/fa6";

/*
 * CHAKRA IMPORTS
 */

/*
 * QUERY
 */
import PodcastRead from "./__query__/index.podcastRead.query";

/*
 * OBJECT
 */

function Index({ account, brandVideoUrl, brandVideoEnded }) {
  /*
   * STATE
   */
  const history = useHistory();
  const [slidesPerView, setSlidesPerView] = useState(5); // Default number of slides per view

  const margin = 10; // Margin between cards

  const _getPodcast = useQuery(PodcastRead);

  /*
   * FUNCTION
   */

  /*
   * USEEFECT
   */
  useEffect(() => {
    const handleResize = () => {
      // Adjust slidesPerView based on screen width
      if (window.innerWidth < 768) {
        setSlidesPerView(2); // Adjust for smaller screens
      } else if (window.innerWidth > 767 && window.innerWidth < 1025) {
        setSlidesPerView(4); // Default for larger screens
      } else {
        setSlidesPerView(5); // Default for larger screens
      }
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Initial call to set slidesPerView based on initial screen width
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
  {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": "Podcast",
    "itemListElement": ${_getPodcast?.data?.PodcastRead?.map((item, index) => {
      return `
      {
        "@type": "ListItem",
        "position": ${index + 1},
        "name": "${item?.podcastTitle}",
        "image": "${item?.horizontalPosterLink}"
      }`;
    }).join(",")}
  }
  `}
        </script>
      </Helmet>
      <Flex mt="3rem" bg="transparent" flexDir="column">
        <Flex align="center" justify="space-between">
          <Text
            pl="30px"
            color="white"
            fontWeight="700"
            fontSize={{ base: "16px", md: "28px" }}
          >
            Audio Podcast
          </Text>
          {_getPodcast?.data?.PodcastRead?.length > 0 ? (
            <Flex
              cursor="pointer"
              mr="1rem"
              color="white"
              zIndex={brandVideoEnded || brandVideoUrl === null ? "99" : "1"}
              onClick={() => history.push(`/Shucae-Films-Audio-Podcast`)}
              // display={localStorage.getItem("brandVideoEnd") ? "flex" : "none"}
            >
              <FaArrowRightLong size="25px" />
            </Flex>
          ) : (
            <></>
          )}
        </Flex>

        <Flex ml="16px" maxW="100%">
          {_getPodcast?.data?.PodcastRead?.length > 0 ? (
            <Flex w="100%">
              {_getPodcast?.loading ? (
                <Flex w="100%">
                  <Loader />
                </Flex>
              ) : (
                <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={margin}
                  centeredSlides={false}
                  // navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  style={{
                    width: "100%",
                    alignItems: "center",
                    overflow: "visible",
                  }}
                >
                  {_getPodcast?.data?.PodcastRead?.map((movie, index) => (
                    <SwiperSlide
                      key={index}
                      style={{
                        width: "100px",
                      }}
                    >
                      <Flex
                        w="100%"
                        justify="center"
                        align="center"
                        h="100%"
                        // display={
                        //   localStorage.getItem("brandVideoEnd") ||
                        //   brandVideoEnded === true
                        //     ? "flex"
                        //     : "none"
                        // }
                      >
                        <CardComp
                          Spinner={Spinner}
                          loading={_getPodcast?.loading}
                          key={index}
                          account={account}
                          item={movie}
                        />
                      </Flex>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </Flex>
          ) : (
            <Flex
              p="100px 0px 100px 0px"
              w="100%"
              justify="center"
              align="center"
              fontWeight="bold"
            >
              <Text color="white" fontSize={{ base: "20px", md: "40px" }}>
                NO CONTENT IN THIS TRAY
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
}

const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(Index);
