/*
 * IMPORT
 */
import React, { useState } from "react";
import _ from "underscore";
import { v4 as uuidv4 } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import YoutubeAuthModal from "./modal";

/*
 * STYLE IMPORT
 */
import {
  labelStyle,
  inputStyle,
  inputStyle1,
} from "components/Styles/index.style";

/*
 * REACT ICONS
 */
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import TimeInput from "../../timeInput/timeInput";
import { IoClose } from "react-icons/io5";

/*
 * CHAKRA UI IMPORT
 */
import {
  FormControl,
  FormLabel,
  Text,
  useDisclosure,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Input,
  Select,
  Checkbox,
  InputGroup,
  InputRightElement,
  Image,
  useToast,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import ProfileCreate from "./__mutation__/index.creatorForm.mutation";

/*
 * QUERY
 */
import UserNameValidation from "./__query__/index.userVerify.query";
import YoutubeAuth from "./__query__/index.youtubeVerify.query";

/*
 * OBJECT
 */

const TimeWrapper = ({ onDataFromChild }) => {
  const [timeDuration, setTimeDuration] = useState("");

  const onTimeChangeHandler = (val) => {
    const dur = parseInt(val.split(":")[0]) * 60 + parseInt(val.split(":")[1]) + parseInt(val.split(":")[2]) / 60 ;
    setTimeDuration(dur);
    onDataFromChild(dur);
  };

  return (
    <TimeInput
      name="duration"
      borderColor="none"
      className="s-input -time"
      onTimeChange={onTimeChangeHandler}
      placeholder="HH:MM:SS" // Set the placeholder text
    />
  );
};

function CreatorForm({ account }) {
  /*
   * STATE
   */
  const toast = useToast();
  const history = useHistory();
  const [formData, setFormData] = useState(initialState);
  const [linkStateMultiple, setLinkStateMultiple] = useState([]);
  const [youtubeVerifiedStatus, setYoutubeVerifiedStatus] = useState(true);
  const [userVerifiedStatus, setUserVerifiedStatus] = useState(true);
  const [linkState, setLinkState] = useState({
    id: uuidv4(),
    contentLink: "",
    plotSummary: "",
    duration: "",
    verticalThumbnailStoredAt: "",
    fileName: "",
    thumbnailStoredAt: "",
    isEmbedded: false,
  });
  const [castState, setCastState] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);

  const {
    isOpen: isOpenYoutubeAuth,
    onOpen: onOpenYoutubeAuth,
    onClose: onCloseYoutubeAuth,
  } = useDisclosure();

  const [ProfileCreateMutation, { loading: loadingCreatorByDate }] =
    useMutation(ProfileCreate, {
      context: {
        headers: {
          "l-authorization": account.token },
      },
    });
  const [UserVerifyQuery, { loading: loadingUserVerify }] = useLazyQuery(
    UserNameValidation,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [YoutubeAuthQuery, { loading: loadingYoutubeAuth }] = useLazyQuery(
    YoutubeAuth,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  /*
   * FUNCTION
   */
  const handleCreatorForm = async (formData) => {
    try {
      const response = await ProfileCreateMutation({
        variables: {
          ageRating: formData.ageRating,
          cast: castState,
          content:
            formData?.contentType === "webSeries"
              ? linkStateMultiple
              : linkState,
          contentLanguage: formData.language,
          contentTitle: formData.contentTitle,
          contentType: formData.contentType,
          dop: formData.dop,
          producer: formData.producer,
          firstName: formData.creatorName,
          genre: formData.genre,
          lastName: formData.lastName,
          userName: formData.userName,
          music: formData.music,
          phone: formData.phone,
          haveCopyrights: formData.haveCopyrights,
          agreeTerms: formData.agreeTerms,
        },
      });

      const data = response.data.ProfileCreate;

      if (!_.isEmpty(data)) {
        if (data.status === "CONTENT_MISSING") {
          toast({
            title: "Error",
            description: "Content details are missing",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (data.status === "FORM_SUBMIT") {
          resetForm();
          setModalOpen(false);
          toast({
            title: "Form Submitted",
            description: "Your form has been submitted successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          window.location.reload();
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          "An error occurred while submitting the form Pls Try Again Later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleUserVerify = async () => {
    try {
      const response = await UserVerifyQuery({
        variables: {
          userName: formData.userName,
        },
      });

      const data = response.data.UserNameValidation;

      if (!_.isEmpty(data)) {
        if (data.status === "USERNAME_EXIST") {
          formData.userName = "";
          toast({
            title: "Error",
            description: "Invalid User Name",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (data.status === "USERNAME_AVAILABLE") {
          setUserVerifiedStatus(false);
          toast({
            title: "Valid User Name",
            description: "Correct User Name",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while verifing username.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleYoutubeAuth = async () => {
    try {
      const response = await YoutubeAuthQuery({
        variables: {
          youtubeLink: linkState.contentLink,
        },
      });

      const data = response.data.YoutubeAuth;

      if (!_.isEmpty(data)) {
        if (data.status === "VIDEO_NOT_VERIFIED") {
          resetForm();
          setLinkState({
            ...linkState,
            contentLink: "",
          });
          toast({
            title: "Error",
            description: "Invalid Youtube Link",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (data.status === "VIDEO_VERIFIED") {
          setYoutubeVerifiedStatus(false);
          toast({
            title: "Valid Youtube Link",
            description: "This is a valid youtube link",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred while verifing youtube link.",
        status: "error",
        duration: 5000, // milliseconds
        isClosable: true,
      });
    }
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        thumbnailStoredAt: file,
      }));
    }
  };
  const handleVerticalImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        verticalThumbnailStoredAt: file,
      }));
    }
  };
  const handleLinkState = () => {
    if (
      linkState.contentLink &&
      linkState.thumbnailStoredAt &&
      linkState.verticalThumbnailStoredAt &&
      linkState.duration &&
      linkState.plotSummary
    ) {
      const uniqueId = uuidv4();

      const newLinkState = {
        ...linkState,
        id: uniqueId,
      };

      setLinkStateMultiple((prevLinkState) => {
        return [...prevLinkState, newLinkState];
      });

      setLinkState((prev) => {
        return {
          ...prev,
          contentLink: "",
          plotSummary: "",
          duration: "",
          verticalThumbnailStoredAt: "",
          thumbnailStoredAt: "",
          isEmbedded: false,
        };
      });
    }
  };
  const handleCastState = () => {
    if (formData.cast && formData.name) {
      const newLink = {
        id: uuidv4(),
        cast: formData.cast,
        name: formData.name,
      };

      setCastState((prevLinkState) => {
        return [...prevLinkState, newLink];
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        cast: "",
        name: "",
      }));
    }
  };
  function handleOpen() {
    if (account.isUserLoggedIn) {
      onOpenYoutubeAuth();
    } else {
      history.push("/signin");
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const form = { ...formData, [name]: value };

    setFormData(form);
  };
  const handleLinkStateChange = (e) => {
    const { name, value } = e.target;

    setLinkState((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const resetForm = () => {
    setFormData({
      creatorName: "",
      lastName: "",
      userName: "",
      email: "",
      phone: "",
      contentType: "",
      contentTitle: "",
      contentLink: "",
      plotSummary: "",
      duration: "",
      language: "",
      verticalThumbnailStoredAt: "",
      thumbnailStoredAt: "",
      ageRating: "",
      genre: "",
      dop: "",
      producer: "",
      cast: "",
      name: "",
      music: "",
      haveCopyrights: false,
      agreeTerms: false,
      isEmbedded: false,
    });

    setLinkState({});
    setLinkStateMultiple([]);
    setCastState([]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.agreeTerms && formData.haveCopyrights) {
      handleCreatorForm(formData);
    } else {
      toast({
        title: "Error",
        description: "Please agree to our terms and conditions",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleDeleteLinkState = (id) => {
    const updatedLinkState = linkStateMultiple.filter((item) => item.id !== id);

    setLinkStateMultiple(updatedLinkState);
  };
  const handleClose = () => {
    resetForm();
    setModalOpen(false);
  };
  const handleDeleteCastState = (id) => {
    const updatedCastState = castState.filter((item) => item.id !== id);

    setCastState(updatedCastState);
  };
  const handleDuration = (data) => {
    // Handle the data received from the child component
    const form = { ...formData, duration: data };
    setLinkState((prevLinkState) => ({
      ...prevLinkState,
      duration: data,
    }));
    setFormData(form);
  };

  return (
    <Flex
      w="100%"
      justifyContent={{
        base: "center",
        md: "center",
        lg: "center",
        xl: "left",
      }}
    >
      <Button
        zIndex="1"
        w="265px"
        h="52px"
        borderRadius="33px"
        border="1px solid linear-gradient(90.49deg, #052DB0 0.15%, #C30280 101.09%)"
        bg={{
          base: "linear-gradient(269.12deg, #C30280 0.36%, #052DB0 99.49%)",
          md: "linear-gradient(269.12deg, #C30280 0.36%, #052DB0 99.49%)",
          lg: "linear-gradient(269.12deg, #C30280 0.36%, #052DB0 99.49%)",
          xl: "rgba(255, 255, 255, 1)",
        }}
        color={{
          base: "rgba(255, 255, 255, 1)",
          md: "rgba(255, 255, 255, 1)",
          lg: "rgba(255, 255, 255, 1)",
          xl: "rgba(5, 7, 22, 1)",
        }}
        mb={{ base: "20px" }}
        fontFamily="Poppins"
        fontWeight="600"
        fontSize="20px"
        lineHeight="31.74px"
        letterSpacing="-0.4px"
        gap=".5rem"
        // onClick={()=>setModalOpen(true)}
        onClick={handleOpen}
        cursor="pointer"
      >
        {shareLoading ? <Spinner /> : "Share your Content"}

        <Flex ml="1" align="center">
          {" "}
          <FaArrowRightLong />
        </Flex>
      </Button>
      <YoutubeAuthModal
        setShareLoading={setShareLoading}
        account={account}
        setModalOpen={setModalOpen}
        isOpen={isOpenYoutubeAuth}
        onClose={onCloseYoutubeAuth}
      />
      <Modal
        size="5xl"
        isOpen={modalOpen}
        onClose={handleClose}
        scrollBehavior="inside"
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          p={{ base: "7px", md: "15px" }}
          borderRadius="20px"
          bg="rgba(42, 45, 62, 1)"
          position="fixed"
        >
          <ModalHeader
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={{ base: "25px", md: "32px" }}
            lineHeight="28.21px"
            letterSpacing="-0.64px"
            color="rgba(255, 255, 255, 1)"
          >
            Submit Your Content
          </ModalHeader>
          <ModalCloseButton
            size="lg"
            mt="15px"
            mr="10px"
            color="rgba(238, 93, 80, 1)"
          />
          <ModalBody pb={10} overflowY="auto" overflowX="hidden">
            {/* /
            Form
            / */}

            <form onSubmit={handleSubmit}>
              <Flex gap="1rem" direction="column">
                {/* /
                Upper Section of Form
                / */}

                <Flex
                  gap="32px"
                  justify={{ base: "start", md: "center" }}
                  flexWrap="wrap"
                  w={{ base: "80%", md: "100%" }}
                >
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired scrollBehavior="inside">
                      <FormLabel style={labelStyle}>Name of creator </FormLabel>
                      <Input
                        name="creatorName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.creatorName}
                        placeholder="User name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Email ID</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        onChange={handleInputChange}
                        value={formData.email}
                        placeholder="Email id"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Type of content</FormLabel>
                      <Select
                        name="contentType"
                        onChange={handleInputChange}
                        placeholder="Choose content type"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="featureFilm">Feature Film</option>
                        <option value="standUpComedy">Standup Comedy</option>
                        <option value="documentary">Documentary</option>
                        <option value="shortFilm">Short Film</option>
                        <option value="animatedFilm">Animated Film</option>
                        <option value="musical">Musical</option>
                        <option value="webSeries">Web Series</option>
                        <option value="vlog">Vlog</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Flex>
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Last name</FormLabel>
                      <Input
                        name="lastName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.lastName}
                        placeholder="Last name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>User name</FormLabel>
                      <InputGroup align="center" justify="center">
                        <Input
                          name="userName"
                          type="text"
                          onChange={handleInputChange}
                          value={formData.userName}
                          placeholder="User name"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                        />
                        <InputRightElement h="100%" width="4.5rem">
                          <Button
                            onClick={handleUserVerify}
                            h="1.75rem"
                            size="sm"
                          >
                            {loadingUserVerify ? <Spinner /> : "Verify"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Contact number</FormLabel>
                      <Input
                        name="phone"
                        type="number"
                        onChange={handleInputChange}
                        value={formData.phone}
                        placeholder="Mobile number"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Content title</FormLabel>
                      <Input
                        name="contentTitle"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.contentTitle}
                        placeholder="Title"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                {/* /
                Link of Content
                and
                Plot Summary
                and 
                Duration
                and
                Thumbnail
                / */}

                <Flex
                  p="1rem 10px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w={{ base: "106%", md: "100%" }}
                  ml={{ base: "-0.4rem", md: "0" }}
                >
                  <Flex
                    align="center"
                    flexWrap="wrap"
                    p="10px 10px 10px 30px"
                    w="100%"
                    gap="1rem"
                  >
                    {linkStateMultiple.map((item, index) => (
                      <Flex
                        p="20px"
                        borderRadius="5px"
                        bg="#D9D9D9"
                        key={item.id}
                        align="top"
                        gap="1rem"
                        style={labelStyle}
                        w="100%"
                      >
                        <Flex
                          w="100%"
                          gap=".5rem"
                          color="#666874"
                          direction="column"
                        >
                          <Text>
                            Link:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.contentLink}
                            </span>
                          </Text>
                          <Text>
                            Plot:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.plotSummary}
                            </span>
                          </Text>
                          <Text>
                            Duration:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.duration} mins
                            </span>
                          </Text>
                          <Text>
                            Embedded Link:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.isEmbedded ? "Yes" : "No"}
                            </span>
                          </Text>
                          <Text>
                            Horizontal Poster Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.thumbnailStoredAt.name}
                            </span>
                          </Text>
                          <Text>
                            Vertical Poster Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.verticalThumbnailStoredAt.name}
                            </span>
                          </Text>
                          <Flex borderRadius="10px" border="1px solid #878992">
                            <Image
                              src={
                                !!item.thumbnailStoredAt
                                  ? URL.createObjectURL(item.thumbnailStoredAt)
                                  : ""
                              }
                            />
                          </Flex>
                          <Flex
                            w="250px"
                            h="375px"
                            borderRadius="10px"
                            border="1px solid #878992"
                          >
                            <Image
                              src={
                                !!item.verticalThumbnailStoredAt
                                  ? URL.createObjectURL(
                                      item.verticalThumbnailStoredAt
                                    )
                                  : ""
                              }
                            />
                          </Flex>
                        </Flex>
                        <Flex>
                          <IoClose
                            size="20px"
                            cursor="pointer"
                            color="#7551FF"
                            onClick={() => handleDeleteLinkState(item.id)}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                  <Flex
                    gap="1rem"
                    direction="column"
                    w={{ base: "100%", md: "92%" }}
                  >
                    <Flex
                      gap="32px"
                      align="center"
                      direction={{ base: "column", md: "row" }}
                      width="100%"
                    >
                      <FormControl>
                        <FormLabel style={labelStyle}>
                          Your Youtube Content Link
                        </FormLabel>
                        <InputGroup align="center" justify="center">
                          <Input
                            name="contentLink"
                            type="text"
                            onChange={handleLinkStateChange}
                            value={linkState.contentLink}
                            placeholder="Paste link"
                            _placeholder={{ color: "#878992" }}
                            style={inputStyle}
                          />
                          <InputRightElement h="100%" width="4.5rem">
                            <Button
                              onClick={handleYoutubeAuth}
                              h="1.75rem"
                              size="sm"
                            >
                              {loadingYoutubeAuth ? <Spinner /> : "Verify"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>

                      <FormControl>
                        <FormLabel style={labelStyle}>Duration</FormLabel>
                        {/* <TimeInput
                          name="example"
                          initTime="11:12"
                          className="s-input -time"
                          mountFocus="true"
                          onTimeChange={onTimeChangeHandler}
                          onFocusHandler={onFocusHandler}
                          onBlurHandler={onBlurHandler}
                        /> */}
                        <TimeWrapper onDataFromChild={handleDuration} />
                      </FormControl>
                    </Flex>

                    <FormControl>
                      <FormLabel style={labelStyle}>Plot summary</FormLabel>
                      <Input
                        name="plotSummary"
                        type="text"
                        onChange={handleLinkStateChange}
                        value={linkState.plotSummary}
                        placeholder="Write summary"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle1}
                        width={{ base: "320px", md: "852px" }}
                      />
                    </FormControl>

                    <FormControl w="200%">
                      <FormLabel style={labelStyle}>
                        Upload content horizontal poster
                      </FormLabel>
                      <span style={labelStyle}>
                        {linkState?.thumbnailStoredAt?.name}
                      </span>
                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document.getElementById("thumbnailInput").click();
                          }}
                          style={inputStyle1}
                          width={{ base: "320px", md: "852px" }}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="thumbnailInput"
                          name="thumbnailStoredAt"
                          onChange={handleImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl w="200%">
                      <FormLabel style={labelStyle}>
                        Upload content vertical poster
                      </FormLabel>
                      <span style={labelStyle}>
                        {linkState?.verticalThumbnailStoredAt?.name}
                      </span>
                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          width={{ base: "320px", md: "852px" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document
                              .getElementById("verticalThumbnailInput")
                              .click();
                          }}
                          style={inputStyle1}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="verticalThumbnailInput"
                          name="verticalThumbnailStoredAt"
                          onChange={handleVerticalImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>

                    {formData.contentType === "webSeries" ? (
                      <Flex
                        w="54px"
                        h="50px"
                        border="1px solid #3D445A"
                        borderRadius="12px"
                        justify="center"
                        align="center"
                        onClick={() => handleLinkState()}
                        cursor="pointer"
                      >
                        <FaPlus color="#666874" />
                      </Flex>
                    ) : null}
                  </Flex>
                </Flex>

                {/* /
                Mid Section of Form
                / */}

                <Flex
                  gap="32px"
                  justify="center"
                  w="100%"
                  direction={{ base: "column", md: "row" }}
                  flexWrap="wrap"
                >
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Genre</FormLabel>
                      <Select
                        name="genre"
                        onChange={handleInputChange}
                        placeholder="Genre"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="action">Action</option>
                        <option value="comedy">Comedy</option>
                        <option value="documentary">Documentary</option>
                        <option value="romance">Romance</option>
                        <option value="drama">Drama</option>
                        <option value="horror">Horror</option>
                        <option value="scienceFiction">Science Fiction</option>
                        <option value="fantasy">Fantasy</option>
                        <option value="mystery">Mystery</option>
                        <option value="thriller">Thriller</option>
                        <option value="music">Music</option>
                        <option value="education">Education</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel style={labelStyle}>Director</FormLabel>
                      <Input
                        name="dop"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.dop}
                        placeholder="Director"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Producer</FormLabel>
                      <Input
                        name="producer"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.producer}
                        placeholder="Producer"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Content language</FormLabel>
                      <Select
                        name="language"
                        onChange={handleInputChange}
                        placeholder="Choose content language"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="english">English</option>
                        <option value="hindi">Hindi</option>
                        <option value="telugu">Telugu</option>
                        <option value="assamese">Assamese</option>
                        <option value="konkani">Konkani</option>
                        <option value="gujarati">Gujarati</option>
                        <option value="kannada">Kannada</option>
                        <option value="malayalam">Malayalam</option>
                        <option value="marathi">Marathi</option>
                        <option value="manipuri">Manipuri</option>
                        <option value="mizo">Mizo</option>
                        <option value="odia">Odia</option>
                        <option value="punjabi">Punjabi</option>
                        <option value="tamil">Tamil</option>
                        <option value="bengali">Bengali</option>
                        <option value="ladakhi">Ladakhi</option>
                        <option value="urdu">Urdu</option>
                        <option value="kashmiri">Kashmiri</option>
                        <option value="chhattisgarhi">Chhattisgarhi</option>
                        <option value="dogri">Dogri</option>
                        <option value="awadhi">Awadhi</option>
                        <option value="bundelkhandi">Bundelkhandi</option>
                        <option value="maithili">Maithili</option>
                        <option value="bhojpuri">Bhojpuri</option>
                        <option value="garhwali">Garhwali</option>
                        <option value="kumaoni">Kumaoni</option>
                        <option value="pahari ">Himachali (Pahari)</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Age rating</FormLabel>
                      <Select
                        name="ageRating"
                        onChange={handleInputChange}
                        placeholder="Choose age rating"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="7">7+</option>
                        <option value="13">13+</option>
                        <option value="16">16+</option>
                        <option value="18">18+</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Flex>
                </Flex>

                {/* /
                Cast
                and
                Name
                / */}

                <Flex
                  p="1rem 10px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w={{ base: "106%", md: "100%" }}
                  ml={{ base: "-0.4rem", md: "0" }}
                >
                  <Flex
                    align="center"
                    flexWrap="wrap"
                    p="10px 10px 10px 30px"
                    w="100%"
                    gap="1rem"
                  >
                    {castState.map((item, index) => (
                      <Flex
                        p="20px"
                        borderRadius="5px"
                        bg="#D9D9D9"
                        key={item.id}
                        align="top"
                        gap="1rem"
                        style={labelStyle}
                        w="100%"
                      >
                        <Flex
                          w="100%"
                          gap=".5rem"
                          color="#666874"
                          direction="column"
                        >
                          <Text>
                            Cast:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.cast}
                            </span>
                          </Text>
                          <Text>
                            Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.name}
                            </span>
                          </Text>
                        </Flex>
                        <Flex>
                          <IoClose
                            size="20px"
                            cursor="pointer"
                            color="#7551FF"
                            onClick={() => handleDeleteCastState(item.id)}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>

                  <Flex
                    direction="column"
                    gap="1rem"
                    w={{ base: "100%", md: "92%" }}
                  >
                    <Flex align="end" direction={{ base: "column", md: "row" }}>
                      <FormControl>
                        <FormLabel style={labelStyle}>Cast member</FormLabel>
                        <Select
                          name="cast"
                          value={formData.cast}
                          onChange={handleInputChange}
                          placeholder="Choose cast"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          w={{ base: "280px", md: "370px" }}
                          color="#666874"
                        >
                          {/* Add your options here */}

                          <option value="ACTOR">Actor</option>
                          <option value="Story Writer">Story writer</option>
                          <option value="Lyricist">Lyricist</option>
                          <option value="Music">Music</option>
                          {/* Add more options as needed */}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel style={labelStyle}>Name</FormLabel>
                        <Input
                          name="name"
                          type="text"
                          onChange={handleInputChange}
                          value={formData.name}
                          placeholder="Name"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          w={{ base: "280px", md: "370px" }}
                        />
                      </FormControl>
                      <Flex
                        w="54px"
                        h="50px"
                        border="1px solid #3D445A"
                        borderRadius="12px"
                        justify="center"
                        align="center"
                        cursor="pointer"
                        onClick={() => handleCastState()}
                      >
                        <FaPlus color="#666874" />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                {/* /
                Lower section of Form
                / */}

                <Flex
                  pl={{ base: "1rem", md: "3rem" }}
                  mt="2rem"
                  direction="column"
                  gap="1rem"
                >
                  <Checkbox
                    style={labelStyle}
                    fontSize={{ base: "10px", md: "14px" }}
                    lineHeight={{ base: "18.37px", md: "24px" }}
                    // letterSpacing={{base:'-0.14px',md:'-0.28px'}}
                    name="haveCopyrights"
                    checked={formData.haveCopyrights}
                    isChecked={formData.haveCopyrights}
                    onChange={() =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          haveCopyrights: !prev.haveCopyrights,
                        };
                      })
                    }
                  >
                    I confirm that I have the copyrights/intellectual property
                    rights for submitted content.
                  </Checkbox>
                  <Checkbox
                    style={labelStyle}
                    fontSize={{ base: "10px", md: "14px" }}
                    name="agreeTerms"
                    checked={formData.agreeTerms}
                    isChecked={formData.agreeTerms}
                    onChange={() =>
                      setFormData((prev) => {
                        return { ...prev, agreeTerms: !prev.agreeTerms };
                      })
                    }
                  >
                    I agree with Terms and Conditions.
                    <span style={{ color: "#7551FF" }}>
                      <NavLink to="/terms-conditions">
                        Terms and Conditions
                      </NavLink>
                    </span>
                  </Checkbox>
                </Flex>
                <Flex
                  color="rgba(43, 54, 116, 1)"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="16px"
                  fontFamily="Roboto"
                  letterSpacing="-0.32px"
                  justifyContent="center"
                  mt="3rem"
                >
                  <Button
                    _focus={{ outline: "2px solid rgba(66, 153, 225, 0.6)" }}
                    type="submit"
                    width={{ base: "361px", md: "385px" }}
                    height={{ base: "41.34px", md: "54px" }}
                    borderRadius={{ base: "7.6px", md: "10px" }}
                    bg="rgba(255, 255, 255, 1)"
                    isDisabled={youtubeVerifiedStatus || userVerifiedStatus}
                  >
                    <Text
                      bg="linear-gradient(96.31deg, #052DB0 7.3%, #C30280 118.79%)"
                      bgClip="text"
                    >
                      {loadingCreatorByDate ? (
                        <Spinner color="black" />
                      ) : (
                        <Text>Submit</Text>
                      )}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

const initialState = {
  creatorName: "",
  lastName: "",
  userName: "",
  email: "",
  phone: "",
  contentType: "",
  contentTitle: "",
  contentLink: "",
  plotSummary: "",
  duration: "",
  language: "",
  verticalThumbnailStoredAt: "",
  thumbnailStoredAt: "",
  ageRating: "",
  genre: "",
  dop: "",
  producer: "",
  cast: "",
  name: "",
  music: "",
  haveCopyrights: false,
  agreeTerms: false,
  isEmbedded: false,
  fileName: "",
};
/*
 * EXPORT
 */

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(CreatorForm);
