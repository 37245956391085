/*
 * IMPORT
 */
import Navbar from "../../../../components/LandingNavbar/index";
import CreatorForm from "../../../../components/CreatorForm/CreatorForm";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";
import { swiperCardStyle } from "../../../../components/Styles/index.style";
import { NavLink, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import ChannelPartner from "./ChannelPartner";
import ForMobile from "./ForMobile";
import ForTab from "./ForTab";
import ForWeb from "./ForWeb";

/*
 * SVG IMPORT
 */
import facebook from "../../../../Assets/images/shucae/facebook.svg";
import insta from "../../../../Assets/images/shucae/insta.svg";
import MadeInIndia from "../../../../Assets/images/landingPage/madeInIndia.svg";
import logo0 from "../../../../Assets/images/shucae/logo0.svg";
import logo3 from "../../../../Assets/images/shucae/logo3.svg";
import global from "./../../../../Assets/images/shucae/global.svg";
import effortless from "./../../../../Assets/images/shucae/effortless.svg";
import creator from "./../../../../Assets/images/shucae/creator.svg";
import pattern from "./../../../../Assets/images/shucae/pattern.svg";

/*
 * CHAKRA IMPORTS
 */
import { Box, Image, useMediaQuery, Flex, Text } from "@chakra-ui/react";

/*
 * QUERY
 */
import RandomWallPaper from "./__query__/index.allDataFetch.query";

/*
 * OBJECT
 */
function CreatorPage({ account }) {
  /*
   * STATE
   */
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [isLg] = useMediaQuery("(min-width: 1280px)");
  const [isMd] = useMediaQuery("(min-width: 768px)");

  const _RandomWallPaper = useQuery(RandomWallPaper, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * FUNCTION
   */
  function handelDeveloperPage() {
    if (account.isUserLoggedIn) {
      history.push("/for-developer");
    } else {
      history.push("/signin");
    }
  }

  /*
   * USEEFFECT
   */
  return (
    <Box bg="#00020A" width={{ base: "122%", md: "100%" }}>
      <Navbar />
      <Box>
        <Flex
          direction={{ base: "column", md: "column", lg: "column", xl: "row" }}
          align={{ base: "center", md: "center", lg: "center", xl: "top" }}
          justify="space-evenly"
          paddingTop="10rem"
          w={{ base: "90vw", md: "70vw" }}
          position="relative"
          margin="0 auto"
          gap="20px"
        >
          <Flex justify="center" w={{ base: "100%", xl: "50%" }} zIndex="1">
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              style={{ width: "250px", height: "375px" }}
              spaceBetween={20}
            >
              {_RandomWallPaper?.data?.RandomWallPaper?.map((item) => (
                <SwiperSlide key={item?.id} style={swiperCardStyle}>
                  <Flex w="100%" h="100%">
                    <Image
                      w="100%"
                      objectFit="fill"
                      src={item?.verticalThumbnailStoredAt}
                    />
                  </Flex>
                </SwiperSlide>
              ))}

              {/* Add more SwiperSlides with inline styles for each slide */}
            </Swiper>
          </Flex>
          <Flex
            align="center"
            w={{ base: "100%", xl: "50%" }}
            // gap="2rem"
            gap="20px"
            direction="column"
          >
            <Flex
              fontFamily="Montserrat"
              fontSize={{ base: "24px", md: "55.11px" }}
              fontWeight="700"
              lineHeight={{ base: "28px", md: "49.87px" }}
              letterSpacing="0.93px"
              direction="column"
              w="100%"
            >
              <Text
                textAlign={{
                  base: "center",
                  md: "center",
                  lg: "center",
                  xl: "left",
                }}
                color="#FFFFFF"
              >
                Unlock the Value of Your Movies,
              </Text>

              <Text
                textAlign={{
                  base: "center",
                  md: "center",
                  lg: "center",
                  xl: "left",
                }}
                bg="linear-gradient(269.83deg, #C30280 25.94%, #062EB0 73.47%)"
                bgClip="text"
              >
                Inspire, upload, thrive!
              </Text>
            </Flex>

            <Flex
              color="rgba(217, 217, 217, 1)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontWeight="400"
              lineHeight="28.12px"
              letterSpacing="0.93px"
              direction="column"
              w="100%"
            >
              <Text
                textAlign={{
                  base: "center",
                  md: "center",
                  lg: "center",
                  xl: "left",
                }}
              >
                Unlock the value of your entire catalog by showcase your Film
                and TV content to buyers anytime and anywhere they are in the
                world. Create new opportunitites in seasoned markets or new
                territories when you list your content on the global
                marketplace.
              </Text>
            </Flex>
            {/* Creator Form   */}
            <CreatorForm />
          </Flex>

          <Flex
            h="800px"
            left="0"
            top="0"
            opacity="0.3"
            w="100%"
            position="absolute"
            zIndex="0"
            bg="radial-gradient(41.5% 41.5% at 59.19% 49%, rgba(25, 118, 210, 0.6) 0%, rgba(117, 81, 255, 0) 100%)"
          ></Flex>

          <Flex
            h="800px"
            right="0"
            top="0"
            opacity="0.3"
            w="100%"
            position="absolute"
            zIndex="0"
            bg="radial-gradient(41.25% 41.25% at 63.41% 47.47%, rgba(195, 2, 128, 0.6) 0%, rgba(5, 45, 176, 0.012) 100%)"
          ></Flex>
        </Flex>
        {/* Channel Partner */}
        {/* <ChannelPartner /> */}

        <Flex mt='50px' bg="#00020A" direction="column">
          <Flex
            bg={!isLargerThan900 ? "rgba(35, 11, 77, 1)" : null}
            p={{ base: "20px", md: "60px" }}
            justify="space-evenly"
            direction={{
              base: "column",
              md: "column",
              lg: "column",
              xl: "row",
            }}
            align="center"
            gap="10px"
          >
            <Flex
              fontFamily="Montserrat"
              width={{ base: "100%", md: "80%", lg: "80%", xl: "50%" }}
              direction="column"
              color="white"
              gap="1rem"
            >
              <Flex
                fontWeight="700"
                fontSize={{ base: "24px", md: "40px" }}
                lineHeight="47.87px"
                letterSpacing={{ base: "0.12px", md: "0.12px", lg: "0.96px" }}
                color="rgba(255, 255, 255, 1)"
              >
                <Text
                  textAlign={{
                    base: "center",
                    md: "center",
                    lg: "center",
                    xl: "left",
                  }}
                >
                  Monetize Your Passion: How Creators Can Earn on Our Platform
                </Text>
              </Flex>
              <Flex
                fontWeight="400"
                fontSize={{ base: "15px", md: "18px" }}
                lineHeight="34.62px"
                letterSpacing="0.18px"
                color="rgba(217, 217, 217, 1)"
                textAlign={{
                  base: "center",
                  md: "center",
                  lg: "center",
                  xl: "left",
                }}
              >
                At Shucae Films, we're dedicated to providing a platform where
                creators can shine. It's simple: if your movie receives approval
                through our review process, it gets showcased on our platform,
                and here's how creators can earn.
              </Flex>
            </Flex>
            <Flex
              gap="2.5rem"
              fontFamily="Poppins"
              direction="column"
              color="white"
              width={{ base: "100%", md: "80%", lg: "80%", xl: "50%" }}
            >
              <Flex
                bg={!isLargerThan900 ? "rgba(0, 0, 0, 1)" : null}
                borderRadius={!isLargerThan900 ? "20px" : null}
                justify="top"
              >
                <img src={global} alt="" />

                <Flex direction="column">
                  <Text
                    fontWeight="600"
                    fontSize="24px"
                    lineHeight="36px"
                    letterSpacing="0.48px"
                    color="rgba(255, 255, 255, 1)"
                  >
                    Global Exposure
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize={{ base: "12px", md: "12px", lg: "16px" }}
                    lineHeight="24px"
                    letterSpacing="0.32px"
                    color="rgba(173, 173, 173, 1)"
                  >
                    Our platform serves as a gateway to global audiences. It
                    allows creators to present their movies to viewers
                    worldwide, providing international recognition.
                  </Text>
                </Flex>
              </Flex>
              <Flex
                bg={!isLargerThan900 ? "rgba(0, 0, 0, 1)" : null}
                borderRadius={!isLargerThan900 ? "20px" : null}
                align="top"
              >
                <img src={effortless} alt="" />
                <Flex direction="column">
                  <Text
                    fontWeight="600"
                    fontSize="24px"
                    lineHeight="36px"
                    letterSpacing="0.48px"
                    color="rgba(255, 255, 255, 1)"
                  >
                    Effortless Monetization
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize={{ base: "12px", md: "12px", lg: "16px" }}
                    lineHeight="24px"
                    letterSpacing="0.32px"
                    color="rgba(173, 173, 173, 1)"
                  >
                    Creators can transform their passion into earnings. Once a
                    film is approved, it becomes a source of income. The more
                    views and engagement it garners, the more creators earn.
                  </Text>
                </Flex>
              </Flex>
              <Flex
                bg={!isLargerThan900 ? "rgba(0, 0, 0, 1)" : null}
                borderRadius={!isLargerThan900 ? "20px" : null}
                align="top"
              >
                <img src={creator} alt="" />
                <Flex direction="column">
                  <Text
                    fontWeight="600"
                    fontSize="24px"
                    lineHeight="36px"
                    letterSpacing="0.48px"
                    color="rgba(255, 255, 255, 1)"
                  >
                    Creator Support
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize={{ base: "12px", md: "12px", lg: "16px" }}
                    lineHeight="24px"
                    letterSpacing="0.32px"
                    color="rgba(173, 173, 173, 1)"
                  >
                    From budding filmmakers to seasoned artists, we offer
                    support throughout your creative journey, helping you
                    express your creativity effectively.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Text
            mt="2.5rem"
            textAlign="center"
            fontFamily="Poppins"
            fontWeight="600"
            fontSize="40px"
            lineHeight="60px"
            letterSpacing="0.8px"
            color="rgba(255, 255, 255, 1)"
          >
            How It Works
          </Text>

          {!isMd ? (
            // mobile
            <ForMobile />
          ) : null}

          {!isLg && isMd ? (
            //tab
            <ForTab />
          ) : null}
          {isLg ? (
            // web
            <ForWeb />
          ) : null}

          <Flex mb="2rem" mt="2rem" justify="center">
            <Flex
              px={{ base: "1rem", md: "5rem" }}
              h={{ base: "10.125rem", md: "400px" }}
              w={{ base: "22.5rem", md: "90%" }}
              borderRadius="32px"
              bg="linear-gradient(180deg, #052DB0 0%, #C30280 100%)"
              justify="space-between"
              position="relative"
            >
              <Flex
                position="absolute"
                w="100%"
                h="100%"
                right="0"
                bgImage={`url(${pattern})`}
              ></Flex>
              <Flex
                align="left"
                fontFamily="Poppins"
                direction="column"
                justifyContent="center"
                gap={{ base: "1rem", md: "2rem" }}
              >
                <Flex direction="column">
                  <Text
                    fontWeight="600"
                    fontSize={{ base: "20px", md: "40px" }}
                    lineHeight={{ base: "24.6px", md: "60px" }}
                    letterSpacing={{ base: "0.4px", md: "0.8px" }}
                    color="rgba(255, 255, 255, 1)"
                  >
                    Interested in working together?
                  </Text>
                  <Text
                    fontWeight="600"
                    fontSize={{ base: "20px", md: "40px" }}
                    lineHeight={{ base: "24.6px", md: "60px" }}
                    letterSpacing={{ base: "0.4px", md: "0.8px" }}
                    color="rgba(255, 255, 255, 1)"
                  >
                    Contact us !
                  </Text>
                  <Text
                    fontWeight="600"
                    fontSize={{ base: "10px", md: "20px" }}
                    lineHeight={{ base: "14.6px", md: "30px" }}
                    letterSpacing={{ base: "0.4px", md: "0.8px" }}
                    color="rgba(255, 255, 255, 1)"
                  >
                    contact.us@shucaefilms.com
                  </Text>
                </Flex>
              </Flex>
              <Flex
                zIndex="1"
                mr="2rem"
                justifyContent="flex-end"
                direction="column"
              >
                <Flex h="250px" w={{ base: "150px", md: "400px" }}>
                  <img
                    style={{ width: "100%", height: "100%", objectFit: "fill" }}
                    src={logo3}
                    alt="img"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Flex
        fontFamily="Montserrat"
        color="#828797"
        flexDirection="column"
        fontSize={["clamp(12px, 1.5vw, 15px)"]}
        fontWeight="500"
        bg="#00020A"
        w="100%"
      >
        {/* hl:  ---------------------------- bottom footer -------------------------------- */}
        <Flex pb="20px" direction="column" width="100%" zIndex="100">
          <Flex
            pt={{ base: "10px", md: "50px" }}
            pr={{ base: "10px", md: "50px" }}
            pl={{ base: "10px", md: "50px" }}
            justify="space-between"
            fontFamily="Plus Jakarta Sans"
            fontWeight="500"
            fontSize="14px"
            lineHeight="24px"
            align="top"
            color="rgba(163, 164, 169, 1)"
          >
            <Flex
              gap="1rem"
              w={{ base: "7.5rem", md: "30%" }}
              direction="column"
            >
              <Flex gap=".3rem" align="center">
                <NavLink to="/">
                  <img src={logo0} alt="img" />
                </NavLink>
                <NavLink to="/">
                  <Text
                    fontFamily="Yeseva One"
                    fontWeight="400"
                    lineHeight={{ base: "18.534px", md: "27.72px" }}
                    fontSize={{ base: "12px", md: "24px" }}
                    color="rgba(255, 255, 255, 1)"
                  >
                    Shucae Films
                  </Text>
                </NavLink>
              </Flex>
              <Text
                fontWeight="500"
                fontSize={{ base: "5px", md: "14px" }}
                marginTop={{ base: "0.2rem", md: "0.45rem" }}
              >
                Shucae Films Pvt. Ltd. , Banyorka (Ropri),Sarka ghat, Himachal
                Pradesh, India, 175024.
              </Text>
            </Flex>
            <Flex
              gap=".5rem"
              width={{ base: "60px", md: "118px" }}
              direction="column"
              // mt={{ base: "0", md: "3.3rem", lg: "0" }}
            >
              <Text
                mb="1rem"
                fontSize={{ base: "7px", md: "12px" }}
                fontWeight="500"
              >
                Contact Us
              </Text>
              {/* <Text>+911204902825</Text>
            <Text>+919818204406</Text>
            <Text>+919205022725</Text> */}
              <Text
                w={{ base: null, md: "190px" }}
                fontWeight={{ base: "500", md: "500" }}
                fontSize={{ base: "5px", md: "14px" }}
              >
                contact.us@shucaefilms.com
              </Text>
            </Flex>
            <Flex
              gap=".5rem"
              direction="column"
              align="center"
              width={{ base: "60px", md: "118px" }}
              textAlign={{ base: "center", md: "right", lg: "center" }}
              //   mt={{ base: "0", md: "3.3rem", lg: "0" }}
            >
              <Text
                mb="1rem"
                fontSize={{ base: "7px", md: "13px" }}
                fontWeight="500"
              >
                Quicklink
              </Text>
              <NavLink to="/">
                <Text
                  fontWeight={{ base: "500", md: "500" }}
                  fontSize={{ base: "5.5px", md: "14px" }}
                >
                  Home
                </Text>
              </NavLink>
              <a
                href="https://shucae-buy-sell.vercel.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Text
                  fontWeight={{ base: "500", md: "500" }}
                  fontSize={{ base: "5.5px", md: "14px" }}
                >
                  Buy/ sell
                </Text>
              </a>
              <Text
                cursor={"pointer"}
                fontWeight={{ base: "500", md: "500" }}
                fontSize={{ base: "5.5px", md: "14px" }}
                onClick={() => handelDeveloperPage()}
              >
                For Developer
              </Text>

              <NavLink to="/blogs">
                <Text
                  fontWeight={{ base: "500", md: "500" }}
                  fontSize={{ base: "5.5px", md: "14px" }}
                >
                  Blogs
                </Text>
              </NavLink>
              <NavLink to="/privacy">
                <Text
                  fontWeight={{ base: "500", md: "500" }}
                  fontSize={{ base: "5.5px", md: "14px" }}
                >
                  Privacy Policy
                </Text>
              </NavLink>
              {/* <Text>Our Presence</Text> */}
            </Flex>
            <Flex
              gap=".5rem"
              direction="column"
              align="center"
              width={{ base: "60px", md: "118px" }}
              // mt={{ base: "0", md: "3.3rem", lg: "0" }}
            >
              <Text
                mb="1rem"
                fontSize={{ base: "7px", md: "12px" }}
                fontWeight="500"
              >
                Follow Us
              </Text>
              <Flex
                gap="1rem"
                align={{ base: "start", md: "center" }}
                direction={{ base: "column", md: "row" }}
              >
                <a
                  href="https://www.facebook.com/profile.php?id=100089912959610"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    width={{ base: "20px", md: "24px" }}
                    height={{ base: "15px", md: "24px" }}
                    src={facebook}
                  />
                </a>
                {/* <img src={whatsapp} /> */}
                <a
                  href="https://www.instagram.com/shucae_films/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    width={{ base: "20px", md: "24px" }}
                    height={{ base: "15px", md: "24px" }}
                    src={insta}
                  />
                </a>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            mt=".5rem"
            align="center"
            justify="space-evenly"
            direction={{ base: "column", md: "row" }}
          >
            <Flex w={{ base: "100px", md: "200px" }}>
              <Image src={MadeInIndia} alt="MadeInIndia" />
            </Flex>

            <Text
              fontFamily="Plus Jakarta Sans"
              fontWeight={{ base: "400", md: "600" }}
              lineHeight={{ base: "8px", md: "24px" }}
              fontSize={{ base: "10px", md: "15px" }}
              color="rgba(163, 164, 169, 1)"
            >
              Copyright 2024 @ Shucae films Pvt Ltd. All rights Reserved.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(CreatorPage);
