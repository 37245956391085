/*
 * IMPORTS
 */
import { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import _ from "underscore";
import { useLazyQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux";
import { TypeAnimation } from "react-type-animation";
import DeveloperForm from "./DeveloperForm";


/*
 * REACT ICONS
 */
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { BsClockHistory } from "react-icons/bs";
import { PiWarningOctagonLight } from "react-icons/pi";
import { FaRegCheckCircle } from "react-icons/fa";

/*
 * IMAGES IMPORT
 */
import moviesDataFormat from "../../../../Assets/images/forDeveloper/moviesDataFormat.jpeg";
import webseriesFormatData from "../../../../Assets/images/forDeveloper/webseriesFormatData.jpeg";
import structureJs from "../../../../Assets/images/forDeveloper/structureJs.jpeg";
import appJs from "../../../../Assets/images/forDeveloper/appJs.jpeg";
import queryJs from "../../../../Assets/images/forDeveloper/queryJs.jpeg";
import graphqlJs from "../../../../Assets/images/forDeveloper/graphqlJs.jpeg";
import componentJs from "../../../../Assets/images/forDeveloper/componentJs.jpeg";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  Flex,
  Text,
  Button,
  useToast,
  Divider,
  Code,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import DeveloperInfo from "./__query__/index.developerInfo.query";
/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */
  const toast = useToast();
  const [developerInfo, setDeveloperInfo] = useState();
  const [disable, setDisable] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [isTokenHidden, setIsTokenHidden] = useState(false);
  const [isSecretKeyHidden, setIsSecretKeyHidden] = useState(false);

  const IndexQuery = `
  query MyQuery {
    GetWebseriesData {
      message
      ageRating
      cast
      contentLanguage
      contentTitle
      contentType
      music
      subtitleDetails
      ContentDetail{
        contentLink
        plotSummary
        duration
        episodeNumber
        thumbnailLink
        verticalThumbnailStoredAt
        contentVideoLink
        subtitleDetails
        trailerLink
      }
    }
  }
`;

  const Index = `
query MyQuery {
  GetMovieData {
    message
    status
    contentTitle
    contentType
    dop
    producer
    music
    cast
    contentLanguage
    subtitleDetails
    ContentDetail {
      watchTime
      verticalThumbnailStoredAt
      trailerLink
      thumbnailStoredAt
      thumbnailLink
      subtitleDetails
      plotSummary
      contentLink
      duration
    }
    
  }
}
`;

  const client = `new ApolloClient({
  uri: "https://node.shucaefilms.com/v1/graphql",
  cache: new InMemoryCache(),
})`;

  const auth = `useQuery(GetMovieData, {
  context: {
    headers: {
      "d-authorization": {
        developerToken: "******************",
        secretKey: "******************",
      },
    },
  },
})`;

  const [
    DeveloperInfoQuery,
    { loading: loadingGenerateToken, refetch: refetchDeveloperInfo },
  ] = useLazyQuery(DeveloperInfo, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * FUNCTION
   */

  const toggleTokenVisibility = () => {
    setIsTokenHidden(!isTokenHidden);
  };

  const toggleSecretKeyVisibility = () => {
    setIsSecretKeyHidden(!isSecretKeyHidden);
  };

  const handleDeveloperInfo = async () => {
    try {
      const response = await DeveloperInfoQuery();

      const data = response.data.DeveloperInfo;

      if (!_.isEmpty(data)) {
        setDeveloperInfo(data);

        setDisable(data?.account?.isAccepted);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateString)
    );
    return formattedDate;
  };

  const showToast = (message) => {
    toast({
      title: message,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  /*
   * USEEFECT
   */
  useEffect(() => {
    handleDeveloperInfo();
  }, [refetch]);

  return (
    <Box bg="#00020A" flexDirection="column">
      <Navbar />

      <Box
        pt={{ base: "90px", md: "70px", lg: "30px" }}
        fontFamily="Poppins"
        fontSize={["clamp(12px, 1.5vw, 15px)"]}
        fontWeight="500"
        color="#263238"
        overflow="hidden"
        w="100%"
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
      >
        <Text
          fontFamily="Montserrat"
          fontSize="50px"
          color="white"
          textAlign="center"
          pt="100px"
        >
          Developer Token
        </Text>

        <Flex mt="40px" mb="7rem" justify="space-evenly">
          <Flex
            p="10px"
            borderRadius="20px"
            direction="column"
            w="500px"
            h="300px"
            justify="space-between"
            border="1px solid white"
          >
            <TypeAnimation
              sequence={[
                "Generate your developer token to access our content which includes movies and podcasts",
                1000,
                "Generate your developer token to access our content which includes of short films and web series",
                1000,
                "Generate your developer token to access our content which includes of shuace original movies",
                1000,
                "Generate your developer token to access our content which includes of documentaries",
                1000,
              ]}
              wrapper="span"
              speed={20}
              style={{
                fontSize: "2em",
                fontFamily: "Poppins",
                display: "inline-block",
                color: "white",
              }}
              repeat={Infinity}
            />
            <Text fontFamily="Poppins"></Text>
            <DeveloperForm disable={disable} setRefetch={setRefetch} />
          </Flex>
          <Flex
            p="10px"
            gap="40px"
            borderRadius="20px"
            w="60%"
            h="700px"
            border="1px solid white"
            direction="column"
          >
            <Text textAlign="center">
              {developerInfo?.account?.isAccepted === "ACCEPTED" && (
                <TypeAnimation
                  sequence={[
                    "How to use Developer Token .",
                    1000,
                    "How to use GraphQl .",
                    1000,
                  ]}
                  wrapper="span"
                  speed={40}
                  style={{
                    fontSize: "2em",
                    fontFamily: "Poppins",
                    display: "inline-block",
                    color: "white",
                  }}
                  repeat={Infinity}
                />
              )}
              {developerInfo?.account?.isAccepted !== "ACCEPTED" && (
                <TypeAnimation
                  sequence={["How to request Developer Token .", 1000]}
                  wrapper="span"
                  speed={40}
                  style={{
                    fontSize: "2em",
                    fontFamily: "Poppins",
                    display: "inline-block",
                    color: "white",
                  }}
                  repeat={Infinity}
                />
              )}
            </Text>
            <Flex
              zIndex={1}
              direction="column"
              gap="50px"
              p="20px"
              h="100%"
              overflowY="scroll"
            >
              {disable === "PENDING" && (
                <>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="25px"
                    fontWeight="400"
                    gap="40px"
                    align="center"
                    direction="column"
                  >
                    <Text>
                      Your request has been submitted and is pending for
                      approval.
                    </Text>
                    <BsClockHistory size="120px" />
                    <Text>
                      You will be notified if your request is approved or
                      declined.
                    </Text>
                  </Flex>
                </>
              )}
              {developerInfo?.status === "NO_ACCOUNT" && (
                <>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="21px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                  >
                    <FaArrowLeftLong /> To request token you have to click{" "}
                    <span style={{ fontWeight: "600" }}>
                      `Request Developer Token`
                    </span>{" "}
                    button.
                  </Flex>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="21px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                  >
                    <FaArrowLeftLong /> Then you have fill a form with all the
                    required details.{" "}
                  </Flex>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="21px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                  >
                    <FaArrowLeftLong /> If your request is approved then you
                    will get the required data.{" "}
                  </Flex>
                </>
              )}
              {disable === "ACCEPTED" && (
                <>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="21px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                    justify="center"
                  >
                    <FaRegCheckCircle size="80px" />
                    Your request for developer account has been approved.
                  </Flex>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="21px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                    zIndex={1}
                  >
                    <FaArrowRightLong /> Developer Token:{" "}
                    {isTokenHidden
                      ? developerInfo?.account?.developerToken
                      : "*****************"}
                    <Button
                      cursor="pointer"
                      color="black"
                      h="32px"
                      onClick={() => toggleTokenVisibility()}
                    >
                      {!isTokenHidden ? "Show" : "Hide"}
                    </Button>
                  </Flex>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="21px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                    zIndex={1}
                  >
                    <FaArrowRightLong /> Secret Key:{" "}
                    {isSecretKeyHidden
                      ? developerInfo?.account?.secretKey
                      : "*****************"}
                    <Button
                      cursor="pointer"
                      color="black"
                      h="32px"
                      onClick={toggleSecretKeyVisibility}
                    >
                      {!isSecretKeyHidden ? "Show" : "Hide"}
                    </Button>
                  </Flex>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="21px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                  >
                    <FaArrowRightLong /> Your token expires on{" "}
                    {formatDate(developerInfo?.account?.tokenExpires)}
                  </Flex>
                  <Divider />
                  <Flex gap="20px" direction="column">
                    <Text
                      fontFamily="Roboto"
                      color="white"
                      fontSize="30px"
                      fontWeight="600"
                      w="100%"
                      textAlign="center"
                    >
                      Documentation To Be Used
                    </Text>
                    <Flex direction="column" gap="10px">
                      <Flex
                        fontFamily="Roboto"
                        color="white"
                        fontSize="21px"
                        fontWeight="400"
                        align="center"
                        gap="10px"
                      >
                        <FaArrowRightLong />
                        How to setup GraphQl
                      </Flex>
                      <Flex ml="30px" direction="column">
                        <UnorderedList color="white" spacing="10px">
                          <ListItem>
                            GraphQL can be used in frontend development with any
                            tech stack. Here's a basic guide on how to use
                            GraphQL in frontend development, irrespective of the
                            tech stack:
                          </ListItem>
                          <Text>
                            <span
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              Understanding GraphQL:{" "}
                            </span>
                            Before diving into frontend development, it's
                            essential to understand the basics of GraphQL.
                            GraphQL is a query language for your API and a
                            runtime for executing those queries by using a type
                            system you define for your data. It enables clients
                            to request only the data they need, which makes it
                            efficient and flexible.
                          </Text>
                          <Text>
                            <span
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              Select a GraphQL Client:{" "}
                            </span>{" "}
                            There are several GraphQL client libraries available
                            for various frontend frameworks and libraries. Some
                            popular ones include: Apollo Client: A
                            fully-featured GraphQL client with caching, state
                            management, and a lot of features. It's available
                            for React, Vue.js, Angular, and more. Relay:
                            Developed by Facebook, Relay is more tightly
                            integrated with React and provides advanced features
                            for managing data fetching and state. urql: A
                            lightweight and modular GraphQL client that can be
                            used with React, Vue.js, and Svelte.
                          </Text>
                          <Text>
                            <span
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              Integrating GraphQL Client with Your Frontend
                              Project:{" "}
                            </span>{" "}
                            Depending on your chosen frontend framework or
                            library, you need to integrate the GraphQL client
                            into your project. Most GraphQL client libraries
                            provide detailed documentation and guides on how to
                            do this. Typically, you'll install the client
                            library via npm or yarn and configure it to
                            communicate with your GraphQL server.
                          </Text>
                          <Text>
                            <span
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              Writing GraphQL Queries:{" "}
                            </span>{" "}
                            Once you've set up the GraphQL client, you can start
                            writing GraphQL queries to fetch data from your
                            GraphQL server. GraphQL queries are similar to JSON
                            objects and allow you to specify exactly what data
                            you need. You can write queries using the GraphQL
                            query language directly or using tagged template
                            literals if you're using JavaScript.
                          </Text>
                          <Text>
                            <span
                              style={{ fontSize: "20px", fontWeight: "600" }}
                            >
                              Executing GraphQL Queries:{" "}
                            </span>{" "}
                            After writing your queries, you can execute them
                            using the GraphQL client. The client will handle
                            sending the queries to the GraphQL server, receiving
                            the data, and updating your application's state
                            accordingly.
                          </Text>
                          <Text>
                            <span
                              style={{ fontSize: "20px", fontWeight: "600" }}
                            >
                              Handling Responses:{" "}
                            </span>{" "}
                            Once you receive a response from the GraphQL server,
                            you can handle it in your frontend application.
                            Depending on the data you've requested, you may need
                            to update your UI to display the retrieved data.
                          </Text>
                          <Text>
                            <spna
                              style={{ fontSize: "20px", fontWeight: "600" }}
                            >
                              Error Handling and Optimistic UI:{" "}
                            </spna>{" "}
                            Like any API request, GraphQL queries can fail.
                            Ensure you implement error handling in your frontend
                            application to gracefully handle errors.
                            Additionally, GraphQL clients often provide features
                            for optimistic UI updates, allowing your UI to
                            update optimistically before the server responds.
                          </Text>
                          <Text>
                            <span
                              style={{ fontSize: "20px", fontWeight: "600" }}
                            >
                              Authentication and Authorization:{" "}
                            </span>{" "}
                            If your GraphQL server requires authentication and
                            authorization, you'll need to handle this in your
                            frontend application. This typically involves
                            managing user authentication tokens and including
                            them in your GraphQL requests.
                          </Text>
                          <Text fontSize="20px" fontWeight="600" color="white">
                            For more info{" "}
                            <a
                              style={{ color: "#4A90E2" }}
                              target="_blank"
                              href="https://graphql.org/" rel="noreferrer"
                            >
                              click here
                            </a>
                          </Text>
                          <ListItem>
                            Install @apollo/client which is the Apollo Client
                            library for React applications:{" "}
                            <Code
                              colorScheme="yellow"
                              children="npm install @apollo/client graphql"
                            />
                          </ListItem>
                          <ListItem>
                            Simplified code structure for integrating GraphQL
                            into a frontend application.
                          </ListItem>
                          <Image src={structureJs} />
                          <Text color="white">
                            (1) src/components/: This directory contains your
                            React components. MyComponent.js is an example
                            component that will use GraphQL to fetch data.
                          </Text>
                          <Image src={componentJs} />
                          <Text color="white">
                            (2) src/graphql/: This directory contains GraphQL
                            query files. queries.js will contain your GraphQL
                            queries/mutations. You can organize your queries by
                            feature or entity.
                          </Text>
                          <Image src={queryJs} />
                          <Text color="white">
                            (3) src/services/: This directory contains services
                            responsible for communicating with your GraphQL
                            server. graphql.js will contain functions to execute
                            GraphQL queries/mutations using Apollo Client.
                          </Text>
                          <Image src={graphqlJs} />
                          <Text color="white">
                            (4) src/App.js: This is the entry point of your
                            application. Here you'll import and use your
                            components.
                          </Text>
                          <Image src={appJs} />
                          <ListItem>
                            This is query that you have to use for webseries:{" "}
                            <pre
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              {IndexQuery}
                            </pre>
                          </ListItem>
                          <ListItem>
                            This is query that you have to use for movie's:{" "}
                            <pre
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              {Index}
                            </pre>
                          </ListItem>
                          <ListItem>
                            This your endpoint:{" "}
                            <pre
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              {client}
                            </pre>
                          </ListItem>
                          <ListItem>
                            This how you have to use d-authorization:{" "}
                            <pre
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              {auth}
                            </pre>
                          </ListItem>
                        </UnorderedList>
                      </Flex>
                    </Flex>
                    <Flex direction="column" gap="20px">
                      <Flex direction="column">
                        <Flex
                          fontFamily="Roboto"
                          color="white"
                          fontSize="21px"
                          fontWeight="400"
                          align="center"
                          gap="10px"
                        >
                          <FaArrowRightLong />
                          Example data structure for movies
                        </Flex>
                        <a
                          href={moviesDataFormat}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image src={moviesDataFormat} cursor="pointer" />
                        </a>
                      </Flex>
                      <Flex direction="column">
                        <Flex
                          fontFamily="Roboto"
                          color="white"
                          fontSize="21px"
                          fontWeight="400"
                          align="center"
                          gap="10px"
                        >
                          <FaArrowRightLong />
                          Example data structure for web series
                        </Flex>
                        <a
                          href={webseriesFormatData}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image src={webseriesFormatData} cursor="pointer" />
                        </a>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}
              {disable === "DECLINED" && (
                <>
                  <Flex
                    fontFamily="Roboto"
                    color="white"
                    fontSize="30px"
                    fontWeight="400"
                    align="center"
                    gap="10px"
                    direction="column"
                  >
                    <PiWarningOctagonLight size="120px" /> Your request for
                    developer account has been declined.
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          h="800px"
          left="0"
          top="10"
          opacity="0.3"
          w="100%"
          position="absolute"
          zIndex="0"
          bg="radial-gradient(41.5% 41.5% at 59.19% 49%, rgba(25, 118, 210, 0.6) 0%, rgba(117, 81, 255, 0) 100%)"
        ></Flex>
        <Flex
          h="900px"
          right="0"
          bottom="10"
          opacity="0.3"
          w="100%"
          position="absolute"
          zIndex="0"
          bg="radial-gradient(41.25% 41.25% at 63.41% 47.47%, rgba(195, 2, 128, 0.6) 0%, rgba(5, 45, 176, 0.012) 100%)"
        ></Flex>
        <Footer />
      </Box>
    </Box>
  );
}

const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(Index);
