/*
 * IMPORT
 */
import { useState, useEffect, useRef } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import "./loader.css";
import Loader from "./Loader";
import { useLocation, useParams, useHistory } from "react-router-dom";
import _ from "underscore";
import { connect } from "react-redux"; // NPM: React-redux library.
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";

/*
 * CHAKRA IMPORTS
 */
import { Box, useMediaQuery, Flex } from "@chakra-ui/react";

/*
 * MUTATION
 */
import { useMutation, useLazyQuery, useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import PremiumfetchVideo from "./__mutation__/index.videoPlay.mutation";

/*
 * QUERY
 */
import AdsPlay from "./__query__/index.adsPlay.query";
import AccountDetail from "./__query__/index.accountDetail.query";

/*
 * OBJECT
 */
function VideoPlayPage({
  account,
  BrandAndLocation,
  brandLocation,
  BrandAndLocationClear,
}) {
  /*
   * STATE
   */

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const currentUrl = `https://www.shucaefilms.com${
    location.pathname + location.search
  }`;
  const overlayRef = useRef(null);
  const [videoData, setVideoData] = useState();
  const [startTime, setStartTime] = useState(null);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const playerRef = useRef(null);
  // Access the parameters from the URL
  const [trackLang, setTrackLang] = useState("off");
  const [showAd, setShowAd] = useState(false);
  const [videoPlay, setVideoPlay] = useState(true);
  const [seekTo, setSeekTo] = useState();
  const [randomTimeStamp0, setRandomTimeStamp0] = useState();
  const [randomTimeStamp1, setRandomTimeStamp1] = useState();
  const [randomTimeStamp2, setRandomTimeStamp2] = useState();
  const [adsPlay, setAdsPlay] = useState();
  const [playedAds, setPlayedAds] = useState([]);

  const [PremiumfetchVideoMutation, { loading: loadingVideoPlay }] =
    useMutation(PremiumfetchVideo, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [AdsPlayQuery, { loading: AdsPlayLoading }] = useLazyQuery(AdsPlay, {
    context: {
      headers: {
        "l-authorizations": account.token,
      },
    },
  });

  const _AccountDetail = useQuery(AccountDetail, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * FUNCTION
   */

  const handlePlay = () => {
    if (!startTime) {
      setStartTime(new Date());
    }
  };
  const handlePause = () => {
    if (startTime) {
      const currentTime = new Date();
      const elapsedTime = (currentTime - startTime) / 1000; // in seconds
      setStartTime(null);

      const existingData =
        JSON.parse(localStorage.getItem("watchTimeData")) || {};

      existingData[id] = (existingData[id] || 0) + elapsedTime;

      localStorage.setItem("watchTimeData", JSON.stringify(existingData));
    }
  };
  const handleOnEnded = () => {
    if (startTime) {
      const currentTime = new Date();
      const elapsedTime = (currentTime - startTime) / 1000; // in seconds
      setStartTime(null);

      const existingData =
        JSON.parse(localStorage.getItem("watchTimeData")) || {};

      existingData[id] = (existingData[id] || 0) + elapsedTime;

      localStorage.setItem("watchTimeData", JSON.stringify(existingData));
    }
  };
  const handleBeforeUnload = () => {
    if (startTime) {
      const currentTime = new Date();
      const elapsedTime = (currentTime - startTime) / 1000; // in seconds

      setStartTime(null);

      const existingData =
        JSON.parse(localStorage.getItem("watchTimeData")) || {};

      existingData[id] = (existingData[id] || 0) + elapsedTime;

      localStorage.setItem("watchTimeData", JSON.stringify(existingData));
    }
  };
  function generateRandomTimestamps(durationInMinutes) {
    // Convert duration to seconds
    const durationInSeconds = durationInMinutes * 60;

    const randomTimestamps = Array.from({ length: 3 }, () =>
      Math.floor(Math.random() * durationInSeconds)
    ).sort((a, b) => a - b);

    // Update state variables with the generated timestamps
    setRandomTimeStamp0(randomTimestamps[0]);
    setRandomTimeStamp1(randomTimestamps[1]);
    setRandomTimeStamp2(randomTimestamps[2]);
  }
  const selectUnplayedAd = (ads) => {

    const unplayedAds = ads?.filter((ad) => !playedAds.includes(ad.id));

    return unplayedAds?.length > 0 ? unplayedAds[0] : null;
  };
  const handleAdsPlay = async () => {
    try {
      const response = await AdsPlayQuery({
        variables: {
          barcodeInfo: {
            Brand: brandLocation.brand,
            location: brandLocation.locationParam,
          },
        },
      });

      const data = response.data.AdsPlay;

      if (!_.isEmpty(data)) {
        setAdsPlay(data);
      }
    } catch (error) {}
  };
  const handleVideoPlay = async (id) => {
    try {
      const response = await PremiumfetchVideoMutation({
        variables: {
          id: id,
        },
      });

      const data = response.data.PremiumfetchVideo;

      if (!_.isEmpty(data)) {
        setVideoData(data);
        generateRandomTimestamps(data?.duration);
        // generateRandomTimestamps(1);
      }
    } catch (error) {}
  };

  const handleVideoPlay1 = async (id) => {
    try {
      const response = await PremiumfetchVideoMutation({
        variables: {
          id: id,
        },
      });

      const data = response.data.PremiumfetchVideo;

      if (!_.isEmpty(data)) {
        if (data?.isPremium !== "PREMIUM") {
          setVideoData(data);
          generateRandomTimestamps(data?.duration);
          // generateRandomTimestamps(1);
        } else {
          history.push("/subscription-page");
        }
      }
    } catch (error) {}
  };

  const convertToIsoDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `PT${hours}H${remainingMinutes}M`;
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    if (account.isUserLoggedIn) {
      if (_AccountDetail?.data?.AccountDetail?.premiumStatus === true) {
        handleAdsPlay();
        handleVideoPlay(id);
      } else if (_AccountDetail?.data?.AccountDetail?.premiumStatus === false) {
        handleAdsPlay();
        handleVideoPlay1(id);
      }
    } else {
      history.push("/signin");
    }
  }, [id, brandLocation, _AccountDetail?.loading]);

  useEffect(() => {
    handleBeforeUnload();
  }, []);

  useEffect(() => {
    const textTracks = playerRef?.current?.getInternalPlayer()?.textTracks;

    for (let i = 0; textTracks?.length && i < textTracks.length; i++) {
      textTracks[i].mode =
        textTracks[i].language === trackLang || trackLang === "off"
          ? "showing"
          : "hidden";
    }
  }, [trackLang]);

  return (
    <>
      <Helmet>
        <title>{videoData?.ContentSummary?.contentTitle}</title>
        <meta name="description" content={videoData?.plotSummary} />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "Watch ${
              videoData?.ContentSummary?.contentTitle
            } on Shucae Films",
            "description": "${videoData?.plotSummary}",
            "thumbnailUrl": "${videoData?.thumbnailLink}",
            "uploadDate": "${videoData?.createdAt}",
            "duration": "${convertToIsoDuration(videoData?.duration)}",
            "embedUrl": "${currentUrl}"
          }
          `}
        </script>
      </Helmet>
      <Box bg="#00020A">
        <Navbar />
        {loadingVideoPlay ? (
          <Flex align="center" justify="center" w="100%" h="100vh">
            <Loader />
          </Flex>
        ) : (
          <Flex pt="100px" w="100%" h="100vh">
            <div style={{ width: "100%", height: "100%" }}>
              <Flex w="100%" h="100%" display={showAd ? "block" : "none"}>
                <ReactPlayer
                  url={
                    brandLocation?.brand === null
                      ? selectUnplayedAd(adsPlay)?.brandVideo
                      : adsPlay && adsPlay[0]?.brandVideo
                  }
                  width="100%"
                  height="100%"
                  controls
                  playing={showAd}
                  autoPlay
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        disablePictureInPicture: true,
                      },
                    },
                    pipControls: false,
                  }}
                  onEnded={() => {
                    const playedAd = selectUnplayedAd(adsPlay);
                    setShowAd(false);
                    setVideoPlay(true);
                    setPlayedAds([...playedAds, playedAd?.id]);
                    playerRef?.current?.seekTo(seekTo + 1);
                  }}
                />
              </Flex>
              <Flex w="100%" h="100%" display={showAd ? "none" : "block"}>
                <ReactPlayer
                  ref={playerRef}
                  light={
                    isLargerThan768
                      ? videoData?.thumbnailLink
                      : videoData?.verticalThumbnailStoredAt
                  }
                  url={videoData && videoData?.straminLinks[0]}
                  onError={(e) => console.log(e, "error")}
                  width="100%"
                  onPlay={handlePlay}
                  onPause={handlePause}
                  height="100%"
                  controls
                  playing={videoPlay}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        disablePictureInPicture: true,
                      },
                      tracks: videoData?.subtitleDetails?.map((subtitle) => ({
                        kind: "subtitles",
                        src: subtitle.subtitleLink,
                        srcLang: subtitle.subtitleLanguage,
                        default: false,
                      })),
                    },
                    pipControls: false,
                  }}
                  onContextMenu={(e) => e.preventDefault()} // Prevents right-click context menu
                  onTouchStart={(e) => e.preventDefault()} // Prevents long-tap context menu
                  onProgress={({ played }) => {
                    const playedSeconds =
                      played * playerRef.current.getDuration();
                    if (!videoData?.ContentSummary?.isYoutube) {
                      if (
                        randomTimeStamp0 === Math.floor(playedSeconds) ||
                        randomTimeStamp1 === Math.floor(playedSeconds) ||
                        randomTimeStamp2 === Math.floor(playedSeconds)
                      ) {
                        playerRef.current.getInternalPlayer().pause();
                        setShowAd(true);
                        setSeekTo(Math.floor(playedSeconds));
                      }
                    }
                  }}
                  onEnded={handleOnEnded}
                  style={{
                    objectFit: "cover",
                    userSelect: "none", // Prevents text selection
                  }}
                />
              </Flex>
            </div>
          </Flex>
        )}

        <Footer />
      </Box>
    </>
  );
}

/*
 * EXPORT
 */
const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
  BrandAndLocationClear: (u) =>
    __dispatch({ type: "CLEAR_BRAND_AND_LOCATION" }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(VideoPlayPage);
