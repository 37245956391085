/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($watchTimeJson: JSON) {
    watchTimeUpdate(watchTimeJson: $watchTimeJson) {
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;