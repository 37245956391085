/*
 * IMPORTS
 */
import createWebStorage from 'redux-persist/lib/storage/createWebStorage' // NPM: redux persist local storage.


/*
 * OBJECTS
 */
const createNoopStorage = () => ({
  getItem(_key) {
    return Promise.resolve(null)
  },
  setItem(_key, value) {
    return Promise.resolve(value)
  },
  removeItem(_key) {
    return Promise.resolve()
  }
})


/*
 * EXPORTS
 */
export default 'undefined' === typeof window ? createNoopStorage() : createWebStorage('local')
