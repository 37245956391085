/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($amount: Int!) {
    PaymentCreate(amount: $amount) {
      message
      status
      orderJson
      userName
      userEmail
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
