/*
 * IMPORTS
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import _ from "underscore";
import { useLazyQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import { useLocation } from "react-router-dom";
import Loader from "Views/User/website/VideoPlayPage/Loader";

/*
 * CHAKRA IMPORTS
 */
import { Flex, Box } from "@chakra-ui/react";

/*
 * QUERY
 */
import ContentSearch from "../../Views/User/website/__query__/index.contentSearch.query";
import CardComponent from "./CardComponent";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */
  const [searchData, setSearchData] = useState();
  const [contentStatus, setContentStatus] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get("searchKeyword");

  const [ContentSearchQuery, { loading: loadingContentSearch }] =
    useLazyQuery(ContentSearch);

  /*
   * FUNCTION
   */
  const handleSearchContent = async () => {
    setContentStatus("");

    try {
      const response = await ContentSearchQuery({
        variables: {
          keyword: searchKeyword,
        },
      });

      const data = response.data.ContentSearch;

      if (data[0]?.status === "NO_CONTENT") {
        setContentStatus(data?.[0]?.status);
      } else {
        setSearchData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    handleSearchContent();
  }, [searchKeyword]);

  return (
    <>
      <Box bg="#00020A">
        <Navbar />

        <Box
          // bg="rgba(22, 7, 39, .4)"
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          pt={{ base: "90px", md: "70px", lg: "30px" }}
          fontFamily="Poppins"
          fontSize={["clamp(12px, 1.5vw, 15px)"]}
          fontWeight="500"
          color="#263238"
          overflow="hidden"
        >
          <Flex
            ml={{ base: "1rem", md: "3rem" }}
            color="white"
            fontSize={{ base: "20px", md: "40px" }}
            pt="5rem"
          >
            {`Related search results for "${searchKeyword}"`}
          </Flex>
          <Flex
            pb="8rem"
            gap={{ base: "2rem", md: "2rem" }}
            flexWrap="wrap"
            justify="center"
          >
            {loadingContentSearch ? (
              <Flex justify="center" w="100%">
                <Loader />
              </Flex>
            ) : contentStatus === "NO_CONTENT" ? (
              <Flex
                mt="5rem"
                justify="center"
                w="100%"
                color="white"
                fontSize={{ base: "20px", md: "50px" }}
              >
                No content available for this search.
              </Flex>
            ) : (
              <>
                {searchData?.map((item, index) => (
                  <CardComponent item={item} />
                ))}
              </>
            )}
          </Flex>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
