/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    AccountDetail {
      id
      bio
      twitter
      instagram
      fullName
      userName
      displayName
      gender
      facebook
      premiumStatus
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
