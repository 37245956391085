/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($accountId: ID) {
    VideoHistoryRead(accountId: $accountId) {
      message
      status
      data
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
