/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
query MyQuery {
  RandomWallPaper {
    id
    verticalThumbnailStoredAt
  }
}
`;

/*
 * EXPORTS
 */
export default Index;
