/*
 * IMPORT
 */
import React, { useState } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import {labelStyle} from "../Styles/index.style.js";
/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Input,
  useMediaQuery,
  Flex,
  Text,
  Button,
  Avatar,
} from "@chakra-ui/react";

/*
 * OBJECT
 */
function EdituserProfile() {
  /*
   * STATE
   */
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [formList, setFormList] = useState({
    username: "",
    bio: "",
    gender: "",
    instagram: "",
    twitter: "",
    facebook: "",
  });

  const labelStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "30px",
    width: "86px",
    textAlign: "right",
  };

  /*
   * FUNCTION
   */

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>
        <Flex justify="center" pt="60px" pb="60px">
          <Flex direction="column" p="24px" w="630px" h="500px" bg="#000000">
            <Text
              color="#FFFFFF"
              fontFamily="Poppins"
              fontSize="22px"
              fontWeight="500"
              lineHeight="30px"
            >
              Edit Profile
            </Text>
            <Flex mt="2rem" gap="2.2rem" direction="column">
              <Flex
                fontFamily="Poppins"
                fontWeight="500"
                fontSize="14px"
                gap="1.5rem"
                ml="6rem"
              >
                <Avatar
                  width="42px"
                  h="42px"
                  name="Dan Abrahmov"
                  src="https://bit.ly/dan-abramov"
                />
                <Text color="#FFFFFF" lineHeight="15px">
                  @bhanu11pratap <br></br>{" "}
                  <span
                    style={{
                      color: "#047FCF",
                      lineHeight: "29px",
                      cursor: "pointer",
                    }}
                  >
                    Change profile photo
                  </span>
                </Text>
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem">
                <Text style={labelStyle}>Username</Text>
                <Input
                  type="text"
                  borderColor="none"
                  border="0.3px solid #8C8C8C"
                  width="320px"
                  height="32px"
                  borderRadius="3px"
                  placeholder="User Name"
                  color="#D9D9D9"
                  _placeholder={{ color: "#262626" }}
                />
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem">
                <Text style={labelStyle}>Bio</Text>
                <Input
                  type="text"
                  borderColor="none"
                  border="0.3px solid #8C8C8C"
                  width="320px"
                  height="60px"
                  borderRadius="3px"
                  placeholder="Add bio"
                  color="#D9D9D9"
                  _placeholder={{ color: "#262626" }}
                />
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem">
                <Text style={labelStyle}>Gender</Text>
                <Flex direction="column">
                  <Input
                    type="text"
                    borderColor="none"
                    border="0.3px solid #8C8C8C"
                    width="320px"
                    height="32px"
                    borderRadius="3px"
                    placeholder="Add Gender"
                    color="#D9D9D9"
                    _placeholder={{ color: "#262626" }}
                  />
                  <Text
                    color="#A8A8A8"
                    fontFamily="Poppins"
                    fontSize="10px"
                    fontWeight="500"
                    lineHeight="29.04px"
                  >
                    This won’t be part of your public profile.
                  </Text>
                </Flex>
              </Flex>

              <Button
                ml="10.2rem"
                bg="#052DB0"
                w="100px"
                h="32px"
                borderRadius="5px"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="16px"
                lineHeight="30px"
                color="#FFFFFF"
                _hover={{ bg: "#052DB0" }}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

/*
 * EXPORT
 */
export default EdituserProfile;
