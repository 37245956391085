/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $ageRating: String!
    $cast: [JSON]!
    $content: [JSON!]!
    $contentLanguage: String!
    $contentTitle: String!
    $contentType: String!
    $dop: String
    $producer: String!
    $firstName: String!
    $genre: [String]!
    $lastName: String!
    $music: String!
    $phone: String!
    $userName: String!
    $haveCopyrights: Boolean
    $agreeTerms: Boolean
  ) {
    ProfileCreate(
      ageRating: $ageRating
      cast: $cast
      content: $content
      contentLanguage: $contentLanguage
      contentTitle: $contentTitle
      contentType: $contentType
      dop: $dop
      producer: $producer
      firstName: $firstName
      genre: $genre
      lastName: $lastName
      music: $music
      phone: $phone
      userName: $userName
      haveCopyrights: $haveCopyrights
      agreeTerms: $agreeTerms
    ) {
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
