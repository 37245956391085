/*
 * IMPORT
 */
import React, { useState, useRef } from "react";
import QRCode from "react-qr-code";
import {
  bigTextStyle,
  smallTextStyle,
  headerStyle,
} from "components/Styles/index.style";

/*
 * REACT-ICONS IMPORT
 */
import { MdEdit } from "react-icons/md";
import { RiBarChartBoxLine } from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";

/*
 * CHAKRA IMPORTS
 */
import {
  Image,
  Flex,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

/*
 * QUERY
 */
/*
 * MUTATAION
 */
/*
 * OBJECT
 */
function ManageVideos({ perUser }) {
  /*
   * States
   */
  const qrCodeRef = useRef(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [expandedSummary, setExpandedSummary] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  /*
   * FUNCTIONS
   */
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const handleGenerateQr = (id) => {
    // Show the modal when Generate QR is clicked
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    // Close the modal
    setIsModalOpen(false);
  };
  function formatDate(inputDateStr) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(inputDateStr);
    return date.toLocaleDateString("en-US", options);
  }
  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);
  
    // eslint-disable-next-line init-declarations
    let formattedDuration = "";
  
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }
  const handleDownloadQR = () => {
    const qrCodeSvg = qrCodeRef.current;
    const svgBlob = new Blob([qrCodeSvg.outerHTML], { type: "image/svg+xml" });
    const svgURL = window.URL.createObjectURL(svgBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = svgURL;
    downloadLink.download = "qrcode.svg";
    downloadLink.click();

    window.URL.revokeObjectURL(svgURL);
  };

  /*
   * USEEFFECT
   */

  return (
    <TableContainer
      height="600px"
      overflowY="scroll"
      overflow="auto"
      mt="44px"
      width="100%"
      padding="0px"
    >
      <Table variant="simple">
        <Thead>
          <Tr borderTop="1px solid #505050" style={headerStyle}>
            <Th borderColor="#505050" w="380px">
              Video
            </Th>
            <Th borderColor="#505050">Restrictions</Th>
            <Th borderColor="#505050">Duration</Th>
            <Th borderColor="#505050">Date</Th>
            <Th borderColor="#505050">Views</Th>

            <Th borderColor="#505050">Likes</Th>
          </Tr>
        </Thead>

        <Tbody>
          {perUser?.map((data) => (
            <Tr
              onMouseEnter={() => setHoveredRow(data.id)}
              onMouseLeave={() => setHoveredRow(null)}
              _hover={{ background: "#0F111B" }}
              key={data.id}
            >
              <Td w="500px" borderColor="#505050">
                <Flex
                  gap="1rem"
                  align="top"
                  direction={{ base: "column", md: "row" }}
                >
                  <Image
                    width="240px"
                    height="156px"
                    src={data?.lowerThumbnailLink}
                  />

                  <Flex gap=".4rem" direction="column" width="300px">
                    <Text style={bigTextStyle}>
                      {data?.ContentSummary?.contentTitle}
                    </Text>
                    <Flex whiteSpace="normal" h="100%" style={smallTextStyle}>
                      {expandedSummary[data.id] ? (
                        <span>
                          {data?.plotSummary}
                          <Text
                            color="rgba(0, 129, 223, 1)"
                            cursor="pointer"
                            onClick={() => toggleSummary(data.id)}
                          >
                            read less
                          </Text>
                        </span>
                      ) : (
                        <span>
                          {data?.plotSummary
                            ?.split(" ")
                            ?.slice(0, 20)
                            ?.join(" ")}
                          {data.plotSummary?.split(" ").length > 20 && (
                            <Text
                              color="rgba(0, 129, 223, 1)"
                              cursor="pointer"
                              onClick={() => toggleSummary(data.id)}
                            >
                              read more
                            </Text>
                          )}
                        </span>
                      )}
                    </Flex>
                    {hoveredRow === data.id && (
                      <Flex
                        gap=".5rem"
                        justify="flex-end"
                        color="#444444"
                        align="center"
                      >
                        {/* <MdEdit style={{ cursor: "pointer" }} /> */}
                        {/* <RiBarChartBoxLine style={{ cursor: "pointer" }} /> */}
                        <Menu bg="red" padding="0px" margin="0px">
                          <MenuButton
                            width="0px"
                            height="0px"
                            padding="0px"
                            margin="0px"
                            _hover={{ bg: "transparent" }}
                            as={IconButton}
                            icon={<HiDotsVertical />}
                            style={{ cursor: "pointer" }}
                          />
                          <MenuList borderRadius="5px" padding="0px">
                            {/* <MenuItem borderRadius="5px">Delete</MenuItem> */}
                            <MenuItem
                              borderRadius="5px"
                              onClick={() => handleGenerateQr(data?.id)}
                            >
                              Generate QR
                            </MenuItem>
                            <Modal
                              isCentered
                              isOpen={isModalOpen}
                              onClose={handleCloseModal}
                            >
                              <ModalOverlay />
                              <ModalContent
                                width={{
                                  base: "80%",
                                  md: "100%",
                                }}
                              >
                                <ModalHeader>Download QR</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                  <Flex>
                                    <QRCode
                                      ref={qrCodeRef}
                                      style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: "100%",
                                      }}
                                      value={`https://shucaefilms.com/video-play-page?id=${data?.id}`}
                                      viewBox={`0 0 256 256`}
                                    />
                                  </Flex>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    colorScheme="blue"
                                    mr={3}
                                    onClick={handleDownloadQR}
                                  >
                                    Download
                                  </Button>
                                  <Button onClick={handleCloseModal}>
                                    Close
                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                          </MenuList>
                        </Menu>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Td>

              <Td borderColor="#505050">
                <Flex justify="center" style={bigTextStyle} gap=".5rem">
                  {`${data?.ContentSummary?.ageRating === "18" ? "A" : "U/A"} ${
                    data?.ContentSummary?.ageRating
                  }+`}
                </Flex>
              </Td>
              <Td borderColor="#505050">
                <Text textAlign="center" style={bigTextStyle}>
                  {formatDuration(data?.duration)}
                </Text>
              </Td>
              <Td borderColor="#505050">
                <Flex direction="column">
                  <Text style={bigTextStyle}>{formatDate(data.createdAt)}</Text>
                  <Text style={smallTextStyle}>Published</Text>
                </Flex>
              </Td>
              <Td borderColor="#505050">
                <Text textAlign="center" style={bigTextStyle}>
                  {data?.History?.length}
                </Text>
              </Td>

              <Td borderColor="#505050">
                <Text style={bigTextStyle}>{data?.likes}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

/*
 * EXPORTS
 */
export default ManageVideos;
