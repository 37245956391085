/*
 * IMPORT
 */
import { useState, useEffect, useRef } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import "../VideoPlayPage/loader.css";
import Loader from "../VideoPlayPage/Loader";
import { useLocation, useParams, useHistory } from "react-router-dom";
import _ from "underscore";
import { connect } from "react-redux"; // NPM: React-redux library.
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { IoCloseCircleSharp } from "react-icons/io5";

/*
 * CHAKRA IMPORTS
 */
import { Box, useMediaQuery, Flex, Image, Text } from "@chakra-ui/react";

/*
 * MUTATION
 */

/*
 * QUERY
 */
import PodcastRead from "./__query__/index.podcastRead.query";

/*
 * OBJECT
 */
function Index({}) {
  /*
   * STATE
   */

  const { id } = useParams();
  const location = useLocation();
  const currentUrl = `https://www.shucaefilms.com${
    location.pathname + location.search
  }`;
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const [expandedSummary, setExpandedSummary] = useState({});

  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const textStyle = {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21.54px",
  };

  const _PodcastRead = useQuery(PodcastRead);

  const audioData = _PodcastRead?.data?.PodcastRead.filter(
    (item) => item.id === id
  )[0];

  /*
   * FUNCTION
   */
  const convertToIsoDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `PT${hours}H${remainingMinutes}M`;
  };

  /*
   * USEEFFECT
   */

  return (
    <>
      <Helmet>
        <title>{decodeURIComponent(audioData?.podcastTitle)}</title>
        <meta name="description" content={audioData?.PodcastDescription} />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "AudioObject",
            "contentUrl": "${currentUrl}",
            "episodeNumber": "${audioData?.PodcastEpisode?.[0]?.episodeNumber}",
            "description": "${audioData?.PodcastDescription}",
            "encodingFormat": "audio/mpeg",
            "name": "${decodeURIComponent(audioData?.podcastTitle)}"
          }
          `}
        </script>
      </Helmet>

      <Box bg="#00020A">
        <Navbar />
        {_PodcastRead?.loading && audioData ? (
          <Flex align="center" justify="center" w="100%" h="100vh">
            <Loader />
          </Flex>
        ) : (
          <Flex
            direction="column"
            align="center"
            justify='center'
            overflowY="scroll"
            pt="150px"
            w="100%"
            h="100vh"
          >
            <Box
              borderRadius="10px"
              w={{ base: "100%", md: "80%" , lg:"60%" }}
              h="30rem"
              bg="black"
            >
              <Image
                w="100%"
                h="30rem"
                borderRadius="10px"
                objectFit="fit"
                src={
                  isLargerThan768
                    ? audioData?.horizontalPosterLink
                    : audioData?.verticalPosterLink
                }
              />
            </Box>
            <Flex
              direction="column"
              align="center"
              justify="center"
              w={{ base: "100%", md: "80%" , lg:"60%" }}
            >
              <Flex
                m="15px 0px 15px 0px"
                borderRadius="10px"
                bg="rgba(39, 39, 39, 1)"
                w="90%"
                direction="column"
                justify="space-between"
                p="20px"
              >
                <Flex
                  mt="1rem"
                  w="100%"
                  justify="space-between"
                  direction={{ base: "column-reverse", md: "row" }}
                >
                  <Flex w="100%" gap="20px" direction="column">
                    <Flex align="center" gap="8px">
                      <Text
                        color="rgba(255, 255, 255, 1)"
                        fontFamily="Roboto"
                        fontSize="20px"
                        fontWeight="700"
                        lineHeight="21.54px"
                        letterSpacing="0.2px"
                      >
                        Podcast Episodes
                      </Text>
                    </Flex>

                    <Flex w="100%" direction="column" gap="30px">
                      {audioData?.PodcastEpisode?.map((item) => (
                        <Flex direction={{ base: "column", md: "row" }}>
                          <Text
                            color="rgba(186, 186, 186, 1)"
                            fontFamily="Roboto"
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="21.54px"
                            letterSpacing="-0.36px"
                          >
                            {formatDuration(item?.duration)}
                          </Text>
                          <Flex w="100%" direction="column" gap="10px">
                            <Flex justify="space-between" align="center">
                              <Text
                                w={{base:"70%" , md:"80%"}}
                                color="rgba(255, 255, 255, 1)"
                                fontFamily="Roboto"
                                fontWeight="700"
                                fontSize="15px"
                                lineHeight="21.54px"
                                letterSpacing="0.14px"
                              >{`${item?.episodeTitle}`}</Text>
                              <Text
                                color="rgba(255, 255, 255, 1)"
                                fontFamily="Roboto"
                                fontWeight="700"
                                fontSize="14px"
                                lineHeight="21.54px"
                                letterSpacing="0.14px"
                                cursor="pointer"
                              >{`Episode ${item?.episodeNumber}`}</Text>
                            </Flex>

                            <Flex>
                              <Text
                                fontFamily="Roboto"
                                fontSize="12px"
                                fontWeight="400"
                                lineHeight="28px"
                                color="rgba(255, 255, 255, 1)"
                                w="100%"
                              >
                                {expandedSummary[item.id] ? (
                                  <span>
                                    {item.episodeDescription}
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(item.id)}
                                    >
                                      read less
                                    </Text>
                                  </span>
                                ) : (
                                  <span>
                                    {item?.episodeDescription
                                      ?.split(" ")
                                      ?.slice(0, 20)
                                      ?.join(" ")}
                                    {item.episodeDescription?.split(" ")
                                      .length > 20 && (
                                      <Text
                                        color="rgba(0, 129, 223, 1)"
                                        cursor="pointer"
                                        onClick={() => toggleSummary(item.id)}
                                      >
                                        read more
                                      </Text>
                                    )}
                                  </span>
                                )}
                              </Text>
                            </Flex>

                            <audio
                              controls
                              controlsList="nodownload"
                              style={{
                                width: "100%",
                                backgroundColor: "#f0f0f0",
                                borderRadius: "5px",
                              }}
                            >
                              <source src={item.audioLink} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        <Footer />
      </Box>
    </>
  );
}

/*
 * EXPORT
 */
const __MapDispatchToProps = (__dispatch) => ({
  BrandSVG: (u) => __dispatch({ type: "SET_BRAND_WATER_MARK", BrandSVG: u }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
  BrandAndLocationClear: (u) =>
    __dispatch({ type: "CLEAR_BRAND_AND_LOCATION" }),
});

const __MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
  brandLocation: state.Brand,
  BrandSVG: state.Watermark,
});

/*
 * EXPORTS
 */
export default connect(__MapStateToProps, __MapDispatchToProps)(Index);
