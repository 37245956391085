/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    ReadAllBlogs {
      bulletpoints
      content
      createdAt
      heading
      horizontalImage
      id
      message
      status
      title
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
