/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($accountId: ID) {
    FollowingList(accountId: $accountId) {
      message
      id
      fullName
      userName
      displayName
      profileStoryLink
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
