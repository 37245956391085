/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import _ from "underscore";
/*
 * SVG IMPORT
 */

/*
 * REACT ICONS
 */
import { BsPlusLg } from "react-icons/bs";
import { useHistory } from "react-router-dom";

/*
 * MUTATION
 */
import VideoMylistCreate from "../__mutation__/index.myList.mutation";

/*
 * QUERY
 */
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import MoreLikeThis from "../__query__/index.getMoreLikeThis.query";
/*
 * CHAKRA UI IMPORT
 */
import { Image, Text, Flex, Spinner, useToast } from "@chakra-ui/react";

/*
 * OBJECT
 */
const Index = ({ data, account }) => {
  /*
   * STATE
   */
  const toast = useToast();
  const history = useHistory();
  const [expandedSummary, setExpandedSummary] = useState({});
  const [addToMyListStatus, setAddToMyListStatus] = useState("");
  const [loadingItems, setLoadingItems] = useState({});

  const _MoreLikeThis = useQuery(MoreLikeThis, {
    variables: {
      contentSummaryId: data?.id,
      genre: data?.genre?.[0],
    },
  });

  const [VideoMylistCreateMutation, { loading: loadingMyListCreate }] =
    useMutation(VideoMylistCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * FUNCTION
   */
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  function checkLogin(id) {
    if (account?.isUserLoggedIn) {
      handleMyList(id);
    } else {
      history.push("/signin");
    }
  }

  const handleMyList = async (id) => {
    try {
      setLoadingItems((prevLoadingItems) => ({
        ...prevLoadingItems,
        [id]: true,
      }));
      const response = await VideoMylistCreateMutation({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.VideoMylistCreate;
      setAddToMyListStatus(data?.id);

      if (
        !_.isEmpty(data) &&
        data.status === "VIDEO_MYLIST_SUCCESSFULLY_ADDED"
      ) {
        toast({
          description: "Video added to your list",
          status: "success",
          duration: 5000, // milliseconds
          isClosable: true,
        });
        setLoadingItems((prevLoadingItems) => ({
          ...prevLoadingItems,
          [id]: false,
        }));
      }
    } catch (error) {
      console.error(error);
      setLoadingItems((prevLoadingItems) => ({
        ...prevLoadingItems,
        [id]: false,
      }));
    }
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  /*
   * USEEFECT
   */


  return (
    <>
      <Flex w="100%" align="center" p="10px" gap="10px" direction="column">
        <Flex
          w="90%"
          color="rgba(255, 255, 255, 1)"
          fontFamily="Roboto"
          fontSize={{ base: "20px", md: "24px" }}
          fontWeight="700"
          lineHeight="21.54px"
          letterSpacing="0.24px"
        >
          More Like This
        </Flex>

        <Flex w="100%" justify="center" gap="20px" flexWrap="wrap">
          {_MoreLikeThis?.data?.MoreLikeThis.map((item, index) => (
            <Flex
              cursor="pointer"
              gap="10px"
              key={item.id}
              direction="column"
              bg="rgba(39, 39, 39, 1)"
              flexBasis="calc(33.33% - 40px)" // Adjusted based on gap of 20px
              boxSizing="border-box"
              marginBottom="10px" // Added to create a gap between rows
            >
              <Flex
                position="relative"
                h="8rem"
                onClick={() =>
                  history.push(
                    `/video-play-page/${item?.contentTitle?.replace(
                      / /g,
                      "-"
                    )}/${item?.ContentDetail?.[0]?.id}`
                  )
                }
              >
                <Image
                  src={item?.ContentDetail?.[0]?.lowerThumbnailLink}
                  objectFit="fill"
                />
                <Flex
                  textShadow="2px 2px 4px #000000"
                  color="white"
                  right="1.5"
                  top="0"
                  position="absolute"
                >
                  {formatDuration(item?.ContentDetail?.[0]?.duration)}
                </Flex>
              </Flex>
              <Flex p="10px" justify="space-between" align="center">
                <Flex direction="column">
                  <Text
                    color="rgba(69, 211, 105, 1)"
                    fontFamily="Roboto"
                    fontSize="19px"
                    fontWeight="700"
                    lineHeight="21.54px"
                    letterSpacing="-0.36px"
                  >
                    80% Match
                  </Text>
                  <Flex color="rgba(186, 186, 186, 1)" align="center" gap="5px">
                    <Flex
                      w="70px"
                      h="20px"
                      justify="center"
                      align="center"
                      border="1px solid rgba(116, 116, 116, 1)"
                    >
                      {`${item?.ageRating === "18" ? "A" : "U/A"} ${
                        item?.ageRating
                      }+`}
                    </Flex>
                    <Text>{new Date(item?.createdAt).getFullYear()}</Text>
                  </Flex>
                </Flex>
                <Flex
                  w="30px"
                  h="30px"
                  borderRadius="50%"
                  border="1px solid white"
                  justify="center"
                  align="center"
                  cursor="pointer"
                  color="rgba(186, 186, 186, 1)"
                  onClick={() => checkLogin(item?.ContentDetail?.[0]?.id)}
                >
                  {loadingItems[item?.ContentDetail?.[0]?.id] ? (
                    <Spinner color="white" />
                  ) : (
                    <BsPlusLg size="25px" />
                  )}
                </Flex>
              </Flex>
              <Text
                color="rgba(186, 186, 186, 1)"
                p="0px 10px 20px 10px"
                textAlign="left"
              >
                {expandedSummary[item?.id] ? (
                  <span>
                    {item?.ContentDetail?.[0]?.plotSummary}
                    <Text
                      color="rgba(0, 129, 223, 1)"
                      cursor="pointer"
                      onClick={() => toggleSummary(item?.id)}
                    >
                      read less
                    </Text>
                  </span>
                ) : (
                  <span>
                    {item?.ContentDetail?.[0]?.plotSummary
                      ?.split(" ")
                      ?.slice(0, 20)
                      ?.join(" ")}
                    {item?.ContentDetail?.[0]?.plotSummary?.split(" ").length >
                      20 && (
                      <Text
                        color="rgba(0, 129, 223, 1)"
                        cursor="pointer"
                        onClick={() => toggleSummary(item?.id)}
                      >
                        read more
                      </Text>
                    )}
                  </span>
                )}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

/*
 * EXPORT
 */
export default Index;
