/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($id: ID!) {
    PremiumfetchVideo(id: $id) {
      premiumStatus
      status
      message
      thumbnailLink
      verticalThumbnailStoredAt
      contentLink
      straminLinks
      createdAt
      duration
      plotSummary
      subtitleDetails
      isPremium
      ContentSummary {
        contentTitle
        isYoutube
      }
      isEmbedded
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
