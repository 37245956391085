// reducers.js

export const _initialState = {
    brandSvg: null,
  };
  
  const _BrandWatermark = (__prevState = _initialState, __action) => {
    /*
     * Switch case for handling actions on
     * account store.
     */
    switch (__action.type) {
      case "SET_BRAND_WATER_MARK":
        return {
          ..._initialState,
          ...__action.BrandSVG,
        };
      default:
        return __prevState;
    }
  };
  
  /*
   * EXPORTS
   */
  export default _BrandWatermark;
  