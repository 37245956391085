/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { useLazyQuery, useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import _ from "underscore";
import { useHistory } from "react-router-dom";
import { labelStyle } from "../Styles/index.style";
/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Input,
  useMediaQuery,
  Flex,
  Text,
  Button,
  Avatar,
  Spinner,
  useToast,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import AccountUpdate from "./__mutation__/index.profileEdit.mutation";

/*
 * QUERY
 */
import AccountDetail from "./__query__/index.profileData.query";

/*
 * OBJECT
 */
function EditProfile({ account }) {
  /*
   * STATE
   */
  const [accountDetail, setAccountDetail] = useState();
  const toast = useToast();
  const history = useHistory();
  const [formData, setFormData] = useState({
    bio: "",
    facebook: "",
    gender: "",
    instagram: "",
    twitter: "",
    userName: "",
  });
  const [AccountUpdateMutation, { loading: loadingUpdate }] = useMutation(
    AccountUpdate,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [AccountDetailQuery, { loading: loadingUpdateQuery }] = useLazyQuery(
    AccountDetail,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  /*
   * FUNCTION
   */
  const handleUpdate = async () => {
    try {
      const response = await AccountUpdateMutation({
        variables: {
          bio: formData.bio,
          facebook: formData.facebook,
          gender: formData.gender,
          instagram: formData.instagram,
          twitter: formData.twitter,
          userName: formData.userName,
        },
      });

      const data = response.data.AccountUpdate;

      if (!_.isEmpty(data) && data?.status === "UPDATE_SUCCESSFUL") {
        toast({
          title: "Profile updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push("/profile-page");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDetailQuery = async () => {
    try {
      const response = await AccountDetailQuery({
        variables: {},
      });

      const data = response.data.AccountDetail;

      if (!_.isEmpty(data)) {
        setAccountDetail(data);
        setFormData({
          ...formData,
          bio: data.bio,
          gender: data.gender,
          instagram: data.instagram,
          facebook: data.facebook,
          twitter: data.twitter,
          userName: data.userName,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = () => {
    handleUpdate();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const form = { ...formData, [name]: value };

    setFormData(form);
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    handleDetailQuery();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>
        <Flex justify="center" pt="60px" pb="60px">
          <Flex
            direction="column"
            p="24px"
            w={{ base: "330px", md: "630px" }}
            h={{ base: "766px", md: "725px" }}
            bg="#000000"
          >
            <Text
              color="#FFFFFF"
              fontFamily="Poppins"
              fontSize="22px"
              fontWeight="500"
              lineHeight="30px"
            >
              Edit Profile
            </Text>
            <Flex mt="2rem" gap="2.2rem" direction="column">
              <Flex
                fontFamily="Poppins"
                fontWeight="500"
                fontSize="14px"
                gap="0.5rem"
                align="center"
              >
                <Avatar
                  width="42px"
                  h="42px"
                  name={account?.fullName}
                  src="https://bit.ly/broken-link"
                />
                <Text color="#FFFFFF" lineHeight="15px">
                  {`@${accountDetail?.userName}`}{" "}
                  {/* <span
                    style={{
                      color: "#047FCF",
                      lineHeight: "29px",
                      cursor: "pointer",
                    }}
                  >
                    Change profile photo
                  </span> */}
                </Text>
              </Flex>
              <Flex
                gap="1.5rem"
                ml="3.3rem"
                
                justify="space-evenly"
              >
                <Text style={labelStyle}>Username</Text>
                <Input
                  type="text"
                  borderColor="none"
                  border="0.3px solid #8C8C8C"
                  width="320px"
                  height="32px"
                  borderRadius="3px"
                  placeholder="User Name"
                  color="#D9D9D9"
                  value={formData.userName}
                  name="userName"
                  onChange={handleInputChange}
                  _placeholder={{ color: "#262626" }}
                />
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem" justify="space-evenly">
                <Text style={labelStyle}>Bio</Text>
                <Input
                  type="text"
                  borderColor="none"
                  border="0.3px solid #8C8C8C"
                  width="320px"
                  height="60px"
                  borderRadius="3px"
                  placeholder="Add bio"

                  color="#D9D9D9"
                  value={formData.bio}
                  name="bio"
                  onChange={handleInputChange}
                  ml='2rem'
                  _placeholder={{ color: "#262626" }}
                />
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem" justify="space-evenly">
                <Text style={labelStyle}>Gender</Text>
                <Flex
                  direction="column"
                  display="inline"
                  w={{ base: "268px", md: "320px" }}
                >
                  <Input
                    type="text"
                    borderColor="none"
                    border="0.3px solid #8C8C8C"
                    height="32px"
                    borderRadius="3px"
                    placeholder="Add Gender"
                    color="#D9D9D9"
                    value={formData.gender}
                    name="gender"
                    ml='0.2rem'
                    onChange={handleInputChange}
                    _placeholder={{ color: "#262626" }}
                  />
                  <Text
                    color="#A8A8A8"
                    fontFamily="Poppins"
                    fontSize="10px"
                    fontWeight="500"
                    lineHeight="29.04px"
                  >
                    This won’t be part of your public profile.
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem" justify="space-evenly">
                <Text style={labelStyle}>Instagram</Text>
                <Input
                  type="text"
                  borderColor="none"
                  border="0.3px solid #8C8C8C"
                  width="320px"
                  height="32px"
                  borderRadius="3px"
                  placeholder="Add Link"
                  color="#D9D9D9"
                  value={formData.instagram}
                  name="instagram"
                  onChange={handleInputChange}
                  _placeholder={{ color: "#262626" }}
                />
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem" justify="space-evenly">
                <Text style={labelStyle}>Twitter</Text>
                <Input
                  type="text"
                  borderColor="none"
                  border="0.3px solid #8C8C8C"
                  width="320px"
                  height="32px"
                  borderRadius="3px"
                  placeholder="Add Link"
                  color="#D9D9D9"
                  value={formData.twitter}
                  name="twitter"
                  ml='1.3rem'
                  onChange={handleInputChange}
                  _placeholder={{ color: "#262626" }}
                />
              </Flex>
              <Flex gap="1.5rem" ml="3.3rem" justify="space-evenly">
                <Text style={labelStyle}>Facebook</Text>
                <Input
                  type="text"
                  borderColor="none"
                  border="0.3px solid #8C8C8C"
                  width="320px"
                  height="32px"
                  borderRadius="3px"
                  placeholder="Add Link"
                  color="#D9D9D9"
                  value={formData.facebook}
                  name="facebook"
                  onChange={handleInputChange}
                  _placeholder={{ color: "#262626" }}
                />
              </Flex>
              <Button
                ml="10.2rem"
                bg="#052DB0"
                w="100px"
                h="32px"
                borderRadius="5px"
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="16px"
                lineHeight="30px"
                color="#FFFFFF"
                _hover={{ bg: "#052DB0" }}
                onClick={() => handleSubmit()}
              >
                {loadingUpdate ? <Spinner /> : "Submit"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

/*
 * EXPORT
 */
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

export default connect(_MapStateToProps, _MapDispatchToProps)(EditProfile);
