/*
 * IMPORT
 */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  videoSmallTextStyle,
  videoBigTextStyle,
} from "components/Styles/index.style";

/*
 * CHAKRA IMPORTS
 */
import { Image, Flex, Text } from "@chakra-ui/react";

/*
 * QUERY
 */

/*
 * OBJECT
 */
function MyList({ data }) {
  /*
   * States
   */
  const history = useHistory();
  const [expandedSummary, setExpandedSummary] = useState({});

  /*
   * Functions
   */
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);
  
    // eslint-disable-next-line init-declarations
    let formattedDuration = "";
  
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  /*
   * USEEFFECT
   */

  return (
    <Flex
      p="15px"
      key={data.id}
      borderRadius="10px"
      bg="#000000"
      w="100%"
      gap="1rem"
      direction={{ base: "column", lg: "row" }}
    >
      <Flex
        w="256px"
        h="158px"
        cursor="pointer"
        onClick={() =>
          history.push(
            `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
              / /g,
              "-"
            )}/${data?.id}`
          )
        }
      >
        <Image w="100%" h="100%" src={data?.lowerThumbnailLink} />
      </Flex>
      <Flex gap=".5rem" direction="column">
        <Text
          cursor="pointer"
          onClick={() =>
            history.push(
              `/video-play-page/${data?.ContentSummary?.contentTitle?.replace(
                / /g,
                "-"
              )}/${data?.id}`
            )
          }
          style={videoBigTextStyle}
        >
          {data?.ContentSummary.contentTitle}
        </Text>
        <Text
          style={videoSmallTextStyle}
          w={{
            base: "fit-content",
            md: "fit-content",
            lg: "850px",
          }}
        >
          {expandedSummary[data.id] ? (
            <span>
              {data.plotSummary}
              <Text
                color="rgba(0, 129, 223, 1)"
                cursor="pointer"
                onClick={() => toggleSummary(data.id)}
              >
                read less
              </Text>
            </span>
          ) : (
            <span>
              {data?.plotSummary?.split(" ")?.slice(0, 20)?.join(" ")}
              {data.plotSummary?.split(" ").length > 20 && (
                <Text
                  color="rgba(0, 129, 223, 1)"
                  cursor="pointer"
                  onClick={() => toggleSummary(data.id)}
                >
                  read more
                </Text>
              )}
            </span>
          )}
        </Text>
        <Flex gap=".5rem" direction="column">
          <Flex align="center" gap="1rem">
            <Text
              color="rgba(69, 211, 105, 1)"
              fontFamily="Roboto"
              fontSize="19px"
              fontWeight="700"
              lineHeight="21.54px"
              letterSpacing="-0.36px"
            >
              {new Date(data.createdAt).getFullYear()}
            </Text>
            <Text
              color="rgba(186, 186, 186, 1)"
              fontFamily="Roboto"
              fontSize="19px"
              fontWeight="400"
              lineHeight="21.54px"
              letterSpacing="-0.36px"
            >
              {formatDuration(data?.duration)}
            </Text>
            <Flex
              borderRadius="3px"
              justify="center"
              align="center"
              border="1px solid rgba(116, 116, 116, 1)"
              color="rgba(255, 255, 255, 1)"
              fontFamily="Roboto"
              fontWeight="400"
              fontSize="12px"
              lineHeight="21.54px"
              p="0px 5px 0px 5px"
            >
              HD
            </Flex>
          </Flex>
          <Flex align="center" gap="1rem">
            <Flex
              color="white"
              w="70px"
              h="20px"
              justify="center"
              align="center"
              border="1px solid rgba(116, 116, 116, 1)"
            >
              {`${data?.ContentSummary?.ageRating === "18" ? "A" : "U/A"} ${
                data?.ContentSummary?.ageRating
              }+`}
            </Flex>
            <Text style={videoSmallTextStyle}>
              <span style={{ textTransform: "capitalize" }}>
                {data?.ContentSummary?.genre}
              </span>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
 * EXPORTS
 */
export default MyList;
