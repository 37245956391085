// reducers.js

export const _initialState = {
  brand: null,
  locationParam: null,
};

const _BrandReducer = (__prevState = _initialState, __action) => {
  /*
   * Switch case for handling actions on
   * account store.
   */
  switch (__action.type) {
    case "SET_BRAND_AND_LOCATION":
      return {
        ..._initialState,
        ...__action.BrandLocation,
      };
    case "CLEAR_BRAND_AND_LOCATION":
      return {
        ..._initialState,
      };
    default:
      return __prevState;
  }
};

/*
 * EXPORTS
 */
export default _BrandReducer;
