/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query GoogleAccountLoginQuery($route: String = "verify" , $scopes:[String] = ["https://www.googleapis.com/auth/youtube"]) {
    GoogleAccountLogin(route: $route , scopes: $scopes) {
      message
      status
      token
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
