/*
 * IMPORT
 */
import React, { useState, useEffect } from "react"; // Npm: React.
import ReactDOM from "react-dom"; // Npm: React dom.
import { BrowserRouter as Router } from "react-router-dom";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client"; // Npm: Apollo client for handling graphql.
import { createUploadLink } from "apollo-upload-client"; // Npm: Apollo client upload link.
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import App from "index.app.js"; // App component.
import { Provider } from "react-redux"; // Npm: Redux provider.
import { PersistGate } from "redux-persist/integration/react"; // Npm: Redux persist gate.
import { ChakraProvider } from "@chakra-ui/react"; // Npm: Chakra UI provider.
import './index.css'; // Your custom global CSS, if any
import { sha256 } from "crypto-hash"; // For hashing persisted queries
/*
 * PACKAGES
 */
import Redux, { Persist } from "./Redux";

/*
 * STYLES
 */
import theme from "theme/theme";
import "./Assets/css/App.css";
import { Analytics } from "@vercel/analytics/react";

/*
 * APOLLO CLIENT PROVIDER
 */

const ApolloClientProvider = (initialState) => {
  // const state = Redux.getState()
  // const token = state.auth.token
  const _NodeBackendHttpLink = ApolloLink.from([
    createUploadLink({
      uri: process.env.REACT_APP_NODE_BACKEND,
    }),
  ]);

  const persistedQueryLink = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
  });

  const _ApolloClient = new ApolloClient({
    connectToDevTools: false,
    ssrMode: true,
    link: ApolloLink.from([_NodeBackendHttpLink, _NodeBackendHttpLink]),
    cache: new InMemoryCache().restore(initialState || {}),
    defaultOptions: {
      query: {
        context: {
          clientName: "default",
        },
      },

      mutate: {
        context: {
          clientName: "default",
        },
      },
      watchQuery: {
        context: {
          clientName: "default",
        },
      },
    },
  });

  return _ApolloClient;
};

/*
 * RENDER
 */
const Root = () => {
  return (
    <Provider store={Redux}>
      <PersistGate persistor={Persist}>
        <ChakraProvider theme={theme}>
          <ApolloProvider client={ApolloClientProvider()}>
            <React.StrictMode>
              <Router>
                <App />
                <Analytics />
              </Router>
            </React.StrictMode>
          </ApolloProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
