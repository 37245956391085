/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query MyQuery($blogId: ID!) {
    SpecificReadBlog(blogId: $blogId) {
      title
      status
      message
      id
      horizontalImage
      heading
      metaTitle
      metaDescription
      keywords
      
      author
      createdAt
      content
      bulletpoints
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
