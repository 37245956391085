/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    SubscriptionPlanRead {
      amount
      duration
      id
      message
      status
      subscriptionName
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
