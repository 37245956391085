export const text = `Shucae Films Private Limited is a company incorporated
  under the Companies Act, 1956, having its registered
  office at BANYORKA, VILL, DHRUMAN, 3131, MANDI,
  Mandi,Himachal Pradesh, 175040, having CIN
  U92490HP2021PTC008884 . Shucae Films inter alia provides
  an online website 'www.shucaefilms.com ' as an
  interactive platform for its viewers and any associated
  mobile sites, applications, games ("Site") to inter alia
  view serials and programs, films, sports content
  including live sports content, trailers of upcoming
  serials, view videos and/or photos of their favourite
  characters on serials, read recaps of serials,
  international content including viewing schedules of
  content offered, provide interactive games plays etc. on
  or through the Site and any other features, tools,
  applications, materials, or other services offered from
  time to time by Shucae Films in connection with its
  business, provided on or through the Site ("Shucae Films
  Content"). For some Shucae Films Content, Shucae Films
  may enable gaming and/or an interactive chat feature
  which may provide various features to the User(s)
  including but not limited to posting of comments,
  videos, pictures and participation in contests (“Social
  Feed”). The Site may also provide you content by
  integrating services and application of other audio
  video streaming platforms (“Partner Content”) and/or
  provide hosting service to third parties to display and
  allow access to their content and interactive games etc.
  (“Third Party Content”).<br/> Shucae Films may also provide
  standalone games and/or other forms of interactive
  engagement features on or through the Site (“Gaming
  Content”). ‘Content’ will hence consist of ‘Shucae Films
  Content’, Partner Content, ‘‘Third Party Content’ and/or
  any Gaming Content made available by Shucae Films. All
  such features of the Site collectively referred to as
  “Services”). The Content will be made available to You
  through different modes, at the discretion of Shucae
  Films, including via video on demand basis for viewing
  at the User's discretion at a time chosen by You and
  download for offline viewing by You or through a
  separate standalone Site or mobile Application. In the
  case of downloadable Content (not being a Gaming Content
  or a standalone mobile application / Site), please note
  that:
  <br/>
  <br/> (a) the downloads are temporary in order to allow
  you to view the Content within a specified period of
  time and you shall not attempt to or actually make a
  permanent copy of the Content in any manner or form, and
  <br/>
  (b) not all Content may be available for download for
  offline viewing. Please note that the availability of,
  and Your ability to access, the Content or some part of
  Services,
  <br/> (c) is subject to Shucae Films's sole
  discretion and 
  <br/>(d) may be dependent upon Your
  geographical location and  <br/>(e) is on the condition that
  not all Content or Services will be available to all
  viewers. <br/> <br/> On account of the nature of the Internet, this
  Site and the Services may also be accessed in various
  geographical locations; and You hereby agree and
  acknowledge that You are accessing this Site and
  availing of the Services, at Your own risk, choice and
  initiative and You agree and undertake to ensure that
  Your use of the Site and the Services complies with all
  applicable laws including the local laws in Your
  jurisdiction. Further, such Services and Content may
  vary from place to place, time to time and device to
  device and would be subject to various parameters such
  as specifications, device, Internet availability and
  speed, bandwidth, etc. You agree and acknowledge that
  <br/>
  Shucae Films may monitor or use certain technologies for
  monitoring of activities, as separately explained in
  Shucae Films’s Privacy Policy. <br/> To facilitate Your
  viewership and access, the Services can be packaged by
  Shucae Films through different models such as, Content
  or Services may be accessible  <br/>(a) free of charge which
  may include advertisements or commercials or  <br/> (b) via
  service through payment of a service fee as per the
  relevant service plan selected or <br/> (c) a pay-per-view
  model with or without advertisements/commercials or <br/> (d)
  with a combination of the foregoing on the Site. <br/> <br/> Note
  that Some Content such as live sports or shows, when
  accessed, will show advertisements even where the
  service plan selected is an advertisement free plan. The
  Service can be availed through platforms, software and
  devices, which Shucae Films approves from time to time,
  the current list for which is as set out below
  ("Compatible System"). In some cases, whether a device
  is (or remains) a Compatible System may depend on
  software or platform provided or maintained by the
  device manufacturer or other third parties. As a result,
  devices that are Compatible System at one time may cease
  to be Compatible System in the future. <br/>
  <b
    
  >
    The Shucae Films website works best with:
  </b>
  <li>Latest version of Google Chrome</li>
  <li>Latest version of Firefox</li>
  <li>Latest version of Safari ·</li>
  <li>
    Microsoft Windows XP or above / MAC OS 10.2 or above
  </li>
  <li>Latest version of Adobe Flash Player <li/> 
   <br/>JavaScript and
  cookies enabled The Shucae Films applications work best
  with a minimum system requirement of: ·
   Android 4.4.4 ·
  iOS 10.0 · AndroidTV - Android 7.1.1 The Dahan game
  application works best with a minimum system requirement
  of: · Android 4.4.4 · iOS 10.0 Subject to compatibility,
  You may be able to access the Services through mobile
  phones, tablets and other IP based connected devices or
  any Compatible System offered by a partner authorized by
  Shucae Films ("Partner").To access the Services at each
  instance, you would be required to undergo a two-step
  verification process to verify your login credentials
  such as your phone number, by way of a one-time password
  which will be sent to you simultaneously. Use of the
  Services is subject to compliance with these Terms of
  Use. Unless otherwise specified, the Services are
  available for individuals who have attained the age of
  majority in their jurisdiction (18 years in the case of
  India).If You are under the relevant age of majority in
  Your jurisdiction, You may access the Site and the
  Services under the supervision of an adult and in
  accordance with applicable law which enables a minor in
  your jurisdiction to access such a service. The Services
  include a section that exclusively provides access to
  only children-friendly content. However, in all cases,
  only persons of the age of majority are allowed to
  register any account on the Site and Services and will
  always be responsible for the operation of any such
  account. <br/> <br/> SHUCAE FILMS MAY CATEGORIZE AND CLASSIFY
  CERTAIN CONTENT AND PROVIDE DISCLAIMERS WITH RESPECT TO
  THE CONTENT, FOR VIEWERS TO CHOOSE SUCH CONTENT FOR
  VIEWING. SOME CONTENT OFFERED ON THE SITE MAY NOT BE
  SUITABLE FOR SOME VIEWERS AND THEREFORE VIEWER
  DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED ON THE
  SITE MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY CHILDREN.
  PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO COMPLY
  WITH APPLICABLE LAWS AND EXERCISE DISCRETION BEFORE
  ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT
  ON THIS WEBSITE. SHUCAE FILMS MAY DISPLAY A CONTENT
  DESCRIPTOR OR GUIDANCE MESSAGE THAT INDICATES AND
  INFORMS THE VIEWER ABOUT AGE-INAPPROPRIATE CONTENT FOR
  MINORS (FOR INSTANCE CONTENT TAGGED FOR VIEWERSHIP BY
  MINORS AGED 13 TO 18 YEARS). YOUR ACCESS TO AND USE OF
  THE SERVICES IS SUBJECT TO THESE TERMS AND CONDITIONS,
  PRIVACY POLICY AND ALL APPLICABLE LAWS, RULES, AND
  REGULATIONS. BY INSTALLING, USING OR OTHERWISE ACCESSING
  THE SERVICES, YOU AGREE TO THESE TERMS OF SERVICE. IF
  YOU DO NOT AGREE TO THESE TERMS OF SERVICES, PLEASE DO
  NOT INSTALL, USE OR OTHERWISE ACCESS THE SERVICES. USE
  OF THE SERVICES IS VOID WHERE PROHIBITED. <br/> <br/> Participation
  in any contest and or game on the Site conducted by
  Shucae Films or viewing of certain Content may be
  subject to further terms and conditions as may be
  notified by Shucae Films from time to time on the Site
  or otherwise. Watch N Play Terms and Conditions
  Accessing the Service through VPN You should not access
  or use the Service or the content therein by means of
  any mechanism or technology which conceals your actual
  geo-location or provides incorrect details of your
  location (for example, use a virtual private network
  (VPN)). Electronic Communications When You use / avail
  and/or install the Services or send any data,
  information or communication to Shucae Films, You agree
  and understand that You are communicating with Shucae
  Films through electronic media/ records and You consent
  to receive communications via electronic records from
  Shucae Films periodically and as and when required.
  Shucae Films will communicate with You by email or any
  push or other message or electronic records on the email
  address and or mobile number available with Shucae Films
  or made available to Shucae Films through a Partner
  which will be deemed adequate service of notice /
  electronic record. You acknowledge and specifically
  consent to Shucae Films or our respective affiliates and
  partners contacting you using the contact information
  you have provided to us at any time during your
  association with us for any purpose including the
  following purposes, which will enable Shucae Films to
  deliver the Services to you and/or enhance the delivery
  of the Services to you: · To obtain feedback regarding
  the Service; · To contact you for offering new products
  or services, whether offered by us, or our respective
  affiliates or partners. You acknowledge and agree that
  you may be contacted for the above purposes, using any
  of the contact details provided by You including via
  emails or other electronic and internet-based means. In
  addition, you may or you will be sent a one-time
  password via SMS to your registered mobile number, to
  verify your login on the Site using a mobile number. The
  Content contained in the Services including but not
  limited to on the Site is protected by copyright,
  trademark, patent, trade secret and other laws, and
  shall not be used except as provided in these Terms and
  Conditions, without the written permission of Shucae
  Films. Service Services Some of the Content made
  available on the Site can be availed only by registered
  Users who have registered under a service model
  ("Service User"). You may need to register Yourself on
  the Site to avail these services on a service model
  ("Service"). To become a Service User, You may be
  required to provide certain personal information to
  Shucae Films and may be required to make payments
  ("Service Fee") as per the applicable membership plan
  through a debit card, credit card, internet banking or
  through, e- prepaid wallet or other payment method
  accepted by Shucae Films ("Payment Method(s)") that You
  opted for during registration. Shucae Films will be
  using third party payment gateways to process and
  facilitate the payment of Your Service Fee to Shucae
  Films. Shucae Films may offer different kinds of Service
  plans, each of these Service plans will be subject to
  different limitations and restrictions and the cost of
  each of these Service plans may vary. You may choose
  more than 1 Service plan during a given period. Shucae
  Films may also offer certain features and / or benefits
  over and above the features and benefits made available
  to you under your existing Service plan (“Add-on”) for
  an additional fee (“Add-on Fee”). The Add-on will
  automatically expire upon the expiry of your Service
  plan. Shucae Films shall have the discretion to make
  certain Content that is a part of the Service available
  to You on more than one end user Compatible System
  concurrently. Shucae Films shall also have the
  discretion to make certain Content that is a part of the
  Service available to You on not more than one end user
  device concurrently. Any personal data whether provided
  by you as a part of the Service registration process, or
  collected automatically when you use the Service will be
  governed by the Privacy Notice accessible at here Free
  Trial Some of the Service are starts with a free. The
  free period may last for a period of subject to company
  policy changes or such other term as may be determined
  by Shucae Films. Your eligibility for a free trial
  including the time for such free trial and the Content
  available to be viewed during free trial period is at
  the discretion of Shucae Films and such free trial can
  be modified, suspended or terminated by Shucae Films at
  any point of time without giving notice to You.; In case
  You are eligible for any free trial, Shucae Films may/
  or may not charge You a token amount of money at the
  time of Your registration for a Service plan. This token
  amount will be refunded to You once Shucae Films
  receives the confirmation from the third-party payment
  gateway provider that Your Payment Method has been
  registered. Payments Shucae Films will automatically
  start billing as per Your selected Payment Method for
  the relevant Service plan(s) and any Add-on(s) selected
  by You at the time of registration through the third
  party payment gateway at the end of the free trial
  period, if applicable unless You cancel a Service(s)
  before the end of the free trial period. The
  cancellation of a Service can be done through the "My
  Account" section of the Site. It may take a few days for
  Your payment made to Shucae Films to be reflected in
  your "My Account" section. In case You cancel any
  Service before the end of the free trial period, You
  will not be entitled to any compensation monetary or
  otherwise from Shucae Films for the unutilized period of
  free trial. You cannot change your Payment Method during
  the tenure of your Service(s). If Your selected Payment
  Method is no longer available or expires or Your Payment
  Method fails for whatsoever reason, You will be
  responsible and liable for any uncollected amounts and
  Shucae Films reserves the right to terminate the
  Service(s) offered to You. The Service Fee for a
  particular Service along with any Add-on Fee for any
  Add-on(s) selected by you, will be billed at the
  beginning of the Service membership or at the end of
  free trial period, as applicable and on each subsequent
  renewal automatically (as per the Service plan and the
  Add-on(s) chosen by You) unless and until You cancel
  Your Service(s) or the Service(s) is otherwise suspended
  or discontinued pursuant to these Terms. To see the
  commencement date for Your next renewal period or to
  cancel Your service to the Service(s), You need to visit
  the "My Account" section on Your profile page. On
  cancellation of a Service(s) You will be notified by
  Shucae Films. Shucae Films will be billing you for some
  of its services as per Your Payment Method for each
  Service period on a monthly basis. However, if You
  change Your Service plan to a higher tier, this could
  result in changing the day on which You are billed and
  the amount which You are billed. Further, on changing
  your existing Service plan to another, you would be
  required to pay the difference in rates (on a pro rata
  basis) for the new Service plan selected and would be
  billed as per the new Service plan from the next billing
  / renewal cycle. Shucae Films will notify you in advance
  of the change in the Service Fee payable. You cannot
  downgrade Your existing Service plan or any Add-on(s)
  prior to the expiry of such Service plan. The new
  Service will be effective from the date on which you
  select such plan and make appropriate payments as
  necessary. The Add-on(s) will be effective and valid
  during the validity of your Service plan. In case of
  change in the Service Fee for a particular membership
  plan that is already opted by You, Shucae Films will
  give You an advance notice of these changes. The Service
  Fees and the Add-on Fees specified for each Service plan
  or Add-on(s), as the case may be are inclusive of
  applicable taxes. The Service Fees and/or the Add-on
  Fees billed are non-refundable irrespective of whether
  the Service or the Add-on has been availed by You or
  not. Any request for change or cancellation in any
  Service plan and/or the Add-on(s) prior to the
  expiration of the current Service plan period will not
  entail You with a refund for any portion of the Service
  Fee paid by You for the unexpired period, except where
  upgrading to a higher Service plan and/or an Add-on(s),
  as the case may be. You also understand and acknowledge
  that Shucae Films only facilitates the third-party
  payment gateway for processing of payments. This
  facility is managed by the third-party payment gateway
  provider and You are required to follow all the terms
  and conditions of such third-party payment gateway
  provider. You are responsible for the accuracy and
  authenticity of the payment information provided by You,
  including the bank account number/credit card details
  and any other information requested during the service
  process. You represent and warrant that you have the
  right to use any credit card or other payment
  information that you submit. You agree and acknowledge
  that Shucae Films shall not be liable and in no way be
  held responsible for any losses whatsoever, whether
  direct, indirect, incidental or consequential, including
  without limitation any losses due to delay in processing
  of payment instruction or any credit card fraud. You can
  access the status of Your Service anytime through the
  "My Account" section on Your profile page. You can file
  any complaint related to payment processing on the Site
  and the same will be forwarded to the concerned
  third-party payment gateway provider for redressal. Upon
  payment through any of the Payment Methods and
  confirmation of receipt of such payment from the payment
  gateway provider, an invoice will be made available to
  You in the "MY Account" section which can be downloaded
  by You in pdf format. Upon payment being received by
  Shucae Films through any of the Payment Method, Shucae
  Films shall make the relevant Service available to You
  through any of the approved modes of delivery that
  Shucae Films adopts. It is hereby clarified that the
  Service(s) offered to You are offered by Shucae Films
  and not by any third party including any payment gateway
  service providers. Shucae Films reserves the right to
  change, terminate or otherwise amend the Service plans,
  Add-on benefits, Service Fees or Add-on Fees for the
  Service and billing cycles at its sole discretion and at
  any time. Such amendments shall be effective upon
  posting on the Site and Your continued use of the
  Service shall be deemed to be Your conclusive acceptance
  of such amendments. Shucae Films reserves the right to
  change, supplement, alter or remove any of the Content
  that is subject to a Service Fee as it deems fit. Shucae
  Films does not guarantee the availability of a specific
  Content or a minimum Content that is subject to a
  Service Fee. Post cancellation of Your Service, if you
  wish to re-subscribe to the Service, you may do so from
  Your "My Account" page. Social Feed The Social Feed will
  be made available on the Site at Shucae Films’s
  discretion to enable a User to participate in any
  contest or competition or share his/her views, comments,
  pictures and / or videos (“Social Posts”) with the other
  like-minded Users on the Site for an immersive playing
  and entertainment experience. You can also invite Your
  friends to participate in the Social Feed. For this, You
  will require to permit Shucae Films to access Your
  contacts list. Friends (i.e. users who also have your
  number in their phonebook or those who have accepted
  your invitation) will be able to see inter alia your
  Social Posts tagged as private contact or by any other
  suitable name. If You have participated in any contest
  or competition in the Social Feed, Your friends will
  also be able to see your scores and/or progress in such
  contest/competition. The Social Feed is a place for
  likeminded User(s) to come together as a community and
  share Social Posts related to the Content and topics
  associated to the Content alongside which, the Social
  Feed is made available. Shucae Films may by automated
  means or manually screen and moderate some or all Social
  Posts and reserves the right to reject or take down any
  Social Post which in Shucae Films’s sole opinion is
  inappropriate or does not pertain to the Content or
  topics associated to the Content. Shucae Films’s
  decision in this regard will be final and binding on all
  User’s and Shucae Films will not be responsible or
  liable for any claims regarding the exercise of the
  Shucae Films’s discretion in this regard. Whether or not
  your Social Posts are made in combination with Shucae
  Films’s preinstalled template content (such preinstalled
  template content is referred to as “Social Components”),
  your Social Posts are considered as newly created
  audio-visual electronic records for which you are the
  publisher. A non-exhaustive list of content and / or
  Social Posts that may be rejected by Shucae Films is as
  follows: post or content which may cause any harm to
  religious sentiments, post or content that is political
  in nature, obscene, vulgar, derogatory remarks, hate
  speech, rumours or fake news, harassment, abuse,
  flooding, spamming, inciting violence or threatening,
  objects that disturb national harmony, racist or sexist
  remarks, potentially objectionable subject matter such
  as criminal behaviour, illegal activities, nudity, sex,
  profanity, out of context comments, disrespecting the
  nation, armed forces or any other matters which are
  perceived to be not in the interest of the general
  public or may cause harm in any nature or are
  distasteful, irrespective of any language used
  whatsoever will be restricted / filtered by Shucae Films
  on a best effort basis. In addition to the restrictions
  set forth above, You agree not to share any information
  /material/Social Post that: • belongs to another person
  and to which the participant does not have any right to;
  • is defamatory, obscene, pornographic, paedophilic,
  invasive of another's privacy including bodily privacy,
  insulting or harassing on the basis of gender, racially
  or ethnically objectionable, relating to or encouraging
  money laundering or gambling, or otherwise inconsistent
  with or contrary to the laws in force; • depicts women
  in an inappropriate or derogatory manner; • is harmful
  to children; • infringes any patent, trademark,
  copyright or other proprietary rights; • violates any
  law for the time being in force; • deceives or misleads
  the addressee about the origin of the message or
  knowingly and intentionally communicates any information
  which is patently false and misleading in nature but may
  reasonably be perceived as a fact; • impersonates
  another person; • threatens the unity, integrity,
  defense, security or sovereignty of India, friendly
  relations with foreign states, or public order or causes
  incitement to the commission of any cognizable offence
  or prevents investigation of any offence or is insulting
  any other nation. The Participant also represents and
  warrants that the posting of his / her Social Posts does
  not violate any right of any party, including privacy
  rights, publicity rights, and intellectual property
  rights. • contains software viruses or any other
  computer code, files or programs designed to interrupt,
  destroy or limit the functionality of any computer
  resource; • is patently false and untrue, and is written
  or published in any form with the intent to mislead or
  harass a person, entity, or agency for financial gain or
  to cause any injury to any person, or to incite hatred
  against any community; Shucae Films hereby grants you a
  revocable, non-exclusive, royalty-free, and worldwide
  license to use, modify, adapt, reproduce, make
  derivative works of, publish, and/or transmit the Social
  Components solely for the purpose of creating and making
  available your Social Posts on the Site. You retain all
  of your ownership rights in your content / Social Posts
  that you submit in the Social Feed. However, by
  submitting Social Posts, You hereby grant to Shucae
  Films and its affiliates, a royalty-free, worldwide,
  perpetual, non-exclusive, sub-licensable, and
  transferable license to use, reproduce, distribute,
  issue copies, make available, publicly communicate,
  adapt, create derivative works of, and display the
  content that you upload, share or provide in connection
  with Your use of the Social feed. You also grant us the
  right, license and permission to use the contents of
  your Social Post in relation to the Site, topics
  associated therewith and for its promotion in any form
  whatsoever, without any compensation. By sharing Social
  Post in the Social Feed, You undertake and confirm that
  you have all the rights, title and interest in and to
  the content of the Social Post being shared by You
  (including to any images or any content that may be
  protected by intellectual property rights). You further
  understand agrees and acknowledge the said Social Post
  may be publicly displayed by Shucae Films in the Social
  Feed to all Users who are engaging with the Social Feed.
  The views expressed by the User(s) on the Social Feed
  are solely their own and do not represent those of
  Shucae Films, its associates and affiliates. Shucae
  Films shall not be held liable or responsible for any
  kind of claims, damages, outrage arising due to the
  comments, pictures or videos posted by the User(s) on
  the Site. Additionally, Shucae Films shall not be held
  liable or responsible for any violation of Intellectual
  Property rights that may arise out of any content posted
  by the users. Shucae Films may cancel or discontinue the
  availability of the Social Feed and any decision in this
  matter shall be at the discretion of Shucae Films and
  shall be final and binding. User(s) are not permitted to
  download any Social Posts or take screenshots of the
  same. Doing so will be in violation of the Terms. Access
  and Use of the Services Limited use: You are given a
  limited, non-exclusive, non-transferable (except in the
  case of a parent/legal guardian-minor relationship),
  non-sub-licensable, revocable permission to view and/or
  access the Site and avail the Service during the
  subsistence of Your Account with Shucae Films for the
  territory of the world or limited territories as
  applicable in respect of specified Content and as set
  forth in these Terms and Conditions and no right, title
  or interest in any Content will be deemed transferred to
  You. By accessing or using the Site / Services: · You
  confirm and warrant that all the data and information
  You supply to Shucae Films and or any Shucae Films's
  affiliates ("Registration Data") is and accurate in all
  respects. · You agree to promptly update Your
  Registration Data as necessary, so that Your
  Registration Data remains accurate at all times. ·
  Shucae Films may temporarily suspend your access to the
  whole or any part of the Site/Services, where Shucae
  Films in its discretion considers that it is reasonably
  necessary. Where possible, Shucae Films will give You as
  much notice of any interruption of access to the
  Services as is reasonably practicable. Shucae Films will
  use reasonable efforts to restore access to the Services
  as soon as reasonably practicable after temporary
  suspension. · You agree that Shucae Films shall be under
  no liability whatsoever to You in the event of
  non-availability of the Site or any portion thereof
  occasioned by Act of God, war, disease, revolution,
  riot, civil commotion, strike, lockout, flood, fire,
  satellite failure, failure of any public utility,
  man-made disaster, satellite failure or any other cause
  whatsoever beyond the control of Shucae Films. · You
  acknowledge and agree not to either directly or through
  the use of any device, software, internet site,
  web-based service, or other means remove, alter, bypass,
  avoid, interfere with, violate, or circumvent any
  patent; trade secret; copyright, trademark, or other
  proprietary notice marked on the Content or any digital
  rights management mechanism or device; any content
  protection or access control measure associated with the
  Content, including geo-filtering mechanisms; privacy;
  publicity; or other proprietary right under applicable
  law. · You agree not to either directly or through the
  use of any device, software, internet site, web-based
  service, or other means copy, download, capture,
  reproduce, duplicate, archive, distribute, upload,
  publish, modify, translate, broadcast, perform, display,
  sell, transmit or retransmit the Content or create any
  work or material that is derived from or based on the
  Content. This prohibition applies regardless of whether
  such derivative works or materials are sold, bartered,
  or given away. · You agree that Shucae Films and/or its
  affiliates or licensors owns and/or retains all rights
  to the Services and the Content throughout the territory
  of the world in perpetuity. You further agree that the
  Content You access and view as part of the Services is
  owned or controlled by Shucae Films, its affiliates and
  or its licensors. The Services and the Content are
  protected by copyright, trademark, design, patent, trade
  secret, and other intellectual property laws. · You
  agree that third parties and/or its affiliates or
  licensors owns and/or retains all rights to the Third
  Party Content. The Third Party Content are protected by
  copyright, trademark, design, patent, trade secret, and
  other intellectual property laws. · Except as provided
  in these Terms (or otherwise expressly provided by
  Shucae Films), You may not copy, download, stream
  capture, reproduce, duplicate, archive, upload, modify,
  translate, publish, broadcast, transmit, retransmit,
  distribute, perform, display, sell, frame or deep-link,
  make available, or otherwise use any Content contained
  in the Services. You further agree not to use the
  Services to: - Post, upload or otherwise transmit or
  link to Content that is: unlawful; threatening; abusive;
  obscene; vulgar; sexually explicit; pornographic or
  inclusive of nudity; offensive; excessively violent;
  invasive of another's privacy, publicity, contract or
  other rights; tortious; false or misleading; defamatory;
  libelous; hateful; or discriminatory; - Harass or harm
  another person; - Exploit or endanger a minor; -
  Impersonate or attempt to impersonate any person or
  entity; - Violate any patent, trademark, trade secret,
  copyright, privacy, publicity or other proprietary
  right; - Introduce or engage in activity that involves
  the use of viruses, software disabling codes, bots,
  worms, or any other computer code, files or programs
  that interrupt, destroy, or limit the functionality of
  any computer software or hardware or telecommunications
  equipment, or otherwise permit the unauthorized use of
  or access to a computer or a computer network; - Attempt
  to decipher, decompile, disassemble or reverse engineer
  any of the software, applications and/or any element
  comprising the Site, the application for accessing the
  Content, or the Services; - Interfere with, damage,
  disable, disrupt, impair, create an undue burden on, or
  gain unauthorized access to the Services, including
  Shucae Films's servers, networks or accounts; - Cover,
  remove, disable, manipulate, block or obscure
  advertisements or other portions of the Services or the
  Content; - Delete or revise any information provided by
  or pertaining to any other user of the Services. · Use
  technology or any automated system such as scripts,
  spiders, offline readers or bots in order to collect or
  disseminate usernames, passwords, email addresses or
  other data from the Services, or to circumvent, delete,
  deactivate, decompile, reverse engineer, disassemble or
  modify any security technology or software that is part
  of the Services or the Site; - Send or cause to send
  (directly or indirectly) unsolicited bulk messages or
  other unsolicited bulk communications of any kind
  through the Services. If You do so, You acknowledge You
  will have caused substantial harm to Shucae Films, but
  that the amount of harm would be extremely difficult to
  measure. As a reasonable estimation of such harm, and by
  way of liquidated damages and not as a penalty, You
  agree to pay Company INR 500 (Indian rupees five hundred
  only) for each actual or intended recipient of such
  communication without prejudice to any other rights of
  action that Shucae Films may have against You in law or
  equity; - Solicit, collect or request any personal
  information for commercial or unlawful purposes; - Post,
  upload or otherwise transmit an image or video of
  another person without that person's consent; - Engage
  in commercial activity (including but not limited to
  advertisements or solicitations of business; sales;
  contests; sweepstakes; creating, recreating,
  distributing or advertising an index of any significant
  portion of the Content; or building a business using the
  Content) without Shucae Films's prior written consent; -
  Use technology or other means to access, index, frame,
  or link to the Site (including the Content) that is not
  authorized by Shucae Films; - Access the
  Site/application/Service (including the Content) through
  any automated means, including "robots," "spiders," or
  "offline readers" (other than by individually performed
  searches on publicly accessible search engines for the
  sole purpose of, and solely to the extent necessary for,
  creating publicly available search indices – but not
  caches or archives – of the Site and excluding those
  search engines or indices that host, promote, or link
  primarily to infringing or unauthorized content); - Use
  the Services to advertise or promote competing services;
  - Use the Services in a manner inconsistent with any and
  all applicable law; - Attempt, facilitate, induce, aid
  and abet, or encourage others to do any of the
  foregoing. · You are responsible for the security and
  proper use of Your unique user ID and password that You
  enter while registering on the Site or on the Partner's
  website or application and must take all necessary steps
  to ensure that they are kept confidential, used
  properly, and not disclosed to unauthorized people. You
  are responsible for any use of your user ID and
  password. You must promptly notify Shucae Films if you
  become aware of any confidentiality breach or
  unauthorized use of your user account.. · You agree that
  Your use of the Services and the Content may give You
  the opportunity to view, publish, transmit, submit posts
  and/or comment in accordance with these Terms and
  Conditions. You agree that You will not misuse the
  Content and/or other user material You access and view
  as part of the Service, including without limitation,
  download/store the Content by illegal/non-permitted
  means, or infringe any of Shucae Films's/ or its
  licensor's / or third party’s copyright, trademark
  design, patent, and other intellectual property
  including any rights of Shucae Films or its licensors or
  any third party subsisting in the Content. You further
  agree that You will not copy/record/edit/modify any of
  the Content You access and view as part of the Service,
  for any purpose, and/or copy/record/edit/modify any
  Content including video, images etc., by using any means
  including software, spyware, etc., for any purpose
  whatsoever. · You further agree that You shall not post,
  publish, transfer, upload, transmit or submit any of the
  Content, including without limitation videos, images,
  comments, User Material (defined below) or articles, of
  the Site on any other website, webpage or software,
  whatsoever. · You agree that the Content provided on the
  Site is strictly for Your private viewing only and not
  for public exhibition irrespective of whether the public
  is charged for such exhibition or not. You hereby agree
  to refrain from further broadcasting or making available
  the Content provided on the Site to any other person(s),
  or in any manner communicate such Content to the public.
  In case the Site allows You to download or stream any of
  the Content therein, You agree that You will not use,
  sell, transfer or transmit the Content to any person or
  upload the Content on any other website, webpage or
  software, which may violate/cause damages or injuries to
  any rights of Shucae Films or its affiliates or any
  third party including privacy rights, publicity rights,
  and intellectual property rights. You may encounter
  third party applications while using the Site including,
  without limitation, websites, widgets, software,
  services that interact with the Site. Your use of these
  third-party applications shall be subject to such
  third-party terms of use or license terms. Shucae Films
  shall not be liable for any representations or
  warranties or obligations made by such third-party
  applications to You under contract or law. Termination
  of Service Shucae Films reserves the right to
  immediately terminate, suspend, limit, or restrict Your
  account or Your use of the Services or access to Content
  at any time, without notice or liability, if Shucae
  Films so determines in its sole discretion, for any
  reason whatsoever, including that You have breached
  these Terms and Conditions, the Privacy Policy, violated
  any law, rule, or regulation, engaged in any
  inappropriate conduct, provided false or inaccurate
  information, or for any other reason. You hereby agree
  and consent to the above and agree and acknowledge that
  Shucae Films can, at its sole discretion, exercise its
  right in relation to any or all of the above, and that
  Shucae Films, its directors, officers, employees,
  affiliates, agents, contractors, principals or licensors
  shall not be liable in any manner for the same; and You
  hereby agree, acknowledge and consent to the same. You
  hereby also agree that Shucae Films shall not refund any
  amounts that you may have paid to access and/or use the
  Services. User Reviews, Comments and Other Material You
  may have an opportunity to publish, transmit, submit, or
  otherwise post (collectively, "Post") only reviews or
  comments or photos or videos on the Site (collectively,
  "User Material"). As it concerns User Material, without
  prejudice to Your obligation to otherwise comply with
  applicable laws during the course of using the Services,
  You agree to hereby comply with any and all applicable
  laws, as well as any other rules and restrictions that
  may be set forth herein or on the Site or Services. You
  agree that Shucae Films shall have the right but have no
  obligation, to monitor User Material and to restrict or
  remove User Material that Shucae Films may determine, in
  its sole discretion, is inappropriate or for any other
  reason. You acknowledge that Shucae Films reserves the
  right to investigate and take appropriate legal action
  against anyone who, in Shucae Films's sole discretion,
  violates these Terms, including, but not limited to,
  terminating their account, terminate, suspend, limit, or
  use of the Services or access to Content and/or
  reporting such User Material, conduct, or activity, to
  law enforcement authorities, in addition to any other
  available remedies under law or equity. In addition to
  the restrictions set forth above, You must adhere to the
  below terms and conditions and not host, display,
  upload, modify, publish, transmit, update or share any
  information /material/User Material that: • belongs to
  another person and to which the Participant does not
  have any right to; • is defamatory, obscene,
  pornographic, paedophilic, invasive of another's privacy
  including bodily privacy, insulting or harassing on the
  basis of gender, racially or ethnically objectionable,
  relating to or encouraging money laundering or gambling,
  or otherwise inconsistent with or contrary to the laws
  in force; • depicts women in an inappropriate or
  derogatory manner; • is harmful to children; • infringes
  any patent, trademark, copyright or other proprietary
  rights; • violates any law for the time being in force;
  • deceives or misleads the addressee about the origin of
  the message or knowingly and intentionally communicates
  any information which is patently false and misleading
  in nature but may reasonably be perceived as a fact; •
  impersonates another person; • threatens the unity,
  integrity, defense, security or sovereignty of India,
  friendly relations with foreign states, or public order
  or causes incitement to the commission of any cognizable
  offence or prevents investigation of any offence or is
  insulting any other nation. The Participant also
  represents and warrants that the posting of his / her
  Social Posts does not violate any right of any party,
  including privacy rights, publicity rights, and
  intellectual property rights. • contains software
  viruses or any other computer code, files or programs
  designed to interrupt, destroy or limit the
  functionality of any computer resource; • is patently
  false and untrue, and is written or published in any
  form with the intent to mislead or harass a person,
  entity, or agency for financial gain or to cause any
  injury to any person, or to incite hatred against any
  community; You acknowledge that the User Material is not
  confidential and that You have no expectation of privacy
  in it. In no event does Shucae Films assume any
  responsibility or liability whatsoever for any User
  Material, and You agree to waive any legal or equitable
  rights or remedies You may have against Shucae Films
  with respect to such User Material. This Site may
  contain articles contributed by several individuals. The
  views are exclusively their own and do not represent the
  views of Shucae Films, affiliates and/ or its
  management. All liability in respect of the above is
  excluded to the extent permitted by law including any
  implied terms. Indian law and jurisdiction apply with
  respect to contents of this Site. Linked Destinations
  Part of this Site/Service contains links to third party
  sites and third-party content. You agree and acknowledge
  that Shucae Films does not endorse or sponsor such
  third-party sites, content, advertising or other
  material on such third-party sites. If Shucae Films
  provides links or pointers to other websites or
  destinations, You should not infer or assume that Shucae
  Films operates, controls, or is otherwise connected with
  these other websites or destinations. When You click on
  a link within the Services, Shucae Films will not warn
  You that You have left the Services and are subject to
  the terms and conditions (including privacy policies) of
  another website or destination. In some cases, it may be
  less obvious than others that You have left the Services
  and reached another website or destination. Please be
  careful to read the terms of use and privacy policy of
  any other website or destination before You provide any
  confidential information or engage in any transactions.
  You should not rely on these Terms and Conditions and/or
  Privacy Policy to govern Your use of another website or
  destination. Shucae Films is not responsible for the
  content or practices of any website or destination other
  than the Site, even if it links to the Site and even if
  the website or destination is operated by a company
  affiliated or otherwise connected with Shucae Films. By
  using the Services, You acknowledge and agree that
  Shucae Films is not responsible or liable to You for any
  content or other materials hosted and served from any
  website or destination other than the Site. Advertising
  Shucae Films expressly disclaim any liability arising
  out of the advertisements, usage or viewing of these
  products or services advertised on our Site or the
  (third party) content made available / hosted on the
  third-party sites. Shucae Films takes no responsibility
  for advertisements or any third-party material posted on
  the Site nor does it take any responsibility for the
  products or services provided by advertisers. Any
  dealings You have with advertisers found while using the
  Services are between You and the advertiser, and You
  agree that Shucae Films is not liable for any loss or
  claim that You may have against an advertiser. Third
  Party Content For Third Party Content, Shucae Films’s
  role is limited to providing a communication platform
  along with hosting services to third parties, to enable
  the transmission of the Third Party Content directly
  from third parties to You. The Third Party Content on
  the Site is directly uploaded onto the Site by third
  parties who avail of Shucae Films’s hosting services,
  without any intervention from Shucae Films in the
  uploading / transmission process. Shucae Films’s role is
  that of an ‘intermediary’ as defined under the
  Information Technology Act, 2000 and the rules
  thereunder, with regard to the Third Party Content.
  Being an intermediary, Shucae Films has no
  responsibility and / or liability in respect of any
  Third Party Content on the Site, including for
  intellectual property rights infringement, defamation,
  obscenity or any other violation under applicable law.
  Third Party Content on the Site may include audio/video
  content and/or interactive content such as free-to-play
  games of skill that are casual/hyper-casual online
  games, developed or owned by the third parties ("Online
  Games"). The Online Games will be run and made available
  over the Site and do not have to be separately
  downloaded or installed. When You play an Online Game,
  You agree and acknowledge that the Site does not create
  and/or broadcast any Online Games on its own accord and
  Shucae Films is not responsible or liable for the
  content or accuracy of the Online Game, including
  copyrights, that may be accessed by You through the
  Site. Please note that each Online Game may be subject
  to its own game rules and code of conduct. Any personal
  data provided by You or collected automatically when You
  use the Service relating to the Online Games will be
  governed by the Privacy Notice accessible at
  www.shucaefilms.com . Please note certain Third Party
  Content may be available only on limited and/or
  compatible devices. The following general rules will
  apply when you access the Online Games and/or Gaming
  Content (Collectively referred to as Games) on the Site:
  • These Games are strictly free-to-play and do not
  entail any monetary rewards or prizes and only entails a
  point accumulation system where applicable. • You have
  to sign up/log in using the available mechanisms in
  order to access the Games. • Users may receive
  achievements and game points (including bonus points,
  lifetime points and achievements) for every correct move
  or manoeuvre as per the rules and format of each Game. •
  For each Game the reward points may be reset once a game
  is finished. • Certain lifetime points and achievements
  can be used on special games or events that may be
  announced by the Site. • The points-system for each Game
  is specified within the individual Game. • Users should
  note that some Games have a limited time before it ends,
  and Users may have to log in and play before the time is
  over. Shucae Films does not endorse, market, advertise
  or publicize any Third Party Content on the Site and is
  not responsible or liable for any Third Party Content.
  Shucae Films does not pre-screen the Third Party Content
  and has no obligation to monitor any Third Party
  Content. Hence, Shucae Films does not have actual or
  specific knowledge of any Third Party Content on the
  Site. However, Shucae Films at its discretion and in
  accordance with applicable law may monitor any Third
  Party Content and may remove any Third Party Content
  from the Site if Shucae Films determines in its sole
  discretion that such Third Party Content is in violation
  of this Agreement or any applicable law. Shucae Films,
  at its discretion, may review the Third Party Content
  when, and only when, complaints are received from You.
  Such actions do not in any manner negate or dilute
  Shucae Films’s position as an intermediary or impose any
  liability on Shucae Films with respect to Third Party
  Content. Shucae Films will consider all communications,
  requests and suggestions sent by You and other members
  of the public provided that such communications are sent
  in a bona fide manner in good faith in the interest of
  the Site and public good. However, Shucae Films is not
  under any obligation to act on any such communications,
  requests and suggestions or respond to anyone. Shucae
  Films’s decision in this respect shall be final.
  Specifically, if any such request relates to a request /
  demand to take down/ disable/ remove/ delete any Third
  Party Content in the Site, Shucae Films is under no
  legal obligation to respond to or act on such requests.
  Shucae Films will take such action as Shucae Films is
  required to take under applicable law. If there is any
  valid court order or administrative order issued
  requiring Shucae Films to take any action, then Shucae
  Films will comply with such court order or
  administrative order. Support 1. Site or Services If You
  have any questions with respect to the Site and/or
  Services and/or Content, then such correspondence should
  be directed to Shucae Films Private Limited at –
  contact.us@shucaefilms.com 2. Grievances a. For any
  Shucae Films Content and Partner Content related
  concerns, complaints or grievances, please click here to
  submit them. b. The grievance contacted at
  contact.us@shucaefilms.com. c. Shucae Films follows the
  guidance Self-Regulatory Body , Digital Media Content
  Regulatory Council (“DMCRC”). d. For any concerns,
  complaints or grievances in relation to the Site or
  Service, please contact the following grievance officer
  who will examine the complaint and take appropriate next
  steps. Name: Manoj Dogra Email Address:
  manoj.doogra@shucaefilms.com You would be required to
  furnish relevant details pertaining to your concern,
  complaint or grievance, specifically including your user
  login ID. Self-Regulatory Content Grievance Redressal
  for Online Curated Content Providers (OCCPs) Compliance
  Report for the month of November 2023 Sr. No. Grievances
  Number 1. Total number of grievances received during the
  month 0 2. Number of grievances disposed of during the
  month 0 3. Number of grievances pending disposal during
  the month 0 4. Number of grievances upheld/actioned by
  Grievance Officer 0 5. Number of grievances dismissed by
  Grievance Officer 0 6. Number of appeals filed with the
  self-regulatory appellate body (DMCRC) against order of
  Grievance Officer 0 7. Number of orders issued by the
  self-regulatory appellate body (DMCRC) 0 3. User
  Material If You have any complaints relating to any
  third party User Material uploaded on the Site, then
  please contact contact.us@shucaefilms.com. Your email,
  which must bear Your digital signature or a signed
  written complaint, should include the following
  information: a. Details of the objectionable user
  material or content; b. Details of the Basis of the
  objection; c. What relief is being requested; d. All
  other relevant information required by Shucae Films to
  assess the complaint. Shucae Films shall on examination
  of the complaint take all reasonable measures, including
  taking down the objectionable User Material
  expeditiously in accordance with applicable law. You
  agree and acknowledge that Shucae Films's responsibility
  in relation to objectionable User Material is limited to
  removing them post receipt of Your objections in
  accordance with these Terms of Use and Shucae Films
  shall not be responsible or liable for any other damages
  or losses incurred by You of any nature whatsoever.
  Disclaimer of Warranties You agree that use of the
  Services and the access to the Site is at Your own risk.
  The Services including the Site, the Content, the Online
  Games, the video player, User Material and any other
  materials contained on or provided through the Site are
  provided "AS IS" and, to the fullest extent permitted by
  law, are provided without warranties or guarantees of
  any kind, either express or implied. Without limiting
  the foregoing, Shucae Films does not make any warranties
  or guarantees of fitness for a particular purpose,
  title, merchantability, completeness, availability,
  security, compatibility or non-infringement; or that the
  Services will be uninterrupted, free of viruses, malware
  or other harmful components, accurate, error free or
  reliable. Limitation of Liability IN NO CASE SHALL
  SHUCAE FILMS, ITS DIRECTORS, OFFICERS, EMPLOYEES,
  AFFILIATES, AGENTS, CONTRACTORS, PRINCIPALS, OR
  LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,
  INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES
  ARISING FROM YOUR USE OF THE SERVICES OR ANY CONTENT OR
  MATERIAL THEREOF OR FOR ANY OTHER CLAIM RELATED IN ANY
  WAY TO YOUR ACCESS OF THE SITE OR USE OF THE SERVICES OR
  ANY CONTENT OR MATERIAL THEREOF, INCLUDING, BUT NOT
  LIMITED TO, ANY ERRORS OR OMISSIONS IN ANY CONTENT OR
  MATERIAL, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS
  A RESULT OF THE USE OF THE SERVICES OR ANY CONTENT OR
  MATERIAL THEREOF, EVEN IF ADVISED OF THEIR POSSIBILITY.
  SHUCAE FILMS’S MONETARY LIABILITY FOR THE SERVICES OR
  THE CONTENT OR MATERIAL THEREOF ON THE SITE SHALL BE
  LIMITED TO INR 1000. WHERE THE LAWS DO NOT ALLOW THE
  EXCLUSION OR THE LIMITATION OF LIABILITY FOR
  CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
  JURISDICTIONS, SHUCAE FILMS'S LIABILITY SHALL BE LIMITED
  TO THE EXTENT PERMITTED BY LAW. Indemnity You agree to
  defend, indemnify and hold harmless Shucae Films, its
  affiliates, officers, directors, employees and agents,
  from and against any and all claims, damages,
  obligations, losses, liabilities, costs or debt, and
  expenses (including but not limited to attorneys' fees)
  arising from: (i) Your use of and access to the
  Services; (ii) Your violation of any term of these Terms
  and Conditions; (iii) Your violation of any third party
  right, including without limitation any publicity,
  privacy, or intellectual property right; (iv) Your
  breach of any applicable laws; and (v) any unauthorized,
  improper, illegal or wrongful use of Your Account by any
  person, including a third party, whether or not
  authorized or permitted by You. This indemnification
  obligation will survive the expiry or termination of
  these Terms and Conditions and Your use of the Service.
  Governing Law and Jurisdiction The relationship between
  You and Shucae Films shall be governed by the laws of
  India without regard to its conflict of law provisions
  and for resolution of any dispute arising out of Your
  use of the Services. Notwithstanding the foregoing, You
  agree that (i) Shucae Films has the right to bring any
  proceedings before any court/forum of competent
  jurisdiction and You irrevocably submit to the
  jurisdiction of such courts or forum; and (ii) any
  proceeding brought by You shall be exclusively before
  the courts at Shimla (Himachal), India. Severability If
  any provision of these Terms and Conditions is held
  invalid, void, or unenforceable, then that provision
  shall be considered severable from the remaining
  provisions, and the remaining provisions given full
  force and effect. Changes Shucae Films reserves the
  right, at any time and from time to time, without prior
  notice to You, to update, revise, supplement, and
  otherwise modify these Terms of Use and to impose new or
  additional rules, policies, terms, or conditions on Your
  use of the Service. Any updates, revisions, supplements,
  modifications, and additional rules, policies, terms,
  and conditions (collectively referred to in this Terms
  of Use as "Revised Terms") will be posted on the Site
  and will be effective immediately after such posting. We
  recommend that you periodically check the Site for the
  Revised Terms Your continued use of the Services will be
  deemed to constitute Your acceptance of any and all such
  Revised Terms. Survival You acknowledge that Your
  representations, undertakings, and warranties and the
  clauses relating to indemnities, limitation of
  liability, grant of license, governing law,
  confidentiality shall survive the efflux of time and the
  termination of these Terms and Conditions. Entire
  Agreement These Terms and Conditions and Privacy Policy
  constitute the entire agreement between You and Shucae
  Films governing Your use of the Services, superseding
  any prior agreements between You and Shucae Films
  regarding such use. Further, Your acceptance of these
  Terms constitutes a valid and binding agreement between
  You and Shucae Films. Should these Terms require to be
  stamped under any applicable stamp duty laws, You should
  bear such stamp duty payable and comply with relevant
  stamping obligations.`;